import React from "react";
import { useParams } from "react-router-dom";

import { Segment, Loader, Header, Message } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import NotValidOption from "components/SiteManage/Content/lib/NotValidOption";
import OptionStatus from "components/lib/OptionStatus";
import UpdateTabelogForm from "components/SiteManage/Content/Tabelog/UpdateTabelogForm";
import UpdateRettyForm from "components/SiteManage/Content/Tabelog/UpdateRettyForm";
import UpdateSubscForm from "components/SiteManage/Content/Tabelog/UpdateSubscForm";

import { tabelogRepository } from "domain/repository";

import { UserContainer, StoreContainer } from "containers";

import { AGENCY_OPTION, StoreIdParam } from "config/raven";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import dayjs from "dayjs";
const AGENCY: AGENCY_OPTION = require("config/agency.json");

const Container: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { currentStore, changeCurrentStore } = StoreContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();
  const { hostname } = window.location;
  const agencyEnabled = AGENCY[hostname].options.tabelog;

  const handleClickEnable = () => {
    if (!storeId) {
      return;
    }
    tabelogRepository
      .enable(isAgency, storeId)
      .then(() => changeCurrentStore(isAgency, storeId));
  };

  if (!agencyEnabled) {
    return <NotValidOption heading="外部サイト連携" />;
  }

  if (!currentStore) {
    return <Loader active />;
  }

  return (
    <>
      <Header content={`外部サイト連携オプション利用状況`} dividing />
      {!isAgency && !currentStore.property.useTabelog && (
        <Message negative content="店舗ユーザはオプションを有効にできません" />
      )}
      <OptionStatus
        isAgency={isAgency}
        enabled={currentStore.property.useTabelog}
        toEnable={handleClickEnable}
      />
      {currentStore.property.useTabelog ? (
        <>
          <Segment>
            <UpdateTabelogForm
              isAgency={isAgency}
              storeId={storeId}
              currentUrl={currentStore.property.tabelogUrl}
              lastSyncedAt={
                currentStore.property.tabelogSyncedAt
                  ? dayjs(currentStore.property.tabelogSyncedAt).format(
                      "YYYY/MM/DD HH:mm:ss"
                    )
                  : "-"
              }
            />
          </Segment>
          <Segment>
            <UpdateRettyForm
              isAgency={isAgency}
              storeId={storeId}
              currentUrl={currentStore.property.rettyUrl}
              lastSyncedAt={
                currentStore.property.rettySyncedAt
                  ? dayjs(currentStore.property.rettySyncedAt).format(
                      "YYYY/MM/DD HH:mm:ss"
                    )
                  : "-"
              }
            />
          </Segment>
          <Segment>
            <UpdateSubscForm
              isAgency={isAgency}
              storeId={storeId}
              currentUrl={currentStore.property.subscUrl}
              lastSyncedAt={
                currentStore.property.subscSyncedAt
                  ? dayjs(currentStore.property.subscSyncedAt).format(
                      "YYYY/MM/DD HH:mm:ss"
                    )
                  : "-"
              }
            />
          </Segment>
        </>
      ) : null}
    </>
  );
};

export const Tabelog = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="外部サイト連携管理"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("外部サイト連携管理")}
    >
      <Segment padded>
        <Container />
      </Segment>
    </PageContainer>
  );
};
