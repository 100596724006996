import React from "react";

import { Icon, Button, Checkbox, Popup } from "semantic-ui-react";

type Props = {
  enabled: boolean;
  toEnable: Function;
  isAgency: boolean;
  useAsEc?: boolean;
};

const OptionTakeoutStatus: React.FC<Props> = ({
  enabled,
  toEnable,
  isAgency,
  useAsEc,
}) => {
  const EnableButton: React.FC = () => {
    if (isAgency) {
      return (
        <Popup
          position="left center"
          hoverable
          wide
          trigger={
            <span css={{ marginLeft: "auto" }}>
              <Button content="有効にする" color="blue" />
            </span>
          }
        >
          <div>
            <p>
              一度有効にすると無効にできません。
              <br />
              有効にしますか？
            </p>
            <div>
              <Checkbox
                checked={useAsEc}
                label="簡易EC機能として利用する"
                onChange={(e, { checked }) => {
                  useAsEc = checked;
                }}
              />
              <div>※注意：注文時に配達先情報が必要となります</div>
            </div>
            <div css={{ textAlign: "center" }}>
              <Button
                content="はい"
                color="blue"
                onClick={() => toEnable(useAsEc)}
              />
            </div>
          </div>
        </Popup>
      );
    }
    return (
      <Popup
        position="left center"
        hoverable
        wide
        trigger={
          <span css={{ marginLeft: "auto" }}>
            <Button content="有効にする" color="blue" disabled />
          </span>
        }
      >
        <div>
          <p>代理店に問い合わせて有効にしてください</p>
        </div>
      </Popup>
    );
  };
  return (
    <div css={{ display: "flex", alignItems: "center", fontSize: "1.5rem" }}>
      <span css={{ marginRight: "0.8rem" }}>
        <Icon name="power off" color={enabled ? "green" : "red"} />
      </span>
      <p css={{ margin: 0 }}>{enabled ? "有効です" : "無効です"}</p>
      {enabled ? null : <EnableButton />}
    </div>
  );
};

export default OptionTakeoutStatus;
