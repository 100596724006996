import { Expose, Type } from "class-transformer";

export class ExternalDnsRecordset {
  @Type(() => Recordset)
  @Expose()
  certificate: Recordset;

  @Type(() => Recordset)
  @Expose()
  publicate: Recordset;
}

class Recordset {
  @Expose() name: string;
  @Expose() value: string;
  @Expose() type: "CNAME" | "ALIAS";
}
