import React from "react";

import { CategoryItem } from "components/lib/subcategory/CategoryItem";

import Category from "domain/model/Category";

import { Table, TableBody } from "semantic-ui-react";

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell css={{ width: "10%" }}>
          {/* index */}
        </Table.HeaderCell>
        <Table.HeaderCell css={{ width: "30%" }}>カテゴリ名</Table.HeaderCell>
        <Table.HeaderCell colSpan="2" css={{ width: "30%" }}>
          カテゴリ値
        </Table.HeaderCell>
        <Table.HeaderCell css={{ width: "10%" }}>作成日</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "10%" }}>
          {/* 編集ボタン */}
        </Table.HeaderCell>
        <Table.HeaderCell css={{ width: "10%" }}>
          {/* 削除ボタン */}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

type Props = {
  categories: Category[];
  editCategoryValue: string | null;
  setEditCategoryValue: React.Dispatch<string>;
  handleUpdateCategory: (
    updateCategoryValue: string,
    updateCategoryName: string
  ) => Promise<any>;
  handleDeleteCategoryButton: (deleteCategoryValue: string) => void;
};

export const CategoryList: React.FC<Props> = ({
  categories,
  editCategoryValue,
  setEditCategoryValue,
  handleUpdateCategory,
  handleDeleteCategoryButton,
}) => {
  return (
    <Table fixed>
      <TableHeader />
      <TableBody>
        {categories.map((category: Category, i: number) => {
          return (
            <CategoryItem
              key={category.categoryValue}
              index={i}
              category={category}
              editCategoryValue={editCategoryValue}
              setEditCategoryValue={setEditCategoryValue}
              handleUpdateCategory={handleUpdateCategory}
              handleDeleteCategoryButton={handleDeleteCategoryButton}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};
