import React, { useState } from "react";
import {
  Message,
  Header,
  Form,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";

import MESSAGE from "config/message.json";
import { restyRepository } from "domain/repository";
import { useRestyStoreProfile } from "hooks/useRestyStoreProfile";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
  setIsRegister: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormValues = {
  groupCode: string;
  shopId: number;
  userId: string;
  password: string;
};

const validationSchema = Yup.object().shape({
  groupCode: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  shopId: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  userId: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  password: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
});

const RestyAuth: React.FC<Props> = ({ isAgency, storeId, setIsRegister }) => {
  const { restyStoreProfile } = useRestyStoreProfile({ isAgency, storeId });

  const [isAuthedOk, setIsAuthedOk] = useState<boolean | null>(null);
  const AuthedMessage = () => {
    if (isAuthedOk === null) {
      return null;
    }
    if (isAuthedOk) {
      return <Message positive content="Restyの登録が完了しました" />;
    }
    return <Message negative content="登録に失敗しました" />;
  };

  const initialGroupCode = restyStoreProfile?.restyGroupCode ?? "";
  const initialShopId = restyStoreProfile?.restyShopId ?? 0;
  const initialUserId = restyStoreProfile?.restyUserId ?? "";

  const initialValues: FormValues = {
    groupCode: initialGroupCode,
    shopId: initialShopId,
    userId: initialUserId,
    password: "",
  };

  return (
    <>
      <Header content="Resty 予約機能情報登録" dividing />
      <Message content="Resty予約機能に必要な情報を入力してください" />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          restyRepository
            .restyAuth({
              isAgency,
              storeId,
              restyGroupCode: values.groupCode,
              restyShopId: values.shopId,
              restyUserId: values.userId,
              restyPassword: values.password,
            })
            .then(() => {
              setIsAuthedOk(true);
            })
            .catch(() => setIsAuthedOk)
            .finally(() => {
              formikHelpers.setSubmitting(false);
            });
        }}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {({ values, handleChange, handleSubmit, errors, isSubmitting }) => (
          <>
            {isSubmitting ? (
              <Dimmer active>
                <Loader active content="登録中" size="large"></Loader>
              </Dimmer>
            ) : null}
            <Form submit={handleSubmit}>
              <Form.Input
                name="groupCode"
                label="グループコード"
                error={errors.groupCode}
                value={values.groupCode}
                onChange={handleChange}
              />
              <Form.Input
                name="shopId"
                label="ショップID"
                error={errors.shopId}
                value={values.shopId}
                onChange={handleChange}
              />
              <Form.Input
                name="userId"
                label="ユーザーID"
                error={errors.userId}
                value={values.userId}
                onChange={handleChange}
              />
              <Form.Input
                type="password"
                name="password"
                label="パスワード"
                error={errors.password}
                value={values.password}
                onChange={handleChange}
              />
              <div css={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  content="登録"
                  color="blue"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={(event) => {
                    handleSubmit();
                    event.preventDefault();
                  }}
                />
              </div>
              <AuthedMessage />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default RestyAuth;
