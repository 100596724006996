import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import DNSRecordSet from "domain/model/DNS/DNSRecordSet";

class Service {
  public async fetchDnsRecordSets(storeId: string): Promise<DNSRecordSet[]> {
    const url = endpoint.agency.dns.recordSet(storeId);
    return api.get(url).then((res) => {
      return res.data.record_sets.map((ele: DNSRecordSet) =>
        plainToClass(DNSRecordSet, ele, { excludeExtraneousValues: true })
      );
    });
  }
}

export const dnsService = new Service();
