import React, { useEffect, useMemo, useState } from "react";
import {
  generatePath,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import { Button, Loader, Message } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { StoreIdParam } from "config/raven";
import routes from "utils/routes";
import Order, { orderStatuses, OrderStatus } from "domain/model/Order";
import OrderSummary from "domain/model/OrderSummary";
import { OrderList } from "components/Order/Index/OrderList";
import { orderService } from "domain/service";
import { StoreContainer, UserContainer } from "containers";
import dayjs, { Dayjs } from "dayjs";
import { SearchForm } from "components/Order/Index/SearchForm";
import { DailySummary } from "components/Order/Index/DailySummary";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import qs from "qs";

export const OrderIndex = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const history = useHistory();
  const { storeId } = useParams<StoreIdParam>();
  const [orders, setOrders] = useState<Order[] | null>(null);
  const [orderSummary, setOrderSummary] = useState<OrderSummary | null>(null);
  const { currentStore } = StoreContainer.useContainer();
  const useTakeout = currentStore?.property?.useTakeout || false;
  const useInvoice = currentStore?.property?.takeoutUseEligibleInvoice || false;
  const location = useLocation();
  const { date, statuses } = qs.parse(location.search.replace(/^\?/, ""));
  const queriedDate = useMemo(() => {
    if (!date) return null;
    if (typeof date !== "string") return null;
    if (date === "now") return dayjs(new Date());
    return dayjs(date);
  }, [date]);
  const [selectedDate, selectDate] = useState<Dayjs | null>(queriedDate);
  const [selectedOrderStatuses, selectOrderStatus] = useState<OrderStatus[]>(
    (Array.isArray(statuses)
      ? statuses
      : Object.keys(orderStatuses)) as OrderStatus[]
  );
  const formatedDate = selectedDate?.format("YYYY-MM-DD") ?? null;

  useEffect(() => {
    selectDate(queriedDate);
    console.log("update date");
  }, [queriedDate, selectDate]);

  useEffect(() => {
    selectOrderStatus(
      (Array.isArray(statuses)
        ? statuses
        : Object.keys(orderStatuses)) as OrderStatus[]
    );
    console.log("update statuses");
    // statuses を直接指定すると無限ループするので JSON.stringify する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(statuses), selectOrderStatus]);

  useEffect(() => {
    orderService
      .fetchAllOrders(isAgency, storeId, formatedDate, selectedOrderStatuses)
      .then((res) => setOrders(res));

    if (formatedDate) {
      orderService
        .fetchOrderSummary(isAgency, storeId, formatedDate)
        .then((res) => setOrderSummary(res));
    }
  }, [isAgency, storeId, formatedDate, selectedOrderStatuses]);

  if (!useTakeout) {
    return <Message content="テイクアウト機能が有効になっていません" />;
  }

  if (orders === null) {
    return <Loader active />;
  }

  return (
    <>
      <PageContainer
        header="受注一覧"
        breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId).build(
          "受注一覧"
        )}
        action={
          <>
            {useInvoice && (
              <>
                <Button
                  color="blue"
                  content="簡易適格請求書[PDF]"
                  onClick={() => {
                    const url = isAgency
                      ? generatePath(routes.agency.orderInvoice.pathname, {
                          storeId,
                        })
                      : routes.store.orderInvoice;
                    history.push(url);
                  }}
                />
              </>
            )}
          </>
        }
      >
        <SearchForm
          selectedDate={selectedDate}
          selectedOrderStatuses={selectedOrderStatuses}
          onSelectDate={(newDate) => {
            history.push(
              `?${qs.stringify({
                date: newDate ? newDate.format("YYYY-MM-DD") : undefined,
                statuses,
              })}`
            );
          }}
          onSelectOrderStatuses={(newStatuses) => {
            history.push(`?${qs.stringify({ date, statuses: newStatuses })}`);
          }}
        />
        {selectedDate && orderSummary && (
          <DailySummary
            isAgency={isAgency}
            storeId={storeId}
            takeoutDate={selectedDate}
            orderSummary={orderSummary}
          />
        )}
        <OrderList
          orders={orders}
          isAgency={isAgency}
          storeId={storeId}
          hasQueriedDate={!!queriedDate}
        />
      </PageContainer>
    </>
  );
};
