import React from "react";
import Form from "domain/model/JobOffer/Form";
import { Card, Button } from "semantic-ui-react";

type Props = {
  form: Form;
  handleClickCopy: () => void;
  handleClickDetailButton: () => void;
};

const JobOfferFormItem: React.FC<Props> = ({
  form,
  handleClickCopy,
  handleClickDetailButton,
}) => {
  return (
    <Card css={{ width: "auto !important" }}>
      <Card.Content>
        <Card.Header textAlign="center">{form.name}</Card.Header>
      </Card.Content>
      <Card.Content extra>
        <div
          css={{
            display: "flex",
            whiteSpace: "nowrap",
          }}
        >
          <div css={{ marginRight: "16px" }}>
            <Button
              basic
              color="green"
              content="HTMLをコピー"
              onClick={handleClickCopy}
            />
          </div>
          <Button
            labelPosition="right"
            icon="right chevron"
            content="フォーム詳細へ"
            onClick={handleClickDetailButton}
          />
        </div>
      </Card.Content>
    </Card>
  );
};

export default JobOfferFormItem;
