import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Segment, Loader, Header, Message } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import NotValidOption from "components/SiteManage/Content/lib/NotValidOption";
import OptionStatus from "components/lib/OptionStatus";

import { sitestRepository } from "domain/repository";

import { UserContainer, StoreContainer } from "containers";

import { AGENCY_OPTION, StoreIdParam } from "config/raven";
import MenuItem from "components/lib/MenuItem";
import { autoLogin } from "utils/sitest";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
const AGENCY: AGENCY_OPTION = require("config/agency.json");

const Container: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { currentStore, changeCurrentStore } = StoreContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const agencyEnabled = AGENCY[window.location.hostname].options.sitest;

  if (!agencyEnabled) {
    return <NotValidOption heading="サイト解析" />;
  }

  if (!currentStore || loading) {
    return <Loader active />;
  }

  const handleClickEnable = () => {
    if (!storeId) {
      return;
    }
    setHasError(false);
    setLoading(true);
    sitestRepository
      .enable(isAgency, storeId)
      .then(() => changeCurrentStore(isAgency, storeId))
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { useSitest, sitestShopId, sitestId } = currentStore.property;

  const handleSitestClick = () => {
    autoLogin(sitestShopId, sitestId);
  };

  return (
    <>
      <Header content="サイト解析オプション利用状況" dividing />
      <OptionStatus
        enabled={useSitest}
        toEnable={handleClickEnable}
        isAgency={isAgency}
      />
      {hasError && (
        <Message
          error
          content="サイト解析機能の有効化及びアカウントの発行に失敗しました。サイト解析画面を利用できない場合は、運営にお問い合わせください。"
        />
      )}
      {useSitest && sitestShopId && sitestId ? (
        <>
          <Header content="サイト解析ページ" dividing />
          <MenuItem
            header="サイト解析へ"
            content="外部リンク"
            onClick={handleSitestClick}
          />
        </>
      ) : null}
    </>
  );
};

const Sitest = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="サイト解析管理"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("サイト解析管理")}
    >
      <Segment padded>
        <Container />
      </Segment>
    </PageContainer>
  );
};

export default Sitest;
