import React from "react";
import { useParams } from "react-router-dom";

import { UserContainer } from "containers";
import { versionRepository } from "domain/repository";

import PageContainer from "components/lib/PageContainer";
import { VersionNewForm } from "components/Version/New/VersionNewForm";

import { StoreIdParam } from "config/raven";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const VersionNew = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  const createVersion = (file: File, title?: string) =>
    versionRepository.createVersion(isAgency, storeId, file, title);

  return (
    <PageContainer
      header="バージョン追加"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .versionPage()
        .build("バージョン追加")}
    >
      <VersionNewForm createVersion={createVersion} />
    </PageContainer>
  );
};
