import { useEffect, useState } from "react";
import { articleService } from "domain/service";

/**
 * 記事管理のテンプレート文字列を取得する
 * template: テンプレートの文字列
 */
export const useArticleTemplate = ({
  isAgency,
  storeId,
}: {
  isAgency: boolean;
  storeId: string | undefined;
}) => {
  const [template, setTemplate] = useState<string | null>(null);
  useEffect(() => {
    const fetchTemplate = () => {
      articleService
        .fetchArticleTemplate(isAgency, storeId)
        .then((template) => {
          if (template === null) {
            setTemplate(null);
          } else {
            setTemplate(template.content);
          }
        });
    };
    fetchTemplate();
  }, [isAgency, storeId]);
  return { template };
};
