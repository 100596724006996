import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Loader, Dimmer } from "semantic-ui-react";
import { UserContainer } from "containers";

import routes from "utils/routes";

type Props = {
  path: string;
  component: React.FC<any>;
};

const PrivateRoute: React.FC<Props> = ({ path, component, ...props }) => {
  const userContainer = UserContainer.useContainer();
  const { currentUser, userToken } = userContainer;

  const redirectUrl = routes.common.signInAgency.pathname;

  if (!userToken) {
    return <Redirect to={redirectUrl} />;
  }

  return !currentUser ? (
    <Dimmer active inverted>
      <Loader content="ユーザ情報取得中..." />
    </Dimmer>
  ) : (
    <Route {...props} path={path} component={component} exact />
  );
};

export default PrivateRoute;
