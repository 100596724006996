import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import BusinessDayType from "domain/model/BusinessDayType";

class BusinessDayTypeService {
  async fetchAllBusinessDayTypes(
    isAgency: boolean,
    storeId: string | undefined
  ): Promise<BusinessDayType[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.businessDayType.root(storeId)
        : endpoint.store.businessDayType.root();

    return api.get(url).then((res) => {
      return res.data.business_day_types.map((row: BusinessDayType) =>
        plainToClass(BusinessDayType, row, { excludeExtraneousValues: true })
      );
    });
  }
}

export default new BusinessDayTypeService();
