import { Expose } from "class-transformer";

export default class OrderSummary {
  @Expose({ name: "total_price" }) totalPrice: number;
  @Expose({ name: "total_price_of_store_payment" })
  totalPriceOfStorePayment: number;
  @Expose({ name: "total_price_of_card_payment" })
  totalPriceOfCardPayment: number;
  @Expose({ name: "ordered_products_count" }) orderedProductsCount: number;
  @Expose({ name: "orders_count" }) ordersCount: number;
}
