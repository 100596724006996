import React from "react";
import AceEditor from "react-ace";

// mode
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-css";

type Props = {
  source: string;
  fileType: string;
  handleChange: (content: string) => void;
  height?: string;
  readOnly?: boolean;
};

export const SourceCodeEditor: React.FC<Props> = ({
  source,
  fileType,
  handleChange,
  height = "700px",
  readOnly = false,
}) => {
  const mode = () => {
    switch (fileType) {
      case "html":
        return "html";
      case "css" || "scss" || "sass":
        // sassは利用者が多いならスタイルをimportしても良さそう
        return "css";
      case "js":
        return "javascript";
      default:
        return "html";
    }
  };
  return (
    <AceEditor
      mode={mode()}
      theme="textmate"
      tabSize={2}
      value={source}
      width="100%"
      height={height}
      onChange={handleChange}
      readOnly={readOnly}
    />
  );
};
