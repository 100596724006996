import api from "utils/api";
import endpoint from "utils/endpoint";

class FaviconRepository {
  // create だけどupdateみたいな動きだからupdateにしてる
  async updateFavicon(storeId: string, file: File) {
    const formData = new FormData();
    formData.append("favicon[file]", file);

    return api.post(endpoint.agency.favicon.update(storeId), formData);
  }
}

export default new FaviconRepository();
