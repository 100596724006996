import api from "utils/api";
import endpoint from "utils/endpoint";

import Staff from "domain/model/Staff";
import { plainToClass } from "class-transformer";

class StaffService {
  async fetchAllStaff() {
    const url = endpoint.agency.staff.root();

    return api.get(url).then((res) => {
      return res.data.map((staff: Staff, index: number) =>
        plainToClass(Staff, staff, { excludeExtraneousValues: true })
      );
    });
  }
}

export default new StaffService();
