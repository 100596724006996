import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Message } from "semantic-ui-react";
import { acceptFileType, validFileSize } from "utils/media";

type MessageType = {
  type: "success" | "error";
  text: string;
};

type Props = {
  uploadMedia: (file: File) => Promise<void>;
  onUploaded: () => void;
};

export const MediaUploadTab: React.FC<Props> = ({
  uploadMedia,
  onUploaded,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [message, setMessage] = useState<MessageType | null>(null);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(([acceptedFile]: File[]) => {
    setMessage(null);
    if (!validFileSize(acceptedFile)) {
      setMessage({ type: "error", text: "ファイルサイズが大きすぎます。" });
    } else {
      setFile(acceptedFile);
    }
  }, []);
  const handleClickUpload = async () => {
    if (file) {
      try {
        setLoading(true);
        await uploadMedia(file);
        setMessage({ type: "success", text: "アップデートしました" });
        onUploaded();
      } catch {
        setMessage({ type: "error", text: "アップデートに失敗しました" });
      } finally {
        setFile(null);
        setLoading(false);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptFileType,
  });

  const Description = () => {
    if (file) {
      return <p>ファイル名: {file.name}</p>;
    }
    if (isDragActive) {
      return <p>ここにファイルをドラッグしてください</p>;
    }
    return <p>ファイルをドラッグするか、クリックしてください</p>;
  };

  return (
    <div>
      <div css={{ display: "flex", justifyContent: "space-between" }}>
        <div {...getRootProps()} css={{ flexGrow: 1 }}>
          <input {...getInputProps()} />
          <Description />
          <p>(ファイルサイズ： 10MB以下, ファイル形式：jpg, jpeg, png, gif)</p>
        </div>
        <Button
          primary
          disabled={file === null}
          onClick={handleClickUpload}
          loading={loading}
        >
          アップロード
        </Button>
      </div>
      {message && (
        <Message
          success={message.type === "success"}
          error={message.type === "error"}
          content={message.text}
        />
      )}
    </div>
  );
};
