import React from "react";
import { Loader as SemanticUILoader, LoaderProps } from "semantic-ui-react";

type Props = LoaderProps;
/**
 * Ravenで使いやすいようにスタイルを上書きしたLoader
 * Confirmより高いz-indexで上書き & Viewportで中央に表示されるように
 */
export const RavenLoader: React.FC<Props> = (props) => {
  return (
    <SemanticUILoader
      {...props}
      css={{
        zIndex: "2500!important" as any,
        position: "fixed!important" as "fixed",
      }}
    />
  );
};
