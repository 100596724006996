import { Type, Expose, Transform } from "class-transformer";
import dayjs from "dayjs";
import { SeoPreferences } from "domain/model/SeoPreferences";

export default class Post {
  @Expose() title: string;
  @Expose() subcategory: string | null;
  @Expose() slug: string;
  @Type(() => Metadata)
  @Expose()
  metadata: Metadata;

  @Expose({ name: "is_published" })
  // isPublishedは途中から実装されたパラメータなので、nullのケースがある。その場合は公開済み。
  @Transform((value) => (value === null ? true : value))
  isPublished: boolean;

  @Expose({ name: "created_at" })
  createdAtStr: string;
  @Expose({ name: "created_at" })
  get createdAt() {
    return dayjs(Number(this.createdAtStr) * 1000).format("YYYY/MM/DD HH:mm");
  }
}

class Metadata {
  @Expose({ name: "thumbnail_url" }) thumbnailUrl: string | null;

  @Type(() => SeoPreferences)
  @Expose({ name: "seo_tags" })
  seoPreferences: SeoPreferences;
}
