import React from "react";

import PageContainer from "components/lib/PageContainer";

import { StaffNewForm } from "components/AgencyStaff/New/StaffNewForm";

export const AgencyStaffNew: React.FC = () => {
  return (
    <PageContainer header="スタッフ作成">
      <StaffNewForm />
    </PageContainer>
  );
};
