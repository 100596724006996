import React from "react";
import Dropzone from "react-dropzone";

import { Formik } from "formik";
import * as Yup from "yup";

import { Segment, Button, Header, Message, Input } from "semantic-ui-react";

const acceptFileType = ["application/zip"];
const acceptFileTypeDisplay = ["zip"];
const warningMessages = [
  "ソースファイルを圧縮したzipファイルをアップロードしてください",
  "zipファイル中には必ずindex.htmlが含まれている必要があります",
  "前バージョンと全く同じ内容のファイルをアップロードすることはできません",
  "アップロードしたzipファイルは新規バージョンとしてバージョン一覧に追加されます",
  <strong>
    ZIPファイルが100MBを超える場合、処理時間の関係でアップロード/ダウンロードに失敗する場合があります。使用している画像ファイルサイズは適切か、不要なファイルをUPしていないか、など確認の上アップロードをお願いします
  </strong>,
  <span>
    上記に沿ってファイルのアップロードができない場合は、下記フォームよりご連絡ください。代行してファイルのアップロード対応いたします。
    <a href="https://tayori.com/form/20113a6393d32e70f1ac719ae2a30637d65b2daa">
      https://tayori.com/form/20113a6393d32e70f1ac719ae2a30637d65b2daa
    </a>
  </span>,
];

const validationSchema = Yup.object().shape({
  files: Yup.array().max(
    1,
    "複数のファイルを同時にアップロードすることはできません。"
  ),
  title: Yup.string(),
});

type Props = {
  createVersion: (file: File, title?: string) => Promise<void>;
};
export const VersionNewForm: React.FC<Props> = ({ createVersion }) => {
  return (
    <Segment>
      <Header content="アップロードファイルについて" />
      <ul style={{ marginLeft: "1.5rem" }}>
        {warningMessages.map((message, i) => (
          <li key={i}>{message}</li>
        ))}
      </ul>
      <Formik
        initialValues={{
          files: [],
          title: "",
        }}
        initialStatus={{
          success: "",
          error: "",
        }}
        onSubmit={(values, actions) => {
          createVersion(values.files[0], values.title)
            .then(() => {
              actions.setStatus({
                success: "ファイルアップロードが完了しました。",
              });
            })
            .catch(() => {
              actions.setStatus({
                error:
                  "ファイルアップロードに失敗しました。「アップロードファイルについて」を確認後再度お試しください。",
              });
            })
            .finally(() => actions.setSubmitting(false));
        }}
        validationSchema={validationSchema}
      >
        {({
          status,
          setFieldValue,
          values,
          handleSubmit,
          errors,
          handleChange,
          setStatus,
          isSubmitting,
        }) => (
          <>
            <Message
              success={values.files.length === 1 && !errors.files}
              negative={values.files.length !== 1 || !!errors.files}
            >
              <Message.Header content="選択中のファイル" />
              <Message.Content
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <ul style={{ margin: "0.5rem 0 0 1rem" }}>
                  {values.files.length === 0 ? (
                    <li>ファイルが選択されていません</li>
                  ) : (
                    values.files.map((file: File, i) => (
                      <li key={i}>{file.name}</li>
                    ))
                  )}
                </ul>
                <Button
                  content="選択解除"
                  color="red"
                  onClick={() => setFieldValue("files", [])}
                  disabled={values.files.length === 0}
                  style={{ alignSelf: "flex-end" }}
                />
              </Message.Content>
            </Message>
            {status.success && <Message success content={status.success} />}
            {status.error && <Message error content={status.error} />}
            {errors.files && <Message error content={errors.files} />}
            <Dropzone
              onDrop={(acceptedFiles) => {
                setFieldValue("files", acceptedFiles);
                setStatus({ success: "", error: "" });
              }}
            >
              {({ getInputProps, getRootProps }) => (
                <div {...getRootProps()}>
                  <Segment
                    placeholder
                    loading={isSubmitting}
                    style={{ marginBottom: "1rem" }}
                  >
                    <Header
                      textAlign="center"
                      content="ここにファイルをドロップ"
                      as="h5"
                      color="grey"
                    />
                    <input
                      {...getInputProps()}
                      type="file"
                      accept={acceptFileType.join(",")}
                    />
                    <Button
                      content="ファイルを選択"
                      color="blue"
                      style={{ marginBottom: "1rem" }}
                    />
                    <p style={{ textAlign: "center" }}>
                      ファイル形式：
                      {acceptFileTypeDisplay.join(", ")}
                    </p>
                  </Segment>
                </div>
              )}
            </Dropzone>
            <div style={{ textAlign: "center" }}>
              <Input
                fluid
                name="title"
                value={values.title}
                onChange={handleChange}
                error={!!errors.title}
                placeholder="バージョンタイトル（変更の概要や修正内容など）"
                style={{ marginBottom: "1rem" }}
              />
              <Button
                color="blue"
                content="アップロード"
                onClick={() => handleSubmit()}
                disabled={
                  !!(
                    values.files.length === 0 ||
                    errors.files ||
                    isSubmitting ||
                    status.success
                  )
                }
              />
            </div>
          </>
        )}
      </Formik>
    </Segment>
  );
};
