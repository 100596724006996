import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Message, Segment } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { StoreIdParam } from "config/raven";
import { StoreContainer, UserContainer } from "containers";
import { productService } from "domain/service";
import dayjs from "dayjs";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

type DownloadEvent = "downloadProductsCsv" | "downloadProductCategoriesCsv";

export const ProductExport = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const history = useHistory();
  const { storeId } = useParams<StoreIdParam>();
  const [currentEvent, setCurrentEvent] = useState<DownloadEvent | null>(null);
  const { currentStore } = StoreContainer.useContainer();
  const useTakeout = currentStore?.property?.useTakeout || false;
  const download = (downloadEvent: DownloadEvent) => async () => {
    setCurrentEvent(downloadEvent);
    const csv = await productService[downloadEvent](isAgency, storeId).catch(
      () => null
    );

    if (!csv) {
      setCurrentEvent(null);
      return;
    }

    const url = URL.createObjectURL(new Blob([csv]));
    const link = document.createElement("a");
    const filename =
      downloadEvent === "downloadProductsCsv" ? "商品データ" : "カテゴリデータ";
    link.href = url;
    link.setAttribute(
      "download",
      `${dayjs().format("YYYY-MM-DD")} ${filename}.csv`
    );
    document.body.appendChild(link);
    link.click();
    setCurrentEvent(null);
  };

  if (!useTakeout) {
    return <Message content="テイクアウト機能が有効になっていません" />;
  }

  return (
    <PageContainer
      header="商品エクスポート"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .productPage()
        .build("商品エクスポート")}
    >
      <Segment>
        <Segment>
          <p>
            全ての商品データを含むCSVファイルを作成して、ダウンロード出来ます。
            ※商品の画像ファイルが必要な場合については「商品詳細」をご利用ください
          </p>
          <Button
            color="blue"
            onClick={download("downloadProductsCsv")}
            loading={currentEvent === "downloadProductsCsv"}
          >
            商品データをダウンロード
          </Button>
        </Segment>

        <Segment>
          <p>
            全てのカテゴリーデータを含むCSVファイルを作成して、ダウンロード出来ます。
          </p>
          <Button
            color="blue"
            onClick={download("downloadProductCategoriesCsv")}
            loading={currentEvent === "downloadProductCategoriesCsv"}
          >
            カテゴリーデータをダウンロード
          </Button>
        </Segment>
      </Segment>
    </PageContainer>
  );
};
