import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { versionService } from "domain/service";
import { StoreContainer, UserContainer } from "containers";

import { Loader, Segment, Header, Message, Tab } from "semantic-ui-react";
import { StoreIdParam } from "config/raven";
import { versionRepository } from "domain/repository";
// import { FullHtmlWysiwygEditorV1 } from "components/Version/Edit/v1/FullHtmlWysiwygEditorV1";
import { VersionFullHtmlWysiwygEditor } from "components/Version/Edit/VersionFullHtmlWysiwygEditor";
import { useAllMediaUrls } from "hooks/useAllMediaUrls";
import { getPreviewUrl } from "utils/version";
import { VersionSourceCodeEditor } from "components/Version/Edit/VersionSourceCodeEditor";
import { FullHtmlWysiwygEditorV1 } from "components/Version/Edit/v1/FullHtmlWysiwygEditorV1";
import { AGENCY_OPTION } from "config/raven";

const AGENCY: AGENCY_OPTION = require("config/agency.json");
const { hostname } = window.location;
const wysiwygV1Enabled = AGENCY[hostname].options.wysiwygV1;

const getBodyAttr = (source: string): { id: string; className: string } => {
  const domParser = new DOMParser();
  const htmlDocument = domParser.parseFromString(source, "text/html");
  const body = htmlDocument.getElementsByTagName("body")[0];
  const id = body.id;
  const className = body.className;
  return { id, className };
};

const enableWysiwygMode = (fileType: string): boolean => {
  return fileType === "html";
};

type Props = {
  objectHash: string;
  versionHash: string;
  filepath: string;
  fileType: string;
};

export const Container: React.FC<Props> = ({
  objectHash,
  versionHash,
  filepath,
  fileType,
}) => {
  const { isAgency } = UserContainer.useContainer();
  const { currentStore, changeCurrentStore } = StoreContainer.useContainer();

  const [source, setSource] = useState<string | null>(null);
  const [body, setBody] = useState<{ id: string; className: string } | null>(
    null
  );
  const { storeId } = useParams<StoreIdParam>();
  const { mediaUrls, refetchMediaUrls } = useAllMediaUrls({
    isAgency,
    storeId,
    imageOnly: true,
  });

  const [status, setStatus] = useState({ success: false, error: false });
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    versionService
      .fetchSourceFile(isAgency, storeId, objectHash)
      .then((res) => {
        setSource(res);
        setBody(getBodyAttr(res));
      });
  }, [isAgency, objectHash, storeId]);

  const submitVersion = (
    title: string,
    editedSource: string,
    deployment: boolean // 保存時に公開するかのフラグ
  ) => {
    if (editedSource !== null) {
      setStatus({ success: false, error: false });
      setSubmitting(true);
      versionRepository
        .updateSourceFile(
          isAgency,
          storeId,
          {
            source: editedSource,
            title,
            versionHash: versionHash,
            filepath,
          },
          deployment
        )
        .then(() => {
          setStatus({ success: true, error: false });
          // head_versionを更新する必要があるので、Store情報を更新
          changeCurrentStore(isAgency, storeId);
        })
        .catch(() => {
          setStatus({ success: false, error: true });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  if (source === null || currentStore === null || body === null) {
    return <Loader active content="読み込み中..." />;
  }

  const wysiwygPane = {
    menuItem: "見たまま編集",
    render: () => (
      <Tab.Pane attached={false}>
        <Header content={filepath} attached="top" block />
        <VersionFullHtmlWysiwygEditor
          source={source}
          onChange={(value) => {
            setSource(value);
          }}
          mediaUrls={mediaUrls ?? []}
          refetchMediaUrls={refetchMediaUrls}
          body={body}
          documentBaseUrl={getPreviewUrl(
            versionHash,
            currentStore.id,
            currentStore.awsResource.s3Bucket
          )}
          submit={(title, deployment) => {
            submitVersion(title, source, deployment);
          }}
          isSubmitting={isSubmitting}
        />
      </Tab.Pane>
    ),
  };

  const wysiwygV1Pane = {
    menuItem: "見たまま編集(旧)",
    render: () => (
      <Tab.Pane attached={false}>
        <Header content={filepath} attached="top" block />
        <FullHtmlWysiwygEditorV1
          source={source}
          mediaUrls={mediaUrls ?? []}
          documentBaseUrl={getPreviewUrl(
            versionHash,
            currentStore.id,
            currentStore.awsResource.s3Bucket
          )}
          submit={(title, editedHtml, deployment) => {
            submitVersion(title, editedHtml, deployment);
          }}
        />
      </Tab.Pane>
    ),
  };

  const sourcePane = {
    menuItem: "ソースコード編集",
    render: () => (
      <Tab.Pane attached={false}>
        <Header content={filepath} attached="top" block />
        <VersionSourceCodeEditor
          handleChange={(newContent) => {
            setSource(newContent);
          }}
          source={source}
          fileType={fileType}
          submit={(title, deployment) => {
            submitVersion(title, source, deployment);
          }}
          isSubmitting={isSubmitting}
        />
      </Tab.Pane>
    ),
  };

  return (
    <>
      {status.success && <Message success content="変更に成功しました" />}
      {status.error && <Message error content="変更に失敗しました" />}
      <Segment>
        {enableWysiwygMode(fileType) && (
          <Message content="見たまま編集モードでは公開されているサイトを確認しながらテキストを直接編集、リンク先URLの編集を行うことができます。" />
        )}
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={
            enableWysiwygMode(fileType)
              ? [
                  wysiwygPane,
                  ...(wysiwygV1Enabled ? [wysiwygV1Pane] : []),
                  sourcePane,
                ]
              : [sourcePane]
          }
        />
      </Segment>
    </>
  );
};
