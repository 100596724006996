import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import Post from "domain/model/Post";
import Category from "domain/model/Category";
import Template from "domain/model/Template";

class NewsService {
  fetchEditNews(
    isAgency: boolean,
    storeId: string | undefined,
    filePath: string
  ): Promise<{ news: Post; content: string | null }> {
    const url =
      isAgency && storeId
        ? endpoint.agency.news.edit(storeId, filePath)
        : endpoint.store.news.edit(filePath);
    return api.get(url).then((res) => {
      return {
        news: plainToClass(Post, res.data.context.post, {
          excludeExtraneousValues: true,
        }),
        content: res.data.context.content,
      };
    });
  }

  async fetchAllNews(
    isAgency: boolean,
    storeId: string | undefined,
    createdAt?: string
  ): Promise<Post[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.news.root(storeId)
        : endpoint.store.news.root();
    return api
      .get(url, { params: { created_at: createdAt } })
      .then((res) =>
        res.data.news.map((d: Post) =>
          plainToClass(Post, d, { excludeExtraneousValues: true })
        )
      );
  }

  fetchNewsTemplate(
    isAgency: boolean,
    storeId: string | undefined
  ): Promise<Template | null> {
    const url =
      isAgency && storeId
        ? endpoint.agency.news.template(storeId)
        : endpoint.store.news.template();
    return api.get(url).then((res) =>
      plainToClass(Template, res.data.news_template, {
        excludeExtraneousValues: true,
      })
    );
  }

  fetchCategory(
    isAgency: boolean,
    storeId: string | undefined
  ): Promise<Category[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.subcategory.news(storeId)
        : endpoint.store.subcategory.news();
    return api.get(url).then((res) => {
      return res.data
        .sort(
          (
            a: { data: { data: { created_at: string } } },
            b: { data: { data: { created_at: string } } }
          ) => {
            const pre: number = parseInt(a.data.data.created_at);
            const cur: number = parseInt(b.data.data.created_at);
            return pre < cur ? 1 : -1;
          }
        )
        .map((d: { data: { data: Category[] } }) => {
          return plainToClass(Category, d.data.data, {
            excludeExtraneousValues: true,
          });
        });
    });
  }
}

export default new NewsService();
