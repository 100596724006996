import React from "react";
import PageContainer from "components/lib/PageContainer";
import { Segment } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { StoreIdParam } from "config/raven";
import { RegisterExternalDomainContainer } from "components/SiteManage/Content/Domain/ExternalDomain/RegisterExternalDomainContainer";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { UserContainer } from "containers";

export const ExternalDomainRegist: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="外部で取得済みのドメインを登録"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .domainPage()
        .build("外部で取得済みのドメインを登録")}
    >
      <Segment>
        <RegisterExternalDomainContainer storeId={storeId} />
      </Segment>
    </PageContainer>
  );
};
