import React from "react";
import { useParams } from "react-router-dom";

import { Segment } from "semantic-ui-react";

import PageContainer from "components/lib/PageContainer";

import NotValidOption from "components/SiteManage/Content/lib/NotValidOption";

import { UserContainer } from "containers";

import { AGENCY_OPTION, StoreIdParam } from "config/raven";

import { TakeoutSalesContainer } from "components/SalesManagement/TakeoutSales/TakeoutSalesContainer";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

const AGENCY: AGENCY_OPTION = require("config/agency.json");

const Container: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();

  const { storeId } = useParams<StoreIdParam>();
  const { hostname } = window.location;
  const agencyEnabled = AGENCY[hostname].options.externalTakeout;

  if (!agencyEnabled) {
    return <NotValidOption heading="売上一元管理　同期" />;
  }

  return (
    <>
      <Segment padded>
        <TakeoutSalesContainer isAgency={isAgency} storeId={storeId} />
      </Segment>
    </>
  );
};

export const TakeoutSales = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="データ同期"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .salesManagementPage()
        .build("データ同期")}
    >
      <Container />
    </PageContainer>
  );
};
