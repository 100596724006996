import React from "react";
import { useHistory, generatePath } from "react-router-dom";
import { ProductItem } from "components/Product/Index/ProductItem";
import routes from "utils/routes";
import { Message, Table } from "semantic-ui-react";
import Product from "domain/model/Product";

type Props = {
  products: Product[];
  isAgency: boolean;
  storeId: string;
};

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>商品コード</Table.HeaderCell>
        <Table.HeaderCell>商品名</Table.HeaderCell>
        <Table.HeaderCell>公開/非公開</Table.HeaderCell>
        <Table.HeaderCell>カテゴリー</Table.HeaderCell>
        <Table.HeaderCell>販売価格（税込）</Table.HeaderCell>
        <Table.HeaderCell>商品説明文</Table.HeaderCell>
        <Table.HeaderCell>{/* 編集ボタン */}</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export const ProductList: React.FC<Props> = ({
  isAgency,
  products,
  storeId,
}) => {
  const history = useHistory();

  if (products.length === 0) {
    return <Message content="商品情報がありません" />;
  }

  return (
    <>
      <Table fixed>
        <TableHeader />
        <Table.Body>
          {products.map((product, i) => {
            const handleEdit = () => {
              const editUrl = isAgency
                ? generatePath(routes.agency.productEdit.pathname, {
                    storeId,
                    id: product.id,
                  })
                : generatePath(routes.store.productEdit.pathname, {
                    id: product.id,
                  });
              history.push(editUrl);
            };
            return (
              <ProductItem
                product={product}
                handleClickEdit={handleEdit}
                index={i}
                key={product.id}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};
