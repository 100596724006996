import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import { SeoPreferences } from "domain/model/SeoPreferences";

class SeoService {
  async fetchPreferences(isAgency: boolean, storeId: string | undefined) {
    const url =
      isAgency && storeId
        ? endpoint.agency.seo.root(storeId)
        : endpoint.store.seo.root();
    return api.get(url).then((res) =>
      plainToClass(SeoPreferences, res.data, {
        excludeExtraneousValues: true,
      })
    );
  }
}

export const seoService = new SeoService();
