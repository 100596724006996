import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Message,
} from "semantic-ui-react";
import MESSAGE from "config/message.json";
import { generatePath, useHistory } from "react-router-dom";
import routes from "utils/routes";
import { Formik } from "formik";
import * as Yup from "yup";
import { yamlRepository } from "domain/repository";
import { yamlService } from "domain/service";
import Yaml from "domain/model/Yaml";
import { RavenLoader } from "components/lib/RavenLoader";

type Props = {
  isAgency: boolean;
  storeId: string;
  id: string;
};

export const EditFormContainer: React.FC<Props> = ({
  isAgency,
  storeId,
  id,
}) => {
  const validationSchema = Yup.object().shape({
    body: Yup.string().min(1, "必須項目です").required(),
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const yamlIndexUrl = isAgency
    ? generatePath(routes.agency.yamlIndex.pathname, {
        storeId,
      })
    : routes.store.yamlIndex.pathname;
  const history = useHistory();
  const [yaml, setYaml] = useState<Yaml | null>(null);

  useEffect(() => {
    const fetchEditArticle = () => {
      yamlService.fetchYaml(isAgency, storeId, id).then((res) => {
        setYaml(res.yaml);
      });
    };
    fetchEditArticle();
  }, [isAgency, storeId, id]);

  if (yaml === null) {
    return <RavenLoader active />;
  }

  const jsonUrl = yaml.public_url.replace(/\.yml$/, ".json");

  return (
    <Formik
      initialValues={{
        body: yaml.body,
        active: yaml.active,
      }}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
      onSubmit={(
        { body, active },
        { setFieldValue, setFieldError, resetForm, setSubmitting }
      ) => {
        setErrorMessage(null);

        yamlRepository
          .updateYaml(isAgency, storeId, id, {
            body,
            active,
          })
          .then(() => {
            resetForm();
            history.push(yamlIndexUrl);
          })
          .catch((e) => {
            console.error();
            setErrorMessage(
              `${MESSAGE.ERROR.YAML.EDIT_ERROR} (${
                e?.response?.data?.message || e
              })`
            );
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        isSubmitting,
        errors,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div
            css={{
              margin: "16px 0",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              css={{ margin: "0 16px 0 0 !important" }}
              positive
              icon="pencil alternate"
              content="下書き保存"
              onClick={() => {
                setFieldValue("active", false);
              }}
              type="submit"
              loading={isSubmitting}
            />
            <Button
              positive
              icon="paper plane"
              content="公開"
              onClick={() => {
                setFieldValue("active", true);
              }}
              type="submit"
              loading={isSubmitting}
            />
          </div>
          {errorMessage ? (
            <Message negative content={errorMessage} size="small" />
          ) : null}

          <>
            <Message>
              タイトル・スラグ・親YAML・データ構造は変更できません。
            </Message>
            <Form.Field required>
              <label>タイトル</label>
              {yaml.title}
            </Form.Field>
            <Form.Field required>
              <label>スラグ</label>
              {yaml.slug}
            </Form.Field>
            <Form.Field required>
              <label>URL</label>
              <a
                href={yaml.public_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {yaml.public_url}
              </a>
              <br />
              <a href={jsonUrl} target="_blank" rel="noopener noreferrer">
                {jsonUrl}
              </a>
            </Form.Field>
            <Form.Field>
              <label>親YAML</label>
              <a href={yaml.parent} target="_blank" rel="noopener noreferrer">
                {yaml.parent}
              </a>
            </Form.Field>
            <Divider horizontal css={{ margin: "2rem 0!important" }}>
              <Header as="h4">
                <Icon name="file alternate" />
                YAMLデータ
              </Header>
            </Divider>
            <Form.Field name="content">
              <Form.TextArea
                name="body"
                error={errors && errors.body}
                value={values.body}
                onChange={handleChange}
                rows={20}
                spellCheck={false}
              />
            </Form.Field>
          </>
        </Form>
      )}
    </Formik>
  );
};
