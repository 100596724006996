import React, { useCallback, useEffect } from "react";
import { Button, Card } from "semantic-ui-react";

export type OptionSelect = {
  name: string;
  options: {
    label: string;
    price: number;
  }[];
};

type Props = {
  value: OptionSelect[] | null;
  onChange: (value: OptionSelect[]) => void;
};

export const OptionForm = ({ value, onChange }: Props) => {
  const addSelect = useCallback(() => {
    onChange([
      ...value!,
      {
        name: "",
        options: [
          {
            label: "",
            price: 0,
          },
          {
            label: "",
            price: 0,
          },
        ],
      },
    ]);
  }, [onChange, value]);

  const addRow = useCallback(
    (targetIndex: number) => {
      const newValue = value!.map((select, index) => {
        if (targetIndex === index) {
          return {
            ...select,
            options: [
              ...select.options,
              // ↓を追加
              { label: "", price: 0 },
            ],
          };
        }
        return select;
      });
      onChange(newValue);
    },
    [onChange, value]
  );

  const setSelectName = useCallback(
    (name, targetIndex) => {
      const newValue = value!.map((select, index) => {
        if (targetIndex === index) {
          return {
            ...select,
            name,
          };
        }
        return select;
      });
      onChange(newValue);
    },
    [onChange, value]
  );

  const updateOption = useCallback(
    (label, price, targetSelectIndex, targetOptionIndex) => {
      const newValue = value!.map((select, index) => {
        if (targetSelectIndex === index) {
          return {
            ...select,
            options: select.options.map((i, index) => {
              if (targetOptionIndex === index)
                return { label, price: parseInt(price) || 0 };
              else return i;
            }),
          };
        }
        return select;
      });
      onChange(newValue);
    },
    [onChange, value]
  );

  // 保存済みの値がない場合、初期値セット
  useEffect(() => {
    if (value) return;
    onChange([
      {
        name: "",
        options: [
          { label: "", price: 0 },
          { label: "", price: 0 },
        ],
      },
    ]);
  }, [onChange, value]);

  // valueが空の場合はuseEffectで初期値がセットされるが、それまでレンダリングをしないようにする
  if (!value) return null;

  return (
    <Card css={{ width: "auto !important", padding: "20px" }}>
      {value.map((select, i) => (
        <div css={{ padding: "20px", borderBottom: "1px solid #ccc" }} key={i}>
          <div css={{ paddingBottom: "10px" }}>
            <label css={{ fontWeight: "bold" }}>
              オプション名:
              <input
                type="text"
                value={select.name}
                onChange={(e) => setSelectName(e.target.value, i)}
                placeholder="未入力の場合、オプションが削除されます"
              />
            </label>
          </div>
          <div>
            {select.options.map((option, j) => (
              <div
                key={j}
                css={{
                  display: "flex",
                  paddingBottom: "10px",
                }}
              >
                <label
                  css={{ flex: 1, paddingRight: "5px", fontWeight: "bold" }}
                >
                  項目名:{" "}
                  <input
                    type="text"
                    value={option.label}
                    onChange={(e) =>
                      updateOption(e.target.value, option.price, i, j)
                    }
                    placeholder="未入力の場合、選択肢が削除されます"
                  />
                </label>
                <label css={{ flex: 1, fontWeight: "bold" }}>
                  金額:
                  <input
                    // numberはマイナスが入力しにくくなるのでtextにしておく
                    type="text"
                    value={option.price}
                    onChange={(e) =>
                      updateOption(option.label, e.target.value, i, j)
                    }
                  />
                </label>
              </div>
            ))}
            <Button
              content="選択肢追加"
              onClick={(e) => {
                e.preventDefault();
                addRow(i);
              }}
            />
          </div>
        </div>
      ))}
      <Button
        content="オプション追加"
        onClick={(e) => {
          e.preventDefault();
          addSelect();
        }}
      />
    </Card>
  );
};
