import React from "react";
import { Button } from "semantic-ui-react";

type Props = {
  handleReadMore: () => void;
  loading: boolean;
};

const ReadMoreButton: React.FC<Props> = ({ handleReadMore, loading }) => {
  return (
    <Button
      content="さらに読み込む"
      color="blue"
      icon="search"
      onClick={handleReadMore}
      loading={loading}
    ></Button>
  );
};

export default ReadMoreButton;
