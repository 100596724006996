import React from "react";
import { Icon } from "semantic-ui-react";
import { StoreContainer } from "containers";
import { autoLogin } from "utils/sitest";
import { AGENCY_OPTION } from "config/raven";
import COLOR from "config/color.json";
const AGENCY: AGENCY_OPTION = require("config/agency.json");

const ShortcutMenu: React.FC = () => {
  const { currentStore } = StoreContainer.useContainer();

  if (!currentStore) {
    return null;
  }

  const { useSitest, sitestId, sitestShopId } = currentStore.property;
  const agencyEnabled = AGENCY[window.location.hostname].options.sitest;

  if (!useSitest || !sitestShopId || !sitestId || !agencyEnabled) {
    return null;
  }

  const handleSitestClick = () => {
    autoLogin(sitestShopId, sitestId);
  };

  return (
    <div
      css={{
        height: "100%",
        backgroundColor: "black",
      }}
    >
      <div
        css={{
          display: "flex",
          fontSize: "1.2rem",
          color: COLOR.ACCENT,
          padding: "1rem",
          cursor: "pointer",
          borderBottom: `1px solid ${COLOR.SUB3}`,
          "&:hover": {
            backgroundColor: COLOR.SUB3,
          },
        }}
        onClick={handleSitestClick}
      >
        <Icon name="options" inverted css={{ flexBasis: "25%" }} />
        <p css={{ textAlign: "center" }}>サイト解析</p>
      </div>
    </div>
  );
};

export default ShortcutMenu;
