import api from "utils/api";
import endpoint from "utils/endpoint";
import dayjs from "dayjs";
import { SeoPreferences } from "domain/model/SeoPreferences";

type Post = {
  postContent: {
    title: string;
    content: string;
    postDate: Date;
    subcategory: string;
    slug: string;
    thumbnailUrl: string | null;
  };
  seoPreferences: SeoPreferences;
};

class NewsRepository {
  async applyNewsTemplate(isAgency: boolean, storeId: string | undefined) {
    const body = {
      apply_template: {
        category: "news",
      },
    };
    const url =
      isAgency && storeId
        ? endpoint.agency.news.applyTemplate(storeId)
        : endpoint.store.news.applyTemplate();

    return api.put(url, body);
  }

  addNewPost(
    isAgency: boolean,
    storeId: string | undefined,
    { postContent, seoPreferences }: Post,
    isDraft: boolean
  ) {
    const news = {
      title: postContent.title.replace(/\t/g, ""),
      content: postContent.content,
      created_at: dayjs(postContent.postDate).unix(),
      subcategory: postContent.subcategory,
      slug: postContent.slug.replace(/\t/g, ""),
      thumbnail_url: postContent.thumbnailUrl,
      is_draft: isDraft,
    };
    const body = {
      news,
      seo_tag: {
        ...seoPreferences,
        og: {
          ...seoPreferences.og,
          site_name: seoPreferences.og.siteName,
        },
      },
    };
    const url =
      isAgency && storeId
        ? endpoint.agency.news.root(storeId)
        : endpoint.store.news.root();

    return api.post(url, body).catch((error) => {
      if (error?.response?.data?.error === undefined) {
        return Promise.reject("error");
      }

      return Promise.reject(error.response.data.error);
    });
  }

  updateNews(
    isAgency: boolean,
    storeId: string | undefined,
    filePath: string,
    { postContent, seoPreferences }: Post,
    isDraft: boolean
  ) {
    const news = {
      title: postContent.title.replace(/\t/g, ""),
      content: postContent.content,
      created_at: dayjs(postContent.postDate).unix(),
      subcategory: postContent.subcategory,
      slug: postContent.slug.replace(/\t/g, ""),
      thumbnail_url: postContent.thumbnailUrl,
      filepath: filePath,
      is_draft: isDraft,
    };
    const body = {
      news,
      seo_tag: {
        ...seoPreferences,
        og: {
          ...seoPreferences.og,
          site_name: seoPreferences.og.siteName,
        },
      },
    };
    const url =
      isAgency && storeId
        ? endpoint.agency.news.root(storeId)
        : endpoint.store.news.root();

    return api.put(url, body).catch((error) => {
      if (error?.response?.data?.error === undefined) {
        return Promise.reject("error");
      }

      return Promise.reject(error.response.data.error);
    });
  }

  deletePost(isAgency: boolean, storeId: string | undefined, filePath: string) {
    const url =
      isAgency && storeId
        ? endpoint.agency.news.root(storeId)
        : endpoint.store.news.root();
    return api.delete(url, {
      params: {
        filepath: filePath,
      },
    });
  }

  createTemplate(
    isAgency: boolean,
    storeId: string | undefined,
    content: string
  ) {
    const body = {
      template: {
        label: "news",
        text: content,
      },
    };
    const url =
      isAgency && storeId
        ? endpoint.agency.news.template(storeId)
        : endpoint.store.news.template();

    return api.post(url, body).catch((error) => {
      if (error?.response?.data?.errors?.content === undefined) {
        return Promise.reject([]);
      }

      return Promise.reject(error.response.data.errors.content);
    });
  }

  updateTemplate(
    isAgency: boolean,
    storeId: string | undefined,
    content: string
  ) {
    const body = {
      template: {
        label: "news",
        text: content,
      },
    };
    const url =
      isAgency && storeId
        ? endpoint.agency.news.template(storeId)
        : endpoint.store.news.template();

    return api.put(url, body).catch((error) => {
      if (error?.response?.data?.errors?.content === undefined) {
        return Promise.reject([]);
      }

      return Promise.reject(error.response.data.errors.content);
    });
  }

  addNewCategory(
    isAgency: boolean,
    storeId: string | undefined,
    categoryName: string
  ) {
    const body = {
      subcategory: {
        category: "news",
        subcategory_name: categoryName,
      },
    };
    const url =
      isAgency && storeId
        ? endpoint.agency.subcategory.root(storeId)
        : endpoint.store.subcategory.root();

    return api.post(url, body);
  }

  updateCategory(
    isAgency: boolean,
    storeId: string | undefined,
    categoryValue: string,
    categoryName: string
  ) {
    const body = {
      subcategory: {
        category: "news",
        subcategory_name: categoryName,
        subcategory_value: categoryValue,
      },
    };
    const url =
      isAgency && storeId
        ? endpoint.agency.subcategory.root(storeId)
        : endpoint.store.subcategory.root();
    return api.put(url, body);
  }

  deleteCategory(
    isAgency: boolean,
    storeId: string | undefined,
    categoryValue: string
  ) {
    const body = {
      subcategory: {
        category: "news",
        subcategory_value: categoryValue,
      },
    };
    const url =
      isAgency && storeId
        ? endpoint.agency.subcategory.root(storeId)
        : endpoint.store.subcategory.root();
    return api.delete(url, {
      data: body,
    });
  }
}

export default new NewsRepository();
