import React, { useState, useEffect } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { UserContainer, StoreContainer } from "containers";
import { storeService } from "domain/service";

import StoreProfileForm from "components/StoreProfile/StoreProfileForm";
import { Button, Loader } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { StoreIdParam } from "config/raven";
import routes from "utils/routes";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

const StoreProfileShow = () => {
  const [storeAccessKey, setStoreAccessKey] = useState<string>("");
  const userContainer = UserContainer.useContainer();
  const storeContainer = StoreContainer.useContainer();
  const { isAgency, currentUser } = userContainer;
  const history = useHistory();
  const { currentStore } = storeContainer;
  const { storeId } = useParams<StoreIdParam>();

  useEffect(() => {
    if (storeId) {
      storeService
        .fetchAccessKey(storeId)
        .then((res) => setStoreAccessKey(res));
    }
  }, [storeId]);

  return (
    <PageContainer
      header="店舗情報"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId).build(
        "店舗情報"
      )}
      action={
        <Button
          color="blue"
          content="営業時間管理"
          onClick={() => {
            const url = isAgency
              ? generatePath(routes.agency.storeProfilBusinessDay.pathname, {
                  storeId,
                })
              : routes.store.storeProfilBusinessDay;
            history.push(url);
          }}
        />
      }
    >
      {currentStore ? (
        <StoreProfileForm
          currentStore={currentStore}
          isAgency={isAgency}
          isAdmin={currentUser?.role === "admin"}
          storeAccessKey={storeAccessKey}
        />
      ) : (
        <Loader active />
      )}
    </PageContainer>
  );
};

export default StoreProfileShow;
