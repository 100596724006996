import api from "utils/api";
import endpoint from "utils/endpoint";

type MessageTemplateParams = {
  title: string;
  body: string;
  active: boolean;
};

class MessageTemplateRepository {
  async updateMessageTemplate(
    isAgency: boolean,
    storeId: string | undefined,
    messageType: string,
    params: MessageTemplateParams
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.messageTemplate.update(storeId, messageType)
        : endpoint.store.messageTemplate.update(messageType);

    return api.patch(url, { message_template: params });
  }
}

export default new MessageTemplateRepository();
