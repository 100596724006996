import React, { useState } from "react";
import { UserContainer, StoreContainer } from "containers";

import { AsideMenu } from "components/Layout/utils/AsidebarContent/Menu";
import { AccountTab } from "components/Layout/utils/AsidebarContent/Account/AccountTab";

type Props = {
  toggleAsidebar: Function;
};

export const AsidebarContent: React.FC<Props> = ({ toggleAsidebar }) => {
  const userContainer = UserContainer.useContainer();
  const storeContainer = StoreContainer.useContainer();

  const { currentUser, signOut, isAgency } = userContainer;
  const { currentStore } = storeContainer;

  const [active] = useState<number>(1);

  return (
    <div css={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <AsideMenu active={active} />
      <div css={{ overflowY: "auto", flex: 1 }}>
        {active === 1 ? (
          <AccountTab
            isAgency={isAgency}
            currentUser={currentUser}
            currentStore={currentStore}
            signOut={() => {
              signOut();
              toggleAsidebar();
            }}
          />
        ) : null}
      </div>
    </div>
  );
};
