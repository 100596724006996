import { Type, Expose } from "class-transformer";
import AwsResource from "domain/model/AwsResource";

export default class StoreUser {
  @Expose() id: number;
  @Expose({ name: "name" }) staffName: string;
  @Expose({ name: "email" }) staffEmail: string;
  @Expose() role: string;

  @Type(() => Affiliation)
  @Expose()
  affiliation: Affiliation;

  @Type(() => AwsResource)
  @Expose({ name: "aws_resource" })
  awsResource: AwsResource;
}

class Affiliation {
  @Expose() id: number;
  @Expose() name: string;
  @Expose() email: string;
  @Expose({ name: "aws_state" }) awsState: string;
}
