import React, { useState } from "react";
import {
  Button,
  Form,
  Label,
  Loader,
  Message,
  Segment,
  Dimmer,
} from "semantic-ui-react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import MESSAGE from "config/message.json";
import { PREFECTURES } from "components/SiteManage/Content/Domain/DomainRegist/prefectures";
import { domainRepository } from "domain/repository";

const SimpleField = ({
  name,
  label,
  placeholder,
  required = true,
  error,
}: {
  name: string;
  label: string;
  placeholder: string;
  required?: boolean;
  error: string | undefined;
}) => {
  return (
    <Form.Field required={required}>
      <label>{label}</label>
      <Field
        as={Form.Input}
        name={name}
        placeholder={placeholder}
        error={error}
      />
    </Form.Field>
  );
};

const validationSchema = Yup.object({
  domain: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  firstName: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  lastName: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  organizationName: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  phoneNumber: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  email: Yup.string()
    .required(MESSAGE.ERROR.NO_INPUT)
    .email(MESSAGE.ERROR.INVALID_EMAIL),
  zipCode: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  state: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  city: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  addressLine1: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  addressLine2: Yup.string(),
});

const stateOptions = PREFECTURES.map((label, index) => ({
  text: label,
  value: `JP-${(index + 1).toString().padStart(2, "0")}`,
  key: index,
}));

type Props = {
  storeId: string | undefined;
  targetDomain: string;
};
export const RegisterDomainContainer: React.FC<Props> = ({
  targetDomain,
  storeId,
}) => {
  const [isDomainContactSuccess, setIsDomainContactSuccess] = useState<
    boolean | null
  >(null);

  const initialValues = {
    domain: targetDomain,
    firstName: "Ryo",
    lastName: "Konuma",
    organizationName: "JITT",
    phoneNumber: "0362605581",
    email: "hiramatsu@jitt.co.jp",
    zipCode: "1010054",
    state: "JP-13",
    city: "Chiyoda-ku",
    addressLine1: "NagonoBuilding Honkan 4F, 3-13-7, Kandanishikichou",
    addressLine2: "",
  };

  const DomainContactMessage: React.FC = () => {
    if (isDomainContactSuccess === null) {
      return null;
    }
    if (isDomainContactSuccess) {
      return (
        <Message
          positive
          content="ドメイン取得を受け付けました。取得完了までしばらくお待ちください。"
        />
      );
    } else {
      return <Message negative content="登録に失敗しました" />;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => {
        if (storeId === undefined) {
          return;
        }
        domainRepository
          .registerDomain(storeId, values)
          .then(() => {
            setIsDomainContactSuccess(true);
          })
          .catch(() => {
            setIsDomainContactSuccess(false);
          })
          .finally(() => {
            formikHelpers.setSubmitting(false);
          });
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors, handleSubmit, setFieldValue, isSubmitting }) => (
        <>
          {isSubmitting ? (
            <Dimmer active>
              <Loader active content="ドメイン取得中" size="large" />
            </Dimmer>
          ) : null}
          <Form onSubmit={handleSubmit}>
            <p>
              レジストラー側でのエラーを回避するため英語での登録を推奨しております。
              <br />
              特段こだわりがなければデフォルト値（JITT）を書き換えずそのままご使用ください。
            </p>
            <Segment>
              <Form.Group unstackable widths={2}>
                <Form.Field required>
                  <label>ドメイン名</label>
                  <Field as={Form.Input} disabled name="domain" />
                  <Label
                    color="blue"
                    basic
                    pointing
                    content="この項目は変更できません"
                  />
                </Form.Field>
                <Form.Field required>
                  <label>郵便番号</label>
                  <Field
                    as={Form.Input}
                    name="zipCode"
                    placeholder="0000000"
                    error={errors.zipCode}
                  />
                  <Label
                    color="blue"
                    basic
                    pointing
                    content="ハイフン（-）なしで入力してください"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group unstackable widths={2}>
                <Form.Group unstackable widths={2}>
                  <SimpleField
                    name="lastName"
                    label="Family Name"
                    placeholder="Yamada"
                    error={errors.lastName}
                  />
                  <SimpleField
                    name="firstName"
                    label="First Name"
                    placeholder="Taro"
                    error={errors.firstName}
                  />
                </Form.Group>
                <Form.Select
                  required
                  label="都道府県"
                  name="state"
                  options={stateOptions}
                  value={values.state}
                  error={errors.state}
                  onChange={(_, { value }) => setFieldValue("state", value)}
                />
              </Form.Group>
              <Form.Group unstackable widths={2}>
                <SimpleField
                  name="organizationName"
                  label="会社名"
                  placeholder="Sample Inc."
                  error={errors.organizationName}
                />
                <SimpleField
                  name="city"
                  label="市町村"
                  placeholder="Shibuya-ku"
                  error={errors.city}
                />
              </Form.Group>
              <Form.Group unstackable widths={2}>
                <Form.Field required>
                  <label>電話番号</label>
                  <Field
                    as={Form.Input}
                    name="phoneNumber"
                    placeholder="00000000000"
                    error={errors.phoneNumber}
                  />
                  <Label
                    color="blue"
                    basic
                    pointing
                    content="ハイフン（-）なしで入力してください"
                  />
                </Form.Field>
                <Form.Field required>
                  <label>住所1</label>
                  <Field
                    as={Form.Input}
                    name="addressLine1"
                    placeholder="1-1, Harajuku"
                    error={errors.addressLine1}
                  />
                  <Label
                    color="blue"
                    basic
                    pointing
                    content="必ず番地を含めてください"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group unstackable widths={2}>
                <SimpleField
                  name="email"
                  label="メールアドレス"
                  placeholder="info@example.com"
                  error={errors.email}
                />
                <SimpleField
                  name="addressLine2"
                  label="住所2"
                  placeholder="（省略可）"
                  required={false}
                  error={errors.addressLine2}
                />
              </Form.Group>
            </Segment>
            <Message
              negative
              content="入力されたメールアドレスに対して認証用メールが送信されることがあります。承認しない場合、ドメイン取得・運用に支障が生じる可能性がありますので、ご注意ください。"
            />
            <Message negative content="ドメイン契約は1年毎に自動更新されます" />
            <div
              css={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                content="取得"
                primary
                type="submit"
                disabled={isSubmitting || isDomainContactSuccess === true}
              />
            </div>
            <DomainContactMessage />
          </Form>
        </>
      )}
    </Formik>
  );
};
