import React from "react";

import { Header, Icon, Button, Message } from "semantic-ui-react";

type Props = {
  isAuthenticated: boolean;
  updatedAtMsg: string;
  buttonTitle: string;
  authUrl: string | null;
  revokeGoogle: () => void;
};

export const GoogleCertificate: React.FC<Props> = ({
  isAuthenticated,
  updatedAtMsg,
  buttonTitle,
  authUrl,
  revokeGoogle,
}) => {
  return (
    <div>
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0 0 0",
        }}
      >
        <Header as="h2">
          <Icon.Group size="large">
            <Icon name="google" color="blue" />
          </Icon.Group>
          Google
        </Header>
        <div css={{ display: "flex" }}>
          <div css={{ marginRight: "16px" }}>
            <Button
              color="blue"
              content={buttonTitle}
              onClick={() => authUrl && (window.location.href = authUrl)}
            />
          </div>
          {isAuthenticated ? (
            <div css={{ marginRight: "16px" }}>
              <Button
                color="blue"
                negative
                content="認証解除"
                onClick={revokeGoogle}
              />
            </div>
          ) : null}
        </div>
      </div>
      <Message content={updatedAtMsg} />
    </div>
  );
};
