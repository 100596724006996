import api from "utils/api";
import endpoint from "utils/endpoint";

class SubscRepository {
  /**
   * 代理店ユーザーだけ利用可能
   */
  public async update({
    isAgency,
    storeId,
    subscUrl,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    subscUrl: string;
  }) {
    const url =
      isAgency && storeId
        ? endpoint.agency.subsc.update(storeId)
        : endpoint.store.subsc.update();

    const body = {
      subsc: {
        url: subscUrl,
      },
    };

    return api.put(url, body);
  }
}

export default new SubscRepository();
