import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import routes from "utils/routes";
import { Button, Message, Segment } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { StoreIdParam } from "config/raven";
import { NewForm } from "components/Product/New/NewForm";
import { StoreContainer, UserContainer } from "containers";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const ProductNew = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const history = useHistory();
  const { storeId } = useParams<StoreIdParam>();
  const { currentStore } = StoreContainer.useContainer();
  const useTakeout = currentStore?.property?.useTakeout || false;

  if (!useTakeout) {
    return <Message content="テイクアウト機能が有効になっていません" />;
  }

  return (
    <>
      <PageContainer
        header="商品登録"
        breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
          .productPage()
          .build("商品登録")}
      >
        <Segment>
          <NewForm isAgency={isAgency} storeId={storeId} />
        </Segment>
      </PageContainer>
    </>
  );
};
