import React from "react";

import { Image, Table, Button } from "semantic-ui-react";
import noImage from "img/no-image.png";

import Post from "domain/model/Post";

type Props = {
  article: Post;
  handleClickEdit: () => void;
  index: number;
};

export const ArticleItem: React.FC<Props> = ({
  article,
  handleClickEdit,
  index,
}) => {
  return (
    <Table.Row key={index}>
      <Table.Cell>{index + 1}</Table.Cell>
      <Table.Cell>
        {article.metadata.thumbnailUrl ? (
          <Image
            src={article.metadata.thumbnailUrl}
            style={{ width: "90px", height: "80px" }}
          />
        ) : (
          <Image src={noImage} style={{ width: "90px", height: "80px" }} />
        )}
      </Table.Cell>
      <Table.Cell>{article.title}</Table.Cell>
      <Table.Cell>{article.subcategory ?? "-"}</Table.Cell>
      <Table.Cell>{article.isPublished ? "公開" : "非公開"}</Table.Cell>
      <Table.Cell>{article.createdAt}</Table.Cell>
      <Table.Cell>
        <Button onClick={handleClickEdit} color="blue" content="編集" />
      </Table.Cell>
    </Table.Row>
  );
};
