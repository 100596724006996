import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";

type ImageItem = {
  src: string;
  alt: string;
};

type Props = {
  images: ImageItem[];
  isActive: boolean;
  onSave: (images: ImageItem[]) => void;
  onClose: () => void;
  onRequestImagePicker: (
    callback: (filename: string, { alt }: { alt: string }) => void
  ) => void;
};

export const SliderImageSelector: React.FC<Props> = ({
  images,
  isActive,
  onSave,
  onClose,
  onRequestImagePicker,
}) => {
  const [imagesTmp, setImagesTmp] = useState<ImageItem[]>(images);
  const [rows, setRows] = useState(images.length + 1);

  // src が空欄の行を削除して onSave に渡す
  const handleSave = () => {
    const images = imagesTmp.filter((i) => !!i.src);
    onSave(images);
  };

  const onAddRow = () => {
    setRows(rows + 1);
  };

  const updateSrc = (i: number, value: string) => {
    // 追加
    if (imagesTmp.length <= i) {
      setImagesTmp([
        ...imagesTmp,
        {
          src: value,
          alt: "",
        },
      ]);
      return;
    }

    // 編集
    setImagesTmp(
      imagesTmp.map((savedItem, index) => {
        if (index === i)
          return {
            src: value,
            alt: savedItem.alt,
          };
        return savedItem;
      })
    );
  };
  const updateAlt = (i: number, value: string) => {
    // 追加
    if (imagesTmp.length <= i) {
      setImagesTmp([
        ...imagesTmp,
        {
          src: "",
          alt: value,
        },
      ]);
      return;
    }

    // 編集
    setImagesTmp(
      imagesTmp.map((savedItem, index) => {
        if (index === i)
          return {
            src: savedItem.src,
            alt: value,
          };
        return savedItem;
      })
    );
  };

  useEffect(() => {
    setImagesTmp(images);
    setRows(images.length + 1);
  }, [images]);

  return (
    <Modal open={isActive}>
      <Modal.Header>スライダー画像を選択してください</Modal.Header>
      <Modal.Content>
        <Form css={{ marginBottom: 20 }}>
          {[...Array(rows)].map((_, i) => (
            <Form.Group inline css={{ marginRight: "10px" }}>
              <div css={{ marginRight: "10px" }}>{i + 1}</div>
              <Form.Input
                name="label"
                value={imagesTmp[i]?.src || ""}
                onChange={(e) => updateSrc(i, e.target.value)}
                placeholder="画像のURL"
                width={9}
              />
              <Form.Input
                name="label"
                value={imagesTmp[i]?.alt || ""}
                onChange={(e) => updateAlt(i, e.target.value)}
                placeholder="代替テキスト（ALT）"
                width={6}
              />
              <Button
                color="blue"
                icon="picture"
                onClick={() =>
                  onRequestImagePicker((filename) => updateSrc(i, filename))
                }
                width={1}
              />
            </Form.Group>
          ))}
          <Button onClick={onAddRow}>＋</Button>
        </Form>
        <div>
          <ul>
            <li>画像を削除したい場合は「URL」を空欄にしてください。</li>
            <li>画像は原則として同じサイズのものを指定してください。</li>
            <li>
              「代替テキスト（ALT）」には、画像内に含まれているテキストや画像の説明を記載してください。画面には表示されませんが、アクセシビリティ向上やSEO対策等の効果があります。
              飾り目的で特に内容に意味のない画像については、「代替テキスト（ALT）」は空欄にしてください。
            </li>
          </ul>
        </div>
        <div css={{ textAlign: "center" }}>
          <Button color="blue" onClick={handleSave}>
            決定
          </Button>
          <Button onClick={onClose}>キャンセル</Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
