import React from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import { Button, Form, Icon, Label, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import { JobType, SalaryType, JobFeedStatus } from "domain/model/JobFeed";
type SubmitType = (typeof SubmitTypes)[keyof typeof SubmitTypes];

export const SubmitTypes = {
  CREATE: "作成",
  UPDATE: "更新",
} as const;

type FormValues = {
  status: JobFeedStatus;
  title: string;
  company: string;
  jobType: JobType;
  description: string;
  postDate: Date;
  salaryType: SalaryType;
  salaryMin: number;
  salaryMax: number;
  zipCode: string | null;
  state: string;
  city: string;
  address: string | null;
  station: string | null;
  experience: string;
  workingTime: string;
  holiday: string;
  benefits: string;
  insurance: string;
  preventSmoke: string;
  contractPeriod: string;
  url: string | null;
  imageUrl: string | null;
  companyUrl: string | null;
  companyLogoUrl: string | null;
  pageHeader: string;
  pageFooter: string;
};

type Props = {
  initialValues: FormValues;
  isAgency: boolean;
  storeId: string | undefined;
  submitType: SubmitType;
  handleSubmit: ({
    values,
    setStatus,
    resetForm,
  }: {
    values: FormValues;
    setStatus: (status: { success: string; error: string }) => void;
    resetForm: () => void;
  }) => void;
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  company: Yup.string().required(),
  description: Yup.string().required(),
  postDate: Yup.string().required(),
  state: Yup.string().required(),
  city: Yup.string().required(),
});

export const JobFeedForm: React.FC<Props> = ({
  initialValues,
  storeId,
  isAgency,
  submitType,
  handleSubmit,
}) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setStatus, resetForm }) => {
      handleSubmit({ values, setStatus, resetForm });
    },
    initialStatus: { success: "", error: "" },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const statusColor = (jobStatus: JobFeedStatus) => {
    switch (jobStatus) {
      case "advertising":
        return "green";
      case "hiring":
        return "yellow";
      case "pending":
        return "red";
      case "deleted":
        return "black";
    }
  };

  const statusText = (jobStatus: JobFeedStatus) => {
    switch (jobStatus) {
      case "advertising":
        return "募集・広告";
      case "hiring":
        return "募集";
      case "pending":
        return "募集停止";
      case "deleted":
        return "削除";
    }
  };

  const statusOptions = (
    [
      {
        value: "advertising",
        label: "求人募集・広告出稿（サイト表示/広告掲載）",
      },
      {
        value: "hiring",
        label: "求人募集・広告停止（サイト表示/広告非掲載）",
      },
      {
        value: "pending",
        label: "求人募集を停止（サイト非表示/広告非掲載）",
      },
      {
        value: "deleted",
        label: "削除（一覧から消えます、復旧はできません）",
      },
    ] as { value: JobFeedStatus; label: string }[]
  ).map(({ value, label }) => ({
    value,
    text: (
      <>
        <StatusLabel color={statusColor(value)}>
          {statusText(value)}
        </StatusLabel>
        {label}
      </>
    ),
  }));

  const jobTypeOptions = [
    { key: "full_time", value: "full_time", text: "正社員" },
    { key: "part_time", value: "part_time", text: "パート・アルバイト" },
    { key: "contractor", value: "contractor", text: "契約社員" },
    { key: "temporary", value: "temporary", text: "一時的な雇用・派遣社員" },
    { key: "intern", value: "intern", text: "インターンシップ" },
    { key: "volunteer", value: "volunteer", text: "ボランティア" },
    { key: "per_diem", value: "per_diem", text: "日雇い" },
    { key: "outsourcing", value: "outsourcing", text: "業務委託・外注" },
    { key: "other", value: "other", text: "その他" },
  ];

  const salaryTypeOptions = [
    { key: "hour", value: "hour", text: "時間給" },
    { key: "day", value: "day", text: "日給" },
    { key: "week", value: "week", text: "週給" },
    { key: "month", value: "month", text: "月給" },
    { key: "year", value: "year", text: "年給・年俸" },
    { key: "piecework", value: "piecework", text: "出来高制・報酬制" },
  ];

  const prefectureOptions = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ].map((x) => ({
    key: x,
    value: x,
    text: x,
  }));

  return (
    <Form onSubmit={formik.handleSubmit}>
      {formik.status.error && (
        <Segment
          inverted
          color="red"
          secondary
          style={{ marginBottom: "2rem" }}
        >
          {formik.status.error}
        </Segment>
      )}

      <Form.Field required>
        <label>ステータス：</label>
        <div>
          <Form.Select
            required
            name="status"
            options={statusOptions}
            error={formik.errors.status}
            value={formik.values.status}
            onChange={(_, { value }) => formik.setFieldValue("status", value)}
          />
        </div>
      </Form.Field>

      <Form.Field>
        <label>遷移先URL</label>
        <Form.Input
          name="url"
          error={formik.errors.url}
          value={formik.values.url}
          onChange={formik.handleChange}
          placeholder="http://example.com"
        />
        <FormHelper>
          空欄の場合は「https://[ドメイン名]/jobs/detail.html?id=[求人ID]」の形式で、各求人サイトに登録されます(この場合、ドメイン登録必須)
        </FormHelper>
      </Form.Field>

      <Form.Field required>
        <label>職務の名称</label>
        <Form.Input
          name="title"
          error={formik.errors.title}
          value={formik.values.title}
          onChange={formik.handleChange}
        />
        <FormHelper>
          () [] &lt;&gt;
          ≪≫【】｛｝などの括弧および括弧で囲まれた文字は、外部求人サイトへの掲載時に削除される可能性があるため、全角／や「」・への変換をお願いいたします。
        </FormHelper>
        <FormHelper>
          求人情報のタイトルではありません。職務の名称以外の、求人コード・住所・日付・給与・会社名などの情報は含めないでください。
        </FormHelper>
        <FormHelper style={{ marginLeft: 10 }}>
          <div>
            <Icon name="thumbs down" color="red" />
            非推奨例：　ブカレストでフランス語を話せる IT 技術者を募集中　
          </div>
          <div>
            <Icon name="thumbs up" color="green" />
            　推奨例：　マーケティング専門家、フランス語話者
          </div>
        </FormHelper>
        <FormHelper>
          また、!や*などの特殊文字の過剰利用は外部求人サイトにスパム行為と判断される可能性があります。
        </FormHelper>
        <FormHelper style={{ marginLeft: 10 }}>
          <div>
            <Icon name="thumbs down" color="red" />
            非推奨例：　*** 倉庫でのお仕事です！バス停のすぐ近く！***
          </div>
          <div>
            <Icon name="thumbs up" color="green" />
            　推奨例：　倉庫での出荷・入荷の担当者
          </div>
        </FormHelper>
      </Form.Field>

      <Form.Field required>
        <label>会社名</label>
        <Form.Input
          name="company"
          error={formik.errors.company}
          value={formik.values.company}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field required>
        <label>雇用形態</label>
        <Form.Select
          required
          name="jobType"
          options={jobTypeOptions}
          error={formik.errors.jobType}
          value={formik.values.jobType}
          onChange={(_, { value }) => formik.setFieldValue("jobType", value)}
        />
      </Form.Field>

      <Form.Field required>
        <label>仕事内容</label>
        <Form.TextArea
          name="description"
          error={formik.errors.description}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field required>
        <label>掲載開始日</label>
        <Form.Input
          type="date"
          name="postDate"
          error={formik.errors.postDate}
          value={formik.values.postDate}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field required>
        <label>給与区分</label>
        <Form.Select
          required
          name="salaryType"
          options={salaryTypeOptions}
          error={formik.errors.salaryType}
          value={formik.values.salaryType}
          onChange={(_, { value }) => formik.setFieldValue("salaryType", value)}
        />
      </Form.Field>

      <Form.Field>
        <label>給与(Min)</label>
        <Form.Input
          name="salaryMin"
          error={formik.errors.salaryMin}
          value={formik.values.salaryMin}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>給与(Max)</label>
        <Form.Input
          name="salaryMax"
          error={formik.errors.salaryMax}
          value={formik.values.salaryMax}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field>
        <label>郵便番号</label>
        <Form.Input
          name="zipCode"
          pattern="\d{3}-\d{4}"
          error={formik.errors.zipCode}
          value={formik.values.zipCode}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field required>
        <label>勤務地(都道府県)</label>
        <Form.Select
          required
          name="state"
          options={prefectureOptions}
          error={formik.errors.state}
          value={formik.values.state}
          onChange={(_, { value }) => formik.setFieldValue("state", value)}
        />
      </Form.Field>
      <Form.Field required>
        <label>勤務地(市区町村)</label>
        <Form.Input
          name="city"
          error={formik.errors.city}
          value={formik.values.city}
          onChange={formik.handleChange}
        />
        <FormHelper>「市区町村」で終わるよう、記入してください</FormHelper>
        <FormHelper>
          （例：　品川区、福岡市、福岡市中央区、相楽郡南山城村、西多摩郡瑞穂町、八丈島八丈町）
        </FormHelper>
      </Form.Field>
      <Form.Field>
        <label>勤務地(番地等)</label>
        <Form.Input
          name="address"
          error={formik.errors.address}
          value={formik.values.address}
          onChange={formik.handleChange}
        />
        <FormHelper>
          「都道府県」「市区町村」を取り除いた、番地・建物名等の情報を記入してください
        </FormHelper>
        <FormHelper>
          勤務地の「番地等」と「最寄り駅」は、どちらかの記入が必須です
        </FormHelper>
      </Form.Field>
      <Form.Field>
        <label>勤務地の最寄り駅</label>
        <Form.Input
          name="station"
          error={formik.errors.station}
          value={formik.values.station}
          onChange={formik.handleChange}
        />
        <FormHelper>
          勤務地の「番地等」と「最寄り駅」は、どちらかの記入が必須です
        </FormHelper>
      </Form.Field>

      <Form.Field>
        <label>経験・資格</label>
        <Form.TextArea
          name="experience"
          error={formik.errors.experience}
          value={formik.values.experience}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>勤務時間</label>
        <Form.TextArea
          name="workingTime"
          error={formik.errors.workingTime}
          value={formik.values.workingTime}
          onChange={formik.handleChange}
        />
        <FormHelper>
          記載例：「7:00～16:00　※休憩60分」
          「フレックスタイム制　標準労働時間1日7時間（コアタイム11：00〜15：00）」
        </FormHelper>
      </Form.Field>
      <Form.Field>
        <label>休日・休暇など</label>
        <Form.TextArea
          name="holiday"
          error={formik.errors.holiday}
          value={formik.values.holiday}
          onChange={formik.handleChange}
        />
        <FormHelper>
          記載例：
          「完全週休2日制（土日祝）※年間休日124日夏季・年末年始・慶弔・産前産後・育児・介護等」
          「週休2日制（シフト制／月8～10日以上）」
        </FormHelper>
      </Form.Field>

      <Form.Field>
        <label>待遇・福利厚生</label>
        <Form.TextArea
          name="benefits"
          error={formik.errors.benefits}
          value={formik.values.benefits}
          onChange={formik.handleChange}
        />
        <FormHelper>
          記載例：
          「交通費全額支給」「社割あり」「バイク・自転車通勤OK」「資格取得支援制度」「制服支給」「服装自由」「退職金・財形貯蓄・確定拠出年金（任意）」
        </FormHelper>
      </Form.Field>
      <Form.Field>
        <label>加入保険</label>
        <Form.TextArea
          name="insurance"
          error={formik.errors.insurance}
          value={formik.values.insurance}
          onChange={formik.handleChange}
        />
        <FormHelper>
          記載例：
          「社会保険完備」「健康保険・厚生年金保険・労災保険・雇用保険」
        </FormHelper>
      </Form.Field>
      <Form.Field>
        <label>受動喫煙防止措置事項</label>
        <Form.TextArea
          name="preventSmoke"
          error={formik.errors.preventSmoke}
          value={formik.values.preventSmoke}
          onChange={formik.handleChange}
        />
        <FormHelper>
          記載例：
          「屋内禁煙」「屋外喫煙可」「屋内原則禁煙。ただし、適用除外の場所あり」「（バス、タクシー、旅客機などにおいて）車内禁煙」
        </FormHelper>
      </Form.Field>
      <Form.Field>
        <label>契約期間</label>
        <Form.TextArea
          name="contractPeriod"
          error={formik.errors.contractPeriod}
          value={formik.values.contractPeriod}
          onChange={formik.handleChange}
        />
        <FormHelper>
          派遣労働者として雇用する場合は、「無期雇用派遣」・「常用型の有期雇用派遣」どちらかを明記します。
        </FormHelper>
        <FormHelper>
          また、その他有期労働契約の場合は、「契約期間」と「更新の有無」を記載します。
        </FormHelper>
      </Form.Field>

      <Form.Field>
        <label>画像URL</label>
        <Form.Input
          name="imageUrl"
          error={formik.errors.imageUrl}
          value={formik.values.imageUrl}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>企業URL(Googleしごと検索用)</label>
        <Form.Input
          name="companyUrl"
          error={formik.errors.companyUrl}
          value={formik.values.companyUrl}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>会社ロゴURL(Googleしごと検索用)</label>
        <Form.Input
          name="companyLogoUrl"
          error={formik.errors.companyLogoUrl}
          value={formik.values.companyLogoUrl}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field>
        <label>【Webページ表示用】ページヘッダー</label>
        <Form.TextArea
          name="pageHeader"
          error={formik.errors.pageHeader}
          value={formik.values.pageHeader}
          onChange={formik.handleChange}
        />
      </Form.Field>

      <Form.Field>
        <label>【Webページ表示用】ページフッター</label>
        <Form.TextArea
          name="pageFooter"
          error={formik.errors.pageFooter}
          value={formik.values.pageFooter}
          onChange={formik.handleChange}
        />
      </Form.Field>

      {formik.values.status === "deleted" && (
        <WarningText>
          以下のボタンを押すとデータが削除されます。元には戻せません。
        </WarningText>
      )}

      <div style={{ textAlign: "center" }}>
        <Button color="blue" content={submitType} type="submit" />
      </div>
    </Form>
  );
};

const FormHelper = styled.div`
  color: #888;
  font-size: 0.9rem;
`;

const StatusLabel = styled(Label)`
  width: 80px;
  text-align: center;
  font-size: 10px;
`;

const WarningText = styled.p`
  text-align: center;
  color: red;
`;
