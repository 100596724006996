import React from "react";

import {
  Divider,
  Form,
  Header,
  Icon,
  Image,
  Label,
  Message,
} from "semantic-ui-react";
import { DateTimePickerForm } from "components/lib/Forms/DateTimePickerForm";
import { useFormikContext } from "formik";
import { DocumentWysiwygEditor } from "components/lib/Editor/DocumentWysiwygEditor";
import noImage from "img/no-image.png";

export type PostFormValues = {
  postContent: {
    title: string;
    postDate: Date;
    subCategoryValue: string;
    slug: string;
    content: string;
    thumbnailUrl: string;
  };
};

type Props = {
  subCategories: { name: string; value: string }[];
  mediaUrls: string[] | null;
  refetchMediaUrls: () => void;
  isEdit?: boolean;
  isPublished: boolean;
};

export const PostContentForm: React.FC<Props> = ({
  subCategories,
  mediaUrls,
  refetchMediaUrls,
  isEdit,
  isPublished,
}) => {
  const option = subCategories.map((c) => ({
    key: c.value,
    value: c.value,
    text: c.name,
  }));

  const {
    values: { postContent: values },
    errors: { postContent: errors },
    handleChange,
    setFieldValue,
  } = useFormikContext<PostFormValues>();

  return (
    <>
      {isEdit && (
        <Form.Field>
          <label>ステータス</label>
          {isPublished ? (
            <Label content="公開" color="blue" size="large" />
          ) : (
            <Label content="非公開" size="large" />
          )}
        </Form.Field>
      )}
      <Form.Field required>
        <label>タイトル</label>
        <Form.Input
          name="postContent.title"
          error={errors && errors.title}
          value={values.title}
          onChange={handleChange}
        />
      </Form.Field>
      <DateTimePickerForm name="postContent.postDate" label="投稿日時" />
      <Form.Select
        name="postContent.subCategoryValue"
        label="カテゴリ"
        error={errors && errors.subCategoryValue}
        value={values.subCategoryValue}
        onChange={(_, { value }) =>
          setFieldValue("postContent.subCategoryValue", value)
        }
        options={option}
      />
      <Form.Field>
        <Form.Input
          disabled={isEdit}
          name="postContent.slug"
          label="スラグ"
          error={errors && errors.slug}
          value={values.slug}
          onChange={handleChange}
        />
        <Message size="tiny" info content="スラグは編集できません" />
      </Form.Field>
      <Form.Field>
        <label>サムネイル</label>
        {values.thumbnailUrl === "" || values.thumbnailUrl === null ? (
          <Image src={noImage} size="small" />
        ) : (
          <Image src={values.thumbnailUrl} size="small" />
        )}
      </Form.Field>
      <Divider horizontal css={{ margin: "2rem 0!important" }}>
        <Header as="h4">
          <Icon name="pencil alternate" />
          本文
        </Header>
      </Divider>
      <Form.Field name="postContent.content">
        <DocumentWysiwygEditor
          data={values.content}
          onChange={(value) => {
            setFieldValue("postContent.content", value);
          }}
          mediaUrls={mediaUrls ?? []}
          refetchMediaUrls={refetchMediaUrls}
        />
      </Form.Field>
    </>
  );
};
