import React, { useState } from "react";

import { Button, Modal } from "semantic-ui-react";

import { NewFormModalMenu } from "components/Contact/lib/NewFormModalMenu";
import { SingleLineForm } from "components/Contact/lib/SingleLineForm";
import { MultipleLineForm } from "components/Contact/lib/MultipleLineForm";

import { FormTypes, FormType } from "domain/model/ContactFormTypes";

type Props = {
  push: (obj: any) => void;
};

export const NewFormModalContainer: React.FC<Props> = ({ push }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<FormType | null>(null);

  const Content: React.FC = () => {
    if (selectedType === null && open === true) {
      return (
        <NewFormModalMenu setSelectedType={setSelectedType} setOpen={setOpen} />
      );
    }
    if (
      selectedType === FormTypes.SINGLE_LINE_TEXT ||
      selectedType === FormTypes.EMAIL ||
      selectedType === FormTypes.DATE ||
      selectedType === FormTypes.MULTIPLE_LINE_TEXT
    ) {
      return (
        <SingleLineForm
          push={push}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          setOpen={setOpen}
        />
      );
    } else {
      return (
        <MultipleLineForm
          push={push}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          setOpen={setOpen}
        />
      );
    }
  };

  return (
    <Modal
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      open={open}
      trigger={<Button type="button" color="yellow" content="フォーム追加" />}
    >
      <Content />
    </Modal>
  );
};
