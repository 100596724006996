import React, { useState } from "react";

import { Table } from "semantic-ui-react";
import { FormattedApplicant } from "components/JobOffer/ApplicantsIndex";
import { FormStatus } from "config/raven";
import ApplicantItem from "components/JobOffer/ApplicantsIndex/ApplicantItem";

type Props = {
  applicants: FormattedApplicant[];
  handleSelectStatus: (contactId: string, status: FormStatus) => void;
  updateRemarks: (contactId: string, newRemarks: string) => void;
};

const ApplicantsList: React.FC<Props> = ({
  applicants,
  handleSelectStatus,
  updateRemarks,
}) => {
  const [openRemarksContactId, setOpenRemarksContactId] = useState<string>();

  return (
    <Table striped>
      <TableHeader />
      <Table.Body>
        {applicants.map((applicant, index) => (
          <ApplicantItem
            applicant={applicant}
            index={index}
            key={applicant.contactId}
            handleSelectStatus={handleSelectStatus}
            updateRemarks={updateRemarks}
            openRemarksContactId={openRemarksContactId}
            setOpenRemarksContactId={setOpenRemarksContactId}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

const TableHeader: React.FC = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell css={{ width: "4%" }} />
      <Table.HeaderCell css={{ width: "9%" }}>応募者</Table.HeaderCell>
      <Table.HeaderCell css={{ width: "5%" }}>年齢</Table.HeaderCell>
      <Table.HeaderCell css={{ width: "10%" }}>現在の職業</Table.HeaderCell>
      <Table.HeaderCell css={{ width: "9%" }}>住所</Table.HeaderCell>
      <Table.HeaderCell css={{ width: "13%" }}>メールアドレス</Table.HeaderCell>
      <Table.HeaderCell css={{ width: "12%" }}>携帯電話番号</Table.HeaderCell>
      <Table.HeaderCell css={{ width: "8%" }}>希望職種</Table.HeaderCell>
      <Table.HeaderCell css={{ width: "8%" }}>採用形態</Table.HeaderCell>
      <Table.HeaderCell css={{ width: "7%" }}>応募日</Table.HeaderCell>
      <Table.HeaderCell css={{ width: "4%" }}>対応状況</Table.HeaderCell>
      <Table.HeaderCell css={{ width: "11%" }}>メモ</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

export default ApplicantsList;
