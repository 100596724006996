import React from "react";
import {
  useHistory,
  useLocation,
  useParams,
  generatePath,
} from "react-router-dom";

import routes from "utils/routes";

import PageContainer from "components/lib/PageContainer";
import { StoreNotificationContent } from "components/StoreNotification/Show/StoreNotificationContent";

import { Button } from "semantic-ui-react";
import { StoreIdParam } from "config/raven";
import UserContainer from "containers/User";

type NotificationContent = {
  title: string;
  content: string;
};

export const StoreNotificationShow: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();
  const history = useHistory();
  const location = useLocation<NotificationContent>();

  const { title, content } = location.state;

  const notificationIndexPath = isAgency
    ? generatePath(routes.agency.notificationIndex.pathname, {
        storeId,
      })
    : routes.store.notificationIndex.pathname;

  return (
    <PageContainer
      header="代理店からのお知らせ表示"
      action={
        <Button
          color="blue"
          content="お知らせ一覧へ"
          onClick={() => history.push(notificationIndexPath)}
        />
      }
    >
      <StoreNotificationContent title={title} content={content} />
    </PageContainer>
  );
};
