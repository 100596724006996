export const getPreviewUrl = (
  versionHash: string,
  id: number,
  s3Bucket: string
): string => {
  const envString = [
    "localhost",
    "v2.staging.ccmphp.com",
    "valuehp.info",
    "staging.valuehp.info",
  ].includes(window.location.hostname)
    ? "staging"
    : ["demo.ccmphp.com"].includes(window.location.hostname)
    ? "demo"
    : "production";
  return `https://${id}-${s3Bucket}-${versionHash}.${envString}.preview.ccmphp.com/`;
};
