import { plainToClass } from "class-transformer";
import api from "utils/api";
import { UserType } from "config/raven";
import endpoint from "utils/endpoint";

import Agency from "domain/model/User/Agency";
import Store from "domain/model/User/Store";

class UserService {
  public signIn(email: string, password: string, type: UserType) {
    const body = { auth: { email, password } };

    return api.post(endpoint[type].user.signIn(), body);
  }

  public async fetchAgencyUser(): Promise<Agency> {
    return api.get(endpoint.agency.user.me()).then((res) =>
      plainToClass(Agency, res.data, {
        excludeExtraneousValues: true,
      })
    );
  }

  public async fetchStoreUser(): Promise<Store> {
    return await api
      .get(endpoint.store.user.me(), { params: "user" })
      .then(
        async (res) =>
          await plainToClass(Store, res.data, { excludeExtraneousValues: true })
      );
  }

  public async requestPassWordReset(email: string) {
    const body = { email };
    return api.post(endpoint.common.password.resetRequest(), body);
  }
}

export default new UserService();
