import React, { useState } from "react";
import { useHistory, generatePath } from "react-router-dom";
import { Formik } from "formik";

import { Segment, Form, Loader, Message } from "semantic-ui-react";

import routes from "utils/routes";

import { FormTypes, FormValues } from "domain/model/ContactFormTypes";
import { contactRepository } from "domain/repository";
import { validationSchema } from "components/Contact/lib/form";

import { ContactFormContent } from "components/Contact/lib/ContactFormContent";

type Props = {
  isAgency: boolean;
  storeId: string;
};

const initialValues: FormValues = {
  formContent: {
    formName: "",
    pubEmail: "",
    redirectUrl: "",
    forms: [
      {
        label: "ご用件",
        type: FormTypes.RADIO,
        options: ["ご質問・お問い合わせ", "見積もり依頼", "求人について"],
        required: false,
      },
      {
        label: "会社名(法人の方のみ)",
        type: FormTypes.SINGLE_LINE_TEXT,
        options: [],
        required: false,
      },
      {
        label: "お名前(ご担当者)",
        type: FormTypes.SINGLE_LINE_TEXT,
        options: [],
        required: false,
      },
      {
        label: "フリガナ",
        type: FormTypes.SINGLE_LINE_TEXT,
        options: [],
        required: false,
      },
      {
        label: "住所",
        type: FormTypes.SINGLE_LINE_TEXT,
        options: [],
        required: false,
      },
      {
        label: "電話番号",
        type: FormTypes.SINGLE_LINE_TEXT,
        options: [],
        required: false,
      },
      {
        label: "メールアドレス",
        type: FormTypes.EMAIL,
        options: [],
        required: false,
      },
      {
        label: "お問い合わせ内容",
        type: FormTypes.MULTIPLE_LINE_TEXT,
        options: [],
        required: false,
      },
    ],
  },
};

export const NewContactForm: React.FC<Props> = ({ isAgency, storeId }) => {
  const history = useHistory();

  const [createError, setCreateError] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const indexPath = isAgency
    ? generatePath(routes.agency.contactFormIndex.pathname, { storeId })
    : routes.store.contactFormIndex.pathname;

  if (isCreating) {
    return <Loader active content="作成しています" />;
  }

  return (
    <Segment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsCreating(true);
          contactRepository
            .createContactForm({
              isAgency,
              storeId,
              formName: values.formContent.formName,
              pubEmail: values.formContent.pubEmail,
              forms: values.formContent.forms,
              redirectUrl: values.formContent.redirectUrl,
            })
            .then((form) => {
              history.push(indexPath, { savedFormDom: form.dom });
              setCreateError(false);
            })
            .catch(() => setCreateError(true))
            .finally(() => setIsCreating(false));
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formik) => {
          return (
            <Form onSubmit={formik.handleSubmit}>
              <ContactFormContent isEdit={false} />
              {createError ? <Message content="作成に失敗しました" /> : null}
            </Form>
          );
        }}
      </Formik>
    </Segment>
  );
};
