import React from "react";

import Label from "./Label";
import Textarea from "./Textarea";
import ErrorMessage from "./ErrorMessage";

export default ({ label, ...props }) => {
  return (
    <div style={{ margin: "15px 0" }}>
      <Label>{label}</Label>
      <Textarea {...props} value={props.value} />
      {props.errorMessage && <ErrorMessage errorMessage={props.errorMessage} />}
    </div>
  );
};
