import React from "react";
import { useParams } from "react-router-dom";

import PageContainer from "components/lib/PageContainer";
import FaviconUploadForm from "components/SiteManage/Content/Favicon/FaviconUploadForm";
import { StoreIdParam } from "config/raven";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { UserContainer } from "containers";

const Favicon = () => {
  // 代理店ユーザーのみ利用可能な画面
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="ファビコン管理"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("ファビコン管理")}
    >
      <FaviconUploadForm storeId={storeId!} />
    </PageContainer>
  );
};

export default Favicon;
