import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import staffRepository from "domain/repository/staff";
import routes from "utils/routes";

import { useFormik } from "formik";
import * as Yup from "yup";
import message from "config/message.json";

import { Button, Form, Label, Segment, Loader } from "semantic-ui-react";

export const RoleTypes = {
  ADMIN: "admin",
  NORMAL: "normal",
} as const;

type RoleType = (typeof RoleTypes)[keyof typeof RoleTypes];

type FormValues = {
  name: string;
  email: string;
  role: RoleType;
  password: string;
  confirmPassword: string;
};

const initialValues: FormValues = {
  name: "",
  email: "",
  role: RoleTypes.ADMIN,
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(message.ERROR.NO_INPUT),
  email: Yup.string()
    .email(message.ERROR.INVALID_EMAIL)
    .required(message.ERROR.NO_INPUT),
  password: Yup.string().required(message.ERROR.NO_INPUT),
  confirmPassword: Yup.string()
    .required(message.ERROR.NO_INPUT)
    .oneOf([Yup.ref("password")], "パスワードが一致しません "),
  role: Yup.string().required(),
});

type Props = {};

export const StaffNewForm: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmailExist, setIsEmailExist] = useState<boolean>(false);

  const history = useHistory();
  const staffIndexPath = routes.adminAgency.agencyStaffIndex.pathname;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      setLoading(true);
      staffRepository
        .staffNew(values.name, values.email, values.password, values.role)
        .then(() => {
          setLoading(false);
          history.push(staffIndexPath);
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.data?.error?.email === undefined) {
            setIsEmailExist(false);
            return;
          } else {
            setIsEmailExist(true);
          }
        });
    },
    validateOnBlur: true,
    validateOnChange: false,
    initialStatus: { success: "", error: "" },
  });

  return (
    <>
      {loading ? (
        <Loader active content="新規スタッフを作成中" />
      ) : (
        <Segment>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Field required>
              <label>名前</label>
              <Form.Input
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.errors.name}
              />
            </Form.Field>
            <Form.Field required>
              <label>メールアドレス</label>
              <Form.Input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
              />
              {isEmailExist ? (
                <Label color="red" pointing>
                  このメールアドレスは既に使用されています
                </Label>
              ) : (
                <></>
              )}
            </Form.Field>
            <Form.Field required>
              <label>パスワード</label>
              <Form.Input
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.errors.password}
              />
            </Form.Field>
            <Form.Field required>
              <label>パスワード(確認)</label>
              <Form.Input
                type="password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.errors.confirmPassword}
              />
            </Form.Field>
            <Form.Field
              name="role"
              control="select"
              label="権限"
              value={formik.values.role}
              onChange={formik.handleChange}
            >
              <option value={RoleTypes.ADMIN}>管理者</option>
              <option value={RoleTypes.NORMAL}>スタッフ</option>
            </Form.Field>
            <div css={{ textAlign: "center" }}>
              <Button color="blue" type="submit">
                作成
              </Button>
            </div>
          </Form>
        </Segment>
      )}
    </>
  );
};
