import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Modal, Button, Form, Header } from "semantic-ui-react";

import message from "config/message.json";
import { FormType } from "domain/model/ContactFormTypes";

import { FormTypeLabel } from "components/Contact/lib/form";

type Props = {
  push: (object: any) => void;
  selectedType: FormType | null;
  setSelectedType: React.Dispatch<FormType | null>;
  setOpen: React.Dispatch<boolean>;
};

type pushValues = {
  label: string;
  type: FormType | null;
  options: Array<string>;
  required: boolean;
};

export const SingleLineForm: React.FC<Props> = ({
  push,
  selectedType,
  setSelectedType,
  setOpen,
}) => {
  const initialValues: pushValues = {
    label: "",
    type: selectedType,
    options: [],
    required: false,
  };

  const validationSchema = Yup.object().shape({
    label: Yup.string().required(message.ERROR.NO_INPUT),
    type: Yup.string(),
    options: Yup.array(),
    required: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit(values) {
      push({
        label: values.label,
        type: selectedType,
        options: values.options,
        required: values.required,
      });
      setOpen(false);
      setSelectedType(null);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <div css={{ margin: "0 60px" }}>
      <Form onSubmit={formik.handleSubmit}>
        <div css={{ textAlign: "center", margin: "20px" }}>
          <Header>
            フォーム追加-
            {selectedType === null ? null : FormTypeLabel[selectedType]}
          </Header>
          <Modal.Description>
            フォームの情報を入力して確定ボタンを押してください
          </Modal.Description>
        </div>
        <Modal.Content>
          <div css={{ textAlign: "center" }}>
            <label>フォーム名</label>
            <div css={{ margin: "16px" }}>
              <Form.Input
                name="label"
                value={formik.values.label}
                error={formik.errors.label}
                onChange={formik.handleChange}
                placeholder="フォーム名"
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div css={{ textAlign: "center", margin: "10px" }}>
            <Button
              type="button"
              color="grey"
              content="戻る"
              onClick={() => setSelectedType(null)}
            />
            <Button type="submit" color="blue" content="確定" />
          </div>
        </Modal.Actions>
      </Form>
    </div>
  );
};
