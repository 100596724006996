/**
 * サイドバーを表示するURLのパスを判定
 * 店舗内のURLはサイドバーを表示することを想定
 */
export const isEnableSidebarUrl = (pathname: string) => {
  if (pathname.match(/^\/users/)) {
    return false;
  }
  if (pathname.match(/^\/agency\/stores\/new/)) {
    return false;
  }

  if (pathname.match(/^\/agency\/(?!stores)/)) {
    return false;
  }

  if (pathname.match(/^\/agency\/stores\/\d/)) {
    return false;
  }

  if (pathname.match(/^\/stores\//)) {
    return true;
  }

  if (pathname.match(/^\/store\//)) {
    return true;
  }

  // この行は実行されないはず
  return true;
};

/**
 * Aサイドバーを表示するURLのパスを判定
 * ログイン系の画面以外のURLはAサイドバーを表示することを想定
 */
export const isEnableASidebarUrl = (pathname: string) => {
  if (pathname.match(/^\/users\//)) {
    return false;
  } else {
    return true;
  }
};
