import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { notificationService } from "domain/service";
import Notification from "domain/model/Notification";

import PageContainer from "components/lib/PageContainer";
import UserContainer from "containers/User";

import routes from "utils/routes";

import { AgencyNotificationList } from "components/AgencyNotification/Index/AgencyNotificationList";

import { Button } from "semantic-ui-react";

export const AgencyNotificationIndex: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;

  const history = useHistory();

  const notificationNewPath = routes.adminAgency.notificationNew.pathname;

  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    const fetchNotifications = () => {
      notificationService
        .fetchNotifications(isAgency)
        .then((res) => setNotifications(res));
    };
    fetchNotifications();
  }, [isAgency]);

  return (
    <PageContainer
      header="代理店からのお知らせ一覧"
      action={
        <>
          <Button
            color="blue"
            content="新規お知らせ投稿"
            onClick={() => history.push(notificationNewPath)}
          ></Button>
        </>
      }
    >
      <AgencyNotificationList notifications={notifications} />
    </PageContainer>
  );
};
