import React from "react";
import {
  useLocation,
  useHistory,
  useParams,
  generatePath,
} from "react-router-dom";

import { Button } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { Container } from "components/Version/Edit/Container";
import { StoreContainer, UserContainer } from "containers";
import { StoreIdParam } from "config/raven";
import routes from "utils/routes";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export type FileMeta = {
  key: string;
  objectHash: string;
  fileType: string;
  versionTitle: string;
  versionHash: string;
};

export const VersionEdit: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { currentStore } = StoreContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  const { state } = useLocation<FileMeta>();
  const history = useHistory();

  const { key, objectHash, fileType, versionTitle, versionHash } = state;

  return (
    <PageContainer
      header={`バージョン編集: ${versionTitle}`}
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .versionPage()
        .build(`バージョン編集: ${versionTitle}`)}
      action={
        <div css={{ display: "flex" }}>
          {!!currentStore?.awsResource.homepageDomain ? (
            <div css={{ marginRight: "16px" }}>
              <Button
                as="a"
                color="green"
                content="公開中のサイトを開く"
                href={`https://${currentStore.awsResource.homepageDomain}`}
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
          ) : null}
          <div css={{ marginRight: "16px" }}>
            <Button
              primary
              content="バージョン一覧へ"
              onClick={() => {
                const url = isAgency
                  ? generatePath(routes.agency.versionIndex.pathname, {
                      storeId,
                    })
                  : routes.store.versionIndex.pathname;
                history.push(url);
              }}
            />
          </div>
          <Button content="ソースツリーへ" onClick={() => history.goBack()} />
        </div>
      }
    >
      <Container
        objectHash={objectHash}
        filepath={key}
        versionHash={versionHash}
        fileType={fileType}
      />
    </PageContainer>
  );
};
