import React from "react";
import { useHistory, generatePath } from "react-router-dom";
import JobFeedItem from "components/JobFeed/Index/JobFeedItem";
import routes from "utils/routes";

import JobFeed from "domain/model/JobFeed";
import { Message, Table } from "semantic-ui-react";
import { jobFeedRepository } from "domain/repository";
import { JOB_FEED_LABEL } from "config/ItemLabel";

type Props = {
  jobFeeds: JobFeed[];
  isAgency: boolean;
  storeId: string;
};

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell css={{ width: "60px" }}>ID</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "120px" }}>求人状況</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "100px" }}>募集職種</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "150px" }}>
          雇用形態/採用区分
        </Table.HeaderCell>
        <Table.HeaderCell>勤務地</Table.HeaderCell>
        <Table.HeaderCell>URL</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "140px" }}>
          {/* 編集ボタン */}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export const JobFeedList: React.FC<Props> = ({
  isAgency,
  jobFeeds,
  storeId,
}) => {
  const history = useHistory();

  if (jobFeeds.length === 0) {
    return <Message content={`${JOB_FEED_LABEL}の情報はありません`} />;
  }

  return (
    <>
      <Table fixed>
        <TableHeader />
        <Table.Body>
          {jobFeeds.map((jobFeed, i) => {
            const handleEdit = () => {
              const editUrl = isAgency
                ? generatePath(routes.agency.jobFeedEdit.pathname, {
                    storeId,
                    id: jobFeed.id,
                  })
                : generatePath(routes.store.jobFeedEdit.pathname, {
                    id: jobFeed.id,
                  });
              history.push(editUrl);
            };
            const handleToggle = () => {
              jobFeedRepository
                .toggle(isAgency, storeId, jobFeed.id.toString())
                .then(() => {
                  window.location.reload();
                })
                .catch((e) => {
                  console.log(e);
                });
            };
            return (
              <JobFeedItem
                jobFeed={jobFeed}
                handleClickEdit={handleEdit}
                handleClickToggle={handleToggle}
                index={i}
                key={jobFeed.id}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default JobFeedList;
