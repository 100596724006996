/**
 * クリップボードに受け取った文字をコピーしてalertを表示する
 * @param text クリップボードにコピーするテキスト
 */
export const clipboardWriteText = (text: string) => {
  // IE以外はClipboard APIを利用可能
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(() => {
      alert("フォームのHTMLコードをコピーしました");
    });
  } else {
    const textarea = document.createElement("textarea");
    textarea.value = text.replace(/\r?\n/g, "\n");
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    alert("フォームのHTMLコードをコピーしました");
  }
};
