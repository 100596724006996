import React, { useState } from "react";
import {
  Message,
  Header,
  Form,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Formik } from "formik";
import { instagramRepository } from "domain/repository";
import { useInstagramStoreProfile } from "hooks/useInstagramStoreProfile";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
  setIsRegister: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormValues = {
  instagramAccountId: string;
  instagramHashtag: string;
};

const InstagramAuth: React.FC<Props> = ({
  isAgency,
  storeId,
  setIsRegister,
}) => {
  const { instagramStoreProfile } = useInstagramStoreProfile({
    isAgency,
    storeId,
  });

  const [isAuthedOk, setIsAuthedOk] = useState<boolean | null>(null);
  const AuthedMessage = () => {
    if (isAuthedOk === null) {
      return null;
    }
    if (isAuthedOk) {
      return (
        <Message positive content="Instagram 連携設定の登録が完了しました" />
      );
    }
    return <Message negative content="登録に失敗しました" />;
  };

  const initialValues: FormValues = {
    instagramAccountId: instagramStoreProfile?.instagramAccountId ?? "",
    instagramHashtag: instagramStoreProfile?.instagramHashtag ?? "",
  };

  return (
    <>
      <Header content="Instagram 連携機能設定登録" dividing />
      <Message content="Instagram 連携の情報を設定してください。アカウントIDとハッシュタグのうち一方のみが指定可能です。" />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          instagramRepository
            .instagramAuth({
              isAgency,
              storeId,
              instagramAccountId: values.instagramAccountId,
              instagramHashtag: values.instagramHashtag,
            })
            .then(() => {
              setIsAuthedOk(true);
            })
            .catch(() => setIsAuthedOk)
            .finally(() => {
              formikHelpers.setSubmitting(false);
            });
        }}
        // validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {({ values, handleChange, handleSubmit, errors, isSubmitting }) => (
          <>
            {isSubmitting ? (
              <Dimmer active>
                <Loader active content="登録中" size="large"></Loader>
              </Dimmer>
            ) : null}
            <Form submit={handleSubmit}>
              <Form.Input
                name="instagramAccountId"
                label="アカウントID"
                error={errors.instagramAccountId}
                value={values.instagramAccountId}
                placeholder="@xxx（@は省略可能です）"
                onChange={handleChange}
              />
              <Form.Input
                name="instagramHashtag"
                label="ハッシュタグ"
                error={errors.instagramHashtag}
                value={values.instagramHashtag}
                placeholder="#xxx（#は省略可能です）"
                onChange={handleChange}
              />
              <div css={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  content="登録"
                  color="blue"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={(event) => {
                    handleSubmit();
                    event.preventDefault();
                  }}
                />
              </div>
              <AuthedMessage />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default InstagramAuth;
