import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import message from "config/message.json";
import routes from "utils/routes";

import { DocumentWysiwygEditor } from "components/lib/Editor/DocumentWysiwygEditor";

import { Form, Segment, Button, Label, Confirm } from "semantic-ui-react";
import { notificationRepository } from "domain/repository";
import { RavenLoader } from "components/lib/RavenLoader";

type Props = {
  id: string;
  title: string;
  content: string;
  notificationIndexPath: string;
};

export const AgencyNotificationEditForm: React.FC<Props> = ({
  id,
  title,
  content,
  notificationIndexPath,
}) => {
  const history = useHistory();
  const [editError, setEditError] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDelete = () => {
    setDeleting(true);
    notificationRepository
      .deleteNotification({ id })
      .then(() => {
        history.push(notificationIndexPath);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const initialValues = {
    title,
    content,
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(message.ERROR.NO_INPUT),
    content: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      notificationRepository
        .updateNotification({
          id,
          title: values.title,
          content: values.content,
        })
        .then(() => {
          history.push(routes.adminAgency.notificationIndex.pathname);
        })
        .catch(() => {
          setEditError(true);
        });
    },
  });

  return (
    <Segment>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Field required>
          <label>タイトル</label>
          <Form.Input
            name="title"
            value={formik.values.title}
            error={formik.errors.title}
            onChange={formik.handleChange}
          />
        </Form.Field>
        <Form.Field name="content">
          <label>内容</label>
          <DocumentWysiwygEditor
            data={formik.values.content}
            onChange={(value) => {
              formik.setFieldValue("content", value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <div css={{ textAlign: "center" }}>
            <Form.Button type="submit" content="更新" color="blue" />
          </div>
          {editError ? (
            <Label color="red" pointing="below">
              編集に失敗しました
            </Label>
          ) : (
            <></>
          )}
        </Form.Field>
      </Form>
      <Button
        color="red"
        content="このお知らせを削除する"
        onClick={() => setOpenDeleteModal(true)}
      />
      <RavenLoader active={deleting} content="削除中" />
      <Confirm
        open={openDeleteModal}
        content="お知らせを削除してもよろしいですか？"
        onCancel={() => setOpenDeleteModal(false)}
        onConfirm={handleDelete}
      />
    </Segment>
  );
};
