import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import message from "config/message.json";
import routes from "utils/routes";

import { DocumentWysiwygEditor } from "components/lib/Editor/DocumentWysiwygEditor";
import { notificationRepository } from "domain/repository";

import { Segment, Form, Label } from "semantic-ui-react";

export const AgencyNotificationNewForm: React.FC = () => {
  const history = useHistory();
  const [createError, setCreateError] = useState<boolean>(false);

  const initialValues = {
    title: "",
    content: "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(message.ERROR.NO_INPUT),
    content: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      notificationRepository
        .createNotification({
          title: values.title,
          content: values.content,
        })
        .then(() => {
          history.push(routes.adminAgency.notificationIndex.pathname);
        })
        .catch(() => {
          setCreateError(true);
        });
    },
  });

  return (
    <Segment>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Field required>
          <label>タイトル</label>
          <Form.Input
            name="title"
            value={formik.values.title}
            error={formik.errors.title}
            onChange={formik.handleChange}
          />
        </Form.Field>
        <Form.Field name="content">
          <label>内容</label>
          <DocumentWysiwygEditor
            data={formik.values.content}
            onChange={(value) => {
              formik.setFieldValue("content", value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <div css={{ textAlign: "center" }}>
            <Form.Button type="submit" content="投稿" color="blue" />
          </div>
          {createError ? (
            <Label color="red" pointing="below">
              投稿に失敗しました
            </Label>
          ) : (
            <></>
          )}
        </Form.Field>
      </Form>
    </Segment>
  );
};
