import React, { useEffect, useState } from "react";
import { useHistory, generatePath } from "react-router-dom";

import routes from "utils/routes";

import { Segment, Header, Grid, List, Label } from "semantic-ui-react";
import MenuItem from "components/lib/MenuItem";
import Notification from "domain/model/Notification";
import { notificationService } from "domain/service";
import dayjs from "dayjs";

type Props = {
  isAgency: boolean;
  storeId: string;
};

const StoreMenu: React.FC<Props> = ({ isAgency, storeId }) => {
  const [notifications, setNotifications] = useState<Notification[] | null>(
    null
  );
  const history = useHistory();

  const notificationIndexPath = isAgency
    ? generatePath(routes.agency.notificationIndex.pathname, {
        storeId,
      })
    : routes.store.notificationIndex.pathname;

  useEffect(() => {
    const fetchNotifications = () => {
      notificationService
        .fetchNotifications(isAgency)
        .then((res) => setNotifications(res));
    };
    fetchNotifications();
  }, [isAgency]);

  return (
    <Segment css={{ height: "250px" }}>
      <Header color="grey" />
      <Grid columns="2" doubling>
        <Grid.Row>
          <Grid.Column>
            <MenuItem
              icon="announcement"
              header="代理店からのお知らせ一覧"
              content="代理店からのお知らせ"
              onClick={() => history.push(notificationIndexPath)}
            />
            {notifications !== null ? (
              <>
                <Header content={`最近のお知らせ`} size="small" />
                <List bulleted>
                  {notifications.map((notification, index) => {
                    if (index > 3) {
                      return null;
                    }
                    // 直近10日間のお知らせはNewと判断する
                    const isNew =
                      dayjs(notification.createdAt).unix() >=
                      dayjs().subtract(10, "day").unix();
                    return (
                      <List.Item>
                        {dayjs(notification.createdAt).format("YYYY-MM-DD")}
                        {": "}
                        {notification.title}
                        {isNew ? (
                          <Label
                            size="mini"
                            color="green"
                            css={{ marginLeft: "6px!important" }}
                          >
                            NEW
                          </Label>
                        ) : null}
                      </List.Item>
                    );
                  })}
                </List>
              </>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default React.memo(StoreMenu);
