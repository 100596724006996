import React, { useState } from "react";
import { Button, Form, Loader, Message } from "semantic-ui-react";
import MESSAGE from "config/message.json";
import { generatePath, useHistory } from "react-router-dom";
import routes from "utils/routes";
import { SeoPreferencesForm } from "components/lib/Post/SeoPreferencesForm";
import { PostContentForm } from "components/lib/Post/PostContentForm";
import { Formik } from "formik";
import dayjs from "dayjs";
import { articleRepository } from "domain/repository";
import { PostPreview, PostTypes } from "components/lib/Post/PostPreview";
import { SelectMediaModal } from "components/lib/SelectMediaModal";
import { usePostSchema } from "hooks/usePostSchema";
import { usePostMenu } from "hooks/usePostMenu";
import { useAllMediaUrls } from "hooks/useAllMediaUrls";
import { useArticleSubCategory } from "components/Post/Article/useArticleSubCategory";
import { useArticleTemplate } from "components/Post/Article/useArticleTemplate";
import { useSeoPreferences } from "hooks/useSeoPreferences";

type Props = {
  isAgency: boolean;
  storeId: string;
};

export const NewFormContainer: React.FC<Props> = ({ isAgency, storeId }) => {
  const { seoPreferences } = useSeoPreferences({ isAgency, storeId });
  const { template } = useArticleTemplate({ isAgency, storeId });
  const { subCategories, NON_SUB_CATEGORY_VALUE } = useArticleSubCategory({
    isAgency,
    storeId,
  });
  const { mediaUrls, refetchMediaUrls } = useAllMediaUrls({
    isAgency,
    storeId,
    imageOnly: true,
  });
  const { activeMenu, MenuItems, renderMenu } = usePostMenu();
  const { validationSchema } = usePostSchema();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [openThumbnailModal, setOpenThumbnailModal] = useState(false);

  const articleIndexUrl = isAgency
    ? generatePath(routes.agency.articleIndex.pathname, {
        storeId,
      })
    : routes.store.articleIndex.pathname;
  const history = useHistory();

  const renderContent = () => {
    if (activeMenu === MenuItems.POST_CONTENT) {
      return (
        <PostContentForm
          subCategories={subCategories}
          mediaUrls={mediaUrls}
          refetchMediaUrls={refetchMediaUrls}
          isPublished={false}
        />
      );
    }
    if (activeMenu === MenuItems.POST_PREVIEW) {
      return <PostPreview template={template} postType={PostTypes.ARTICLE} />;
    }
    if (activeMenu === MenuItems.SEO_PREFERENCES) {
      return (
        <SeoPreferencesForm
          mediaUrls={mediaUrls}
          refetchMediaUrls={refetchMediaUrls}
        />
      );
    }
  };

  if (seoPreferences === null) {
    return <Loader active />;
  }

  return (
    <Formik
      initialValues={{
        postContent: {
          title: "",
          postDate: dayjs(dayjs().format("YYYY/MM/DD HH:mm")).toDate(), // UIに合わせて分までの情報で丸める
          subCategoryValue: NON_SUB_CATEGORY_VALUE,
          slug: "",
          content: "",
          thumbnailUrl: "",
        },
        seoPreferences,
        isDraft: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(
        { postContent, seoPreferences, isDraft },
        { setFieldValue, setFieldError, resetForm, setSubmitting }
      ) => {
        // https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
        const slug = (postContent.slug || postContent.title).replace(
          /[ -/:-@[-`{-~]/g,
          "_"
        );
        setErrorMessage(null);
        articleRepository
          .addNewPost(
            isAgency,
            storeId,
            {
              postContent: {
                title: postContent.title,
                slug,
                content: postContent.content,
                postDate: postContent.postDate,
                thumbnailUrl: postContent.thumbnailUrl,
                subcategory:
                  postContent.subCategoryValue === NON_SUB_CATEGORY_VALUE
                    ? ""
                    : postContent.subCategoryValue,
              },
              seoPreferences,
            },
            isDraft
          )
          .then(() => {
            resetForm();
            history.push(articleIndexUrl);
          })
          .catch((e: string) => {
            if (e === `{:filepath=>["has already been taken"]}`) {
              setFieldError(
                "postContent.slug",
                MESSAGE.ERROR.POST.ALREADY_USE_SLUG
              );
            } else if (e === `{:slug=>["is invalid"]}`) {
              setFieldError(
                "postContent.slug",
                MESSAGE.ERROR.POST.INVALID_SLUG
              );
            } else {
              setErrorMessage(MESSAGE.ERROR.POST.NEW_ERROR);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ handleSubmit, setFieldValue, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <div
            css={{
              margin: "16px 0",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              css={{ margin: "0 16px 0 0 !important" }}
              content="サムネイル"
              icon="image"
              primary
              onClick={(e) => {
                setOpenThumbnailModal(true);
                e.preventDefault();
              }}
            />
            <SelectMediaModal
              title="サムネイルの設定"
              mediaUrls={mediaUrls ?? []}
              onUploaded={refetchMediaUrls}
              open={openThumbnailModal}
              handleMediaClick={(url) => {
                setFieldValue("postContent.thumbnailUrl", url);
                setOpenThumbnailModal(false);
              }}
              close={() => setOpenThumbnailModal(false)}
            />
            <Button
              css={{ margin: "0 16px 0 0 !important" }}
              positive
              icon="pencil alternate"
              content="下書き保存"
              onClick={() => {
                setFieldValue("isDraft", true);
              }}
              type="submit"
              loading={isSubmitting}
            />
            <Button
              positive
              icon="paper plane"
              content="公開"
              onClick={() => {
                setFieldValue("isDraft", false);
              }}
              type="submit"
              loading={isSubmitting}
            />
          </div>
          {renderMenu()}
          {errorMessage ? (
            <Message negative content={errorMessage} size="small" />
          ) : null}
          {renderContent()}
        </Form>
      )}
    </Formik>
  );
};
