import api from "utils/api";
import endpoint from "utils/endpoint";

class Repository {
  createSitemap(
    storeId: string,
    {
      homeChangeFreqValue,
      postChangeFreqValue,
    }: { homeChangeFreqValue: string; postChangeFreqValue: string }
  ): Promise<string> {
    const body = {
      sitemap: {
        home_change_freq: homeChangeFreqValue,
        post_change_freq: postChangeFreqValue,
      },
    };
    const url = endpoint.agency.sitemap.root(storeId);
    return api.post(url, body).then((res) => res.data.sitemap_uri);
  }

  submitSitemap(storeId: string) {
    const url = endpoint.agency.sitemap.root(storeId);
    return api.put(url);
  }
}

export const sitemapRepository = new Repository();
