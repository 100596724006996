import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import TakeoutLogin, {
  TakeoutSales,
  TakeoutSalesUpdated,
  TakeoutAuth,
} from "domain/model/ExternalTakeout";

class ExternalTakeoutService {
  public fetchTakeoutLogin({
    isAgency,
    storeId,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
  }): Promise<TakeoutLogin[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.takeoutLogin.root(storeId)
        : endpoint.store.takeoutLogin.root();

    return api.get(url).then((res) => {
      return plainToClass<TakeoutLogin, unknown[]>(TakeoutLogin, res.data, {
        excludeExtraneousValues: true,
      });
    });
  }

  public async fetchTakeoutSalesUpdated({
    isAgency,
    storeId,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
  }): Promise<TakeoutSalesUpdated[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.takeoutSales.updated(storeId)
        : endpoint.store.takeoutSales.updated();

    return api.get(url).then((res) => {
      return plainToClass<TakeoutSalesUpdated, unknown[]>(
        TakeoutSalesUpdated,
        res.data,
        {
          excludeExtraneousValues: true,
        }
      );
    });
  }

  public async fetchOrderHistory({
    isAgency,
    storeId,
    takeoutSite,
    fromDate,
    toDate,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    takeoutSite: string;
    fromDate?: number;
    toDate?: number;
  }) {
    const url =
      isAgency && storeId
        ? endpoint.agency.takeoutSales.root(storeId)
        : endpoint.store.takeoutSales.root();

    return api
      .get(url, {
        params: { site: takeoutSite, from_date: fromDate, to_date: toDate },
      })
      .then((res) => {
        return res.data.map((takeoutSales: TakeoutSales) =>
          plainToClass(TakeoutSales, takeoutSales, {
            excludeExtraneousValues: true,
          })
        );
      });
  }

  public async fetchExternalTakeoutAuth({
    isAgency,
    storeId,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
  }): Promise<TakeoutAuth[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.externalTakeoutAuth.root(storeId)
        : endpoint.store.externalTakeoutAuth.root();

    return api.get(url).then((res) => {
      return plainToClass<TakeoutAuth, unknown[]>(TakeoutAuth, res.data, {
        excludeExtraneousValues: true,
      });
    });
  }
}

export const externalTakeoutService = new ExternalTakeoutService();
