import React from "react";
import { JobFeedForm, SubmitTypes } from "components/JobFeed/JobFeedForm";
import { generatePath, useHistory } from "react-router-dom";
import routes from "utils/routes";
import { jobFeedRepository } from "domain/repository";
import dayjs from "dayjs";

type Props = {
  isAgency: boolean;
  storeId: string;
};

export const NewForm: React.FC<Props> = ({ isAgency, storeId }) => {
  const history = useHistory();

  return (
    <JobFeedForm
      initialValues={{
        status: "advertising",
        title: "",
        company: "",
        jobType: "full_time",
        description: "",
        postDate: dayjs().toDate(),
        salaryType: "hour",
        salaryMin: 1000,
        salaryMax: 1000,
        zipCode: null,
        state: "",
        city: "",
        address: null,
        station: null,
        experience: "",
        workingTime: "",
        holiday: "",
        benefits: "",
        insurance: "",
        preventSmoke: "",
        contractPeriod: "",
        url: null,
        imageUrl: null,
        companyUrl: null,
        companyLogoUrl: null,
        pageHeader: "",
        pageFooter: "",
      }}
      isAgency={isAgency}
      storeId={storeId}
      handleSubmit={({ values, setStatus, resetForm }) => {
        jobFeedRepository
          .create(isAgency, storeId, values)
          .then(() => {
            resetForm();
            const jobFeedIndex = isAgency
              ? generatePath(routes.agency.jobFeedIndex.pathname, {
                  storeId,
                })
              : routes.store.jobFeedIndex;
            history.push(jobFeedIndex);
          })
          .catch((e) => {
            if (e.response.status === 422) {
              setStatus({ success: "", error: e.response.data.message });
            } else if (e.response.data.error["ref_url"] !== undefined) {
              setStatus({
                success: "",
                error: "ドメイン名を取得するか、遷移先URLを記述してください",
              });
            } else {
              setStatus({ success: "", error: "更新に失敗しました" });
            }
          });
      }}
      submitType={SubmitTypes.CREATE}
    />
  );
};
