import React from "react";
import { useHistory } from "react-router-dom";

import routes from "utils/routes";

import Staff from "domain/model/Staff";

import { RoleType, RoleTypes } from "components/AgencyStaff/Index";

import { Table, Button } from "semantic-ui-react";

type Props = {
  staffs: Staff[];
  userType: RoleType;
};

export const StaffList: React.FC<Props> = ({ staffs, userType }) => {
  const staffShowPath = routes.adminAgency.agencyStaffShow.pathname;
  const history = useHistory();
  return (
    <Table singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>代理店スタッフID</Table.HeaderCell>
          <Table.HeaderCell>名前</Table.HeaderCell>
          <Table.HeaderCell>メールアドレス</Table.HeaderCell>
          <Table.HeaderCell>権限</Table.HeaderCell>
          {userType === RoleTypes.ADMIN ? (
            <Table.HeaderCell>操作</Table.HeaderCell>
          ) : (
            <></>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {staffs?.map((staff: Staff, index: number) => {
          return (
            <Table.Row key={index}>
              <Table.Cell>{staff.id}</Table.Cell>
              <Table.Cell>{staff.name}</Table.Cell>
              <Table.Cell>{staff.email}</Table.Cell>
              <Table.Cell>
                {staff.role === "admin" ? "管理者" : "スタッフ"}
              </Table.Cell>
              {userType === RoleTypes.ADMIN ? (
                <Table.Cell>
                  {
                    <Button
                      icon="eye"
                      content="詳細へ"
                      onClick={() =>
                        history.push(staffShowPath, {
                          name: staff.name,
                          email: staff.email,
                          id: staff.id,
                        })
                      }
                    ></Button>
                  }
                </Table.Cell>
              ) : (
                <></>
              )}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
