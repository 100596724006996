import api from "utils/api";
import endpoint from "utils/endpoint";

class RecruitRepository {
  /**
   * 代理店ユーザーのみ利用可能
   */
  public async enable(isAgency: boolean, storeId: string) {
    const url = endpoint.agency.jobOffer.use(storeId);
    return api.post(url);
  }
}

export default new RecruitRepository();
