import React from "react";
import { Card, Message } from "semantic-ui-react";

type Props = {
  mediaUrls: string[];
  handleMediaClick: (url: string) => void;
};

export const SelectMediaTab: React.FC<Props> = ({
  mediaUrls,
  handleMediaClick,
}) => (
  <Card.Group itemsPerRow={4} css={{ maxHeight: "60vh", overflow: "auto" }}>
    {mediaUrls.length === 0 && (
      <Message content="画像がありません。メディア管理から画像をアップロードしてください。" />
    )}
    {mediaUrls.map((url, i) => (
      <Card
        key={`${url}-${i}`}
        onClick={() => handleMediaClick(url)}
        raised
        image={url}
      ></Card>
    ))}
  </Card.Group>
);
