import { useEffect } from "react";

export default (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current.contains(e.target)) return;

    callback(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });
};
