import React from "react";
import { Table, Button, Label, Popup } from "semantic-ui-react";
import JobFeed from "domain/model/JobFeed";
import styled from "@emotion/styled";

type Props = {
  jobFeed: JobFeed;
  handleClickEdit: () => void;
  handleClickToggle: () => void;
  index: number;
};

const JobFeedItem: React.FC<Props> = ({
  jobFeed,
  handleClickEdit,
  handleClickToggle,
  index,
}) => {
  const handleClickLink = () => {
    if (jobFeed.refUrl != null) {
      window.open(jobFeed.refUrl, "_blank");
    }
  };
  return (
    <Table.Row key={index}>
      <Table.Cell>{jobFeed.id}</Table.Cell>
      <Table.Cell>
        <StatusLabel color={jobFeed.statusColor()}>
          {jobFeed.statusText()}
        </StatusLabel>
        {(() => {
          const errorText = jobFeed.errorText();
          if (errorText !== null) {
            return <StatusLabel color="red">{errorText}</StatusLabel>;
          }
        })()}
      </Table.Cell>
      <Table.Cell title={jobFeed.title}>{jobFeed.title}</Table.Cell>
      <Table.Cell>{jobFeed.jobTypeText()}</Table.Cell>
      <Table.Cell>
        {jobFeed.state}
        {jobFeed.city}
      </Table.Cell>
      <Table.Cell css={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
        {jobFeed.refUrl}
      </Table.Cell>
      <Table.Cell>
        <div css={{ display: "flex", justifyContent: "flex-end" }}>
          <Popup
            content="遷移先を確認する"
            inverted
            style={{ opacity: 0.8 }}
            trigger={
              <Button
                icon="linkify"
                disabled={jobFeed.refUrl != null ? false : true}
                onClick={handleClickLink}
                color="blue"
                size="small"
                primary
              />
            }
          />
          <Popup
            content="編集する"
            inverted
            style={{ opacity: 0.8 }}
            trigger={
              <Button
                icon="edit"
                onClick={handleClickEdit}
                color="blue"
                size="small"
                primary
              />
            }
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

const StatusLabel = styled(Label)`
  width: 80px;
  text-align: center;
`;

export default JobFeedItem;
