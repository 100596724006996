import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { UserContainer, StoreContainer } from "containers";
import { businessDayTypeService } from "domain/service";

import { BusinessDayForm } from "components/StoreProfile/BusinessDay/BusinessDayForm";
import { Loader } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { StoreIdParam } from "config/raven";
import BusinessDayType from "domain/model/BusinessDayType";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const StoreProfileBusinessDay = () => {
  const [businessDayTypes, setBusinessDayTypes] = useState<
    BusinessDayType[] | null
  >(null);
  const userContainer = UserContainer.useContainer();
  const storeContainer = StoreContainer.useContainer();
  const { isAgency } = userContainer;
  const { currentStore } = storeContainer;
  const { storeId } = useParams<StoreIdParam>();

  useEffect(() => {
    businessDayTypeService
      .fetchAllBusinessDayTypes(isAgency, storeId)
      .then(setBusinessDayTypes);
  }, [isAgency, storeId]);

  return (
    <PageContainer
      header="営業時間管理"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .storeProfilePage()
        .build("営業時間管理")}
    >
      {currentStore && businessDayTypes ? (
        <BusinessDayForm
          currentStore={currentStore}
          isAgency={isAgency}
          storeId={storeId}
          businessDayTypes={businessDayTypes}
        />
      ) : (
        <Loader active />
      )}
    </PageContainer>
  );
};
