import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";

import Yaml from "domain/model/Yaml";

class YamlService {
  async fetchYaml(
    isAgency: boolean,
    storeId: string | undefined,
    id: string
  ): Promise<{ yaml: Yaml; content: string | null }> {
    const url =
      isAgency && storeId
        ? endpoint.agency.yaml.show(storeId, id)
        : endpoint.store.yaml.show(id);
    return api.get(url).then((res) => {
      return {
        yaml: plainToClass(Yaml, res.data.yaml, {
          excludeExtraneousValues: true,
        }),
        content: res.data.content,
      };
    });
  }

  async fetchAllYamls(
    isAgency: boolean,
    storeId: string | undefined
  ): Promise<Yaml[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.yaml.root(storeId)
        : endpoint.store.yaml.root();
    return api
      .get(url, {})
      .then((res) =>
        res.data.yamls.map((d: Yaml) =>
          plainToClass(Yaml, d, { excludeExtraneousValues: true })
        )
      );
  }
}

export default new YamlService();
