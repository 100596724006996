import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";

import { userRepository } from "domain/repository";

import { Form, Segment, Message, Header } from "semantic-ui-react";
import MESSAGE from "config/message.json";

type Props = {
  isAgency: boolean;
};

const initialValues = {
  password: "",
  password_confirmation: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  password_confirmation: Yup.string()
    .required(MESSAGE.ERROR.NO_INPUT)
    .oneOf([Yup.ref("password")], "新しいパスワードと一致していません。"),
});

const PasswordUpdateForm: React.FC<Props> = ({ isAgency }) => {
  return (
    <Segment>
      <Header content="パスワード" size="small" icon="lock" />
      <Formik
        initialValues={initialValues}
        initialStatus={{ success: "", error: "" }}
        onSubmit={(values, { setStatus }) => {
          userRepository
            .passwordUpdate(isAgency, values)
            .then(() => {
              setStatus({ success: "パスワードを更新しました。", error: "" });
            })
            .catch(() => {
              setStatus({
                success: "",
                error:
                  "パスワードの更新に失敗しました。時間を置いて再度お試し下さい。",
              });
            });
        }}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({ values, errors, status, handleChange, handleSubmit }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Input
                name="password"
                type="password"
                label="新しいパスワード"
                error={errors.password}
                value={values.password}
                onChange={handleChange}
              />
              <Form.Input
                name="password_confirmation"
                type="password"
                label="新しいパスワード（確認）"
                error={errors.password_confirmation}
                value={values.password_confirmation}
                onChange={handleChange}
              />
              <div css={{ textAlign: "center" }}>
                <Form.Button type="submit" color="blue" content="更新" />
              </div>
            </Form>
            {status.success && <Message success content={status.success} />}
            {status.error && <Message error content={status.error} />}
          </>
        )}
      </Formik>
    </Segment>
  );
};

export default PasswordUpdateForm;
