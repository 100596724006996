import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "@emotion/styled";
import COLOR from "config/color.json";

import Store from "domain/model/Store";

import { Table, Icon, Popup, Message } from "semantic-ui-react";

type Props = {
  stores: Store[];
  moveDashboard: Function;
};

const StoreList: React.FC<Props> = ({ stores, moveDashboard }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const ActionIconWrap = styled.div`
    border: 1px solid ${COLOR.SUB2};
    border-radius: 3px;
    color: ${COLOR.SUB2};
    height: 2.2rem;
    width: ${isMobile ? "auto" : "2.2rem"};
    margin: 0 0 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: ${COLOR.SUB3};
    }
  `;

  return stores.length > 0 ? (
    <Table striped fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell css={{ width: "10%" }}>店舗ID</Table.HeaderCell>
          <Table.HeaderCell css={{ width: "30%" }}>店舗名</Table.HeaderCell>
          <Table.HeaderCell css={{ width: "25%" }}>
            メールアドレス
          </Table.HeaderCell>
          <Table.HeaderCell css={{ width: "20%" }}>代理店名</Table.HeaderCell>
          <Table.HeaderCell css={{ width: "15%" }} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {stores.map((store, index) => {
          if (store.isDeleted) {
            return null;
          }
          return (
            <Table.Row key={index}>
              <Table.Cell>{store.id}</Table.Cell>
              <Table.Cell>{store.name}</Table.Cell>
              <Table.Cell>{store.email}</Table.Cell>
              <Table.Cell>{store.agency.name}</Table.Cell>
              <Table.Cell>
                <div css={{ display: "flex", justifyContent: "flex-end" }}>
                  <Popup
                    content="この店舗の管理画面へ"
                    inverted
                    style={{ opacity: 0.8 }}
                    position="left center"
                    trigger={
                      <ActionIconWrap onClick={() => moveDashboard(store.id)}>
                        {isMobile ? (
                          "この店舗の管理画面へ"
                        ) : (
                          <Icon name="sign-in" fitted />
                        )}
                      </ActionIconWrap>
                    }
                  />
                  <Popup
                    content="公開中のサイトを開く"
                    inverted
                    style={{ opacity: 0.8 }}
                    position="left center"
                    trigger={
                      <ActionIconWrap
                        onClick={() =>
                          window.open(
                            `https://${store.awsResource.homepageDomain}`
                          )
                        }
                      >
                        {isMobile ? (
                          "公開中のサイトを開く"
                        ) : (
                          <Icon name="globe" fitted />
                        )}
                      </ActionIconWrap>
                    }
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  ) : (
    <Message error content="データが存在しません。" />
  );
};

export default StoreList;
