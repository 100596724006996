import React from "react";
import AwsResource from "domain/model/AwsResource";
import { DomainInformation } from "components/SiteManage/Content/Domain/DomainInformation/DomainInformation";
import { ExternalDnsRecordSet } from "components/SiteManage/Content/Domain/DomainInformation/ExternalDnsRecordset";
import { AwsStatus } from "config/raven";

type Props = {
  awsResource: AwsResource;
  awsState: AwsStatus;
};

export const DomainInformationContainer: React.FC<Props> = ({
  awsResource,
  awsState,
}) => {
  const { externalRegistrar } = awsResource;
  return (
    <>
      <DomainInformation awsResource={awsResource} awsState={awsState} />
      {externalRegistrar ? (
        <ExternalDnsRecordSet awsResource={awsResource} />
      ) : null}
    </>
  );
};
