import React from "react";
import { UserContainer } from "containers";
import { useParams } from "react-router-dom";
import { StoreIdParam } from "config/raven";
import PageContainer from "components/lib/PageContainer";
import { Segment } from "semantic-ui-react";
import { EditFormContainer } from "components/SiteManage/Content/Yaml/Edit/EditFormContainer";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const YamlEdit: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId, id } = useParams<StoreIdParam & { id: string }>();

  return (
    <PageContainer
      header="YAML編集"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("YAML管理 編集")}
    >
      <Segment>
        <EditFormContainer isAgency={isAgency} storeId={storeId} id={id} />
      </Segment>
    </PageContainer>
  );
};
