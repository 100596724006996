import React from "react";
import { Formik, FieldArray, Field } from "formik";
import * as Yup from "yup";

import { Modal, Button, Form, Segment, Header } from "semantic-ui-react";

import message from "config/message.json";
import { FormType } from "domain/model/ContactFormTypes";

import { FormTypeLabel } from "components/Contact/lib/form";

type Props = {
  push: (object: any) => void;
  selectedType: FormType | null;
  setSelectedType: React.Dispatch<FormType | null>;
  setOpen: React.Dispatch<boolean>;
};

type pushValues = {
  label: string;
  type: FormType | null;
  options: Array<string>;
  required: boolean;
};

export const MultipleLineForm: React.FC<Props> = ({
  push,
  selectedType,
  setSelectedType,
  setOpen,
}) => {
  const initialValues: pushValues = {
    label: "",
    type: selectedType,
    options: ["", "", ""],
    required: false,
  };

  const validationSchema = Yup.object().shape({
    label: Yup.string().required(message.ERROR.NO_INPUT),
    type: Yup.string(),
    options: Yup.array<string>()
      .min(1)
      .test("form-test", message.ERROR.INVALID_SELECT, (forms) => {
        if (!forms) {
          return false;
        }
        const filteredArray = forms.filter((char: string) => char?.length > 0);
        return filteredArray.length !== 0;
      }),
    required: Yup.boolean(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        push({
          label: values.label,
          type: selectedType,
          options: values.options,
          required: values.required,
        });
        setSelectedType(null);
        setOpen(false);
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formik) => {
        return (
          <div css={{ margin: "0 60px" }}>
            <Form onSubmit={formik.handleSubmit}>
              <div css={{ textAlign: "center", margin: "20px" }}>
                <Header>
                  フォーム追加-
                  {selectedType === null ? null : FormTypeLabel[selectedType]}
                </Header>
                <Modal.Description>
                  フォームの情報を入力して確定ボタンを押してください
                </Modal.Description>
              </div>
              <Modal.Content>
                <div css={{ textAlign: "center" }}>
                  <label>フォーム名</label>
                  <div css={{ margin: "16px" }}>
                    <Form.Input
                      name="label"
                      value={formik.values.label}
                      error={formik.errors.label}
                      onChange={formik.handleChange}
                      placeholder="フォーム名"
                    />
                  </div>
                  <Segment>
                    <FieldArray
                      name="options"
                      render={({ push, remove }) => (
                        <>
                          {formik.values.options.map((_, index) => (
                            <Form.Field key={index}>
                              <label>選択肢{index + 1}</label>
                              <div
                                css={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div css={{ marginRight: "16px" }}>
                                  <Field
                                    name={`options.${index}`}
                                    error={formik.errors.options}
                                    as={Form.Input}
                                    placeholder="選択肢"
                                  />
                                </div>
                                <Field
                                  as={Form.Button}
                                  type="button"
                                  color="red"
                                  content="削除"
                                  onClick={() => remove(index)}
                                />
                              </div>
                            </Form.Field>
                          ))}
                          <Button
                            type="button"
                            color="yellow"
                            content="選択肢追加"
                            onClick={() => {
                              push("");
                            }}
                          />
                        </>
                      )}
                    />
                  </Segment>
                </div>
              </Modal.Content>
              <Modal.Actions>
                <div css={{ textAlign: "center", margin: "10px" }}>
                  <Button
                    type="button"
                    color="grey"
                    content="戻る"
                    onClick={() => setSelectedType(null)}
                  />
                  <Button type="submit" color="blue" content="確定" />
                </div>
              </Modal.Actions>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
