import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { userRepository } from "domain/repository";

import { Form, Segment, Message, Header } from "semantic-ui-react";
import MESSAGE from "config/message.json";
import { CurrentUser } from "config/raven";

type Props = {
  isAgency: boolean;
  currentUser: CurrentUser;
  updateCurrentUser: () => void;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  email: Yup.string()
    .email(MESSAGE.ERROR.INVALID_EMAIL)
    .required(MESSAGE.ERROR.NO_INPUT),
});

const AccountUpdateForm: React.FC<Props> = ({
  isAgency,
  currentUser,
  updateCurrentUser,
}) => {
  const id = currentUser?.id;
  const name = currentUser?.staffName ?? "";
  const email = currentUser?.staffEmail ?? "";
  const formik = useFormik({
    initialValues: {
      name,
      email,
    },
    onSubmit: (values, { setStatus }) => {
      if (id) {
        userRepository
          .accountUpdate(isAgency, {
            staffId: id,
            name: values.name,
            email: values.email,
          })
          .then(() => {
            updateCurrentUser();
            setStatus({ success: "アカウント情報を更新しました。", error: "" });
          })
          .catch(() => {
            setStatus({
              success: "",
              error: "更新に失敗しました。",
            });
          });
      } else {
        setStatus({
          success: "",
          error: "更新に失敗しました。",
        });
      }
    },
    initialStatus: { success: "", error: "" },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });
  const isNormalRole = currentUser?.role === "normal";
  return (
    <Segment>
      <Header content="アカウント情報" size="small" icon="user" />
      <Form onSubmit={formik.handleSubmit}>
        <Form.Input
          name="name"
          label="ユーザー名"
          error={formik.errors.name}
          value={formik.values.name}
          onChange={formik.handleChange}
          disabled={isNormalRole}
        />
        <Form.Input
          name="email"
          type="email"
          label="メールアドレス"
          error={formik.errors.email}
          value={formik.values.email}
          onChange={formik.handleChange}
          disabled={isNormalRole}
        />
        <div css={{ textAlign: "center" }}>
          <Form.Button
            type="submit"
            color="blue"
            content="更新"
            disabled={isNormalRole}
          />
        </div>
      </Form>
      {formik.status.success && (
        <Message success content={formik.status.success} />
      )}
      {formik.status.error && <Message error content={formik.status.error} />}
    </Segment>
  );
};

export default AccountUpdateForm;
