import api from "utils/api";
import endpoint from "utils/endpoint";
import { DomainRegistrar } from "config/raven";

type DomainInfo = {
  domain: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  phoneNumber: string;
  email: string;
  zipCode: string;
  state: string;
  city: string;
  addressLine1: string;
  addressLine2: string;
};

type ExternalDomainInfo = {
  domain: string;
  hpDomain: string;
  registrarType: DomainRegistrar;
};

class Repository {
  registerDomain(
    storeId: string,
    {
      domain,
      firstName,
      lastName,
      organizationName,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      phoneNumber,
      email,
    }: DomainInfo
  ) {
    const body = {
      domain: { domain, duration: 1 },
      contact: {
        first_name: firstName,
        last_name: lastName,
        contact_type: "COMPANY",
        organization_name: organizationName,
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        city,
        state,
        country_code: "JP",
        zip_code: zipCode,
        phone_number: phoneNumber,
        email,
      },
    };
    const url = endpoint.agency.domain.contact(storeId);
    return api.post(url, body);
  }

  registerExternalDomain(
    storeId: string,
    { domain, hpDomain, registrarType }: ExternalDomainInfo
  ) {
    const body = {
      domain: {
        domain,
        hp_domain: hpDomain,
        registrar_type: registrarType,
      },
    };
    const url = endpoint.agency.domain.externalDomain(storeId);
    return api
      .post(url, body)
      .then(() => true)
      .catch(() => false);
  }
}

export const domainRepository = new Repository();
