import React, { useEffect } from "react";
import { Segment, Header, Grid } from "semantic-ui-react";
import MenuItem from "components/lib/MenuItem";
import { StoreContainer } from "containers";
import { autoLogin } from "utils/sitest";
import { AGENCY_OPTION } from "config/raven";
const AGENCY: AGENCY_OPTION = require("config/agency.json");

const ShortcutMenu: React.FC = () => {
  const { currentStore } = StoreContainer.useContainer();

  if (!currentStore) {
    return null;
  }

  const { useSitest, sitestId, sitestShopId } = currentStore.property;
  const agencyEnabled = AGENCY[window.location.hostname].options.sitest;

  if (!useSitest || !sitestShopId || !sitestId || !agencyEnabled) {
    return null;
  }

  const handleSitestClick = () => {
    autoLogin(sitestShopId, sitestId);
  };

  return (
    <Segment>
      <Header content="機能ショートカット" color="grey" />
      <Grid columns={2} doubling>
        <Grid.Row>
          <Grid.Column>
            <MenuItem
              header="サイト解析"
              content="外部リンク"
              onClick={handleSitestClick}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default ShortcutMenu;
