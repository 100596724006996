import React from "react";
import { useHistory } from "react-router-dom";
import { Divider, Icon, Menu, Label } from "semantic-ui-react";
import routes from "utils/routes";

import { CurrentUser, CurrentStore } from "config/raven";

const getUserRoleLabel = (role: string | undefined): string => {
  if (!role) {
    return "店舗ユーザ";
  }

  if (role === "admin") {
    return "管理者";
  } else {
    return "スタッフ";
  }
};

type Props = {
  isAgency: boolean;
  currentUser: CurrentUser;
  currentStore: CurrentStore;
  signOut: Function;
};

export const AccountTab: React.FC<Props> = ({
  isAgency,
  currentUser,
  currentStore,
  signOut,
}) => {
  const history = useHistory();
  const movePasswordUpdate = () => {
    history.push(
      routes[isAgency ? "adminAgency" : "store"].accountSetting.pathname
    );
  };
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2rem 0",
      }}
    >
      {currentUser && (
        <>
          <Icon
            name="user"
            size="large"
            css={{ marginBottom: "0.5rem !important" }}
          />
          <p css={{ margin: 0, fontSize: "1.2rem", fontWeight: "bold" }}>
            {currentUser.staffName}
          </p>
          <p css={{ margin: 0 }}>{getUserRoleLabel(currentUser.role)}</p>
          <p>{currentUser.staffEmail}</p>
          <Menu
            vertical
            secondary
            size="large"
            fluid
            css={{ textAlign: "center" }}
          >
            <Divider fitted />
            <Menu.Item
              as="a"
              onClick={movePasswordUpdate}
              content="アカウント情報変更"
              css={{ margin: "0 !important" }}
            />
            <Divider fitted />
            <Menu.Item
              as="a"
              onClick={() => signOut()}
              content="ログアウト"
              css={{ margin: "0 !important" }}
            />
            <Divider fitted />
          </Menu>
          <Divider />
        </>
      )}
      {isAgency && (
        <>
          <Menu
            vertical
            secondary
            size="large"
            icon="labeled"
            fluid
            css={{ textAlign: "center" }}
          >
            <Divider fitted />
            <Menu.Item
              onClick={() =>
                history.push(routes.adminAgency.agencyIndex.pathname)
              }
              content="代理店一覧"
              icon="building"
            />
            <Divider fitted />
            <Menu.Item
              onClick={() =>
                history.push(routes.adminAgency.agencyStaffIndex.pathname)
              }
              content="スタッフ一覧"
              icon="address book"
            />
            <Divider fitted />
            <Menu.Item
              onClick={() =>
                history.push(routes.adminAgency.notificationIndex.pathname)
              }
              content="お知らせ一覧"
              icon="announcement"
            />
            <Divider fitted />
          </Menu>
          <Divider />
        </>
      )}
      {currentStore && (
        <>
          <Icon
            name="home"
            size="large"
            css={{ marginBottom: "0.5rem !important" }}
          />
          <p css={{ margin: 0, fontSize: "1.2rem", fontWeight: "bold" }}>
            {currentStore.name}
          </p>
          <p css={{ margin: 0 }}>{currentStore.email}</p>
          <Label
            color={
              currentStore.awsState === "setup_completed" ? "green" : "red"
            }
          >
            awsステータス：{" "}
            {currentStore.awsState === "setup_completed"
              ? "認証済み"
              : "未認証"}
          </Label>
          {currentStore.awsState === "setup_completed" && (
            <Menu
              vertical
              secondary
              size="large"
              fluid
              css={{ textAlign: "center" }}
            >
              <Divider fitted />
              <Menu.Item
                as="a"
                onClick={() =>
                  window.open(
                    `https://${currentStore.awsResource.homepageDomain}`
                  )
                }
                content="公開中のサイトを開く"
                css={{ margin: "0 !important" }}
              />
              <Divider fitted />
            </Menu>
          )}
        </>
      )}
    </div>
  );
};
