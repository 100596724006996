import { useEffect, useState } from "react";
import { basicAuthService } from "domain/service";
import { BasicAuthPreferences } from "domain/model/BasicAuthPreferences";

/**
 * BASIC認証設定を取得する
 */
export const useBasicAuthPreferences = ({ storeId }: { storeId: string }) => {
  const [basicAuthPreferences, setBasicAuthPreferences] = useState<
    BasicAuthPreferences | false | null
  >(null);
  useEffect(() => {
    const fetchBasicAuthPreferences = () => {
      basicAuthService.fetchPreferences(storeId).then((res) => {
        setBasicAuthPreferences(res);
      });
    };
    fetchBasicAuthPreferences();
  }, [storeId]);
  return { basicAuthPreferences };
};
