import api from "utils/api";
import endpoint from "utils/endpoint";

class AgencyRepository {
  async createAgencyStore({
    agencyName,
    agencyEmail,
    staffName,
    staffEmail,
    password,
    passwordConfirmation,
  }: {
    agencyName: string;
    agencyEmail: string;
    staffName: string;
    staffEmail: string;
    password: string;
    passwordConfirmation: string;
  }) {
    const body = {
      agency: {
        name: agencyName,
        email: agencyEmail,
      },
      staff: {
        name: staffName,
        email: staffEmail,
        role: "admin",
        password,
        password_confirmation: passwordConfirmation,
      },
    };

    const url = endpoint.agency.agencies.root();

    return api.post(url, body);
  }
}

export default new AgencyRepository();
