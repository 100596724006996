import React from "react";
import { UserContainer } from "containers";
import { useParams } from "react-router-dom";
import { StoreIdParam } from "config/raven";
import PageContainer from "components/lib/PageContainer";
import { Segment } from "semantic-ui-react";
import { EditFormContainer } from "components/Post/News/Edit/EditFormContainer";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const NewsEdit: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId, slug } = useParams<StoreIdParam & { slug: string }>();

  const filePath = `news/${slug}`;

  return (
    <PageContainer
      header="お知らせ投稿編集"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .newsPage()
        .build("お知らせ投稿編集")}
    >
      <Segment>
        <EditFormContainer
          isAgency={isAgency}
          storeId={storeId}
          filePath={filePath}
        />
      </Segment>
    </PageContainer>
  );
};
