import { useState, useEffect } from "react";

import StoreProfile from "domain/model/Instagram";
import { instagramService } from "domain/service";

/**
 * Instagram 連携設定を取得する
 */

export const useInstagramStoreProfile = ({
  isAgency,
  storeId,
}: {
  isAgency: boolean;
  storeId: string | undefined;
}) => {
  const [instagramStoreProfile, setInstagramStoreProfile] =
    useState<StoreProfile | null>(null);

  useEffect(() => {
    const fetchStoreProfile = async () => {
      instagramService
        .fetchStoreProfile({
          isAgency,
          storeId,
        })
        .then((instagramStoreProfile) => {
          setInstagramStoreProfile(instagramStoreProfile);
        });
    };
    fetchStoreProfile();
  }, [isAgency, storeId]);

  return { instagramStoreProfile };
};
