import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import routes from "utils/routes";
import { Button, Message, Segment } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { StoreIdParam } from "config/raven";
import { EditForm } from "components/Product/Edit/EditForm";
import { StoreContainer, UserContainer } from "containers";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const ProductEdit = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const history = useHistory();
  const { storeId, id } = useParams<StoreIdParam & { id: string }>();
  const { currentStore } = StoreContainer.useContainer();
  const useTakeout = currentStore?.property?.useTakeout || false;

  if (!useTakeout) {
    return <Message content="テイクアウト機能が有効になっていません" />;
  }

  return (
    <>
      <PageContainer
        header="商品編集"
        breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
          .productPage()
          .build("商品編集")}
        action={
          <Button
            color="blue"
            content="一覧へ"
            onClick={() => {
              const productIndex = isAgency
                ? generatePath(routes.agency.productIndex.pathname, {
                    storeId,
                  })
                : routes.store.productIndex;
              history.push(productIndex);
            }}
          />
        }
      >
        <Segment>
          <EditForm isAgency={isAgency} storeId={storeId} id={id} />
        </Segment>
      </PageContainer>
    </>
  );
};
