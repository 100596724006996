import React from "react";

import { useFormikContext } from "formik";
import { PostFormValues } from "components/lib/Post/PostContentForm";

export const PostTypes = {
  ARTICLE: "ARTICLE",
  NEWS: "NEWS",
} as const;

type PostType = (typeof PostTypes)[keyof typeof PostTypes];

const getDetailClass = (typeEnum: PostType) => {
  return `CMS-${typeEnum}-DETAIL`;
};
const getTitleClass = (typeEnum: PostType) => {
  return `CMS-${typeEnum}-TITLE`;
};

const getContentClass = (typeEnum: PostType) => {
  return `CMS-${typeEnum}-CONTENT`;
};

const generatePostHtmlElement = (
  postType: PostType,
  template: string,
  title: string,
  content: string
) => {
  const detailClass = getDetailClass(postType);
  const titleClass = getTitleClass(postType);
  const contentClass = getContentClass(postType);

  const domParser = new DOMParser();
  const postHTMLDocument = domParser.parseFromString(template, "text/html");
  const details = postHTMLDocument.getElementsByClassName(detailClass);
  [...details].forEach((detail) => {
    const titles = detail.getElementsByClassName(titleClass);
    Array.from(titles).forEach((titleDom) => {
      titleDom.innerHTML = title;
    });
    const contents = detail.getElementsByClassName(contentClass);
    Array.from(contents).forEach((contentDom) => {
      contentDom.innerHTML = content;
    });
  });

  return postHTMLDocument.documentElement.outerHTML;
};

type Props = {
  postType: PostType;
  template: string | null;
};
export const PostPreview: React.FC<Props> = ({ template, postType }) => {
  const {
    values: { postContent: values },
  } = useFormikContext<PostFormValues>();

  if (template === null) {
    return null;
  }

  const previewHtml = generatePostHtmlElement(
    postType,
    template,
    values.title,
    values.content
  );

  return (
    <iframe
      title="プレビュー"
      css={{ width: "100%", height: "500px", backgroundColor: "#fff" }}
      srcDoc={previewHtml}
    />
  );
};
