import React from "react";

import { Image, Table, Button } from "semantic-ui-react";
import noImage from "img/no-image.png";

import Post from "domain/model/Post";

type Props = {
  news: Post;
  index: number;
  handleEdit: () => void;
};

export const NewsItem: React.FC<Props> = ({ news, index, handleEdit }) => {
  return (
    <Table.Row key={index}>
      <Table.Cell>{index + 1}</Table.Cell>
      <Table.Cell>
        {news.metadata.thumbnailUrl ? (
          <Image
            src={news.metadata.thumbnailUrl}
            style={{ width: "90px", height: "80px" }}
          />
        ) : (
          <Image src={noImage} style={{ width: "90px", height: "80px" }} />
        )}
      </Table.Cell>
      <Table.Cell>{news.title}</Table.Cell>
      <Table.Cell>{news.subcategory ?? "-"}</Table.Cell>
      <Table.Cell>{news.isPublished ? "公開" : "非公開"}</Table.Cell>
      <Table.Cell>{news.createdAt}</Table.Cell>
      <Table.Cell>
        <Button onClick={handleEdit} color="blue" content="編集" />
      </Table.Cell>
    </Table.Row>
  );
};
