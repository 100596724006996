import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { routes } from "utils/routes";

import staffRepository from "domain/repository/staff";
import { useFormik } from "formik";
import * as Yup from "yup";
import message from "config/message.json";

import { Button, Loader, Label, Segment, Form } from "semantic-ui-react";

type Props = {
  name: string;
  email: string;
  id: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(message.ERROR.NO_INPUT),
  email: Yup.string()
    .email(message.ERROR.INVALID_EMAIL)
    .required(message.ERROR.NO_INPUT),
});

export const StaffShow: React.FC<Props> = ({ name, email, id }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmailExist, setIsEmailExist] = useState<boolean>(false);

  const initialValues = {
    name,
    email,
  };

  const history = useHistory();
  const staffIndexPath = routes.adminAgency.agencyStaffIndex.pathname;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      setLoading(true);

      staffRepository
        .staffUpdate(values.name, values.email, id)
        .then(() => {
          setLoading(false);
          history.push(staffIndexPath);
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.errors?.email[0] === "has already been taken"
          ) {
            setIsEmailExist(true);
            return;
          } else {
            setIsEmailExist(true);
          }
        });
    },
    validateOnBlur: true,
    validateOnChange: true,
    initialStatus: { success: "", error: "" },
  });
  return (
    <>
      {loading ? (
        <Loader active content="スタッフを更新中 " />
      ) : (
        <Segment>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Field required>
              <label>名前</label>
              <Form.Input
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.errors.name}
              />
            </Form.Field>
            <Form.Field required>
              <label>メールアドレス</label>
              <Form.Input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
              />
              {isEmailExist ? (
                <Label color="red" pointing>
                  このメールアドレスは既に使用されています
                </Label>
              ) : (
                <></>
              )}
            </Form.Field>
            <div css={{ textAlign: "center" }}>
              <Button color="blue" type="submit">
                保存
              </Button>
            </div>
          </Form>
        </Segment>
      )}
    </>
  );
};
