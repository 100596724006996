import React from "react";

import { Message } from "semantic-ui-react";

type Props = {
  heading: string;
};

const NotValidOption: React.FC<Props> = ({ heading }) => {
  return (
    <Message warning>
      {heading}は利用できません。ご利用を希望の方は代理店までご連絡ください。
    </Message>
  );
};

export default NotValidOption;
