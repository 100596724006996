import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import { routes } from "utils/routes";

import PageContainer from "components/lib/PageContainer";

import { StaffShow } from "components/AgencyStaff/Show/StaffShow";

import { Message, Button } from "semantic-ui-react";

type StaffType = {
  name?: string;
  email?: string;
  id?: string;
};

export const AgencyStaffShow: React.FC = () => {
  const location = useLocation<StaffType>();
  const history = useHistory();
  const url = routes.adminAgency.agencyStaffIndex.pathname;

  const { name, email, id } = location.state;

  return (
    <PageContainer
      header="スタッフ情報"
      action={
        <Button color="blue" onClick={() => history.push(url)}>
          一覧へ
        </Button>
      }
    >
      {name && email && id ? (
        <StaffShow name={name} email={email} id={id} />
      ) : (
        <Message negative>スタッフ情報が存在しません</Message>
      )}
    </PageContainer>
  );
};
