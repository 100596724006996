import React from "react";
import { useHistory, generatePath } from "react-router-dom";
import Yaml from "domain/model/Yaml";
import { YamlItem } from "components/SiteManage/Content/Yaml/Index/YamlItem";
import routes from "utils/routes";
import { Table } from "semantic-ui-react";

type Props = {
  yamls: Yaml[];
  isAgency: boolean;
  storeId: string;
};

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell css={{ width: "10%" }}>
          {/* index */}
        </Table.HeaderCell>
        <Table.HeaderCell css={{ width: "30%" }}>タイトル</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "30%" }}>URL</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "10%" }}>ステータス</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "10%" }}>
          {/* 編集ボタン */}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export const YamlList: React.FC<Props> = ({ isAgency, yamls, storeId }) => {
  const history = useHistory();
  return (
    <>
      <Table fixed>
        <TableHeader />
        <Table.Body>
          {yamls.map((yaml, i) => {
            const handleEdit = () => {
              const editUrl = isAgency
                ? generatePath(routes.agency.yamlEdit.pathname, {
                    storeId,
                    id: yaml.id,
                  })
                : generatePath(routes.store.yamlEdit.pathname, {
                    id: yaml.id,
                  });
              history.push(editUrl);
            };
            return (
              <YamlItem
                yaml={yaml}
                handleClickEdit={handleEdit}
                index={i}
                storeId={storeId}
                key={yaml.slug}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};
