import React, { ReactNode, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "react-sidebar";
import Header from "components/Layout/Header";
import Head from "components/Layout/utils/Head";
import { SidebarContent } from "components/Layout/utils/SidebarContent";
import { AsidebarContent } from "components/Layout/utils/AsidebarContent";
import { isEnableSidebarUrl } from "utils/isEnableSidebarUrl";
import COLOR from "config/color.json";

type Props = {
  children: ReactNode;
};

const Layout: React.FC<Props> = (props) => {
  const { children } = props;
  const [openAsidebar, setOpenAsidebar] = useState(false);
  const [enableSidebar, setEnabledSidebar] = useState<boolean>(false);

  const toggleAsidebar = () => {
    setOpenAsidebar(!openAsidebar);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    const isEnableSidebar = isEnableSidebarUrl(pathname);
    setEnabledSidebar(isEnableSidebar);
  }, [pathname]);

  return (
    <div css={{ backgroundColor: COLOR.SUB3, height: "100%" }}>
      <Head />
      <Header
        toggleAsidebar={toggleAsidebar}
        closeAsidebar={() => setOpenAsidebar(false)}
      />

      <Sidebar
        sidebar={enableSidebar && <SidebarContent />}
        docked={enableSidebar}
        styles={{
          root: { top: "3.5rem" },
          sidebar: { background: "black", width: "180px", zIndex: "10" },
        }}
      >
        <Sidebar
          sidebar={<AsidebarContent toggleAsidebar={toggleAsidebar} />}
          open={openAsidebar}
          pullRight
          styles={{
            sidebar: { background: "white", width: "300px" },
          }}
        >
          <MainContainer>{children}</MainContainer>
        </Sidebar>
      </Sidebar>
    </div>
  );
};

const MainContainer: React.FC<{ children: ReactNode }> = React.memo(
  (props) => <div>{props.children}</div>,
  (prev, next) => {
    if (prev.children === next.children) {
      return true;
    } else {
      return false;
    }
  }
);

export default Layout;
