import api from "utils/api";
import endpoint from "utils/endpoint";

type Store = {
  name: string;
  email: string;
};

type Staff = {
  name: string;
  email: string;
  role: string;
  password: string;
  password_confirmation: string;
};

class StoreRepository {
  /**
   * 店舗の作成(代理店のみ実行可)
   */
  public createStore({
    storeName,
    staffName,
    staffEmail,
    password,
    passwordConfirmation,
    role,
  }: {
    storeName: string;
    staffName: string;
    staffEmail: string;
    password: string;
    passwordConfirmation: string;
    role: string;
  }) {
    const body: {
      store: Store;
      staff: Staff;
    } = {
      store: { name: storeName, email: staffEmail },
      staff: {
        name: staffName,
        email: staffEmail,
        role,
        password,
        password_confirmation: passwordConfirmation,
      },
    };

    return api.post(endpoint.agency.stores.root(), body).catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error);
    });
  }

  /**
   * 店舗情報の更新(店舗・代理店)
   */
  public async updateProfile(
    isAgency: boolean,
    storeId: number,
    {
      name,
      email,
      tel,
      fax,
      lineAccessToken,
      property,
      editLineAccessToken,
      editTakeoutStripeSecret,
    }: {
      name: string;
      email: string;
      tel: string;
      fax: string;
      lineAccessToken: string;
      property: {
        takeoutMinimumLeadTime: number;
        takeoutStripeSecret: string;
      };
      editLineAccessToken: boolean;
      editTakeoutStripeSecret: boolean;
    }
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.stores.update(storeId)
        : endpoint.store.store.root();
    return api.put(url, {
      store: {
        name,
        email,
        tel,
        fax,
        ...(editLineAccessToken
          ? { line_access_token: lineAccessToken ?? "" }
          : {}),
        property: {
          takeout_minimum_lead_time: property.takeoutMinimumLeadTime,
          ...(editTakeoutStripeSecret
            ? { takeout_raw_stripe_secret: property.takeoutStripeSecret ?? "" }
            : {}),
        },
      },
    });
  }

  public async delete(storeId: number) {
    return api.delete(endpoint.agency.stores.delete(storeId));
  }
}

export default new StoreRepository();
