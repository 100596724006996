import React from "react";
import { useFormik } from "formik";

import {
  Message,
  Header,
  Segment,
  Form,
  Grid,
  Checkbox,
  Table,
  Select,
} from "semantic-ui-react";

import { CurrentStore } from "config/raven";
import BusinessDayType, {
  Weekday,
  weekdaysWithLabel,
} from "domain/model/BusinessDayType";
import { businessDayTypeRepository } from "domain/repository";
import { businessDayOffsetHours } from "config/store";

type Props = {
  currentStore: CurrentStore;
  isAgency: boolean;
  storeId: string | undefined;
  businessDayTypes: BusinessDayType[];
};

type WeekdayValue = {
  isClosed: boolean;
  isRegularTime: boolean;
} & Record<
  "section1" | "section2",
  {
    openingTime: string;
    closingTime: string;
  }
>;

const timeOptions = [...Array(24).keys()]
  .map((hour) =>
    [0, 30].map((min) =>
      [
        `${hour + businessDayOffsetHours}`.padStart(2, "0"),
        `${min}`.padStart(2, "0"),
      ].join(":")
    )
  )
  .flat()
  .map((time) => ({ key: time, value: time, text: time }));

const buildInitialValue = (
  values: BusinessDayType[]
): { hasDinnerTime: boolean; weekdayValues: Record<Weekday, WeekdayValue> } => {
  const hasDinnerTime = !values.find(
    ({ timeType }) => timeType === "entire_day"
  );

  return {
    hasDinnerTime,
    weekdayValues: {
      monday: convertApiValueToWeekdayValue({
        hasDinnerTime,
        weekday: "monday",
        values,
      }),
      tuesday: convertApiValueToWeekdayValue({
        hasDinnerTime,
        weekday: "tuesday",
        values,
      }),
      wednesday: convertApiValueToWeekdayValue({
        hasDinnerTime,
        weekday: "wednesday",
        values,
      }),
      thursday: convertApiValueToWeekdayValue({
        hasDinnerTime,
        weekday: "thursday",
        values,
      }),
      friday: convertApiValueToWeekdayValue({
        hasDinnerTime,
        weekday: "friday",
        values,
      }),
      saturday: convertApiValueToWeekdayValue({
        hasDinnerTime,
        weekday: "saturday",
        values,
      }),
      sunday: convertApiValueToWeekdayValue({
        hasDinnerTime,
        weekday: "sunday",
        values,
      }),
      holiday: convertApiValueToWeekdayValue({
        hasDinnerTime,
        weekday: "holiday",
        values,
      }),
      pre_holiday: convertApiValueToWeekdayValue({
        hasDinnerTime,
        weekday: "pre_holiday",
        values,
      }),
    },
  };
};

const convertApiValueToWeekdayValue = ({
  hasDinnerTime,
  weekday,
  values,
}: {
  hasDinnerTime: boolean;
  weekday: Weekday;
  values: BusinessDayType[];
}): WeekdayValue => {
  const weekdayValues = values.filter((v) => v.dayType === weekday);
  const section1 = hasDinnerTime
    ? weekdayValues.find((v) => v.dayType === weekday && v.timeType === "lunch")
    : weekdayValues.find(
        (v) => v.dayType === weekday && v.timeType === "entire_day"
      );
  const section2 = hasDinnerTime
    ? weekdayValues.find(
        (v) => v.dayType === weekday && v.timeType === "dinner"
      )
    : { openingTime: "18:00", closingTime: "23:00" };

  return {
    isClosed: !!weekdayValues.find((v) => v.timeType === "closed"),
    isRegularTime:
      // レコードが存在しない場合は各曜日に従う
      (weekday === "holiday" || weekday === "pre_holiday") &&
      weekdayValues.length === 0,
    section1: {
      openingTime: section1?.openingTime || "10:00",
      closingTime: section1?.closingTime || "18:00",
    },
    section2: {
      openingTime: section2?.openingTime || "18:00",
      closingTime: section2?.closingTime || "23:00",
    },
  };
};

const convertWeekdayValueToApiValue = ({
  originalValues,
  hasDinnerTime,
  weekdayValues,
}: {
  originalValues: BusinessDayType[];
} & ReturnType<typeof buildInitialValue>): BusinessDayType[] => {
  // 一旦入力値を変換する
  const converted = (
    Object.entries(weekdayValues) as [Weekday, WeekdayValue][]
  ).reduce<BusinessDayType[]>((acc, [weekday, value]) => {
    if (value.isRegularTime === true) {
      return acc;
    }

    if (value.isClosed) {
      return [
        ...acc,
        {
          id:
            originalValues.find(
              (v) => v.dayType === weekday && v.timeType === "closed"
            )?.id ?? null,
          dayType: weekday,
          timeType: "closed",
          openingTime: null,
          closingTime: null,
        },
      ];
    }

    if (hasDinnerTime) {
      return [
        ...acc,
        {
          id:
            originalValues.find(
              (v) => v.dayType === weekday && v.timeType === "lunch"
            )?.id ?? null,
          dayType: weekday,
          timeType: "lunch",
          openingTime: value.section1.openingTime,
          closingTime: value.section1.closingTime,
        },
        {
          id:
            originalValues.find(
              (v) => v.dayType === weekday && v.timeType === "dinner"
            )?.id ?? null,
          dayType: weekday,
          timeType: "dinner",
          openingTime: value.section2.openingTime,
          closingTime: value.section2.closingTime,
        },
      ];
    }

    return [
      ...acc,
      {
        id:
          originalValues.find(
            (v) => v.dayType === weekday && v.timeType === "entire_day"
          )?.id ?? null,
        dayType: weekday,
        timeType: "entire_day",
        openingTime: value.section1.openingTime,
        closingTime: value.section1.closingTime,
      },
    ];
  }, []);

  return [
    // 変換後の値に id が含まれていたら消し、それ以外は destroy フラグを立てる
    ...originalValues.reduce<(BusinessDayType & { destroy: true })[]>(
      (acc, value) => {
        if (converted.find(({ id }) => id === value.id)) {
          return acc;
        }

        return [...acc, { ...value, destroy: true }];
      },
      []
    ),
    ...converted,
  ];
};

export const BusinessDayForm: React.FC<Props> = ({
  currentStore,
  isAgency,
  storeId,
  businessDayTypes,
}) => {
  const { values, status, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: buildInitialValue(businessDayTypes),
      initialStatus: { success: "", error: "" },
      onSubmit: ({ hasDinnerTime, weekdayValues }, actions) => {
        const converted = convertWeekdayValueToApiValue({
          originalValues: businessDayTypes,
          hasDinnerTime,
          weekdayValues,
        });

        businessDayTypeRepository
          .updateAllBusinessDayTypes(isAgency, storeId, converted)
          .then(() => actions.setStatus({ success: "営業時間を更新しました" }))
          .catch(() => actions.setStatus({ error: "更新に失敗しました" }));
      },
    });

  if (!currentStore) {
    return <></>;
  }

  return (
    <Segment padded>
      {status.success && <Message success content={status.success} />}
      {status.error && <Message error content={status.error} />}
      <Header content="営業時間管理" dividing />
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <div css={{ marginBottom: "16px" }}>
            <Checkbox
              label="ランチ・ディナー区別あり"
              checked={values.hasDinnerTime}
              id="hasDinnerTime"
              name="hasDinnerTime"
              onChange={handleChange}
            />
          </div>

          <Table>
            <Table.Header css={{ textAlign: "center" }}>
              <Table.Row>
                <Table.HeaderCell width={2}></Table.HeaderCell>
                <Table.HeaderCell width={2}>定休日</Table.HeaderCell>
                <Table.HeaderCell>
                  {values.hasDinnerTime ? "ランチ" : "営業時間"}
                </Table.HeaderCell>
                {values.hasDinnerTime && (
                  <Table.HeaderCell>ディナー</Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body css={{ textAlign: "center" }}>
              {(Object.entries(weekdaysWithLabel) as [Weekday, string][]).map(
                ([weekday, label]) => (
                  <Table.Row
                    key={weekday}
                    warning={["holiday", "pre_holiday"].includes(weekday)}
                  >
                    <Table.Cell>
                      {label}
                      {["holiday", "pre_holiday"].includes(weekday) && (
                        <div css={{ marginTop: "8px" }}>
                          <Checkbox
                            label="各曜日に従う"
                            id={`weekdayValues[${weekday}].isRegularTime`}
                            name={`weekdayValues[${weekday}].isRegularTime`}
                            onChange={handleChange}
                            checked={
                              values.weekdayValues[weekday].isRegularTime
                            }
                          />
                        </div>
                      )}
                      <input
                        type="hidden"
                        name={`weekdayValues[${weekday}].dayType`}
                        value={weekday}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        id={`weekdayValues[${weekday}].isClosed`}
                        name={`weekdayValues[${weekday}].isClosed`}
                        onChange={handleChange}
                        disabled={values.weekdayValues[weekday].isRegularTime}
                        checked={values.weekdayValues[weekday].isClosed}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Grid>
                        <Grid.Column width={7}>
                          <Select
                            options={timeOptions}
                            value={
                              values.weekdayValues[weekday].section1.openingTime
                            }
                            disabled={
                              values.weekdayValues[weekday].isClosed ||
                              values.weekdayValues[weekday].isRegularTime
                            }
                            onChange={(_, { value }) => {
                              setFieldValue(
                                `weekdayValues[${weekday}].section1.openingTime`,
                                value
                              );
                            }}
                            fluid
                            selection
                          />
                        </Grid.Column>
                        <Grid.Column width={1}>~</Grid.Column>
                        <Grid.Column width={7}>
                          <Select
                            options={timeOptions}
                            value={
                              values.weekdayValues[weekday].section1.closingTime
                            }
                            disabled={
                              values.weekdayValues[weekday].isClosed ||
                              values.weekdayValues[weekday].isRegularTime
                            }
                            onChange={(_, { value }) => {
                              setFieldValue(
                                `weekdayValues[${weekday}].section1.closingTime`,
                                value
                              );
                            }}
                            fluid
                            selection
                          />
                        </Grid.Column>
                      </Grid>
                    </Table.Cell>
                    {values.hasDinnerTime && (
                      <Table.Cell>
                        <Grid>
                          <Grid.Column width={7}>
                            <Select
                              options={timeOptions}
                              value={
                                values.weekdayValues[weekday].section2
                                  .openingTime
                              }
                              disabled={
                                values.weekdayValues[weekday].isClosed ||
                                values.weekdayValues[weekday].isRegularTime
                              }
                              onChange={(_, { value }) => {
                                setFieldValue(
                                  `weekdayValues[${weekday}].section2.openingTime`,
                                  value
                                );
                              }}
                              fluid
                              selection
                            />
                          </Grid.Column>
                          <Grid.Column width={1}>~</Grid.Column>
                          <Grid.Column width={7}>
                            <Select
                              options={timeOptions}
                              value={
                                values.weekdayValues[weekday].section2
                                  .closingTime
                              }
                              disabled={
                                values.weekdayValues[weekday].isClosed ||
                                values.weekdayValues[weekday].isRegularTime
                              }
                              onChange={(_, { value }) => {
                                setFieldValue(
                                  `weekdayValues[${weekday}].section2.closingTime`,
                                  value
                                );
                              }}
                              fluid
                              selection
                            />
                          </Grid.Column>
                        </Grid>
                      </Table.Cell>
                    )}
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </Form.Field>

        <Form.Button content="更新" color="blue" type="submit" />
      </Form>
    </Segment>
  );
};
