import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import { ExternalDnsRecordset } from "domain/model/ExternalDnsRecordset";

class Service {
  checkAvailability(domain: string): Promise<boolean> {
    const url = endpoint.agency.domain.availability();
    const body = {
      domain: {
        domain,
      },
    };
    return api
      .post(url, body)
      .then((res) => res.data.available)
      .catch(() => false);
  }

  fetchExternalDnsRecordset(storeId: string): Promise<ExternalDnsRecordset> {
    const url = endpoint.agency.domain.externalDnsRecordset(storeId);
    return api.get(url).then((res) => {
      return plainToClass(ExternalDnsRecordset, res.data, {
        excludeExtraneousValues: true,
      });
    });
  }
}

export const domainService = new Service();
