import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Segment, Loader, Header, Message } from "semantic-ui-react";

import PageContainer from "components/lib/PageContainer";
import { UserContainer, StoreContainer } from "containers";
import { AGENCY_OPTION, StoreIdParam } from "config/raven";
import { restyRepository } from "domain/repository";
import NotValidOption from "components/SiteManage/Content/lib/NotValidOption";
import OptionStatus from "components/lib/OptionStatus";
import RestyAuth from "components/SiteManage/Content/Resty/RestyAuth/RestyAuth";
import { RestyStoreProfile } from "components/SiteManage/Content/Resty/StoreProfile/RestyStoreProfile";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { useRestyStoreProfile } from "hooks/useRestyStoreProfile";

const AGENCY: AGENCY_OPTION = require("config/agency.json");

const Container: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { currentStore, changeCurrentStore } = StoreContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();
  const { hostname } = window.location;
  const agencyEnabled = AGENCY[hostname].options.resty;
  const { restyStoreProfile } = useRestyStoreProfile({
    isAgency,
    storeId,
  });

  const [isRegister, setIsRegister] = useState<boolean>(false);

  useEffect(() => {
    const confirm = restyStoreProfile?.restyGroupCode;

    if (confirm !== null || undefined) {
      setIsRegister(true);
    } else {
      setIsRegister(false);
    }
  }, [isAgency, storeId, restyStoreProfile]);

  const handleClickEnable = () => {
    if (!storeId) {
      return;
    }
    restyRepository
      .enable(isAgency, storeId)
      .then(() => changeCurrentStore(isAgency, storeId));
  };

  if (!agencyEnabled) {
    return <NotValidOption heading="Resty 予約機能情報" />;
  }

  if (!restyStoreProfile) {
    return <Loader active />;
  }

  if (!currentStore) {
    return <Loader active />;
  }

  return (
    <>
      <Segment padded>
        <Header content={`Resty 予約機能情報 オプション利用状況`} dividing />
        {!isAgency && !currentStore.property.useResty && (
          <Message
            negative
            content="店舗ユーザはオプションを有効にできません"
          />
        )}
        <OptionStatus
          isAgency={isAgency}
          enabled={currentStore.property.useResty}
          toEnable={handleClickEnable}
        />
      </Segment>
      <Segment padded>
        <>
          {currentStore.property.useResty ? (
            isRegister ? (
              <>
                <RestyStoreProfile
                  isAgency={isAgency}
                  storeId={storeId}
                  setIsRegister={setIsRegister}
                />
              </>
            ) : (
              <>
                <RestyAuth
                  isAgency={isAgency}
                  storeId={storeId}
                  setIsRegister={setIsRegister}
                />
              </>
            )
          ) : null}
        </>
      </Segment>
    </>
  );
};

export const Resty = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="Resty予約機能情報"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("Resty予約機能情報")}
    >
      <Container />
    </PageContainer>
  );
};
