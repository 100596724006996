import api from "utils/api";
import endpoint from "utils/endpoint";

class ExternalTakeoutRepository {
  /**
   * 代理店ユーザーだけ利用可能
   */
  public async enable(isAgency: boolean, storeId: string) {
    const url = endpoint.agency.takeoutLogin.use(storeId);
    return api.post(url);
  }

  public linkTakeout({
    isAgency,
    storeId,
    takeoutId,
    takeoutPassword,
    takeoutCode,
    takeoutToken,
    takeoutSite,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    takeoutId: string;
    takeoutPassword: string;
    takeoutCode: string;
    takeoutToken: string;
    takeoutSite: string;
  }) {
    const url =
      isAgency && storeId
        ? endpoint.agency.takeoutLogin.root(storeId)
        : endpoint.store.takeoutLogin.root();
    const body = {
      takeout_login: {
        auth: {
          id: takeoutId,
          password: takeoutPassword,
          token: takeoutToken,
          code: takeoutCode,
        },
        site: takeoutSite,
      },
    };
    return api
      .post(url, body)
      .then((res) => res.data.takeout_login)
      .catch(() => Promise.reject());
  }

  public syncTakeout({
    isAgency,
    storeId,
    takeoutSite,
    fromDate,
    toDate,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    takeoutSite: string;
    fromDate: number | null;
    toDate: number | null;
  }) {
    const url =
      isAgency && storeId
        ? endpoint.agency.takeoutSales.root(storeId)
        : endpoint.store.takeoutSales.root();
    const body = {
      takeout_sales: {
        site: takeoutSite,
        from_date: fromDate,
        to_date: toDate,
      },
    };
    return api
      .post(url, body)
      .then((res) => res.data.takeout_sales)
      .catch((error) => {
        if (error.response) {
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
      });
  }
}

export default new ExternalTakeoutRepository();
