import React, { useEffect } from "react";
import { YamlContainer } from "containers";
import { Button, Loader, Segment } from "semantic-ui-react";
import * as YamlList from "components/SiteManage/Content/Yaml/Index/YamlList";

type Props = {
  isAgency: boolean;
  storeId: string;
};

export const Container: React.FC<Props> = ({ isAgency, storeId }) => {
  const yamlContainer = YamlContainer.useContainer();
  const { yamls, fetchYamls, hasMoreYamls, loading } = yamlContainer;

  useEffect(() => {
    fetchYamls(isAgency, storeId);
  }, [fetchYamls, isAgency, storeId]);

  if (yamls === null) {
    return <Loader content="YAML一覧取得中" active />;
  }

  return (
    <Segment>
      <Loader active={loading} size="large" />
      <YamlList.YamlList yamls={yamls} isAgency={isAgency} storeId={storeId} />
      {hasMoreYamls ? (
        <Button
          content={loading ? "読込中…" : "続きを読み込む"}
          disabled={loading}
          fluid
          onClick={() => {
            if (yamls === null || loading) {
              return;
            }
            fetchYamls(isAgency, storeId);
          }}
        />
      ) : null}
    </Segment>
  );
};
