import React, { useState, useEffect } from "react";
import { useHistory, useParams, generatePath } from "react-router-dom";

import { StoreIdParam } from "config/raven";
import routes from "utils/routes";
import { notificationService } from "domain/service";
import Notification from "domain/model/Notification";

import PageContainer from "components/lib/PageContainer";
import UserContainer from "containers/User";

import { StoreNotificationList } from "components/StoreNotification/Index/StoreNotificationList";
import { Button, Loader } from "semantic-ui-react";

export const StoreNotificationIndex: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  const dashBoardPath = isAgency
    ? generatePath(routes.agency.dashboard.pathname, {
        storeId,
      })
    : routes.store.dashboard.pathname;

  const history = useHistory();

  const [notifications, setNotifications] = useState<Notification[] | null>(
    null
  );

  useEffect(() => {
    const fetchNotifications = () => {
      notificationService
        .fetchNotifications(isAgency)
        .then((res) => setNotifications(res));
    };
    fetchNotifications();
  }, [isAgency]);

  return (
    <PageContainer
      header="代理店からのお知らせ一覧"
      action={
        <Button
          color="blue"
          content="ダッシュボードに戻る"
          onClick={() => history.push(dashBoardPath)}
        />
      }
    >
      {notifications !== null ? (
        <StoreNotificationList
          isAgency={isAgency}
          storeId={storeId}
          notifications={notifications}
        />
      ) : (
        <Loader active />
      )}
    </PageContainer>
  );
};
