import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import routes from "utils/routes";

import PageContainer from "components/lib/PageContainer";
import { AgencyNotificationEditForm } from "components/AgencyNotification/Edit/AgencyNotificationEditForm";

import { Button } from "semantic-ui-react";

type State = {
  id: string;
  title: string;
  content: string;
};

export const AgencyNotificationEdit: React.FC = () => {
  const history = useHistory();
  const location = useLocation<State>();

  const notificationIndexPath = routes.adminAgency.notificationIndex.pathname;

  const { id, title, content } = location.state;

  return (
    <PageContainer
      header="代理店からのお知らせ編集"
      action={
        <Button
          color="blue"
          content="お知らせ一覧へ"
          onClick={() => history.push(notificationIndexPath)}
        />
      }
    >
      <AgencyNotificationEditForm
        id={id}
        title={title}
        content={content}
        notificationIndexPath={notificationIndexPath}
      />
    </PageContainer>
  );
};
