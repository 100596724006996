import React from "react";
import { useHistory, generatePath } from "react-router-dom";
import { UserType } from "config/raven";
import { Form, Message } from "semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";
import routes from "utils/routes";
import MESSAGE from "config/message.json";
import SegmentWithHeader from "components/lib/SegmentWithHeader";

type Props = {
  signIn: Function;
  userType: UserType;
};

type FormValues = {
  email: string;
  password: string;
  unAuthorized: string;
};

const initialValues: FormValues = {
  email: "",
  password: "",
  unAuthorized: "",
};
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(MESSAGE.ERROR.INVALID_EMAIL)
    .required(MESSAGE.ERROR.NO_INPUT),
  password: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
});

const SignInForm: React.FC<Props> = ({ signIn, userType }) => {
  const isAgency = userType === "agency";
  const history = useHistory();

  return (
    <SegmentWithHeader
      color={isAgency ? "red" : "blue"}
      headerContent={`${isAgency ? "代理店" : "店舗"}ログイン`}
      headerText="Sign In to your account"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          signIn(values.email, values.password, userType)
            .then(() => {
              const refirectPath = isAgency
                ? generatePath(routes.adminAgency.storeIndex.pathname, {
                    page: 1,
                  })
                : routes.store.dashboard.pathname;
              // TODO: location.hrefにする必要がある
              window.location.href = refirectPath;
            })
            .catch(() => {
              actions.setFieldError(
                "unAuthorized",
                MESSAGE.ERROR.SIGN_IN.NO_MATCH
              );
            });
        }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ handleChange, handleSubmit, values, errors }) => (
          <Form>
            <Form.Input
              name="email"
              label="メールアドレス"
              value={values.email}
              onChange={handleChange}
              error={errors.email}
            />
            <Form.Input
              name="password"
              label="パスワード"
              value={values.password}
              onChange={handleChange}
              type="password"
              error={errors.password}
            />
            <div css={{ textAlign: "right", marginBottom: "1rem" }}>
              {isAgency ? (
                <p
                  onClick={() =>
                    history.push(routes.common.signInStore.pathname)
                  }
                  css={{
                    display: "block",
                    cursor: "pointer",
                    color: "royalblue",
                    marginBottom: "0.3rem",
                  }}
                >
                  店舗の方はこちら
                </p>
              ) : (
                <p
                  onClick={() =>
                    history.push(routes.common.signInAgency.pathname)
                  }
                  css={{
                    display: "block",
                    cursor: "pointer",
                    color: "royalblue",
                    marginBottom: "0.3rem",
                  }}
                >
                  代理店の方はこちら
                </p>
              )}
              <p
                onClick={() =>
                  history.push(routes.common.passwordResetRequest.pathname)
                }
                css={{ cursor: "pointer", color: "royalblue" }}
              >
                パスワードをお忘れの方
              </p>
            </div>
            <div css={{ textAlign: "center" }}>
              <Form.Button
                content="送信"
                color="blue"
                onClick={() => handleSubmit()}
              />
            </div>
            {errors.unAuthorized && (
              <Message negative content={errors.unAuthorized} />
            )}
          </Form>
        )}
      </Formik>
    </SegmentWithHeader>
  );
};

export default SignInForm;
