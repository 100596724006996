import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import color from "./color";

const Message = styled.div`
  font-size: 11px;
  color: ${(props) => (props.valid ? color.green : color.red)};
  margin: 5px 0;
`;

export default ({ errorMessage }) => {
  const valid = errorMessage === "ok";

  return (
    <Message valid={valid}>
      <FontAwesomeIcon
        icon={valid ? faCheckCircle : faExclamationCircle}
        style={{ marginRight: 3 }}
      />
      {!valid && errorMessage}
    </Message>
  );
};
