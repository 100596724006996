import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";
import { yamlService } from "domain/service";

import Yaml from "domain/model/Yaml";

const IndexLimit = 10;

const YamlContainer = () => {
  const [yamls, setYamls] = useState<Yaml[] | null>(null);
  const [editYaml, setEditYaml] = useState<string | null>(null);
  const [hasMoreYamls, setHasMoreYamls] = useState<boolean | null>(false);
  const [loading, setLoading] = useState(false);

  const fetchYamls = useCallback(
    async (isAgency: boolean, storeId: string | undefined) => {
      setLoading(true);
      const lastFetchedYaml = await yamlService
        .fetchAllYamls(isAgency, storeId)
        .then((fetchedYamls) => {
          setYamls((currentState) => {
            if (currentState === null) {
              return fetchedYamls;
            } else {
              return [...currentState, ...fetchedYamls];
            }
          });
          // limit 以下の場合はそれ以上の記事取得は行わない
          if (fetchedYamls.length < IndexLimit) {
            return undefined;
          }
          return fetchedYamls[fetchedYamls.length - 1];
        });
      setLoading(false);
      if (lastFetchedYaml === undefined) {
        return;
      }
      yamlService.fetchAllYamls(isAgency, storeId).then((fetchedNextYamls) => {
        if (fetchedNextYamls.length !== 0) {
          setHasMoreYamls(true);
        } else {
          setHasMoreYamls(false);
        }
      });
    },
    []
  );

  return {
    loading,
    yamls,
    editYaml,
    fetchYamls,
    setEditYaml,
    hasMoreYamls,
  };
};

export default createContainer(YamlContainer);
