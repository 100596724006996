import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  Segment,
  Message,
  Header,
  List,
  Label,
  Divider,
  Button,
} from "semantic-ui-react";

import { useInstagramStoreProfile } from "hooks/useInstagramStoreProfile";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
  setIsRegister: React.Dispatch<React.SetStateAction<boolean>>;
};

export const InstagramStoreProfile: React.FC<Props> = ({
  isAgency,
  storeId,
  setIsRegister,
}) => {
  const { instagramStoreProfile } = useInstagramStoreProfile({
    isAgency,
    storeId,
  });

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Segment>
      <Header content="Instagram 連携機能情報" dividing />
      <Message content="Instagram 連携機能情報登録済です。" success />

      <List divided relaxed>
        <List.Item>
          <Label
            color="teal"
            horizontal
            css={{ width: isMobile ? "40%" : "20%" }}
            content="アカウントID"
          />

          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              href={`https://instagram.com/${instagramStoreProfile?.instagramAccountId.replace(
                /^@/,
                ""
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {instagramStoreProfile?.instagramAccountId}
            </a>
          </div>
        </List.Item>
        <List.Item>
          <Label
            color="teal"
            horizontal
            css={{ width: isMobile ? "40%" : "20%" }}
            content="ハッシュタグ"
          />

          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {instagramStoreProfile?.instagramHashtag}
          </div>
        </List.Item>
        <Divider fitted css={{ marginBottom: "12px!important" }} />
        <div css={{ textAlign: "center" }}>
          <Button
            type="button"
            content="再登録フォーム"
            color="yellow"
            onClick={() => setIsRegister(false)}
          />
        </div>
      </List>
    </Segment>
  );
};
