import React, { useState } from "react";
import { generatePath, Link } from "react-router-dom";
import routes from "utils/routes";
import {
  Table,
  Button,
  Modal,
  Label,
  Select,
  Message,
  List,
} from "semantic-ui-react";
import Order, {
  OrderStatus,
  makePaymentTypeLabel,
  makeStatusLabel,
} from "domain/model/Order";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ja";
import { orderRepository } from "domain/repository";

dayjs.extend(relativeTime);
dayjs.locale("ja");

type Props = {
  order: Order;
  isAgency: boolean;
  storeId: string;
};

export const OrderItem: React.FC<Props> = ({ order, isAgency, storeId }) => {
  const [modalMode, setModalMode] = useState<OrderStatus | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const detailUrl = isAgency
    ? generatePath(routes.agency.orderDetail.pathname, {
        storeId,
        id: order.id,
      })
    : generatePath(routes.store.orderDetail.pathname, {
        id: order.id,
      });

  const totalPrice = order.orderDetails.reduce(
    (acc, { unitPrice, number }) => acc + unitPrice * number,
    0
  );

  const selectableStatuses = listSelectableStatuses(order);

  return (
    <Table.Row>
      <Table.Cell>
        <Link to={detailUrl}>
          {order.takeoutDate.replaceAll("-", "")} - {order.orderNumber}
        </Link>
      </Table.Cell>
      <Table.Cell>
        {order.orderDateTimeFromNow}
        <br />
        <small>{order.orderDateTimeRenderValue}</small>
      </Table.Cell>
      <Table.Cell>
        {order.takeoutDateTimeFromNow}
        <br />
        <small>{order.takeoutDateTimeRendeValue}</small>
      </Table.Cell>
      <Table.Cell>
        {order.orderDetails.map(({ product, number, options }, i) => (
          <>
            <div key={i}>
              {product.name} × {number}
            </div>
            <List>
              {options
                ? (
                    JSON.parse(options) as {
                      name: string;
                      value: string;
                      price: number;
                    }[]
                  )
                    .map((i) => `${i.name}: ${i.value}`)
                    .map((i) => <List.Item>{i}</List.Item>)
                : ""}
            </List>
          </>
        ))}
      </Table.Cell>
      <Table.Cell>
        {makePaymentTypeLabel(order.paymentType, order.settlement)}
        {order.paymentType === "store" && (
          <div>¥{totalPrice.toLocaleString()}</div>
        )}
      </Table.Cell>
      <Table.Cell css={{ overflow: "visible !important" }}>
        {selectableStatuses.length === 0 ? (
          makeStatusLabel(order.orderStatus)
        ) : (
          <Select
            options={[order.orderStatus, ...selectableStatuses].map(
              (status) => ({
                key: status,
                value: status,
                text: makeStatusLabel(status),
              })
            )}
            value={order.orderStatus}
            onChange={(_, { value }) => {
              setModalMode(value as OrderStatus);
            }}
            css={{ minWidth: "10em !important" }}
          />
        )}

        {modalMode !== null && (
          <ChangeStatusModal
            order={order}
            toStatus={modalMode}
            isLoading={isLoading}
            onChangeState={() => {
              setIsLoading(true);
              changeStatus({
                order,
                isAgency,
                storeId,
                toStatus: modalMode,
              }).catch(() => setIsLoading(false));
            }}
            onClose={() => setModalMode(null)}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

const listSelectableStatuses = ({ orderStatus }: Order): OrderStatus[] => {
  switch (orderStatus) {
    case "new":
      return ["processing", "completed", "cancel"];
    case "processing":
      return ["completed", "cancel"];
    default:
      return [];
  }
};

const changeStatus = async ({
  isAgency,
  storeId,
  order,
  toStatus,
}: {
  isAgency: boolean;
  storeId: string | undefined;
  order: Order;
  toStatus: OrderStatus;
}) => {
  const action = findChangeStatusAction(toStatus);

  if (action === null) {
    return;
  }

  await action(isAgency, storeId, String(order.id));
  window.location.reload();
};

const findChangeStatusAction = (toStatus: OrderStatus) => {
  switch (toStatus) {
    case "processing":
      return orderRepository.changeStatusToProcessing;
    case "completed":
      return orderRepository.changeStatusToCompleted;
    case "cancel":
      return orderRepository.changeStatusToCancel;
    default:
      return null;
  }
};

const ChangeStatusModal: React.FC<{
  order: Order;
  toStatus: OrderStatus;
  isLoading: boolean;
  onChangeState: () => void;
  onClose: () => void;
}> = ({ toStatus, order, isLoading, onChangeState, onClose }) => (
  <Modal open={true}>
    <Modal.Header>ステータス変更</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <div style={{ marginBottom: 21 }}>
          <Label size="large">{makeStatusLabel(order.orderStatus)}</Label>
          &nbsp;→&nbsp;
          <Label size="large" color="red">
            {makeStatusLabel(toStatus)}
          </Label>
        </div>

        <h3>受注番号</h3>
        <p style={{ fontSize: "1.2rem" }}>
          {order.takeoutDate.replaceAll("-", "")} - {order.orderNumber}
        </p>

        <h3>受注日時</h3>
        <p style={{ fontSize: "1.2rem" }}>{order.orderDateTimeRenderValue}</p>

        <h3>受け渡し日時</h3>
        <p style={{ fontSize: "1.2rem" }}>{order.takeoutDateTimeRendeValue}</p>

        <h3>注文商品</h3>
        <ul>
          {order.orderDetails.map(({ product, number }, i) => (
            <li key={i}>
              {product.name} × {number}
            </li>
          ))}
        </ul>

        {toStatus === "cancel" && (
          <Message
            color="red"
            content="キャンセル後は取り消すことはできません"
          />
        )}
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={onClose} disabled={isLoading} loading={isLoading}>
        閉じる
      </Button>
      <Button
        color="blue"
        onClick={onChangeState}
        disabled={isLoading}
        loading={isLoading}
      >
        変更
      </Button>
    </Modal.Actions>
  </Modal>
);
