export const acceptFileType = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/pdf",
];

/**
 * 10MB以下かチェック
 */
export const validFileSize = (file: File): boolean => {
  return file.size <= 10 * 1024 * 1024;
};
