import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";

import Agency from "domain/model/Agency";

class AgencyService {
  fetchAgencies(page: number) {
    return api
      .get(endpoint.agency.agencies.root(), { params: { page } })
      .then(({ data }) => {
        const response: { agencies: Agency[]; total: number } = {
          agencies: data.agencies.map((a: Agency) =>
            plainToClass(Agency, a, {
              excludeExtraneousValues: true,
            })
          ),
          total: data.total,
        };
        return response;
      });
  }
}

export default new AgencyService();
