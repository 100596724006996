import React from "react";
import { useHistory, generatePath } from "react-router-dom";

import Post from "domain/model/Post";
import { ArticleItem } from "components/Post/Article/Index/ArticleItem";
import routes from "utils/routes";

import { Table } from "semantic-ui-react";

type Props = {
  articles: Post[];
  isAgency: boolean;
  storeId: string;
};

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell css={{ width: "10%" }}>
          {/* index */}
        </Table.HeaderCell>
        <Table.HeaderCell css={{ width: "20%" }}>サムネイル</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "20%" }}>タイトル</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "20%" }}>カテゴリ</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "10%" }}>ステータス</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "10%" }}>投稿日</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "10%" }}>
          {/* 編集ボタン */}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export const ArticleList: React.FC<Props> = ({
  isAgency,
  articles,
  storeId,
}) => {
  const history = useHistory();
  return (
    <>
      <Table fixed>
        <TableHeader />
        <Table.Body>
          {articles.map((article, i) => {
            const handleEdit = () => {
              const editUrl = isAgency
                ? generatePath(routes.agency.articleEdit.pathname, {
                    storeId,
                    slug: article.slug,
                  })
                : generatePath(routes.store.articleEdit.pathname, {
                    slug: article.slug,
                  });
              history.push(editUrl);
            };
            return (
              <ArticleItem
                article={article}
                handleClickEdit={handleEdit}
                index={i}
                key={article.slug}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};
