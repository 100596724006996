import React, { useState, useEffect } from "react";
import { useParams, generatePath, useHistory } from "react-router-dom";

import PageContainer from "components/lib/PageContainer";
import { Loader, Button } from "semantic-ui-react";

import { Container } from "components/Post/Article/Index/Container";
import { UserContainer } from "containers";
import { articleService } from "domain/service";
import { StoreIdParam } from "config/raven";
import routes from "utils/routes";
import {
  NoTemplateModal,
  Category as NoTemplateCategory,
} from "components/lib/NoTemplateModal";
import {
  ApplyTemplateModal,
  Category as ApplyTemplateCategory,
} from "components/lib/ApplyTemplateModal";
import { articleRepository } from "domain/repository";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const ArticleIndex = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  const [isTemplateExist, setIsTemplateExist] = useState<boolean | null>(null);

  // Modal State
  const [openApplyTemplateModal, setOpenApplyTemplateModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const fetchTemplate = () => {
      articleService
        .fetchArticleTemplate(isAgency, storeId)
        .then((template) => {
          if (template === null) {
            setIsTemplateExist(false);
          } else {
            setIsTemplateExist(true);
          }
        });
    };
    fetchTemplate();
  }, [isAgency, storeId]);

  const getContents = () => {
    // テンプレート
    if (isTemplateExist === null) {
      return <Loader active />;
    }
    if (!isTemplateExist) {
      return (
        <NoTemplateModal
          category={NoTemplateCategory.ARTICLE}
          handleTemplatePageButton={() => {
            const templatePageUrl = isAgency
              ? generatePath(routes.agency.articleTemplate.pathname, {
                  storeId,
                })
              : routes.store.articleTemplate.pathname;
            history.push(templatePageUrl);
          }}
        />
      );
    }

    return (
      <>
        <ApplyTemplateModal
          open={openApplyTemplateModal}
          setOpen={(open: boolean) => setOpenApplyTemplateModal(open)}
          applyTemplate={() =>
            articleRepository.applyArticleTemplate(isAgency, storeId)
          }
          category={ApplyTemplateCategory.ARTICLE}
        />
        <Button
          color="red"
          content="テンプレートの一括適用"
          onClick={() => setOpenApplyTemplateModal(true)}
        />
        <Container isAgency={isAgency} storeId={storeId} />
      </>
    );
  };

  return (
    <PageContainer
      header="記事一覧"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId).build(
        "記事一覧"
      )}
      action={
        <Button
          color="blue"
          content="新規投稿"
          onClick={() => {
            const articleNewUrl = isAgency
              ? generatePath(routes.agency.articleNew.pathname, {
                  storeId,
                })
              : routes.store.articleNew.pathname;
            history.push(articleNewUrl);
          }}
        />
      }
    >
      {getContents()}
    </PageContainer>
  );
};
