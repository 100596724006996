import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";

import SocialAccount from "domain/model/SocialAccount";

import { SocialAuthUris } from "config/raven";
//UIにはないが,将来実装する可能性があるので残す
class SnsService {
  async fetchAllLoginUrls(
    isAgency: boolean,
    storeId: string | undefined
  ): Promise<SocialAuthUris> {
    const url =
      isAgency && storeId
        ? endpoint.agency.sns.auth(storeId)
        : endpoint.store.sns.auth();
    return api.get(url).then((res) => res.data.auth_uris);
  }

  async fetchAllSocialAccountInfos(
    isAgency: boolean,
    storeId: string | undefined
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.sns.root(storeId)
        : endpoint.store.sns.root();
    return api.get(url).then((res) =>
      res.data.reduce((res: any, data: SocialAccount) => {
        const account = plainToClass(SocialAccount, data);
        return { ...res, [account.provider]: account };
      }, {})
    );
  }
}

export default new SnsService();
