import { Expose } from "class-transformer";

export default class StoreProfile {
  @Expose({ name: "store_id" }) storeId: number;
  @Expose() name: string;
  @Expose({ name: "resty_group_code" }) restyGroupCode: string;
  @Expose({ name: "resty_shop_id" }) restyShopId: number;
  @Expose({ name: "resty_user_id" }) restyUserId: string;
  static storeId: any;
}
