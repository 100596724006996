import dayjs from "dayjs";

/**
 * 現在時刻以前かをチェックする
 */
export const isValidDateByNow = (value: Date): boolean => {
  const targetUnixtime = dayjs(value).unix();
  const nowUnixtime = dayjs().unix();
  const isValid = nowUnixtime >= targetUnixtime;
  return isValid;
};
