import React, { useEffect, useState } from "react";
import { useHistory, useParams, generatePath } from "react-router-dom";
import { Button, Loader, Message, Segment } from "semantic-ui-react";

import routes from "utils/routes";
import PageContainer from "components/lib/PageContainer";
import { UserContainer } from "containers";
import { StoreIdParam } from "config/raven";
import JobFeedList from "components/JobFeed/Index/JobFeedList";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import JobFeed from "domain/model/JobFeed";
import { useIsJobOfferEnabled } from "hooks/useIsJobOfferEnabled";
import { jobFeedService } from "domain/service";
import { JOB_FEED_LABEL } from "config/ItemLabel";

export const JobFeedIndex = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();
  const history = useHistory();
  const [jobFeeds, setJobFeeds] = useState<JobFeed[] | null>(null);

  const isJobOfferEnabled = useIsJobOfferEnabled({ isAgency, storeId });

  useEffect(() => {
    jobFeedService
      .fetchJobFeeds(isAgency, storeId)
      .then((res) => setJobFeeds(res));
  }, [isAgency, storeId]);

  if (isJobOfferEnabled === false) {
    return <Message content="求人管理機能が利用できません" negative />;
  }
  if (jobFeeds === null) {
    return <Loader active />;
  }

  return (
    <>
      <PageContainer
        header={`${JOB_FEED_LABEL}一覧`}
        breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId).build(
          `${JOB_FEED_LABEL}一覧`
        )}
        action={
          <Button
            color="blue"
            content="新規登録"
            onClick={() => {
              const formNewUrl = isAgency
                ? generatePath(routes.agency.jobFeedNew.pathname, {
                    storeId,
                  })
                : routes.store.jobFeedNew.pathname;
              history.push(formNewUrl);
            }}
          />
        }
      >
        <Segment>
          <JobFeedList
            isAgency={isAgency}
            jobFeeds={jobFeeds}
            storeId={storeId}
          />
        </Segment>
      </PageContainer>
    </>
  );
};

export default JobFeedIndex;
