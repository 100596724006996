import React, { useEffect, useState } from "react";
import PageContainer from "components/lib/PageContainer";
import { Loader, Button, Message } from "semantic-ui-react";
import { UserContainer } from "containers";
import { useParams } from "react-router-dom";
import { StoreIdParam } from "config/raven";
import { newsService } from "domain/service";
import { SourceCodeEditor } from "components/lib/Editor/SourceCodeEditor";
import { newsRepository } from "domain/repository";
import { useMessage } from "hooks/useMessage";
import {
  ApplyTemplateModal,
  Category as ApplyTemplateCategory,
} from "components/lib/ApplyTemplateModal";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

const Container: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  const [isTemplateExist, setIsTemplateExist] = useState<boolean | null>(null);
  const [templateContent, setTemplateContent] = useState<string | null>(null);

  // Modal State
  const [openApplyTemplateModal, setOpenApplyTemplateModal] = useState(false);

  const { renderMessage, setSuccessMessage, setErrorMessage } = useMessage({
    messageSize: "tiny",
  });

  useEffect(() => {
    const fetchTemplate = () => {
      newsService.fetchNewsTemplate(isAgency, storeId).then((template) => {
        if (template === null) {
          setTemplateContent("");
          setIsTemplateExist(false);
        } else {
          setTemplateContent(template.content);
          setIsTemplateExist(true);
        }
      });
    };
    fetchTemplate();
  }, [isAgency, storeId]);
  const handleSaveButton = () => {
    if (templateContent === null) {
      return;
    }

    const execute = isTemplateExist
      ? newsRepository.updateTemplate
      : newsRepository.createTemplate;

    execute(isAgency, storeId, templateContent)
      .then(() => {
        setSuccessMessage("テンプレートを更新しました");
        setIsTemplateExist(true);
      })
      .catch((errors) => {
        if (
          errors.some((error: string) => error === "not found required classes")
        ) {
          setErrorMessage("必須のタグが含まれていません");
        } else {
          setErrorMessage("テンプレートの更新に失敗しました");
        }
      });
  };

  if (templateContent === null) {
    return <Loader active></Loader>;
  }
  return (
    <>
      <ApplyTemplateModal
        open={openApplyTemplateModal}
        setOpen={(open: boolean) => setOpenApplyTemplateModal(open)}
        applyTemplate={() =>
          newsRepository.applyNewsTemplate(isAgency, storeId)
        }
        category={ApplyTemplateCategory.NEWS}
      />
      <div
        css={{
          display: "flex",
          margin: "16px 0",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="red"
          content="テンプレートの一括適用"
          onClick={() => {
            handleSaveButton();
            setOpenApplyTemplateModal(true);
          }}
        />

        <Button content="保存" primary onClick={handleSaveButton}></Button>
      </div>
      {renderMessage()}
      <SourceCodeEditor
        source={templateContent}
        fileType="html"
        handleChange={(newContent) => setTemplateContent(newContent)}
      ></SourceCodeEditor>
      <Message
        icon="code"
        header="※HTMLには必ず以下のタグを含んでください。"
        list={[
          '1. <div class="CMS-NEWS-TITLE"></div>',
          '2. <div class="CMS-NEWS-CONTENT"></div>',
        ]}
      />
    </>
  );
};

export const NewsTemplate: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();
  return (
    <PageContainer
      header="お知らせテンプレート設定"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .newsPage()
        .build("お知らせテンプレート設定")}
    >
      <Container />
    </PageContainer>
  );
};
