import React from "react";
import { Table, Button, Label } from "semantic-ui-react";
import Yaml from "domain/model/Yaml";

type Props = {
  yaml: Yaml;
  storeId: string;
  handleClickEdit: () => void;
  index: number;
};

export const YamlItem: React.FC<Props> = ({
  yaml,
  storeId,
  handleClickEdit,
  index,
}) => {
  const jsonUrl = yaml.public_url.replace(/\.yml$/, ".json");
  return (
    <Table.Row key={index}>
      <Table.Cell>{index + 1}</Table.Cell>
      <Table.Cell>{yaml.title}</Table.Cell>
      <Table.Cell>
        <a href={yaml.public_url} target="_blank" rel="noopener noreferrer">
          {yaml.public_url}
        </a>
        <br />
        <a href={jsonUrl} target="_blank" rel="noopener noreferrer">
          {jsonUrl}
        </a>
      </Table.Cell>
      <Table.Cell>
        {yaml.active ? (
          <Label color="green">有効</Label>
        ) : (
          <Label color="grey">無効</Label>
        )}
      </Table.Cell>
      <Table.Cell>
        <Button onClick={handleClickEdit} color="blue" content="編集" />
      </Table.Cell>
    </Table.Row>
  );
};
