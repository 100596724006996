import React from "react";
import { useHistory } from "react-router-dom";

import routes from "utils/routes";

import PageContainer from "components/lib/PageContainer";
import { AgencyNotificationNewForm } from "components/AgencyNotification/New/AgencyNotificationNewForm";

import { Button } from "semantic-ui-react";

export const AgencyNotificationNew: React.FC = () => {
  const history = useHistory();

  const notificationIndexPath = routes.adminAgency.notificationIndex.pathname;

  return (
    <PageContainer
      header="代理店からの新規お知らせ投稿"
      action={
        <Button
          color="blue"
          content="お知らせ一覧へ"
          onClick={() => history.push(notificationIndexPath)}
        />
      }
    >
      <AgencyNotificationNewForm />
    </PageContainer>
  );
};
