import React, { useCallback, useState } from "react";
import { sitemapRepository } from "domain/repository";
import {
  ExecuteSitemapModal,
  ModalTypes,
} from "components/SiteManage/Content/Sitemap/ExecuteSitemapModal";
import { Button, Header, Message, Segment } from "semantic-ui-react";

type Props = { storeId: string | undefined };
export const SubmitSitemapSegment: React.FC<Props> = ({ storeId }) => {
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const submitSitemap = useCallback(() => {
    if (storeId === undefined) {
      return Promise.reject();
    }
    return sitemapRepository.submitSitemap(storeId).then(() => {});
  }, [storeId]);
  return (
    <>
      <ExecuteSitemapModal
        open={openSubmitModal}
        setOpen={setOpenSubmitModal}
        execute={submitSitemap}
        modalType={ModalTypes.SUBMIT}
      />

      <Segment>
        <Header content="サイトマップの提出" size="small" />
        <Message
          warning
          content="あらかじめGoogleSearchConsoleでウェブサイトの登録を行う必要があります"
        />
        <Button
          primary
          content="サイトマップを提出"
          onClick={() => setOpenSubmitModal(true)}
        />
      </Segment>
    </>
  );
};
