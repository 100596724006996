import { plainToClass } from "class-transformer";
import api from "utils/api";
import endpoint from "utils/endpoint";

import { Version } from "domain/model/Version";

class VersionService {
  fetchVersions(
    isAgency: boolean,
    storeId: string | undefined,
    createdAt?: string
  ): Promise<Version[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.version.root(storeId)
        : endpoint.store.version.root();
    return api
      .get(url, {
        params: { created_at: createdAt },
      })
      .then((res) =>
        res.data.map((ele: { data: Version }) =>
          plainToClass(Version, ele.data, { excludeExtraneousValues: true })
        )
      );
  }

  downloadVersion(
    isAgency: boolean,
    storeId: string | undefined,
    { versionHash }: { versionHash: string }
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.version.archive(storeId)
        : endpoint.store.version.archive();
    return api.get(url, {
      params: { version_hash: versionHash },
      responseType: "blob",
      // 時間がかかることがあるので長くしておく
      timeout: 30 * 60 * 1000,
    });
  }

  public async fetchSourceFile(
    isAgency: boolean,
    storeId: string | undefined,
    objectHash: string
  ): Promise<string> {
    const url =
      isAgency && storeId
        ? endpoint.agency.version.source(storeId)
        : endpoint.store.version.source();
    return api
      .get(url, {
        params: { object_hash: objectHash },
      })
      .then((res) => res.data.context.source);
  }
}

export default new VersionService();
