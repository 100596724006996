import api from "utils/api";
import endpoint from "utils/endpoint";
import { SeoPreferences } from "domain/model/SeoPreferences";

class Repository {
  public async updateSeoPreferences(
    isAgency: boolean,
    storeId: string | undefined,
    seoPreferences: SeoPreferences
  ) {
    const body = {
      seo_preferences: {
        ...seoPreferences,
        og: {
          ...seoPreferences.og,
          site_name: seoPreferences.og.siteName,
        },
      },
    };
    const url =
      isAgency && storeId
        ? endpoint.agency.seo.root(storeId)
        : endpoint.store.seo.root();
    return api.post(url, body);
  }
}

export const SeoRepository = new Repository();
