import React from "react";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { UserContainer } from "containers";
import { StoreIdParam } from "config/raven";

import PageContainer from "components/lib/PageContainer";

import { ContactFormCards } from "components/Contact/FormIndex/ContactFormCards";
import { Button, Segment } from "semantic-ui-react";
import { CopyModal, FormTypes } from "components/lib/CopyModal";
import routes from "utils/routes";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const ContactFormIndex: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  const { state } = useLocation<{ savedFormDom: string }>();
  const savedFormDom = state?.savedFormDom;

  const history = useHistory();

  return (
    <>
      <CopyModal savedFormDom={savedFormDom} formType={FormTypes.CONTACT} />
      <PageContainer
        header="問い合わせフォーム一覧"
        breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId).build(
          "問い合わせフォーム一覧"
        )}
        action={
          <Button
            color="blue"
            content="新規フォーム作成"
            onClick={() => {
              const formNewUrl = isAgency
                ? generatePath(routes.agency.contactFormNew.pathname, {
                    storeId,
                  })
                : routes.store.contactFormNew.pathname;
              history.push(formNewUrl);
            }}
          />
        }
      >
        <Segment>
          <ContactFormCards isAgency={isAgency} storeId={storeId} />
        </Segment>
      </PageContainer>
    </>
  );
};
