import React, { useState, useEffect } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import routes from "utils/routes";

import { storeService } from "domain/service";
import { StoreContainer } from "containers";
import Store from "domain/model/Store";

import { Loader, Button } from "semantic-ui-react";
import StoreList from "components/StoreIndex/StoreList";
import PageContainer from "components/lib/PageContainer";
import Pagination from "components/lib/Pagination";
import SearchInput from "components/StoreIndex/SearchInput";

const StoreIndex = () => {
  const { changeCurrentStore } = StoreContainer.useContainer();
  const history = useHistory();

  const [stores, setStores] = useState<Store[] | null>(null);
  const [total, setTotal] = useState<number | null>(null);
  const { page } = useParams<{ page: string }>();
  const pageNumber = Number(page);
  const [searchWord, setSearchWord] = useState<string>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (searchWord) {
      // 検索して店舗を取得
      storeService
        .fetchStores({ search: searchWord, page: pageNumber })
        .then((res) => {
          setStores(res.stores);
          setTotal(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // 店舗を取得
      storeService
        .fetchStores({ page: pageNumber })
        .then((res) => {
          setStores(res.stores);
          setTotal(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [pageNumber, searchWord]);

  const moveDashboard = (storeId: number) => {
    changeCurrentStore(true, storeId.toString());
    history.push(generatePath(routes.agency.dashboard.pathname, { storeId }));
  };

  const handleSearch = (search: string) => {
    setSearchWord(search);
    const pageUrl = generatePath(routes.adminAgency.storeIndex.pathname, {
      page: 1,
    });
    history.push(pageUrl);
  };

  if (loading || stores === null) {
    return <Loader active />;
  }

  return (
    <>
      <PageContainer
        header="店舗一覧"
        action={
          <Button
            color="blue"
            content="新規店舗作成"
            onClick={() => history.push(routes.adminAgency.storeNew.pathname)}
          />
        }
      >
        <div css={{ textAlign: "right" }}>
          <SearchInput handleSearch={handleSearch} searchWord={searchWord} />
        </div>
        <StoreList stores={stores} moveDashboard={moveDashboard} />
        <div css={{ textAlign: "center" }}>
          <Pagination
            active={pageNumber}
            total={total ?? 0}
            handleChange={(activePage: number) => {
              const pageUrl = generatePath(
                routes.adminAgency.storeIndex.pathname,
                {
                  page: activePage,
                }
              );
              history.push(pageUrl);
            }}
          />
        </div>
      </PageContainer>
    </>
  );
};

export default StoreIndex;
