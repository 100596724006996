import React, { useState } from "react";
import {
  fetchDom,
  replaceText,
  editAnchor,
  replaceOuterHTML,
  replaceImage,
  removeDOM,
  undo,
  redo,
} from "./wysiwygActions";
import WysiwygIframe from "./WysiwygIframe";
import WysiwygReplaceImageModal from "./WysiwygReplaceImageModal";
import WysiwygActionModal from "./WysiwygActionModal";
import WysiwygActionBox from "./WysiwygActionBox";
import WysiwygActionButtonRow from "./WysiwygActionButtonRow";

type Props = {
  source: string;
  documentBaseUrl: string;
  submit: (title: string, editedHTML: string, deployment: boolean) => void;
  mediaUrls: string[];
};

export const FullHtmlWysiwygEditorV1: React.FC<Props> = ({
  source,
  documentBaseUrl,
  submit,
  mediaUrls,
}) => {
  const [html, setHtml] = useState("");
  const [baseTag, setBaseTag] = useState("");
  const [imagesModal, showImagesModal] = useState(false);
  const [actionModal, showActionModal] = useState(false);
  const [src, setSrc] = useState("");
  const [href, setHref] = useState("");
  const [target, setTarget] = useState<any>("");
  const [text, setText] = useState("");
  const [outerHTML, setOuterHTML] = useState("");
  const [actionActiveNumber, setActionActiveNumber] = useState(0);
  const [actionBox, setActionBox] = useState(false);
  const [actionBoxParams, setActionBoxParams] = useState<any>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    tagName: "",
    className: "",
    id: "",
    background: "",
    backgroundColor: "",
    backgroundImage: "",
    backgroundSize: "",
    backgroundPosition: "",
    src: "",
  });
  const [previousChild, setPreviousChild] = useState();

  const [title, setTitle] = useState("");

  React.useEffect(() => {
    const addBaseTag = () => {
      const splited = source.split("<head>");
      const base = `\n<base href="${documentBaseUrl}">`;
      splited.splice(1, 0, `<head>${base}`);
      const joinedHTML = splited.join("");
      setHtml(joinedHTML);
      setBaseTag(base);
    };
    addBaseTag();
    const timer = setTimeout(() => {
      const iframe = document.querySelector("#iframe") as HTMLIFrameElement;
      // @ts-ignore
      iframe.contentDocument.addEventListener(
        "click",
        (e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick(e.target);
        },
        true
      );
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [source, documentBaseUrl, html, baseTag]);

  const onClick = (newTarget: any) => {
    if (!newTarget) return;

    setPreviousChild(undefined);
    setTimeout(function () {
      newTarget.focus();
    }, 0);

    const preFrameBorder = document.getElementById("frameBorder");
    preFrameBorder && preFrameBorder.remove();

    const iframe: any = document.getElementById("iframe");
    const clientRect = newTarget.getBoundingClientRect();
    const iframeRect = iframe.getBoundingClientRect();

    const left = clientRect.left + clientRect.width + 25;
    const iframeTopOffset = iframeRect.top - 150;
    const adjustedTop = clientRect.top + 120;
    const top = adjustedTop < iframeTopOffset ? iframeTopOffset : adjustedTop;

    const targetSrc =
      newTarget.tagName === "IMG" ? newTarget.getAttribute("src") : "";
    setActionBoxParams({
      ...actionBoxParams,
      top,
      left,
      width: newTarget.clientWidth,
      height: newTarget.clientHeight,
      tagName: newTarget.tagName.toLowerCase(),
      className: newTarget.className,
      id: newTarget.id,
      background: newTarget.style.background || "",
      backgroundColor: newTarget.style.backgroundColor || "",
      backgroundImage: newTarget.style.backgroundImage || "",
      backgroundSize: newTarget.style.backgroundSize || "",
      backgroundPosition: newTarget.style.backgroundPosition || "",
      src: targetSrc,
    });
    !actionBox && setActionBox(true);

    if (target !== "") {
      target.contentEditable = false;
    }
    newTarget.contentEditable = true;

    setTarget(newTarget);
  };

  const detailEdit = () => {
    setText(target.innerHTML);
    target.tagName === "A" && setHref(target.href);

    showActionModal(true);
    setTarget(target);
    setOuterHTML(target.outerHTML);
  };

  const update = (deployment: boolean) => {
    target.removeAttribute("contenteditable");
    const dom = fetchDom();
    const editedHTML = dom.replace(baseTag, "");
    submit(title, editedHTML, deployment);
  };

  const replaceElement = (source: any, newType: any) => {
    const frag = document.createDocumentFragment();
    while (source.firstChild) {
      frag.appendChild(source.firstChild);
    }
    const newElem = document.createElement(newType);
    newElem.appendChild(frag);
    source.parentNode.replaceChild(newElem, source);
  };

  const setParams = (type: any) => {
    switch (type) {
      case "tagName":
        const newTagName = actionBoxParams[type].toLowerCase();
        replaceElement(target, newTagName);
        break;
      case "backgroundColor":
      case "backgroundImage":
      case "backgroundSize":
      case "backgroundPosition":
      case "background":
        target.style[type] = actionBoxParams[type];
        break;
      default:
        target[type] = actionBoxParams[type];
    }
  };

  const cloneElement = () => {
    const clone = target.cloneNode(true);
    target.parentNode.insertBefore(clone, target);
  };

  const moveFocus = (toParent: any) => {
    if (toParent) {
      onClick(target.parentNode);
      setPreviousChild(target);
    } else {
      onClick(previousChild || target.children[0]);
    }
  };

  return (
    <div>
      <WysiwygActionModal
        show={actionModal}
        close={() => showActionModal(false)}
        target={target}
        showImagesModal={showImagesModal}
        text={text}
        setText={(value: any) => setText(value)}
        replaceText={() => replaceText(target, text)}
        replaceOuterHTML={() => {
          replaceOuterHTML(target, outerHTML);
        }}
        removeDOM={removeDOM}
        href={href}
        setHref={(e: any) => setHref(e.target.value)}
        editAnchor={() => editAnchor(target, href)}
        outerHTML={outerHTML}
        setOuterHTML={setOuterHTML}
      />
      <WysiwygReplaceImageModal
        show={imagesModal}
        close={() => {
          showImagesModal(false);
        }}
        mediaUrls={mediaUrls}
        src={src}
        setSrc={setSrc}
        replaceImage={(src: any) => replaceImage(target, src)}
      />
      <WysiwygActionButtonRow
        update={update}
        handleTitleChange={(event: any) => {
          setTitle(event.target.value);
        }}
        title={title}
      />
      <WysiwygIframe html={html} />
      {actionBox && (
        <WysiwygActionBox
          actionActiveNumber={actionActiveNumber}
          setActionActiveNumber={setActionActiveNumber}
          actionBoxParams={actionBoxParams}
          setActionBoxParams={setActionBoxParams}
          setParams={setParams}
          cloneElement={cloneElement}
          detailEdit={detailEdit}
          target={target}
          moveFocus={moveFocus}
          undo={undo}
          redo={redo}
        />
      )}
    </div>
  );
};
