import React from "react";
import styled from "@emotion/styled";
import { Icon, Segment } from "semantic-ui-react";
import FileBrowser from "react-keyed-file-browser";
import { FileMeta } from "components/Version/Edit";

export type File = Pick<FileMeta, "key" | "objectHash">;

type Props = {
  files: File[] | undefined;
  handleClickFile: (file: File) => void;
};
export const FileTree: React.FC<Props> = ({ files, handleClickFile }) => (
  <FileBrowserWrap>
    <FileBrowser
      files={files}
      icons={{
        File: <Icon name="file" />,
        Image: <Icon name="image" />,
        PDF: <Icon name="file pdf" />,
        Folder: <Icon name="folder" />,
        FolderOpen: <Icon name="folder open" />,
      }}
      onSelectFile={(file: File) => handleClickFile(file)}
    />
  </FileBrowserWrap>
);

const FileBrowserWrap = styled(Segment)`
  .rendered-file-browser + div {
    display: none;
  }
  .selected {
    background-color: ghostwhite;
  }
  input {
    width: 100%;
    background-color: white;
    color: black;
    height: 2rem;
    padding: 0.375rem 0.75rem;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    box-sizing: border-box;
  }
  .files {
    display: flex;
    justify-content: center;
    table {
      width: 95%;
    }
    th {
      display: none;
    }
    td {
      font-size: 1.2rem;
      cursor: pointer;
      div {
        margin: 1rem 0;
        div {
          .icon {
            color: gray;
          }
          a {
            color: darkslategray;
          }
          div {
            .icon {
              color: gray;
            }
            a {
              color: gray;
            }
          }
        }
      }
      border-bottom: 1px solid lightgray;
    }
    td:nth-of-type(2) {
      display: none;
    }
  }
  .modified {
    display: none;
  }
`;
