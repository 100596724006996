import { Expose } from "class-transformer";
import { DNSRecordType } from "domain/model/DNS/DNSRecordType";

export default class DNSRecordSet {
  @Expose() name: string;
  @Expose() type: DNSRecordType;
  @Expose() values: Value[];
}

class Value {
  value: string;
}

export type DNSRecord = {
  name: string;
  type: DNSRecordType;
  value: string;
};
