import { Expose } from "class-transformer";
import dayjs from "dayjs";

import { FormStatus } from "config/raven";

type Content = Partial<{
  年齢: string | null;
  氏名: string | null;
  住所: string | null;
  携帯電話番号: string | null;
  電話番号: string | null;
  メールアドレス: string | null;
  採用形態: string | null;
  現在のご職業: string | null;
}>;

export default class Applicant {
  @Expose({ name: "contact_idef" }) contactId: string;
  // change union
  @Expose({ name: "contact_type" }) contactType: string;
  @Expose() status: FormStatus;
  @Expose({ name: "form_idef" }) formId: string;
  @Expose({ name: "contents" }) content: Content;

  @Expose({ name: "created_at" })
  unixCreatedAt: number;

  @Expose({ name: "created_at" })
  get createdAt() {
    return dayjs(Number(this.unixCreatedAt) * 1000).format("YYYY/MM/DD");
  }

  @Expose() remarks: string;
}
