import React from "react";

import { Segment, Header, Grid } from "semantic-ui-react";
import MenuItem from "components/lib/MenuItem";

type Props = {
  isAgency: boolean;
  useTakeout: boolean;
  moveDomain: Function;
  moveSitemap: Function;
  moveFavicon: Function;
  moveOgp: Function;
  moveBasicAuth: Function;
  moveGoogleAnalytics: Function;
  moveTabelog: Function;
  moveYaml: Function;
  moveRecruit: Function;
  moveSitest: Function;
  moveTakeout: Function;
  moveTakeoutMessageTemplate: Function;
  moveDNSRecord: Function;
  moveTakeoutLogin: Function;
  moveResty: Function;
  moveInstagram: Function;
};

const MenuCard: React.FC<Props> = ({
  isAgency,
  useTakeout,
  moveDomain,
  moveSitemap,
  moveFavicon,
  moveOgp,
  moveBasicAuth,
  moveGoogleAnalytics,
  moveTabelog,
  moveRecruit,
  moveSitest,
  moveDNSRecord,
  moveTakeoutLogin,
  moveTakeout,
  moveTakeoutMessageTemplate,
  moveResty,
  moveInstagram,
}) => {
  return (
    <Segment>
      {isAgency && (
        <>
          <Header content="サイト管理（代理店向け）" dividing />
          <Grid columns={2} doubling>
            <Grid.Row>
              <Grid.Column as="a">
                <MenuItem
                  icon="globe"
                  header="ドメイン設定"
                  content="ドメインの取得・設定"
                  onClick={() => moveDomain()}
                />
              </Grid.Column>
              <Grid.Column as="a">
                <MenuItem
                  icon="sitemap"
                  header="サイトマップ設定"
                  content="サイトマップの作成・提出"
                  onClick={() => moveSitemap()}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column as="a">
                <MenuItem
                  icon="star"
                  header="ファビコン管理"
                  content="公開中サイトのファビコンアップロード"
                  onClick={() => moveFavicon()}
                />
              </Grid.Column>
              <Grid.Column as="a">
                <MenuItem
                  icon="google"
                  header="Google連携設定"
                  content="Googleアカウント認証・Google Analyticsプロジェクト作成"
                  onClick={() => moveGoogleAnalytics()}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column as="a">
                <MenuItem
                  icon="table"
                  header="DNSレコード管理"
                  content="DNSレコードの追加・削除"
                  onClick={() => moveDNSRecord()}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
      <Header content="各種設定" dividing />
      <Grid columns={2} doubling>
        <Grid.Row>
          <Grid.Column as="a">
            <MenuItem
              icon="tags"
              header="OGPデフォルト値設定"
              content="OGPタグの設定が行なえます"
              onClick={() => moveOgp()}
            />
          </Grid.Column>
          {useTakeout && (
            <Grid.Column as="a">
              <MenuItem
                icon="male"
                header="テイクアウト通知設定"
                content="通知有効化・メッセージテンプレート編集"
                onClick={() => moveTakeoutMessageTemplate()}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>

      <Header content="オプション" dividing />
      <Grid columns={2} doubling>
        <Grid.Row>
          <Grid.Column as="a">
            <MenuItem
              icon="external alternate"
              header="外部サイト連携管理"
              content="オプション有効化・同期"
              onClick={() => moveTabelog()}
            />
          </Grid.Column>
          <Grid.Column as="a">
            <MenuItem
              icon="male"
              header="テイクアウト機能"
              content="オプション有効化・配送料設定"
              onClick={() => moveTakeout()}
            />
          </Grid.Column>
        </Grid.Row>

        {isAgency && (
          <>
            <Grid.Row>
              <Grid.Column as="a">
                <MenuItem
                  icon="male"
                  header="求人管理"
                  content="オプション有効化"
                  onClick={() => moveRecruit()}
                />
              </Grid.Column>
              <Grid.Column as="a">
                <MenuItem
                  icon="chart pie"
                  header="サイト解析管理"
                  content="オプション有効化・サイト解析ページリンク"
                  onClick={() => moveSitest()}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column as="a">
                <MenuItem
                  icon="sync"
                  header="売上一元管理"
                  content="外部サービスの管理画面との連携"
                  onClick={() => moveTakeoutLogin()}
                />
              </Grid.Column>
              <Grid.Column as="a">
                <MenuItem
                  icon="calendar alternate outline"
                  header="Resty予約機能情報"
                  content="オプション有効化・Resty予約機能情報登録"
                  onClick={() => moveResty()}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column as="a">
                <MenuItem
                  icon="key"
                  header="BASIC認証設定"
                  content="パスワード付きのページを設定できます"
                  onClick={() => moveBasicAuth()}
                />
              </Grid.Column>
              <Grid.Column as="a">
                <MenuItem
                  icon="instagram"
                  header="Instagram連携機能"
                  content="Instagram連携を設定できます。"
                  onClick={() => moveInstagram()}
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </Segment>
  );
};

export default MenuCard;
