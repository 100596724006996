import React, { useCallback, useMemo } from "react";
import { Input, Table } from "semantic-ui-react";

type Props = {
  defaultShippingFee?: string;
  ecPrefectureShippingFeesJson?: string;
  onChange: (ecPrefectureShippingFeesJson: string) => void;
};

export const PrefectureShippingFeeInputTable = ({
  defaultShippingFee,
  ecPrefectureShippingFeesJson,
  onChange,
}: Props) => {
  const parsedData: Record<string, number> = useMemo(() => {
    if (!ecPrefectureShippingFeesJson) {
      return {};
    }
    try {
      return JSON.parse(ecPrefectureShippingFeesJson);
    } catch {
      return {};
    }
  }, [ecPrefectureShippingFeesJson]);
  const setValue = useCallback(
    (prefectureCode: number, value: number) => {
      const data = {
        ...parsedData,
        [prefectureCode.toString().padStart(2, "0")]: value,
      };
      onChange(JSON.stringify(data));
    },
    [onChange, parsedData]
  );

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>都道府県</Table.HeaderCell>
          <Table.HeaderCell>手数料</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {[
          "北海道",
          "青森県",
          "岩手県",
          "宮城県",
          "秋田県",
          "山形県",
          "福島県",
          "茨城県",
          "栃木県",
          "群馬県",
          "埼玉県",
          "千葉県",
          "東京都",
          "神奈川県",
          "新潟県",
          "富山県",
          "石川県",
          "福井県",
          "山梨県",
          "長野県",
          "岐阜県",
          "静岡県",
          "愛知県",
          "三重県",
          "滋賀県",
          "京都府",
          "大阪府",
          "兵庫県",
          "奈良県",
          "和歌山県",
          "鳥取県",
          "島根県",
          "岡山県",
          "広島県",
          "山口県",
          "徳島県",
          "香川県",
          "愛媛県",
          "高知県",
          "福岡県",
          "佐賀県",
          "長崎県",
          "熊本県",
          "大分県",
          "宮崎県",
          "鹿児島県",
          "沖縄県",
        ].map((prefecture, index) => (
          <Table.Row key={index}>
            <Table.Cell>{prefecture}</Table.Cell>
            <Table.Cell>
              <Input
                placeholder={defaultShippingFee}
                value={
                  parsedData[(index + 1).toString().padStart(2, "0")] ?? ""
                }
                onChange={(e) => setValue(index + 1, parseInt(e.target.value))}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
