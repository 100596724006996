import "reflect-metadata";
import React from "react";
import ReactDOM from "react-dom";
import "index.css";
import App from "App";

// polyfill for IE11
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

ReactDOM.render(<App />, document.getElementById("root"));
