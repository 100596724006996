import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import Order, { OrderStatus } from "domain/model/Order";
import OrderSummary from "domain/model/OrderSummary";
import qs from "qs";

class OrderService {
  async fetchAllOrders(
    isAgency: boolean,
    storeId: string | undefined,
    takeoutDate: string | null,
    orderStatuses: OrderStatus[] | null
  ): Promise<Order[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.order.root(storeId)
        : endpoint.store.order.root();

    return api
      .get(
        `${url}?${qs.stringify(
          {
            takeout_date: takeoutDate,
            statuses: orderStatuses,
          },
          { arrayFormat: "brackets" }
        )}`
      )
      .then((res) => {
        return res.data.orders.map((row: Order) =>
          plainToClass(Order, row, { excludeExtraneousValues: true })
        );
      });
  }

  async fetchOrder(
    isAgency: boolean,
    storeId: string | undefined,
    id: string
  ): Promise<Order> {
    const url =
      isAgency && storeId
        ? endpoint.agency.order.show(storeId, id)
        : endpoint.store.order.show(id);

    return api.get(url).then((res) => {
      return plainToClass(Order, res.data.order, {
        excludeExtraneousValues: true,
      });
    });
  }

  async fetchOrderSummary(
    isAgency: boolean,
    storeId: string | undefined,
    takeoutDate: string
  ): Promise<OrderSummary> {
    const url =
      isAgency && storeId
        ? endpoint.agency.order.summary(storeId)
        : endpoint.store.order.summary();

    return api
      .get(`${url}?${qs.stringify({ takeout_date: takeoutDate })}`)
      .then((res) => {
        return plainToClass(OrderSummary, res.data, {
          excludeExtraneousValues: true,
        });
      });
  }

  async downloadInvoicePdf(
    isAgency: boolean,
    storeId: string,
    id: string
  ): Promise<Blob> {
    const url =
      isAgency && storeId
        ? endpoint.agency.order.invoicePdf(storeId, id)
        : endpoint.store.order.invoicePdf(id);

    return api
      .get(url, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/pdf",
          Accept: "application/pdf",
        },
      })
      .then((res) => res.data);
  }

  async downloadInvoicePeriodPdf(
    isAgency: boolean,
    storeId: string,
    startDate: Date,
    endDate: Date
  ): Promise<Blob> {
    const url =
      isAgency && storeId
        ? endpoint.agency.order.invoicePeriodPdf(storeId)
        : endpoint.store.order.invoicePeriodPdf();

    return api
      .get(url, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/pdf",
          Accept: "application/pdf",
        },
        params: { start_date: startDate, end_date: endDate },
      })
      .then((res) => res.data);
  }
}

export default new OrderService();
