import React, { useState } from "react";
import { Button, Form, Loader, Message, Dimmer } from "semantic-ui-react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import MESSAGE from "config/message.json";
import { domainRepository } from "domain/repository";
import { DomainRegistrar } from "config/raven";

const DOMAIN_REGISTRAR: Record<
  DomainRegistrar,
  {
    NAME: string;
    URL: string;
  }
> = require("config/domain.json").DOMAIN_REGISTRAR;

const registrarOptionKeys = Object.keys(DOMAIN_REGISTRAR);
const registrarOptions = registrarOptionKeys.map((p) => ({
  text: DOMAIN_REGISTRAR[p as DomainRegistrar].NAME,
  value: p,
  key: p,
}));

const validationSchema = Yup.object({
  registrarType: Yup.string(),
  domain: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  hpDomain: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
});

type ValueType = {
  registrarType: DomainRegistrar;
  domain: string;
  hpDomain: string;
};

const initialValues: ValueType = {
  registrarType: "muumuu",
  domain: "",
  hpDomain: "",
};

type Props = {
  storeId: string | undefined;
};
export const RegisterExternalDomainContainer: React.FC<Props> = ({
  storeId,
}) => {
  const [isDomainContactSuccess, setIsDomainContactSuccess] = useState<
    boolean | null
  >(null);

  const DomainContactMessage: React.FC = () => {
    if (isDomainContactSuccess === null) {
      return null;
    }
    if (isDomainContactSuccess) {
      return <Message positive content="ドメインを登録しました" />;
    } else {
      return <Message negative content="登録に失敗しました" />;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => {
        if (storeId === undefined) {
          return;
        }
        domainRepository
          .registerExternalDomain(storeId, values)
          .then((isSuccess) => {
            setIsDomainContactSuccess(isSuccess);
          })
          .finally(() => {
            formikHelpers.setSubmitting(false);
          });
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors, handleSubmit, setFieldValue, isSubmitting }) => (
        <>
          {isSubmitting ? (
            <Dimmer active>
              <Loader active content="ドメイン登録中" size="large" />
            </Dimmer>
          ) : null}
          <Form onSubmit={handleSubmit}>
            <Form.Select
              label="外部レジストラの種類"
              name="registrarType"
              options={registrarOptions}
              value={values.registrarType}
              onChange={(_, { value }) => setFieldValue("registrarType", value)}
            />
            <Form.Field required>
              <label>ドメイン</label>
              <Field
                as={Form.Input}
                name="domain"
                error={errors.domain}
                placeholder="example.com"
              />
            </Form.Field>
            <Form.Field required>
              <label>ホームページに使うドメイン(公開用ドメイン)</label>
              <Field
                as={Form.Input}
                name="hpDomain"
                error={errors.hpDomain}
                placeholder="www.example.com"
              />
            </Form.Field>
            <div
              css={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                content="登録"
                primary
                type="submit"
                disabled={isSubmitting}
              />
            </div>
            <DomainContactMessage />
          </Form>
        </>
      )}
    </Formik>
  );
};
