import api from "utils/api";
import endpoint from "utils/endpoint";

class GoogleRepository {
  /**
   * Google Analyticsプロジェクトを作成
   * 予め手動でGoogle Analyticsのアカウントが作成されている必要有
   */
  async createGaProject(storeId: string) {
    const url = endpoint.agency.google.analyticsManage(storeId);

    return api.post(url);
  }

  async revokeGoogleConnection(storeId: string) {
    const url = endpoint.agency.google.oauth(storeId);

    return api.delete(url);
  }
}

export default new GoogleRepository();
