import React from "react";
import { Dayjs } from "dayjs";
import { Segment, Statistic } from "semantic-ui-react";
import OrderSummary from "domain/model/OrderSummary";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
  takeoutDate: Dayjs;
  orderSummary: OrderSummary;
};

export const DailySummary: React.FC<Props> = ({
  takeoutDate,
  orderSummary,
}) => {
  return (
    <Segment>
      <h2 css={{ marginBottom: 0 }}>
        {takeoutDate.format("YYYY年MM月DD日")}の受注情報
      </h2>
      <Statistic size="mini">
        <Statistic.Label>注文金額</Statistic.Label>
        <Statistic.Value>
          {formatNumber(orderSummary.totalPrice)}
          <small> 円</small>
        </Statistic.Value>
      </Statistic>
      <Statistic size="mini">
        <Statistic.Label>注文金額（現金）</Statistic.Label>
        <Statistic.Value>
          {formatNumber(orderSummary.totalPriceOfStorePayment)}
          <small> 円</small>
        </Statistic.Value>
      </Statistic>
      <Statistic size="mini">
        <Statistic.Label>注文金額（クレジットカード）</Statistic.Label>
        <Statistic.Value>
          {formatNumber(orderSummary.totalPriceOfCardPayment)}
          <small> 円</small>
        </Statistic.Value>
      </Statistic>
      <Statistic size="mini">
        <Statistic.Label>注文商品数</Statistic.Label>
        <Statistic.Value>
          {formatNumber(orderSummary.orderedProductsCount)}
          <small> 個</small>
        </Statistic.Value>
      </Statistic>
      <Statistic size="mini">
        <Statistic.Label>注文件数</Statistic.Label>
        <Statistic.Value>
          {formatNumber(orderSummary.ordersCount)}
          <small> 件</small>
        </Statistic.Value>
      </Statistic>
    </Segment>
  );
};

const formatNumber = (num: number) =>
  new Intl.NumberFormat("ja-JP").format(num);
