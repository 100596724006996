import api from "utils/api";
import endpoint from "utils/endpoint";
import { DNSRecord } from "domain/model/DNS/DNSRecordSet";

class Repository {
  /**
   * 代理店ユーザーだけ利用可能
   */
  enable(storeId: string) {
    const url = endpoint.agency.dns.use(storeId);
    return api.post(url);
  }

  addDnsRecord(storeId: string, { name, type, value }: DNSRecord) {
    const url = endpoint.agency.dns.recordSet(storeId);
    const body = {
      dns_management: {
        name,
        type,
        value,
      },
    };
    return api.post(url, body);
  }

  deleteDnsRecord(storeId: string, { name, type, value }: DNSRecord) {
    const url = endpoint.agency.dns.recordSet(storeId);
    return api.delete(url, {
      data: {
        dns_management: {
          name,
          type,
          value,
        },
      },
    });
  }
}

export const dnsRepository = new Repository();
