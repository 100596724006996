import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  Segment,
  Message,
  Header,
  List,
  Label,
  Divider,
  Button,
} from "semantic-ui-react";

import { useRestyStoreProfile } from "hooks/useRestyStoreProfile";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
  setIsRegister: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RestyStoreProfile: React.FC<Props> = ({
  isAgency,
  storeId,
  setIsRegister,
}) => {
  const { restyStoreProfile } = useRestyStoreProfile({ isAgency, storeId });

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Segment>
      <Header content="Resty 予約機能情報" dividing />
      <Message content="Resty 予約機能情報登録済です。" success />

      <List divided relaxed>
        <List.Item>
          <Label
            color="teal"
            horizontal
            css={{ width: isMobile ? "40%" : "20%" }}
            content="グループコード"
          />

          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restyStoreProfile?.restyGroupCode}
          </div>
        </List.Item>
        <List.Item>
          <Label
            color="teal"
            horizontal
            css={{ width: isMobile ? "40%" : "20%" }}
            content="ショップID"
          />

          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restyStoreProfile?.restyShopId}
          </div>
        </List.Item>
        <List.Item>
          <Label
            color="teal"
            horizontal
            css={{ width: isMobile ? "40%" : "20%" }}
            content="ユーザーID"
          />

          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {restyStoreProfile?.restyUserId}
          </div>
        </List.Item>
        <Divider fitted css={{ marginBottom: "12px!important" }} />
        <div css={{ textAlign: "center" }}>
          <Button
            type="button"
            content="再登録フォーム"
            color="yellow"
            onClick={() => setIsRegister(false)}
          />
        </div>
      </List>
    </Segment>
  );
};
