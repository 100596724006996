import React from "react";
import { Form, Button } from "semantic-ui-react";
import { Field, FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { BasicAuthPreferences } from "domain/model/BasicAuthPreferences";

const validationSchema = Yup.object({
  authUser: Yup.string(),
  authPass: Yup.string(),
  privatePages: Yup.string(),
});

type Props = {
  basicAuthPreferences: BasicAuthPreferences;
  handleUpdate: (basicAuthPreferences: BasicAuthPreferences) => Promise<void>;
};
export const BasicAuthPreferencesForm: React.FC<Props> = ({
  basicAuthPreferences,
  handleUpdate,
}) => {
  return (
    <Formik
      initialValues={basicAuthPreferences}
      validationSchema={validationSchema}
      onSubmit={(values, formikHelpers) => {
        handleUpdate(values).finally(() => {
          formikHelpers.setSubmitting(false);
        });
      }}
    >
      {({ errors, values, handleChange, submitForm, isSubmitting }) => (
        <Form>
          <div css={{ marginBottom: "20px" }}>
            <Form.Field css={{ marginBottom: "20px" }}>
              <label>ユーザー名</label>
              <Field
                name="authUser"
                as={Form.Input}
                width={16}
                error={errors?.authUser}
                value={values.authUser}
              />
            </Form.Field>

            <Form.Field css={{ marginBottom: "20px" }}>
              <label>パスワード</label>
              <Field
                name="authPass"
                as={Form.Input}
                type="password"
                placeholder="変更しない場合は空欄のままにしてください"
                error={errors?.authPass}
                value={values.authPass}
              />
            </Form.Field>

            <Form.Field css={{ marginBottom: "20px" }}>
              <label>対象ページ</label>
              <p>
                対象ページを指定してください（完全一致）。
                <br />
                複数のページを対象とする場合は、「追加」を押して対象ページを追加してください。
              </p>
              <FieldArray name="privatePages">
                {({ push }) => {
                  console.log({ errors, values });
                  return (
                    <>
                      {values.privatePages?.map((i, index) => (
                        <Field
                          key={index}
                          name={`privatePages.${index}`}
                          as={Form.Input}
                          css={{ marginBottom: "10px" }}
                          placeholder="例: index.html"
                        />
                      ))}
                      <strong color="red">{errors?.privatePages}</strong>
                      <Button onClick={() => push("")}>追加</Button>
                    </>
                  );
                }}
              </FieldArray>
            </Form.Field>
          </div>
          <p>設定をよくご確認の上、「保存」ボタンを押してください。</p>
          <div
            css={{
              textAlign: "center",
            }}
          >
            <Button
              primary
              type="submit"
              content="保存"
              loading={isSubmitting}
              onClick={submitForm}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
