import React from "react";
import { generatePath, useParams, useHistory } from "react-router-dom";

import { StoreContainer, UserContainer } from "containers";

import PageContainer from "components/lib/PageContainer";
import MenuCard from "components/SiteManage/MenuCard";

import routes from "utils/routes";
import { StoreIdParam } from "config/raven";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

const SiteManage = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();
  const { push } = useHistory();
  const { currentStore } = StoreContainer.useContainer();
  const useTakeout = currentStore?.property?.useTakeout || false;

  return (
    <PageContainer
      header="サイト管理"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId).build(
        "サイト管理"
      )}
    >
      <MenuCard
        isAgency={isAgency}
        useTakeout={useTakeout}
        moveDomain={() =>
          push(
            generatePath(
              routes[isAgency ? "agency" : "store"].domain.pathname,
              { storeId }
            )
          )
        }
        moveSitemap={() =>
          push(generatePath(routes.agency.sitemap.pathname, { storeId }))
        }
        moveFavicon={() =>
          push(
            generatePath(
              routes[isAgency ? "agency" : "store"].favicon.pathname,
              { storeId }
            )
          )
        }
        moveOgp={() =>
          push(
            generatePath(routes[isAgency ? "agency" : "store"].ogp.pathname, {
              storeId,
            })
          )
        }
        moveBasicAuth={() =>
          push(
            generatePath(routes.agency.basicAuth.pathname, {
              storeId,
            })
          )
        }
        moveGoogleAnalytics={() =>
          push(
            generatePath(routes.agency.googleAnalytics.pathname, { storeId })
          )
        }
        moveTabelog={() =>
          push(
            generatePath(
              routes[isAgency ? "agency" : "store"].tabelog.pathname,
              { storeId }
            )
          )
        }
        moveYaml={() =>
          push(
            generatePath(
              routes[isAgency ? "agency" : "store"].yamlIndex.pathname,
              {
                storeId,
              }
            )
          )
        }
        moveTakeoutLogin={() =>
          push(
            generatePath(
              routes[isAgency ? "agency" : "store"].takeoutLogin.pathname,
              { storeId }
            )
          )
        }
        moveResty={() =>
          push(
            generatePath(routes[isAgency ? "agency" : "store"].resty.pathname, {
              storeId,
            })
          )
        }
        moveInstagram={() =>
          push(
            generatePath(
              routes[isAgency ? "agency" : "store"].instagram.pathname,
              {
                storeId,
              }
            )
          )
        }
        moveRecruit={() =>
          push(
            generatePath(
              routes[isAgency ? "agency" : "store"].recruit.pathname,
              { storeId }
            )
          )
        }
        moveSitest={() =>
          push(
            generatePath(
              routes[isAgency ? "agency" : "store"].sitest.pathname,
              { storeId }
            )
          )
        }
        moveTakeout={() =>
          push(
            generatePath(
              routes[isAgency ? "agency" : "store"].takeout.pathname,
              { storeId }
            )
          )
        }
        moveTakeoutMessageTemplate={() => {
          push(
            generatePath(
              routes[isAgency ? "agency" : "store"].takeoutMessageTemplate
                .pathname,
              { storeId }
            )
          );
        }}
        moveDNSRecord={() =>
          push(generatePath(routes.agency.dnsRecord.pathname, { storeId }))
        }
      />
    </PageContainer>
  );
};

export default SiteManage;
