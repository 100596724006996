import { Expose } from "class-transformer";
import dayjs from "dayjs";

export default class Category {
  @Expose({ name: "created_at" })
  createdAtStr: string;
  @Expose({ name: "category_name" })
  categoryName: string;
  @Expose({ name: "category_value" })
  categoryValue: string;
  @Expose({ name: "store_category" })
  storeCategory: string;

  @Expose({ name: "created_at" })
  get createdAt() {
    return dayjs(Number(this.createdAtStr) * 1000).format("YYYY/MM/DD HH:mm");
  }
}
