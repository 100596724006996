import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Segment, Loader, Header, Message } from "semantic-ui-react";

import PageContainer from "components/lib/PageContainer";
import { UserContainer, StoreContainer } from "containers";
import { AGENCY_OPTION, StoreIdParam } from "config/raven";
import { instagramRepository } from "domain/repository";
import NotValidOption from "components/SiteManage/Content/lib/NotValidOption";
import OptionStatus from "components/lib/OptionStatus";
import InstagramAuth from "components/SiteManage/Content/Instagram/InstagramAuth/InstagramAuth";
import { InstagramStoreProfile } from "components/SiteManage/Content/Instagram/StoreProfile/InstagramStoreProfile";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { useInstagramStoreProfile } from "hooks/useInstagramStoreProfile";

const AGENCY: AGENCY_OPTION = require("config/agency.json");

const Container: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { currentStore, changeCurrentStore } = StoreContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();
  const { hostname } = window.location;
  const agencyEnabled = AGENCY[hostname].options.instagram;
  const { instagramStoreProfile } = useInstagramStoreProfile({
    isAgency,
    storeId,
  });

  const [isRegister, setIsRegister] = useState<boolean>(false);

  useEffect(() => {
    const confirm =
      instagramStoreProfile?.instagramAccountId ||
      instagramStoreProfile?.instagramHashtag;

    if (confirm !== null || undefined) {
      setIsRegister(true);
    } else {
      setIsRegister(false);
    }
  }, [isAgency, storeId, instagramStoreProfile]);

  const handleClickEnable = () => {
    if (!storeId) {
      return;
    }
    instagramRepository
      .enable(isAgency, storeId)
      .then(() => changeCurrentStore(isAgency, storeId));
  };

  if (!agencyEnabled) {
    return <NotValidOption heading="Instagram 連携情報" />;
  }

  if (!instagramStoreProfile) {
    return <Loader active />;
  }

  if (!currentStore) {
    return <Loader active />;
  }

  return (
    <>
      <Segment padded>
        <Header content={`Instagram 連携機能利用状況`} dividing />
        {!isAgency && !currentStore.property.useInstagram && (
          <Message
            negative
            content="店舗ユーザはオプションを有効にできません"
          />
        )}
        <OptionStatus
          isAgency={isAgency}
          enabled={currentStore.property.useInstagram}
          toEnable={handleClickEnable}
        />
      </Segment>
      <Segment padded>
        <>
          {currentStore.property.useInstagram ? (
            isRegister ? (
              <>
                <InstagramStoreProfile
                  isAgency={isAgency}
                  storeId={storeId}
                  setIsRegister={setIsRegister}
                />
              </>
            ) : (
              <>
                <InstagramAuth
                  isAgency={isAgency}
                  storeId={storeId}
                  setIsRegister={setIsRegister}
                />
              </>
            )
          ) : null}
        </>
      </Segment>
    </>
  );
};

export const Instagram = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="Instagram連携"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("Instagram連携")}
    >
      <Container />
    </PageContainer>
  );
};
