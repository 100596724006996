import { Expose } from "class-transformer";

export const weekdaysWithLabel = {
  monday: "月",
  tuesday: "火",
  wednesday: "水",
  thursday: "木",
  friday: "金",
  saturday: "土",
  sunday: "日",
  holiday: "祝日",
  pre_holiday: "祝前日",
};

export type Weekday = keyof typeof weekdaysWithLabel;

export type TimeType = "entire_day" | "lunch" | "dinner" | "closed";

export default class BusinessDayType {
  @Expose() id: string | null;
  @Expose({ name: "day_type" }) dayType: Weekday;
  @Expose({ name: "time_type" }) timeType: TimeType;
  @Expose({ name: "opening_time" }) openingTime: string | null;
  @Expose({ name: "closing_time" }) closingTime: string | null;
}
