import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { SelectMediaModal } from "components/lib/SelectMediaModal";
import { SliderImageSelector } from "components/lib/Editor/SliderImageSelector";

type Props = {
  source: string;
  onChange: (value: string) => void;
  mediaUrls: string[];
  refetchMediaUrls: () => void;
  body: { id: string; className: string };
  documentBaseUrl: string;
};

// TODO: TinyMCEのモーダルとsemantic-uiのモーダルのz-indexを調整するために、本当はしたくないが、index.cssでスタイルを上書きしている。
export const FullHtmlWysiwygEditor: React.FC<Props> = ({
  source,
  onChange,
  mediaUrls,
  refetchMediaUrls,
  body,
  documentBaseUrl,
}) => {
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [mediaCallback, setMediaCallback] = useState<{
    callback: Function;
  } | null>(null);

  // エディターの key
  // これを変化させることで強制リセットする
  // （もっと良い実装方法があるかもしれない）
  const [editorKey, setEditorKey] = useState(0);

  // スライダー画像関連
  const sliderRef = useRef<HTMLElement>(null!);
  const [sliderImagesTmp, setSliderImagesTmp] = useState<
    { src: string; alt: string }[]
  >([]);
  const [slierImageModalShown, setSlierImageModalShown] = useState(false);
  // モーダルでセットされた画像をHTMLに反映する
  const applySliderImages = (images: { src: string; alt: string }[]) => {
    // エディターの DOM にあるコンテナー要素（読み取り専用）
    const el = sliderRef.current;
    if (!el) throw Error("Invalid sliderRef");
    console.log(sliderRef);

    const containerTemplateId = el.getAttribute(
      "data-cms-slider-container-template"
    );
    const itemTemplateId = el.getAttribute("data-cms-slider-item-template");
    if (!itemTemplateId) {
      throw Error("Empty itemTemplateId");
    }
    const iframeDoc = document.querySelector<HTMLIFrameElement>(
      ".tox-edit-area__iframe"
    )?.contentDocument;
    if (!iframeDoc) return;

    const containerTemplate = containerTemplateId
      ? iframeDoc.querySelector(`#${containerTemplateId}`)?.innerHTML
      : "%%ITEMS%%";
    const itemTemplate = iframeDoc.querySelector(
      `#${itemTemplateId}`
    )?.innerHTML;
    const insertTargetId = el.getAttribute("data-cms-slider-id");

    if (!itemTemplate) throw Error("Failed to load itemTemplate");

    // HTMLに反映
    const itemsHtml = images
      .map((image) =>
        itemTemplate
          // TinyMCE が勝手に挿入する属性を削除
          .replace(/ ?data-mce-src=".*?"/, "")
          // データ適用
          .replace("%%SRC%%", image.src)
          .replace("%%ALT%%", image.alt)
      )
      .join("\n");
    const html = containerTemplate?.replace("%%ITEMS%%", itemsHtml);
    const newSource = source.replace(
      // < は \x3C と表記する
      // cf. https://stackoverflow.com/questions/236073/why-split-the-script-tag-when-writing-it-with-document-write
      new RegExp(
        `(\x3C!-- CMS-DYNAMIC-SLIDER-CONTENT-START\\[${insertTargetId}\\] -->)([\\s\\S]*)(\x3C!-- CMS-DYNAMIC-SLIDER-CONTENT-END\\[${insertTargetId}\\] -->)`
      ),
      `$1\n${html}\n$3`
    );
    console.log({
      images,
      insertTargetId,
      containerTemplate,
      itemTemplate,
      itemsHtml,
      html,
      source,
      newSource,
    });

    // エディターのDOMに反映するため、エディターを再描画する
    // 全体が再描画されるので、もっとスマートな方法があるかもしれない（setContent を呼び出すとか）
    setEditorKey(new Date().getTime());

    // ソースに反映
    onChange(newSource);
    setSlierImageModalShown(false);
    alert(
      "スライダー画像を反映しました。保存後、プレビューで表示をご確認ください。"
    );
  };

  return (
    <>
      <SliderImageSelector
        images={sliderImagesTmp}
        isActive={slierImageModalShown}
        onSave={applySliderImages}
        onClose={() => setSlierImageModalShown(false)}
        onRequestImagePicker={(callback: Function) => {
          setMediaCallback({ callback });
          setOpenMediaModal(true);
        }}
      />
      <SelectMediaModal
        title="画像を選択"
        open={openMediaModal}
        mediaUrls={mediaUrls}
        onUploaded={refetchMediaUrls}
        handleMediaClick={(url) => {
          if (mediaCallback !== null) {
            mediaCallback.callback(url);
            setOpenMediaModal(false);
          }
        }}
        close={() => setOpenMediaModal(false)}
      />
      <Editor
        key={editorKey}
        value={source}
        apiKey={process.env.REACT_APP_TYNY_MCE_API_KEY}
        init={{
          height: 800,
          menubar: false,
          body_id: body.id,
          body_class: body.className,
          content_css: `${window.location.protocol}//${window.location.host}/css/wysiwyg.css`,
          document_base_url: documentBaseUrl,
          plugins: [
            "fullpage",
            "quickbars",
            "link",
            "fullscreen",
            "image",
            "contextmenu",
          ],
          contextmenu:
            "link image cut copy paste inserttable | cell row column deletetable",
          toolbar: "undo redo | fullscreen | image",
          language: "ja",
          branding: false,
          quickbars_insert_toolbar: false,
          quickbars_selection_toolbar:
            "styleselect | fontsizeselect | forecolor backcolor | quicklink | ",
          // TinyMCEがHTMLを加工しないように制限するオプション
          verify_html: false,
          convert_urls: false,
          // a タグの中の div が消えないようにする
          valid_children: "+a[div|p]",
          // p タグが勝手に挿入されないようにする
          forced_root_block: false,
          init_instance_callback: function (editor) {
            // スライダー画像選択モード
            editor.on("click", function (e) {
              const el = e.target as HTMLElement;
              const containerTemplateId = el.getAttribute("data-cms-slider-id");
              if (containerTemplateId) {
                // 現状設定されている画像設定を取得
                const images = Array.from(el.querySelectorAll("img")).map(
                  (i) => ({ src: i.src, alt: i.alt })
                );

                setSliderImagesTmp(images);
                setSlierImageModalShown(true);
                sliderRef.current = el;
              }
            });
          },
          file_picker_callback: function (
            callback: Function,
            value: string,
            meta: Record<string, any>
          ) {
            if (meta.filetype === "image") {
              setMediaCallback({ callback });
              setOpenMediaModal(true);
            }
          },
        }}
        onEditorChange={onChange}
      />
    </>
  );
};
