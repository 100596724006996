import React, { useState } from "react";

import PageContainer from "components/lib/PageContainer";
import StoreNewForm from "components/StoreNew/StoreNewForm";
import OptionForm from "components/StoreNew/OptionForm";
import { Step } from "semantic-ui-react";
import { UserContainer, StoreContainer } from "containers";
import { useHistory, generatePath } from "react-router-dom";
import routes from "utils/routes";

const isCreateStep = (step: 0 | 1) => {
  return step === 0;
};
const isOptionStep = (step: 0 | 1) => {
  return step === 1;
};

const StoreNew = () => {
  const { isAgency } = UserContainer.useContainer();
  const { changeCurrentStore } = StoreContainer.useContainer();

  const [storeId, setStoreId] = useState<number | undefined>();
  const [step, setStep] = useState<0 | 1>(0);

  const history = useHistory();
  const moveDashboard = (storeId: number | undefined) => {
    if (storeId) {
      changeCurrentStore(true, storeId.toString());
      history.push(generatePath(routes.agency.dashboard.pathname, { storeId }));
    }
  };

  return (
    <PageContainer header="新規店舗作成">
      <Step.Group size="small">
        <Step title="店舗作成" icon="home" disabled={!isCreateStep(step)} />
        <Step
          title="オプション選択"
          icon="check square"
          disabled={!isOptionStep(step)}
        />
      </Step.Group>
      {isCreateStep(step) && (
        <StoreNewForm
          storeId={storeId}
          setStoreId={setStoreId}
          setStep={setStep}
        />
      )}
      {isOptionStep(step) && (
        <OptionForm
          storeId={storeId!}
          isAgency={isAgency}
          moveDashboard={moveDashboard}
        />
      )}
    </PageContainer>
  );
};

export default StoreNew;
