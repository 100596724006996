import React from "react";
import Button from "./Button";
import Wrap from "./Wrap";
import Typography from "./Typography";
import FileImg from "./FileImg";
import Modal from "./Modal";

export default ({ mediaUrls, close, show, src, setSrc, replaceImage }) => {
  return (
    <Modal title="画像選択" show={show} close={close}>
      <Typography margin="0 0 10px">
        変更する画像を選択してください。
      </Typography>
      <Wrap maxHeight="50vh" style={{ overflowX: "scroll" }}>
        {mediaUrls
          ? mediaUrls.map((url) => (
              <FileImg
                key={url}
                src={url}
                selected={url === src}
                onClick={() => setSrc(url)}
              />
            ))
          : "画像がありません。メディア管理で画像を追加してください。"}
      </Wrap>
      <Wrap justifyContent="flex-end" margin="10px 0 0">
        <Button onClick={close} style={{ marginRight: 10 }}>
          キャンセル
        </Button>
        <Button
          onClick={() => {
            replaceImage(src);
            close();
          }}
        >
          確定
        </Button>
      </Wrap>
    </Modal>
  );
};
