import React from "react";
import styled from "@emotion/styled";

import {
  Segment,
  Header,
  SemanticICONS,
  SemanticCOLORS,
} from "semantic-ui-react";

import COLOR from "config/color.json";

type Props = {
  color?: SemanticCOLORS;
  colorHex?: string;
  icon?: SemanticICONS;
  header: string;
  content?: string;
  onClick?: Function;
};

const MenuItem: React.FC<Props> = ({
  color,
  colorHex,
  icon,
  header,
  content,
  onClick,
}) => {
  const HoverSegment = styled(Segment)`
    border-top-color: ${colorHex} !important;
    cursor: pointer;
    &:hover {
      background-color: ${COLOR.SUB3};
    }
  `;
  return (
    <HoverSegment color={color} onClick={onClick}>
      <Header icon={icon} content={header} subheader={content} />
    </HoverSegment>
  );
};

export default MenuItem;
