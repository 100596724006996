import routes from "utils/routes";
import { AGENCY_OPTION } from "config/raven";
import { SemanticICONS } from "semantic-ui-react";
import { generatePath } from "react-router-dom";
import { JOB_FEED_LABEL, JOB_OFFER_LABEL } from "config/ItemLabel";
import dayjs from "dayjs";

export type SidebarConfig = {
  icon: SemanticICONS;
  title: string;
  path?: string;
  disabled?: boolean;
  subMenu?: {
    title: string;
    disabled?: boolean;
    path: string;
  }[];
};

type Options = {
  useTakeout: boolean;
  useTakeoutDashboard: boolean;
};

const AGENCY: AGENCY_OPTION = require("config/agency.json");
const jobFeedEnabled = AGENCY[window.location.hostname].options.jobFeed;
const yamlEditorEnabled = AGENCY[window.location.hostname].options.yamlEditor;

export const agencyMenus = (
  storeId: string,
  options: Options
): SidebarConfig[] => {
  return [
    {
      icon: "tachometer alternate",
      title: "ダッシュボード",
      path: generatePath(routes.agency.dashboard.pathname, { storeId }),
    },
    {
      icon: "zip",
      title: "バージョン管理",
      subMenu: [
        {
          title: "バージョン一覧",
          path: generatePath(routes.agency.versionIndex.pathname, { storeId }),
        },
        {
          title: "バージョン追加",
          path: generatePath(routes.agency.versionNew.pathname, { storeId }),
        },
      ],
    },
    {
      icon: "home",
      title: "店舗情報",
      path: generatePath(routes.agency.storeProfileShow.pathname, { storeId }),
    },
    {
      icon: "picture",
      title: "メディア管理",
      path: generatePath(routes.agency.mediaIndex.pathname, { storeId }),
    },
    {
      icon: "globe",
      title: "サイト管理",
      path: generatePath(routes.agency.siteManage.pathname, { storeId }),
    },
    {
      icon: "file",
      title: "YAML管理",
      path: generatePath(routes.agency.yamlIndex.pathname, { storeId }),
      disabled: !yamlEditorEnabled,
    },
    {
      icon: "bell",
      title: "お知らせ管理",
      subMenu: [
        {
          title: "お知らせ一覧",
          path: generatePath(routes.agency.newsIndex.pathname, { storeId }),
        },
        {
          title: "テンプレート設定",
          path: generatePath(routes.agency.newsTemplate.pathname, { storeId }),
        },
        {
          title: "カテゴリ管理",
          path: generatePath(routes.agency.newsCategory.pathname, { storeId }),
        },
      ],
    },
    {
      icon: "book",
      title: "記事管理",
      subMenu: [
        {
          title: "記事一覧",
          path: generatePath(routes.agency.articleIndex.pathname, { storeId }),
        },
        {
          title: "テンプレート設定",
          path: generatePath(routes.agency.articleTemplate.pathname, {
            storeId,
          }),
        },
        {
          title: "カテゴリ管理",
          path: generatePath(routes.agency.articleCategory.pathname, {
            storeId,
          }),
        },
      ],
    },
    {
      icon: "male",
      title: "求人管理",
      subMenu: [
        {
          title: "応募者一覧",
          path: generatePath(routes.agency.applicantsIndex.pathname, {
            storeId,
          }),
        },
        {
          title: `${JOB_OFFER_LABEL}一覧`,
          path: generatePath(routes.agency.jobOfferFormIndex.pathname, {
            storeId,
          }),
        },
        {
          title: `${JOB_FEED_LABEL}一覧`,
          disabled: !jobFeedEnabled,
          path: generatePath(routes.agency.jobFeedIndex.pathname, {
            storeId,
          }),
        },
      ],
    },
    {
      icon: "mail",
      title: "問い合わせ管理",
      subMenu: [
        {
          title: "問い合わせ一覧",
          path: generatePath(routes.agency.contactIndex.pathname, { storeId }),
        },
        {
          title: "フォーム一覧",
          path: generatePath(routes.agency.contactFormIndex.pathname, {
            storeId,
          }),
        },
        {
          title: "フォーム作成",
          path: generatePath(routes.agency.contactFormNew.pathname, {
            storeId,
          }),
        },
      ],
    },
    {
      icon: "shop",
      title: "テイクアウト",
      disabled: !options.useTakeout,
      subMenu: [
        {
          title: "受注一覧",
          path:
            generatePath(routes.agency.orderIndex.pathname, { storeId }) +
            "?date=now",
        },
        {
          title: "商品一覧",
          path: generatePath(routes.agency.productIndex.pathname, { storeId }),
        },
      ],
    },
    {
      icon: "database",
      title: "売上一元管理",
      disabled: !options.useTakeoutDashboard,
      subMenu: [
        {
          title: "ログイン",
          path: generatePath(routes.agency.takeoutLogin.pathname, { storeId }),
        },
        {
          title: "データ同期",
          path: generatePath(routes.agency.takeoutSales.pathname, { storeId }),
        },
        {
          title: "注文履歴",
          path: generatePath(routes.agency.orderHistory.pathname, { storeId }),
        },
      ],
    },
  ];
};

export const storeMenus = (options: Options): SidebarConfig[] => {
  return [
    {
      icon: "tachometer alternate",
      title: "ダッシュボード",
      path: routes.store.dashboard.pathname,
    },
    {
      icon: "zip",
      title: "バージョン管理",
      subMenu: [
        {
          title: "バージョン一覧",
          path: routes.store.versionIndex.pathname,
        },
        {
          title: "バージョン追加",
          path: routes.store.versionNew.pathname,
        },
      ],
    },
    {
      icon: "home",
      title: "店舗情報",
      path: routes.store.storeProfileEdit.pathname,
    },
    {
      icon: "bell",
      title: "お知らせ管理",
      subMenu: [
        { title: "お知らせ一覧", path: routes.store.newsIndex.pathname },
        {
          title: "テンプレート設定",
          path: routes.store.newsTemplate.pathname,
        },
        { title: "カテゴリ管理", path: routes.store.newsCategory.pathname },
      ],
    },
    {
      icon: "book",
      title: "記事管理",
      subMenu: [
        { title: "記事一覧", path: routes.store.articleIndex.pathname },
        {
          title: "テンプレート設定",
          path: routes.store.articleTemplate.pathname,
        },
        { title: "カテゴリ管理", path: routes.store.articleCategory.pathname },
      ],
    },
    {
      icon: "picture",
      title: "メディア管理",
      subMenu: [
        {
          title: "メディア一覧",
          path: routes.store.mediaIndex.pathname,
        },
        {
          title: "メディアアップロード",
          path: routes.store.mediaNew.pathname,
        },
      ],
    },
    {
      icon: "globe",
      title: "サイト管理",
      path: routes.store.siteManage.pathname,
    },
    {
      icon: "globe",
      title: "YAML管理",
      path: generatePath(routes.store.yamlIndex.pathname),
    },
    {
      icon: "mail",
      title: "問い合わせ管理",
      subMenu: [
        {
          title: "問い合わせ一覧",
          path: routes.store.contactIndex.pathname,
        },
        {
          title: "フォーム一覧",
          path: routes.store.contactFormIndex.pathname,
        },
        {
          title: "フォーム作成",
          path: routes.store.contactFormNew.pathname,
        },
      ],
    },
    {
      icon: "male",
      title: "求人管理",
      subMenu: [
        {
          title: "応募者一覧",
          path: routes.store.applicantsIndex.pathname,
        },
        {
          title: "フォーム一覧",
          path: routes.store.jobOfferFormIndex.pathname,
        },
        {
          title: "フォーム作成",
          path: routes.store.jobOfferFormNew.pathname,
        },
        {
          title: `${JOB_FEED_LABEL}一覧`,
          disabled: !jobFeedEnabled,
          path: routes.store.jobFeedIndex.pathname,
        },
      ],
    },
    {
      icon: "shop",
      title: "テイクアウト",
      disabled: !options.useTakeout,
      subMenu: [
        {
          title: "受注一覧",
          path: routes.store.orderIndex.pathname + "?date=now",
        },
        {
          title: "商品一覧",
          path: routes.store.productIndex.pathname,
        },
      ],
    },
    {
      icon: "database",
      title: "売上一元管理",
      disabled: !options.useTakeoutDashboard,
      subMenu: [
        {
          title: "ログイン",
          path: routes.store.takeoutLogin.pathname,
        },
        {
          title: "データ同期",
          path: routes.store.takeoutSales.pathname,
        },
        {
          title: "注文履歴",
          path: routes.store.orderHistory.pathname,
        },
      ],
    },
  ];
};
