import { Expose } from "class-transformer";
import { Form } from "domain/model/ContactFormTypes";

export default class Contact {
  @Expose() dom: any;
  @Expose() id: string;
  @Expose() idf: string;

  @Expose({ name: "name" })
  formName: string;

  @Expose({ name: "schema" })
  forms: Array<Form>;

  @Expose({ name: "contact_type" })
  contactType: string;
  @Expose({ name: "pub_email" })
  pubEmail: string;
  @Expose({ name: "redirect_to" })
  redirectUrl: string;
}
