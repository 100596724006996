import { Expose } from "class-transformer";

export default class Staff {
  @Expose() email: string;
  @Expose() id: string;
  @Expose() name: string;
  @Expose() role: string;

  @Expose({ name: "afflication_id" })
  afflicationId: string;
  @Expose({ name: "afflication_type" })
  afflicationType: string;
  @Expose({ name: "created_at" })
  createdAt: string;
  @Expose({ name: "password_digest" })
  passwordDigest: string;
  @Expose({ name: "reset_password_token_expires_at" })
  resetPasswordTokenExpiresAt: string;
  @Expose({ name: "updated_at" })
  updatedAt: string;
  @Expose({ name: "reset_password_token" })
  resetPasswordToken: string;
}
