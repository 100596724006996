import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageContainer from "components/lib/PageContainer";
import { Button, Loader } from "semantic-ui-react";
import routes from "utils/routes";
import Agency from "domain/model/Agency";
import { agencyService } from "domain/service";
import UserContainer from "containers/User";
import AgencyList from "components/Agency/AgencyIndex/AgencyList";
import Pagination from "components/lib/Pagination";

export const AgencyIndex = () => {
  const history = useHistory();
  const userContainer = UserContainer.useContainer();
  const userRole = userContainer.currentUser?.role;

  const [agencies, setAgencies] = useState<Agency[] | []>([]);

  const [active, setActive] = useState<number>(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    agencyService.fetchAgencies(active).then(({ agencies, total }) => {
      setAgencies(agencies);
      setTotal(total);
    });
  }, [active]);
  return (
    <PageContainer
      header="代理店一覧"
      action={
        <>
          {userRole === "admin" ? (
            <Button
              color="blue"
              content="新規代理店作成"
              onClick={() =>
                history.push(routes.adminAgency.agencyNew.pathname)
              }
            />
          ) : (
            <></>
          )}
        </>
      }
    >
      {agencies.length > 0 ? (
        <AgencyList agencies={agencies} />
      ) : (
        <Loader active />
      )}
      <div css={{ textAlign: "center" }}>
        <Pagination active={active} total={total} handleChange={setActive} />
      </div>
    </PageContainer>
  );
};
