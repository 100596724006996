import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import MessageTemplate from "domain/model/MessageTemplate";

class MessageTemplateService {
  async fetchAllMessageTemplates(
    isAgency: boolean,
    storeId: string | undefined
  ): Promise<MessageTemplate[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.messageTemplate.root(storeId)
        : endpoint.store.messageTemplate.root();

    return api.get(url).then((res) => {
      return res.data.message_templates.map((row: MessageTemplate) =>
        plainToClass(MessageTemplate, row, { excludeExtraneousValues: true })
      );
    });
  }

  async fetchAllMessageTemplate(
    isAgency: boolean,
    storeId: string | undefined,
    messageType: string
  ): Promise<MessageTemplate> {
    const url =
      isAgency && storeId
        ? endpoint.agency.messageTemplate.show(storeId, messageType)
        : endpoint.store.messageTemplate.show(messageType);

    return api.get(url).then((res) => {
      console.log(res);
      return plainToClass(MessageTemplate, res.data.message_template, {
        excludeExtraneousValues: true,
      });
    });
  }
}

export default new MessageTemplateService();
