import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";

import JobFeed from "domain/model/JobFeed";

class JobFeedService {
  // 求人系ということでJobOfferと同じフラグでON/OFF
  public async isEnable(isAgency: boolean, storeId: string | undefined) {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobOffer.use(storeId)
        : endpoint.store.jobOffer.use();
    return api.get(url).then((res) => res.data.use_recruit);
  }

  async fetchJobFeeds(
    isAgency: boolean,
    storeId: string | undefined
  ): Promise<JobFeed[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobFeed.feeds(storeId)
        : endpoint.store.jobFeed.feeds();

    return api.get(url).then((res) => {
      return res.data.job_feeds.map((row: JobFeed) =>
        plainToClass(JobFeed, row, { excludeExtraneousValues: true })
      );
    });
  }

  fetchJobFeed(
    isAgency: boolean,
    storeId: string | undefined,
    feedId: string
  ): Promise<JobFeed> {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobFeed.feed(storeId, feedId)
        : endpoint.store.jobFeed.feed(feedId);

    return api.get(url).then((res) => {
      return plainToClass(JobFeed, res.data.job_feed, {
        excludeExtraneousValues: true,
      });
    });
  }
}

export default new JobFeedService();
