import React from "react";
import { OrderItem } from "components/Order/Index/OrderItem";
import { Message, Table } from "semantic-ui-react";
import Order from "domain/model/Order";
import { Link } from "react-router-dom";
import Typography from "components/Version/Edit/v1/Typography";

type Props = {
  orders: Order[];
  isAgency: boolean;
  storeId: string;
  hasQueriedDate: boolean;
};

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          css={{
            width: "9em",
          }}
        >
          受注番号
        </Table.HeaderCell>
        <Table.HeaderCell>受注日時</Table.HeaderCell>
        <Table.HeaderCell>受け渡し日時</Table.HeaderCell>
        <Table.HeaderCell>注文商品</Table.HeaderCell>
        <Table.HeaderCell>決済方法</Table.HeaderCell>
        <Table.HeaderCell>ステータス</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export const OrderList: React.FC<Props> = ({
  isAgency,
  orders,
  storeId,
  hasQueriedDate,
}) => {
  if (orders.length === 0) {
    return (
      <Message
        content={
          <>
            <Typography>受注情報がありません</Typography>
            {hasQueriedDate && (
              <Link to="./orders" css={{ fontSize: 11, fontWeight: "normal" }}>
                [受取日フィルターをリセットする]
              </Link>
            )}
          </>
        }
      />
    );
  }

  return (
    <>
      <Table fixed>
        <TableHeader />
        <Table.Body>
          {orders.map((order, i) => (
            <OrderItem
              key={order.id}
              order={order}
              storeId={storeId}
              isAgency={isAgency}
            />
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
