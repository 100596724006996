import api from "utils/api";
import endpoint from "utils/endpoint";
import { ReplyType } from "domain/model/JobOffer/FormDetails";

const forms = [
  { label: "氏名", type: "text", placeholder: "山田太郎", options: [] },
  { label: "年齢", type: "number", placeholder: "22", options: [] },
  {
    label: "現在のご職業",
    type: "text",
    placeholder: "学生、営業、経理、エンジニアなど",
    options: [],
  },
  {
    label: "メールアドレス",
    type: "email",
    placeholder: "sample@mail.com",
    options: [],
  },
  {
    label: "携帯電話番号",
    type: "tel",
    placeholder: "090-1234-5678",
    options: [],
  },
  {
    label: "住所",
    type: "text",
    placeholder: "〇〇県〇〇市××-×",
    options: [],
  },
  {
    label: "採用形態",
    type: "selectbox",
    options: ["正社員（新卒）", "正社員（中途）", "アルバイト・パート"],
  },
];

class JobOfferRepository {
  async updateStatus(
    isAgency: boolean,
    storeId: string | undefined,
    contact_idef: string,
    contact_status: string
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobOffer.root(storeId)
        : endpoint.store.jobOffer.root();
    return api.put(url, { applicant: { contact_idef, contact_status } });
  }
  async updateRemarks(
    isAgency: boolean,
    storeId: string | undefined,
    contactDef: string,
    remarks: string
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobOffer.root(storeId)
        : endpoint.store.jobOffer.root();
    return api.put(url, {
      applicant: { contact_idef: contactDef, contact_remarks: remarks },
    });
  }
  createForm(
    isAgency: boolean,
    storeId: string | undefined,
    {
      name,
      email,
      redirectUrl,
      replyType,
      replyMessage,
      replySubject,
    }: {
      name: string;
      email: string;
      redirectUrl: string;
      replyType: ReplyType;
      replySubject: string;
      replyMessage: string;
    }
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobOffer.forms(storeId)
        : endpoint.store.jobOffer.forms();

    const body = {
      contact_form: {
        contact_type: "recruit",
        form: forms,
        name: name || "名称未設定",
        pub_email: email,
        redirect_to: redirectUrl,
        reply_message: replyMessage,
        reply_subject: replySubject,
        reply_type: replyType,
      },
    };
    return api.post(url, body).then((res) => res.data.form.dom);
  }

  updateForm(
    isAgency: boolean,
    storeId: string | undefined,
    {
      name,
      email,
      redirectUrl,
      replyType,
      replyMessage,
      replySubject,
    }: {
      name: string;
      email: string;
      redirectUrl: string;
      replyType: ReplyType;
      replySubject: string;
      replyMessage: string;
    },
    formId: string
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobOffer.form(storeId, formId)
        : endpoint.store.jobOffer.form(formId);
    const body = {
      contact_form: {
        form: forms,
        name: name || "名称未設定",
        pub_email: email,
        redirect_to: redirectUrl,
        reply_message: replyMessage,
        reply_subject: replySubject,
        reply_type: replyType,
      },
    };
    return api.put(url, body).then((res) => res.data.form.dom);
  }

  deleteForm(isAgency: boolean, storeId: string | undefined, formId: string) {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobOffer.form(storeId, formId)
        : endpoint.store.jobOffer.form(formId);
    return api.delete(url).then((res) => res.data);
  }
}

export default new JobOfferRepository();
