import React, { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { jobFeedService } from "domain/service";
import JobFeed from "domain/model/JobFeed";
import { SubmitTypes } from "components/JobOffer/lib/JobOfferForm";
import { generatePath, useHistory } from "react-router-dom";
import routes from "utils/routes";
import { jobFeedRepository } from "domain/repository";
import { JobFeedForm } from "components/JobFeed/JobFeedForm";

type Props = {
  isAgency: boolean;
  storeId: string;
  id: string;
};

export const EditForm: React.FC<Props> = ({ isAgency, storeId, id }) => {
  const [jobFeed, setJobFeed] = useState<JobFeed | null>(null);
  const history = useHistory();

  useEffect(() => {
    jobFeedService
      .fetchJobFeed(isAgency, storeId, id)
      .then((res) => setJobFeed(res));
  }, [id, isAgency, storeId]);

  if (jobFeed === null) {
    return <Loader active />;
  }

  const {
    status,
    title,
    company,
    jobType,
    description,
    postDate,
    salaryType,
    salaryMin,
    salaryMax,
    zipCode,
    state,
    city,
    address,
    station,
    experience,
    workingTime,
    holiday,
    benefits,
    insurance,
    preventSmoke,
    contractPeriod,
    url,
    imageUrl,
    companyUrl,
    companyLogoUrl,
    pageHeader,
    pageFooter,
  } = jobFeed;

  return (
    <JobFeedForm
      initialValues={{
        status,
        title,
        company,
        jobType,
        description,
        postDate,
        salaryType,
        salaryMin,
        salaryMax,
        zipCode,
        state,
        city,
        address,
        station,
        experience,
        workingTime,
        holiday,
        benefits,
        insurance,
        preventSmoke,
        contractPeriod,
        url,
        imageUrl,
        companyUrl,
        companyLogoUrl,
        pageHeader,
        pageFooter,
      }}
      isAgency={isAgency}
      storeId={storeId}
      handleSubmit={({ values, setStatus, resetForm }) => {
        jobFeedRepository
          .update(isAgency, storeId, id, values)
          .then(() => {
            resetForm();
            const jobFeedIndex = isAgency
              ? generatePath(routes.agency.jobFeedIndex.pathname, {
                  storeId,
                })
              : routes.store.jobFeedIndex;
            history.push(jobFeedIndex);
          })
          .catch((e) => {
            if (e.response.status === 422) {
              setStatus({ success: "", error: e.response.data.message });
            } else if (e.response.data.error["ref_url"] !== undefined) {
              setStatus({
                success: "",
                error: "ドメイン名を取得するか、遷移先URLを記述してください",
              });
            } else {
              setStatus({ success: "", error: "更新に失敗しました" });
            }
          });
      }}
      submitType={SubmitTypes.UPDATE}
    />
  );
};
