import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";

import Media from "domain/model/Media";

class MediaService {
  fetchMedias(
    isAgency: boolean,
    storeId: string | undefined,
    imageOnly?: boolean,
    page?: number
  ): Promise<{ medias: Media[]; total: number }> {
    const url =
      isAgency && storeId
        ? endpoint.agency.media.root(storeId)
        : endpoint.store.media.root();
    const params = { image_only: imageOnly, page };
    return api
      .get(url, {
        params: params,
      })
      .then((res) => {
        const medias = res.data.images.map((ele: Media) =>
          plainToClass(Media, ele, { excludeExtraneousValues: true })
        );

        return { medias, total: res.data.total };
      });
  }
}

export default new MediaService();
