import React from "react";
import { UserContainer } from "containers";

import PageContainer from "components/lib/PageContainer";

import MainContentMenu from "components/DashBoard/MainContentMenu";
import ShortcutMenu from "components/DashBoard/ShortcutMenu";
import StoreMenu from "components/DashBoard/StoreMenu";
import { useParams } from "react-router-dom";
import { StoreIdParam } from "config/raven";

const DashBoard = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer header="ダッシュボード">
      <StoreMenu isAgency={isAgency} storeId={storeId} />
      <MainContentMenu isAgency={isAgency} />
      <ShortcutMenu />
    </PageContainer>
  );
};

export default DashBoard;
