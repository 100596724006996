import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ProductCategory, TaxRateType } from "domain/model/Product";
import { useFormik } from "formik";
import {
  Button,
  Checkbox,
  Form,
  Image,
  Segment,
  Select,
} from "semantic-ui-react";
import * as Yup from "yup";
import { MediaModal } from "components/Product/MediaModal";
import { SubmitTypes } from "components/JobOffer/lib/JobOfferForm";
import { productService } from "domain/service";
import { OptionForm, OptionSelect } from "components/Product/Edit/OptionForm";

type SubmitType = (typeof SubmitTypes)[keyof typeof SubmitTypes];

type FormValues = {
  code: string;
  name: string;
  price: number;
  taxRateType: TaxRateType;
  display: boolean;
  displayOrder: number;
  description: string;
  cautions: string;
  image: string;
  categoryIds: number[];
  options: OptionSelect[];
};

type Props = {
  initialValues: FormValues;
  isAgency: boolean;
  storeId: string | undefined;
  submitType: SubmitType;
  handleSubmit: ({
    values,
    setStatus,
    resetForm,
  }: {
    values: FormValues;
    setStatus: (status: { success: string; error: string }) => void;
    resetForm: () => void;
  }) => void;
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required(),
  name: Yup.string().required(),
  display: Yup.boolean(),
  displayOrder: Yup.number().required().min(0),
  price: Yup.number().required(),
  image: Yup.string().nullable(),
  description: Yup.string().nullable(),
  cautions: Yup.string().nullable(),
  categoryIds: Yup.array(Yup.string()),
});

const taxRateTypeOptions = [
  { key: "normal", value: "normal", text: "通常税率（軽減税率対象外）" },
  { key: "reduced", value: "reduced", text: "軽減税率対象" },
];

export const ProductForm: React.FC<Props> = ({
  initialValues,
  storeId,
  isAgency,
  submitType,
  handleSubmit,
}) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setStatus, resetForm }) => {
      handleSubmit({ values, setStatus, resetForm });
    },
    initialStatus: { success: "", error: "" },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [productCategories, setProductCategories] = useState<ProductCategory[]>(
    []
  );

  useEffect(() => {
    productService
      .fetchAllProductCategories(isAgency, storeId)
      .then(setProductCategories);
  }, [isAgency, storeId]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      {formik.status.error && (
        <Segment
          inverted
          color="red"
          secondary
          style={{ marginBottom: "2rem" }}
        >
          {formik.status.error}
        </Segment>
      )}

      <Form.Field required>
        <label>商品コード</label>
        <Form.Input
          name="code"
          error={formik.errors.code}
          value={formik.values.code}
          onChange={formik.handleChange}
          placeholder="p1234"
        />
        <FormHelper>
          他の商品と重複しないコードを入力してください。
          <br />
          CSV
          インポート/エクスポート機能では、この商品コードが同じ場合に同じ商品だと判定されます。
        </FormHelper>
      </Form.Field>
      <Form.Field required>
        <label>商品名</label>
        <Form.Input
          name="name"
          error={formik.errors.name}
          value={formik.values.name}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>公開</label>
        <Checkbox
          id="display"
          name="display"
          onChange={formik.handleChange}
          checked={formik.values.display}
        />
      </Form.Field>
      <Form.Field required>
        <label>表示順</label>
        <Form.Input
          type="number"
          name="displayOrder"
          error={formik.errors.displayOrder}
          value={formik.values.displayOrder}
          onChange={formik.handleChange}
          min={0}
        />
        <FormHelper>数字が小さいほど商品一覧の上に表示されます。</FormHelper>
      </Form.Field>
      <Form.Field required>
        <label>販売価格（税込）</label>
        <Form.Input
          type="number"
          name="price"
          error={formik.errors.price}
          value={formik.values.price}
          onChange={formik.handleChange}
          min={0}
        />
      </Form.Field>
      <Form.Field required>
        <label>消費税率区分</label>
        <Form.Select
          required
          name="taxRateType"
          options={taxRateTypeOptions}
          error={formik.errors.taxRateType}
          value={formik.values.taxRateType}
          onChange={(_, { value }) =>
            formik.setFieldValue("taxRateType", value)
          }
        />
      </Form.Field>
      <Form.Field>
        <label>商品画像</label>
        <div
          css={{
            marginTop: 8,
            marginBottom: 8,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "streach",
          }}
        >
          <div css={{ width: "100%", marginRight: 8 }}>
            <Form.Input
              name="image"
              placeholder="https://example.com/sample.png"
              error={formik.errors.image}
              value={formik.values.image}
              onChange={formik.handleChange}
            />
          </div>
          <Button
            size="small"
            color="green"
            type="button"
            css={{
              display: "inline-block",
              whiteSpace: "nowrap",
              marginLeft: 8,
            }}
            onClick={() => setIsOpenModal(true)}
          >
            メディアから挿入
          </Button>
        </div>
        {formik.values.image && (
          <Image src={formik.values.image} size="small" />
        )}
      </Form.Field>
      <Form.Field>
        <label>カテゴリ</label>
        {productCategories.map((category) => {
          const selected = formik.values.categoryIds?.includes(category.id);

          return (
            <Button
              key={category.id}
              basic={!selected}
              color={selected ? "green" : "grey"}
              type="button"
              size="small"
              style={{
                display: "inline-block",
                marginBottom: 6,
                marginRight: 6,
              }}
              onClick={() => {
                if (selected) {
                  formik.setFieldValue(
                    "categoryIds",
                    (formik.values.categoryIds || []).filter(
                      (id) => id !== category.id
                    )
                  );
                } else {
                  formik.setFieldValue("categoryIds", [
                    ...(formik.values.categoryIds || []),
                    category.id,
                  ]);
                }
              }}
            >
              {category.name}
            </Button>
          );
        })}
        {productCategories.length === 0 && (
          <ErrorMessage>商品カテゴリが登録されていません。</ErrorMessage>
        )}
      </Form.Field>
      <Form.Field>
        <label>商品説明</label>
        <Form.TextArea
          name="description"
          type="text"
          error={formik.errors.description}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>
          注意事項
          <br />
          （原材料・アレルギーなど）
        </label>
        <Form.TextArea
          name="cautions"
          error={formik.errors.cautions}
          value={formik.values.cautions}
          onChange={formik.handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label>オプション（ベータ版）</label>
        <OptionForm
          value={formik.values.options}
          onChange={(value) =>
            formik.setValues({ ...formik.values, options: value }, false)
          }
        />
        <p>{formik.errors.options}</p>
      </Form.Field>

      <div style={{ textAlign: "center" }}>
        <Button color="blue" content={submitType} type="submit" />
      </div>

      {isOpenModal && (
        <MediaModal
          isAgency={isAgency}
          storeId={storeId}
          selectImage={(image) => {
            formik.setValues({ ...formik.values, image }, false);
          }}
          onClose={() => setIsOpenModal(!isOpenModal)}
        />
      )}
    </Form>
  );
};

const FormHelper = styled.div`
  color: #888;
  font-size: 0.9rem;
`;

const ErrorMessage = styled.div`
  color: #f55555;
  font-weight: bolder;
`;
