import * as Yup from "yup";
import MESSAGE from "config/message.json";
import { isValidDateByNow } from "utils/validate";

const validationSchema = Yup.object({
  postContent: Yup.object({
    title: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
    postDate: Yup.date().test(
      "is-valid-date",
      MESSAGE.ERROR.INVALID_DATE,
      (value) => {
        if (!value) {
          return false;
        }
        return isValidDateByNow(value);
      }
    ),
    subCategoryValue: Yup.string(),
    slug: Yup.string(),
    content: Yup.string(),
    thumbnailUrl: Yup.string().url().nullable(),
  }),
  seoPreferences: Yup.object({
    doc: Yup.object({
      title: Yup.string(),
      description: Yup.string(),
      keywords: Yup.string(),
    }),
    og: Yup.object({
      title: Yup.string(),
      description: Yup.string(),
      image: Yup.string().url().nullable(),
      siteName: Yup.string(),
    }),
    twitter: Yup.object({
      title: Yup.string(),
      description: Yup.string(),
      image: Yup.string().url().nullable(),
      card: Yup.string(),
    }),
  }),
});

/**
 * 投稿フォームのスキーマを返す
 */
export const usePostSchema = () => {
  return {
    validationSchema,
  };
};
