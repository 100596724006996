import { UserType } from "config/raven";

const setToken = (type: UserType, token: string) => {
  localStorage.removeItem(`${type === "agency" ? "store" : "agency"}_token`);
  localStorage.setItem(`${type}_token`, token);
};

const getToken = (type: UserType) => localStorage.getItem(`${type}_token`);

const removeToken = () => {
  localStorage.removeItem("agency_token");
  localStorage.removeItem("store_token");
};

export { setToken, getToken, removeToken };
