import React from "react";
import { useParams } from "react-router-dom";

import PageContainer from "components/lib/PageContainer";
import UserContainer from "containers/User";
import { StoreIdParam } from "config/raven";
import { EditContactForm } from "components/Contact/FormEdit/EditContactForm";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const ContactFormEdit: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;

  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="問い合わせフォーム詳細"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .contactsFormPage()
        .build("問い合わせフォーム詳細")}
    >
      <EditContactForm isAgency={isAgency} storeId={storeId} />
    </PageContainer>
  );
};
