import React from "react";
import { useParams } from "react-router-dom";

import { Segment, Loader, Header, Message } from "semantic-ui-react";

import PageContainer from "components/lib/PageContainer";
import NotValidOption from "components/SiteManage/Content/lib/NotValidOption";
import OptionStatus from "components/lib/OptionStatus";

import { UserContainer, StoreContainer } from "containers";

import { AGENCY_OPTION, StoreIdParam } from "config/raven";
import { TakeoutLoginForm } from "components/SalesManagement/TakeoutLogin/TakeoutLoginForm";
import { externalTakeoutRepository } from "domain/repository";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

const AGENCY: AGENCY_OPTION = require("config/agency.json");

const Container: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { currentStore, changeCurrentStore } = StoreContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();
  const { hostname } = window.location;
  const agencyEnabled = AGENCY[hostname].options.externalTakeout;

  const handleClickEnable = () => {
    if (!storeId) {
      return;
    }
    externalTakeoutRepository
      .enable(isAgency, storeId)
      .then(() => changeCurrentStore(isAgency, storeId));
  };

  if (!agencyEnabled) {
    return <NotValidOption heading="売上一元管理" />;
  }

  if (!currentStore) {
    return <Loader active />;
  }

  return (
    <>
      <Segment padded>
        <Header content={`売上一元管理オプション利用状況`} dividing />
        {!isAgency && !currentStore.property.useTakeoutDashboard && (
          <Message
            negative
            content="店舗ユーザーはオプションを有効にできません"
          />
        )}
        <OptionStatus
          isAgency={isAgency}
          enabled={currentStore.property.useTakeoutDashboard}
          toEnable={handleClickEnable}
        />
      </Segment>
      <Segment padded>
        {currentStore.property.useTakeoutDashboard ? (
          <TakeoutLoginForm isAgency={isAgency} storeId={storeId} />
        ) : null}
      </Segment>
    </>
  );
};

export const TakeoutLogin = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  return (
    <>
      <PageContainer
        header="売上一元管理"
        breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
          .siteManagePage()
          .build("売上一元管理")}
      >
        <Container />
      </PageContainer>
    </>
  );
};
