import React, { useState } from "react";

import { Formik } from "formik";
import { Button, Form, Header, Icon, Label, Message } from "semantic-ui-react";

import * as Yup from "yup";
import MESSAGE from "config/message.json";

import { subscRepository } from "domain/repository";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
  currentUrl: string | undefined;
  lastSyncedAt: string | undefined;
};

const UpdateSubscForm: React.FC<Props> = ({
  isAgency,
  storeId,
  currentUrl,
  lastSyncedAt,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <Formik
      initialValues={{
        url: currentUrl || "",
      }}
      onSubmit={(values, formikHelpers) => {
        setTimeout(() => {
          subscRepository
            .update({
              isAgency,
              storeId,
              subscUrl: values.url,
            })
            .then(() => {
              requestAnimationFrame(() => {
                alert("処理が完了しました。データ再取得のためリロードします。");
                window.location.reload();
              });
            })
            .catch(() => {
              alert("処理中にエラーが発生しました。");
            })
            .finally(() => {
              formikHelpers.setSubmitting(false);
            });
        }, 5000);
      }}
      validationSchema={Yup.object().shape({
        url: Yup.string()
          .required(MESSAGE.ERROR.NO_INPUT)
          .url(MESSAGE.ERROR.INVALID_URL),
      })}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        resetForm,
        errors,
        isSubmitting,
      }) => (
        <>
          <Header
            content={
              <>
                {currentUrl ? (
                  <Label color="green">同期中</Label>
                ) : (
                  <Label color="grey">未設定</Label>
                )}
                &nbsp;サブスクストア
              </>
            }
          />
          <div css={{ margin: "8px" }}>
            {isEditMode && (
              <>
                <p>同期するページのURLを入力してください (https://...)</p>
                <Form>
                  <Form.Input
                    name="url"
                    error={errors.url}
                    value={values.url}
                    placeholder="https://..."
                    onChange={handleChange}
                  />
                  <div css={{ display: "flex", justifyContent: "center" }}>
                    <Form.Button
                      size="small"
                      type="submit"
                      content="保存＆同期"
                      color="blue"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => handleSubmit()}
                    />
                    <Form.Button
                      size="small"
                      onClick={() => {
                        resetForm();
                        setIsEditMode(false);
                      }}
                    >
                      キャンセル
                    </Form.Button>
                  </div>
                </Form>
              </>
            )}
            {!isEditMode &&
              (currentUrl ? (
                <div>
                  <Message color="green">
                    最終データ同期日時: {lastSyncedAt}
                  </Message>
                  <span css={{ marginRight: "16px" }}>
                    {currentUrl}
                    <a
                      href={currentUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                      css={{ marginLeft: "8px" }}
                    >
                      <Icon name="external alternate" />
                    </a>
                  </span>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    onClick={() => handleSubmit()}
                    color="blue"
                    size="mini"
                  >
                    再同期
                  </Button>
                  <Button onClick={() => setIsEditMode(true)} size="mini">
                    編集
                  </Button>
                </div>
              ) : (
                <div css={{ textAlign: "center" }}>
                  <Button color="blue" onClick={() => setIsEditMode(true)}>
                    同期を有効にする
                  </Button>
                </div>
              ))}
          </div>
        </>
      )}
    </Formik>
  );
};

export default UpdateSubscForm;
