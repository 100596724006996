import React from "react";
import { UserContainer } from "containers";
import PageContainer from "components/lib/PageContainer";
import NewForm from "components/JobOffer/JobOfferFormNew/NewForm";
import { useParams } from "react-router-dom";
import { StoreIdParam } from "config/raven";
import { Segment } from "semantic-ui-react";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { JOB_OFFER_LABEL } from "config/ItemLabel";

const JobOfferFormNew = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header={`${JOB_OFFER_LABEL}作成`}
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .jobOfferPage()
        .build(`${JOB_OFFER_LABEL}作成`)}
    >
      <Segment>
        <NewForm isAgency={isAgency} storeId={storeId}></NewForm>
      </Segment>
    </PageContainer>
  );
};

export default JobOfferFormNew;
