import React, { useState } from "react";
import { SourceCodeEditor } from "components/lib/Editor/SourceCodeEditor";
import { Button, Form, Popup, Segment } from "semantic-ui-react";

type Props = {
  source: string;
  fileType: string;
  handleChange: (content: string) => void;
  submit: (title: string, deployment: boolean) => void;
  isSubmitting: boolean;
};

export const VersionSourceCodeEditor: React.FC<Props> = ({
  source,
  fileType,
  handleChange,
  submit,
  isSubmitting,
}) => {
  const [title, setTitle] = useState("");

  return (
    <>
      <Segment>
        <Form>
          <Form.Group inline>
            <Form.Input
              width="12"
              name="title"
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              placeholder="バージョンタイトル（編集の概要や修正内容など）"
            />
            <div css={{ marginRight: "16px" }}>
              <Button
                primary
                content="保存"
                type="submit"
                loading={isSubmitting}
                onClick={() => {
                  submit(title, false);
                }}
              />
            </div>
            <Popup
              content="バージョンを保存して、公開します"
              inverted
              trigger={
                <Button
                  primary
                  content="保存して公開"
                  icon="upload"
                  type="submit"
                  loading={isSubmitting}
                  onClick={() => {
                    submit(title, true);
                  }}
                />
              }
            />
          </Form.Group>
        </Form>
      </Segment>
      <SourceCodeEditor
        handleChange={handleChange}
        source={source}
        fileType={fileType}
      />
    </>
  );
};
