import React, { useState } from "react";
import { Modal, Button, Header, Form, TextArea } from "semantic-ui-react";

type Props = {
  open: boolean;
  applicantName: string;
  remarks: string | null;
  onOpen: () => void;
  onClose: () => void;
  handleUpdateButton: (newRemarks: string) => void;
};

const RemarkModal: React.FC<Props> = ({
  remarks,
  applicantName,
  open,
  onOpen,
  onClose,
  handleUpdateButton,
}) => {
  const isNewRemarks: boolean = remarks === null || remarks === "";
  const [remarksState, setRemarksState] = useState(remarks ?? "");

  return (
    <Modal
      open={open}
      trigger={
        <Button
          content={isNewRemarks ? "作成" : "確認"}
          color={isNewRemarks ? undefined : "blue"}
          icon="pencil alternate"
          onClick={onOpen}
        ></Button>
      }
    >
      <Header icon="pencil alternate" content={`応募者: ${applicantName}`} />
      <Modal.Content>
        <Form>
          <TextArea
            placeholder="メモを保存できます"
            value={remarksState ?? ""}
            onChange={(e) => setRemarksState(e.currentTarget.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="閉じる" onClick={onClose} />
        <Button
          content={isNewRemarks ? "作成" : "更新"}
          positive
          key="done"
          onClick={() => {
            handleUpdateButton(remarksState);
            onClose();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default RemarkModal;
