import api from "utils/api";
import endpoint from "utils/endpoint";

class SitestRepository {
  /**
   * 代理店ユーザーのみ利用可能
   * サイト解析を有効化して、その後、サイテストアカウントを作成する
   */
  public async enable(isAgency: boolean, storeId: string) {
    // サイト解析機能(サイテスト)の有効化
    return api.put(endpoint.agency.sitest.update(storeId)).then((res) => {
      if (!res.data.use_sitest) {
        return Promise.reject();
      }
      // サイテストのアカウント作成
      return api.post(endpoint.agency.sitest.root(storeId));
    });
  }
}

export default new SitestRepository();
