import React, { useState } from "react";
import PageContainer from "components/lib/PageContainer";
import { Segment, Step } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { StoreIdParam } from "config/raven";
import { CheckDomainContainer } from "components/SiteManage/Content/Domain/DomainRegist/CheckDomainContainer";
import { RegisterDomainContainer } from "components/SiteManage/Content/Domain/DomainRegist/RegisterDomainContainer";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { UserContainer } from "containers";

const isCheckStep = (step: 0 | 1) => {
  return step === 0;
};
const isRegisterStep = (step: 0 | 1) => {
  return step === 1;
};

export const DomainRegist: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  const [step, setStep] = useState<0 | 1>(0);
  const [targetDomain, setTargetDomain] = useState<string | null>(null);
  return (
    <PageContainer
      header="ドメインを取得"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .domainPage()
        .build("ドメインを取得")}
    >
      <Segment>
        <Step.Group size="small">
          <Step
            title="ドメイン入力"
            icon="check circle"
            description="ドメインが利用可能か確認"
            disabled={!isCheckStep(step)}
          />
          <Step
            title="ドメイン取得"
            icon="home"
            description="ドメインに関する情報を入力"
            disabled={!isRegisterStep(step)}
          />
        </Step.Group>
        {isCheckStep(step) ? (
          <CheckDomainContainer
            toNextStep={(targetDomain: string) => {
              setTargetDomain(targetDomain);
              setStep(1);
            }}
          />
        ) : null}
        {isRegisterStep(step) && targetDomain !== null ? (
          <RegisterDomainContainer
            storeId={storeId}
            targetDomain={targetDomain}
          />
        ) : null}
      </Segment>
    </PageContainer>
  );
};
