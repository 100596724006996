import React from "react";
import { Progress } from "semantic-ui-react";

export type ProgressBarStatus =
  | "active"
  | "success"
  | "warning"
  | "error"
  | null;

type Props = {
  status: ProgressBarStatus;
};

const ProgressBar: React.FC<Props> = ({ status }) => {
  return (
    status && (
      <Progress
        size="small"
        percent={100}
        style={{ position: "absolute", top: 0, left: 0, width: "100vw" }}
        active={status === "active"}
        success={status === "success"}
        warning={status === "warning"}
        error={status === "error"}
      />
    )
  );
};

export default React.memo(ProgressBar);
