import React from "react";
import PageContainer from "components/lib/PageContainer";
import { Segment } from "semantic-ui-react";
import { UserContainer } from "containers";
import { useParams } from "react-router-dom";
import { StoreIdParam } from "config/raven";
import { NewFormContainer } from "components/Post/Article/New/NewFormContainer";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const ArticleNew: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="記事新規投稿"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .articlePage()
        .build("記事新規投稿")}
    >
      <Segment>
        <NewFormContainer isAgency={isAgency} storeId={storeId} />
      </Segment>
    </PageContainer>
  );
};
