import React, { useCallback, useState } from "react";
import { sitemapRepository } from "domain/repository";
import {
  ExecuteSitemapModal,
  ModalTypes,
} from "components/SiteManage/Content/Sitemap/ExecuteSitemapModal";
import { Button, Header, Segment, Select } from "semantic-ui-react";

const freqOptions = [
  { text: "1時間ごと", value: "hourly", key: "hourly" },
  { text: "毎日", value: "daily", key: "daily" },
  { text: "1週間ごと", value: "weekly", key: "weekly" },
  { text: "1ヶ月ごと", value: "monthly", key: "monthly" },
  { text: "1年ごと", value: "yearly", key: "yearly" },
  { text: "更新しない", value: "never", key: "never" },
];

const DEFAULT_FREQ_VALUE = freqOptions[0].value;
type Props = { storeId: string | undefined };
export const CreateSitemapSegment: React.FC<Props> = ({ storeId }) => {
  const [homeChangeFreqValue, setHomeChangeFreqValue] =
    useState(DEFAULT_FREQ_VALUE);
  const [postChangeFreqValue, setPostChangeFreqValue] =
    useState(DEFAULT_FREQ_VALUE);
  const [sitemapUrl, setSitemapUrl] = useState<string>();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const createSitemap = useCallback(() => {
    if (storeId === undefined) {
      return Promise.reject();
    }
    return sitemapRepository
      .createSitemap(storeId, {
        homeChangeFreqValue,
        postChangeFreqValue,
      })
      .then((sitemapUrl) => {
        setSitemapUrl(sitemapUrl);
      });
  }, [homeChangeFreqValue, postChangeFreqValue, storeId]);
  return (
    <>
      <ExecuteSitemapModal
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        execute={createSitemap}
        modalType={ModalTypes.CREATE}
        sitemapUri={sitemapUrl}
      />
      <Segment>
        <Header content="サイトマップ作成" size="small" />
        <div css={{ margin: "16px 0" }}>
          <label css={{ marginRight: "16px" }}>ホームページの更新頻度</label>
          <Select
            label="ホームページの更新頻度"
            options={freqOptions}
            value={homeChangeFreqValue}
            onChange={(_, { value }) => setHomeChangeFreqValue(value as string)}
          />
        </div>
        <div css={{ margin: "16px 0" }}>
          <label css={{ marginRight: "16px" }}>投稿の更新頻度</label>
          <Select
            options={freqOptions}
            value={postChangeFreqValue}
            onChange={(_, { value }) => setPostChangeFreqValue(value as string)}
          />
        </div>
        <Button
          primary
          content="サイトマップを作成"
          onClick={() => setOpenCreateModal(true)}
        />
      </Segment>
    </>
  );
};
