import React from "react";
import {
  generatePath,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";

import { Button, Message } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";

import { UserContainer } from "containers";
import routes from "utils/routes";

import { Version } from "domain/model/Version";
import { StoreIdParam } from "config/raven";
import { File, FileTree } from "components/Version/Show/FileTree";
import { FileMeta } from "components/Version/Edit";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const VersionShow = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();
  const { state } = useLocation<{ version?: Version }>();
  const { version } = state;

  const history = useHistory<FileMeta>();

  const files = version?.data.flattenTree.map<File>((ele) => ({
    key: ele.path,
    objectHash: ele.endpoint.replace("/objects/", ""),
  }));

  const handleClickFile = (file: File) => {
    const fileName = file.key.split("/").pop();
    const fileType = fileName?.split(".").pop() ?? "";
    if (!["html", "css", "scss", "sass", "js"].includes(fileType)) {
      return;
    }
    if (version) {
      const editUrl = isAgency
        ? generatePath(routes.agency.versionEdit.pathname, {
            storeId,
          })
        : routes.store.versionEdit.pathname;
      const state = {
        ...file,
        fileType,
        versionHash: version.data.versionHash,
        versionTitle: version.data.title,
      };
      history.push(editUrl, state);
    }
  };

  return version?.data ? (
    <PageContainer
      header={`バージョン詳細: ${version.data.title}`}
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .versionPage()
        .build(`バージョン詳細: ${version.data.title}`)}
    >
      <Message content="ソースファイル(拡張子: html, css, js)をクリックすると編集画面へ遷移します" />
      <FileTree
        files={files}
        handleClickFile={(file: File) => handleClickFile(file)}
      />
    </PageContainer>
  ) : null;
};
