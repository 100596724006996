import { Expose, Transform } from "class-transformer";

export type JobFeedStatus =
  | "advertising" // 広告募集中(サイト表示/広告掲載)
  | "hiring" // 募集中(サイト表示/広告非掲載)
  | "pending" // 募集停止(サイト非表示/広告非掲載)
  | "deleted"; // 削除済み(サーバから流れては来ない)

export type JobType =
  | "full_time" // 正社員
  | "part_time" // パート・アルバイト
  | "contractor" // 契約社員
  | "temporary" // 一時的な雇用・派遣社員
  | "intern" // インターンシップ
  | "volunteer" // ボランティア
  | "per_diem" // 日雇い
  | "outsourcing" // 業務委託・外注
  | "other"; // その他

export type SalaryType =
  | "hour" // 時間給
  | "day" // 日給
  | "week" // 週給
  | "month" // 月給
  | "year" // 年給・年俸
  | "piecework"; // 出来高制・報酬制

export default class JobFeed {
  @Expose() id: number;
  @Expose() status: JobFeedStatus;
  @Expose() title: string;
  @Expose() company: string;
  @Expose({ name: "job_type" }) jobType: JobType;
  @Expose() description: string;
  @Expose({ name: "post_date" }) postDate: Date;
  @Expose({ name: "salary_type" }) salaryType: SalaryType;
  @Expose({ name: "salary_min" }) salaryMin: number;
  @Expose({ name: "salary_max" }) salaryMax: number;
  @Expose({ name: "zip_code" }) zipCode: string | null;
  @Expose() @Transform((value) => value || "") state: string;
  @Expose() @Transform((value) => value || "") city: string;
  @Expose() address: string | null;
  @Expose() station: string | null;
  @Expose() experience: string;
  @Expose({ name: "working_time" }) workingTime: string;
  @Expose() holiday: string;
  @Expose() benefits: string;
  @Expose() insurance: string;
  @Expose({ name: "prevent_smoke" }) preventSmoke: string;
  @Expose({ name: "contract_period" }) contractPeriod: string;
  @Expose() url: string | null;
  @Expose({ name: "image_url" }) imageUrl: string | null;
  @Expose({ name: "company_url" }) companyUrl: string | null;
  @Expose({ name: "company_logo_url" }) companyLogoUrl: string | null;
  @Expose({ name: "page_header" }) pageHeader: string;
  @Expose({ name: "page_footer" }) pageFooter: string;
  // 以下、導出項目(Read Only)
  @Expose({ name: "ref_url" }) refUrl: string | null;

  statusText(): string {
    switch (this.status) {
      case "advertising":
        return "募集・広告";
      case "hiring":
        return "募集";
      case "pending":
        return "募集停止";
      case "deleted":
        return "削除済み";
    }
  }

  statusColor() {
    switch (this.status) {
      case "advertising":
        return "green";
      case "hiring":
        return "yellow";
      case "pending":
        return "red";
      case "deleted":
        return "black";
    }
  }

  jobTypeText(): string {
    switch (this.jobType) {
      case "full_time":
        return "正社員";
      case "part_time":
        return "パート・アルバイト";
      case "contractor":
        return "契約社員";
      case "temporary":
        return "一時的な雇用・派遣社員";
      case "intern":
        return "インターンシップ";
      case "volunteer":
        return "ボランティア";
      case "per_diem":
        return "日雇い";
      case "outsourcing":
        return "業務委託・外注";
      case "other":
        return "その他";
    }
  }

  salaryTypeText(): string {
    switch (this.salaryType) {
      case "hour":
        return "時給";
      case "day":
        return "日給";
      case "week":
        return "週給";
      case "month":
        return "月給";
      case "year":
        return "年俸";
      case "piecework":
        return "出来高制";
    }
  }

  yenText(salary: number): string {
    return `${
      salary >= 10000 && salary % 1000 === 0 ? salary / 10000 + "万" : salary
    }`;
  }

  yenRangeText(min: number, max: number): string {
    return min >= 20000 && min % 1000 === 0
      ? `${min / 10000}〜${max / 10000}万`
      : `${min}〜${max}`;
  }

  salaryText(): string {
    if (this.salaryMin == null && this.salaryMax == null) {
      return this.salaryTypeText();
    } else if (this.salaryMin == null) {
      return `${this.salaryTypeText()} 〜${this.yenText(this.salaryMax)}円`;
    } else if (this.salaryMax == null) {
      return `${this.salaryTypeText()} ${this.yenText(this.salaryMin)}円〜`;
    } else {
      return `${this.salaryTypeText()} ${this.yenRangeText(
        this.salaryMin,
        this.salaryMax
      )}円`;
    }
  }

  /**
   * XMLフィードに反映されないものの画面表示用
   * (現状、「勤務地無効」しか想定していないが、他条件の追加を一応考慮しておく)
   */
  errorText(): string | null {
    if (this.state == null || !this.state.match(/\S{2,3}[都道府県]$/)) {
      return "勤務地(都道府県)無効"; // セレクトボックス入力だが、古いデータで「都内近郊」など入力されているものが指摘される
    }
    if (this.city == null || !this.city.match(/\S+[市区町村]$/)) {
      return "勤務地(市区町村)無効"; // 「市区町村」のいずれかで終わっていることを求める
    }
    if (!this.address && !this.station) {
      return "勤務地無効"; // 「番地等」「最寄り駅」のいずれかが入力されていることを求める(入力内容の妥当性には立ち入らない)
    }

    return null;
  }
}
