import React, { useState } from "react";

import { Segment, Button, Header, Message } from "semantic-ui-react";
import { Formik } from "formik";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import { acceptFileType, validFileSize } from "utils/media";

const acceptFileTypeDisplay = ["jpg", "jpeg", "png", "gif", "pdf"];

const initialValues = {
  files: [],
};

const validationSchema = Yup.object().shape({
  files: Yup.array(
    Yup.mixed().test(
      "fileSize",
      "ファイルサイズが大きすぎます。",
      (value) => value && validFileSize(value)
    )
  ).max(20, "同時にアップロード可能なファイルは20件までです。"),
});

type Props = {
  createMedia: Function;
};

export const MediaNewForm: React.FC<Props> = ({ createMedia }) => {
  const [upload, setUpload] = useState(false);

  return (
    <Segment>
      <Formik
        initialValues={initialValues}
        initialStatus={{
          success: "",
          error: "",
        }}
        onSubmit={(values, actions) => {
          setUpload(true);
          createMedia(values.files)
            .then(() => {
              actions.setStatus({
                success: "ファイルアップロードに成功しました。",
              });
              actions.setFieldValue("files", []);
            })
            .catch(() =>
              actions.setStatus({
                error: "ファイルアップロードに失敗しました。",
              })
            )
            .finally(() => setUpload(false));
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleSubmit,
          status,
          setFieldValue,
          setStatus,
        }) => (
          <>
            <Message>
              <Message.Header content="選択中のファイル" />
              <Message.Content
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <ul style={{ margin: "0.5rem 0 0 1rem" }}>
                  {values.files.length === 0 ? (
                    <li>ファイルが選択されていません</li>
                  ) : (
                    values.files.map((file: File, i) => (
                      <li key={i}>
                        {file.name} （
                        {Math.floor((file.size / 1024 / 1024) * 100) / 100}
                        MB）
                      </li>
                    ))
                  )}
                </ul>
                <Button
                  content="選択解除"
                  color="red"
                  onClick={() => setFieldValue("files", [])}
                  disabled={values.files.length === 0}
                  style={{ alignSelf: "flex-end" }}
                />
              </Message.Content>
            </Message>
            {status.success && <Message success content={status.success} />}
            {status.error && <Message error content={status.error} />}
            {errors.files && <Message error content={errors.files} />}
            <Dropzone
              onDrop={(acceptedFiles) => {
                setFieldValue("files", acceptedFiles);
                setStatus({ success: "", error: "" });
              }}
            >
              {({ getInputProps, getRootProps }) => (
                <div {...getRootProps()}>
                  <Segment
                    placeholder
                    loading={upload}
                    style={{ marginBottom: "1rem" }}
                  >
                    <Header
                      textAlign="center"
                      content="ここにファイルをドロップ"
                      as="h5"
                      color="grey"
                    />
                    <input
                      {...getInputProps()}
                      type="file"
                      accept={acceptFileType.join(",")}
                    />
                    <Button
                      content="ファイルを選択"
                      color="blue"
                      style={{ marginBottom: "1rem" }}
                    />
                    <div style={{ textAlign: "center" }}>
                      <p>ファイルサイズ： 10MB以下</p>
                      <p>
                        ファイル形式：
                        {acceptFileTypeDisplay.join(", ")}
                      </p>
                    </div>
                  </Segment>
                </div>
              )}
            </Dropzone>
            <div style={{ textAlign: "center" }}>
              <Button
                color="blue"
                content="アップロード"
                onClick={() => handleSubmit()}
                disabled={
                  values.files.length === 0 || errors.files || upload
                    ? true
                    : false
                }
              />
            </div>
          </>
        )}
      </Formik>
    </Segment>
  );
};
