import React from "react";
import PageContainer from "components/lib/PageContainer";
import { Segment, Message, Loader } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { UserContainer } from "containers";
import { StoreIdParam } from "config/raven";
import { EditForm } from "components/JobOffer/JobOfferFormEdit/EditForm";
import { FormDelete } from "components/JobOffer/JobOfferFormEdit/FormDelete";
import { useIsJobOfferEnabled } from "hooks/useIsJobOfferEnabled";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { JOB_OFFER_LABEL } from "config/ItemLabel";

const JobOfferFormEdit: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId, formId } = useParams<StoreIdParam & { formId: string }>();

  const isJobOfferEnabled = useIsJobOfferEnabled({ isAgency, storeId });

  let contents = null;

  if (isJobOfferEnabled === false) {
    contents = (
      <Message content="求人管理機能が利用できません" negative></Message>
    );
  } else if (isJobOfferEnabled === null) {
    contents = <Loader active />;
  } else {
    contents = (
      <>
        <EditForm isAgency={isAgency} storeId={storeId} formId={formId} />
        <div css={{ marginRight: "16px", marginTop: "16px" }}>
          <FormDelete isAgency={isAgency} storeId={storeId} formId={formId} />
        </div>
      </>
    );
  }

  return (
    <PageContainer
      header={`${JOB_OFFER_LABEL}編集`}
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .jobOfferPage()
        .build(`${JOB_OFFER_LABEL}編集`)}
    >
      <Segment>{contents}</Segment>
    </PageContainer>
  );
};

export default JobOfferFormEdit;
