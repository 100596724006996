import { useEffect, useState } from "react";
import { newsService } from "domain/service";

/**
 * お知らせ管理のテンプレート文字列を取得する
 * template: テンプレートの文字列
 */
export const useNewsTemplate = ({
  isAgency,
  storeId,
}: {
  isAgency: boolean;
  storeId: string | undefined;
}) => {
  const [template, setTemplate] = useState<string | null>(null);
  useEffect(() => {
    const fetchTemplate = () => {
      newsService.fetchNewsTemplate(isAgency, storeId).then((template) => {
        if (template === null) {
          setTemplate(null);
        } else {
          setTemplate(template.content);
        }
      });
    };
    fetchTemplate();
  }, [isAgency, storeId]);
  return { template };
};
