import { Type, Expose } from "class-transformer";
import { OptionSelect } from "components/Product/Edit/OptionForm";

export type TaxRateType =
  | "normal" // 通常税率（軽減税率対象外）
  | "reduced"; // 軽減税率対象

export default class Product {
  @Expose() id: number;
  @Expose() code: string;
  @Expose() name: string;
  @Expose() price: number;
  @Expose({ name: "tax_rate_type" }) taxRateType: TaxRateType;
  @Expose() display: boolean;
  @Expose({ name: "display_order" }) displayOrder: number;
  @Expose() description: string;
  @Expose() cautions: string;
  @Expose() options: OptionSelect[];
  @Expose() image: string;
  @Expose({ name: "product_categories" })
  @Type(() => ProductCategory)
  productCategories: ProductCategory[];
}

export class ProductCategory {
  @Expose() id: number;
  @Expose() code: string;
  @Expose() name: string;
  @Expose() display: boolean;
  @Expose({ name: "display_order" }) displayOrder: number;
}
