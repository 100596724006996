import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";

import Media from "domain/model/Media";

class MediaRepository {
  public async createMedias(
    isAgency: boolean,
    storeId: string | undefined,
    files: File[]
  ) {
    const promiseList: Promise<any>[] = [];
    let count = 0;

    const url =
      isAgency && storeId
        ? endpoint.agency.media.root(storeId)
        : endpoint.store.media.root();

    files.forEach((file) => {
      const formData = new FormData();
      formData.append("image[file]", file);

      promiseList.push(api.post(url, formData, { params: count }));
      count++;
    });

    return Promise.all(promiseList).then((res) =>
      res.map((r) =>
        plainToClass(Media, r.data.image, { excludeExtraneousValues: true })
      )
    );
  }

  public async deleteMedia(
    isAgency: boolean,
    storeId: string | undefined,
    mediaId: number
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.media.delete(mediaId.toString(), storeId)
        : endpoint.store.media.delete(mediaId.toString());
    return api.delete(url);
  }
}

export default new MediaRepository();
