import React from "react";

import PageContainer from "components/lib/PageContainer";
import { Segment, Loader } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { UserContainer } from "containers";
import { StoreIdParam } from "config/raven";
import { OgpDefaultValueForm } from "components/SiteManage/Content/Ogp/OgpDefaultValueForm";
import { useSeoPreferences } from "hooks/useSeoPreferences";
import { useAllMediaUrls } from "hooks/useAllMediaUrls";
import { SeoPreferences } from "domain/model/SeoPreferences";
import { SeoRepository } from "domain/repository";
import { useMessage } from "hooks/useMessage";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
};

const Container: React.FC<Props> = ({ isAgency, storeId }) => {
  const { seoPreferences } = useSeoPreferences({ isAgency, storeId });
  const { mediaUrls, refetchMediaUrls } = useAllMediaUrls({
    isAgency,
    storeId,
    imageOnly: false,
  });
  const { renderMessage, setErrorMessage, setSuccessMessage } = useMessage({
    messageSize: "tiny",
  });
  const handleUpdate = (seoPreferences: SeoPreferences) => {
    return SeoRepository.updateSeoPreferences(isAgency, storeId, seoPreferences)
      .then(() => {
        setSuccessMessage("更新しました");
      })
      .catch(() => {
        setErrorMessage("更新に失敗しました");
      });
  };

  if (seoPreferences === null) {
    return <Loader active />;
  }

  return (
    <>
      {renderMessage()}
      <OgpDefaultValueForm
        seoPreferences={seoPreferences}
        mediaUrls={mediaUrls}
        refetchMediaUrls={refetchMediaUrls}
        handleUpdate={handleUpdate}
      />
    </>
  );
};

export const Ogp: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="OGPタグデフォルト値設定"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("OGPタグデフォルト値設定")}
    >
      <Segment>
        <Container isAgency={isAgency} storeId={storeId} />
      </Segment>
    </PageContainer>
  );
};
