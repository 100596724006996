import React, { useState } from "react";
import { SeoPreferences } from "domain/model/SeoPreferences";
import { useFormikContext } from "formik";
import {
  Form,
  Message,
  Divider,
  Header,
  Icon,
  Image,
  Button,
} from "semantic-ui-react";
import noImage from "img/no-image.png";
import { SelectMediaModal } from "components/lib/SelectMediaModal";

type FormValues = {
  seoPreferences: SeoPreferences;
};

type Props = {
  mediaUrls: string[] | null;
  refetchMediaUrls: () => void;
};

export const SeoPreferencesForm: React.FC<Props> = ({
  mediaUrls,
  refetchMediaUrls,
}) => {
  const {
    values: { seoPreferences: values },
    errors: { seoPreferences: errors },
    handleChange,
    setFieldValue,
  } = useFormikContext<FormValues>();

  const [openOgImageModal, setOpenOgImageModal] = useState(false);
  const [openTwitterImageModal, setOpenTwitterImageModal] = useState(false);
  return (
    <>
      <Form.Input
        label="title"
        name="seoPreferences.doc.title"
        error={errors?.doc?.title}
        value={values.doc.title}
        onChange={handleChange}
      />
      <Form.TextArea
        label="description"
        name="seoPreferences.doc.description"
        error={errors?.doc?.description}
        value={values.doc.description}
        onChange={handleChange}
      />
      <Form.Input
        label="keywords"
        name="seoPreferences.doc.keywords"
        error={errors?.doc?.keywords}
        value={values.doc.keywords}
        onChange={handleChange}
      />
      <Message
        size="tiny"
        info
        icon="pointing up"
        content="カンマ区切りで入力してください（例. いちご,レモン,果物）"
      />
      <Divider horizontal>
        <Header as="h4">
          <Icon name="tag" />
          OGPタグ
        </Header>
      </Divider>
      <Form.Input
        label="og:title"
        name="seoPreferences.og.title"
        error={errors?.og?.title}
        value={values.og.title}
        onChange={handleChange}
      />
      <Form.TextArea
        label="og:description"
        name="seoPreferences.og.description"
        error={errors?.og?.description}
        value={values.og.description}
        onChange={handleChange}
      />
      <Form.Input
        label="og:site_name"
        name="seoPreferences.og.siteName"
        error={errors?.og?.siteName}
        value={values.og.siteName}
        onChange={handleChange}
      />
      <Form.Field>
        <label>og:image</label>
        {values.og.image === "" || values.og.image === null ? (
          <Image src={noImage} size="small" />
        ) : (
          <Image src={values.og.image} size="small" />
        )}
        <Button
          css={{ margin: "16px !important" }}
          content="画像を選択"
          onClick={(e) => {
            setOpenOgImageModal(true);
            e.preventDefault();
          }}
        />
        <SelectMediaModal
          title="画像を選択(og.image)"
          mediaUrls={mediaUrls ?? []}
          onUploaded={refetchMediaUrls}
          open={openOgImageModal}
          handleMediaClick={(url) => {
            setFieldValue("seoPreferences.og.image", url);
            setOpenOgImageModal(false);
          }}
          close={() => setOpenOgImageModal(false)}
        />
      </Form.Field>
      <Divider horizontal>
        <Header as="h4">
          <Icon name="twitter" />
          Twitterタグ
        </Header>
      </Divider>
      <Form.Input
        label="twitter.title"
        name="seoPreferences.twitter.title"
        error={errors?.twitter?.title}
        value={values.twitter.title}
        onChange={handleChange}
      />
      <Form.TextArea
        label="twitter.description"
        name="seoPreferences.twitter.description"
        error={errors?.twitter?.description}
        value={values.twitter.description}
        onChange={handleChange}
      />
      <Form.Select
        label="twitter:card"
        name="seoPreferences.twitter.card"
        error={errors?.twitter?.card}
        value={values.twitter.card}
        onChange={(_, { value }) =>
          setFieldValue("seoPreferences.twitter.card", value)
        }
        options={[
          { key: "summary", value: "summary", text: "summary" },
          {
            key: "summary_large_image",
            value: "summary_large_image",
            text: "summary_large_image",
          },
        ]}
      />
      <Form.Field>
        <label>twitter:image</label>
        {values.twitter.image === "" || values.twitter.image === null ? (
          <Image src={noImage} size="small" />
        ) : (
          <Image src={values.twitter.image} size="small" />
        )}
        <Button
          css={{ margin: "16px !important" }}
          content="画像を選択"
          onClick={(e) => {
            setOpenTwitterImageModal(true);
            e.preventDefault();
          }}
        />
        <SelectMediaModal
          title="画像を選択(twitter.image)"
          mediaUrls={mediaUrls ?? []}
          onUploaded={refetchMediaUrls}
          open={openTwitterImageModal}
          handleMediaClick={(url) => {
            setFieldValue("seoPreferences.twitter.image", url);
            setOpenTwitterImageModal(false);
          }}
          close={() => setOpenTwitterImageModal(false)}
        />
      </Form.Field>
    </>
  );
};
