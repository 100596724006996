import React from "react";
import Switch from "./Switch";
import Button from "./Button";
import Wrap from "./Wrap";
import WysiwygTagChildren from "./WysiwygTagChildren";
import Modal from "./Modal";
import TextareaLabel from "./TextareaLabel";
import color from "./color";
import { imageExist } from "./wysiwygActions";

export default ({
  close,
  show,
  target,
  showImagesModal,
  text,
  setText,
  replaceText,
  href,
  setHref,
  editAnchor,
  outerHTML,
  setOuterHTML,
  replaceOuterHTML,
  removeDOM,
}) => {
  const [active, setActive] = React.useState("text");

  return (
    <Modal title="要素の編集" show={show} close={close} scroll>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Switch
          checked={active === "source"}
          onClick={() => setActive(active === "text" ? "source" : "text")}
        />
      </div>
      <WysiwygTagChildren
        active={active === "source"}
        target={target}
        outerHTML={outerHTML}
        setOuterHTML={setOuterHTML}
      />
      <TextareaLabel
        disabled={active !== "text"}
        label={`テキスト${
          active === "text" ? "（こちらの変更が反映されます。）" : ""
        }`}
        value={text}
        onChange={(e) => setText(e.target.value)}
        // onKeyDown={e => {
        //   if (e.keyCode === 13) {
        //     setText(`${text}<br/>`);
        //   }
        // }}
      />
      {target.tagName === "A" && (
        <TextareaLabel label="リンク先URL" vaue={href} onChange={setHref} />
      )}
      <Wrap justifyContent="center" margin="50px 0 0">
        <Button
          backgroundColor={color.red}
          onClick={() => {
            removeDOM(target);
            close();
          }}
          style={{ marginRight: "auto" }}
        >
          このタグを削除
        </Button>
        <Button
          onClick={close}
          backgroundColor={color.gray}
          style={{ marginLeft: 10 }}
        >
          キャンセル
        </Button>
        {target && imageExist(target) ? (
          <Button
            style={{ marginLeft: 10 }}
            onClick={() => {
              showImagesModal(true);
            }}
          >
            画像変更
          </Button>
        ) : (
          <Button
            backgroundColor={color.gray}
            style={{ marginLeft: 10 }}
            data-tip="画像が挿入されている要素を選択した場合のみご利用いただけます。"
          >
            画像変更
          </Button>
        )}
        <Button
          style={{ marginLeft: 10 }}
          onClick={() => {
            editAnchor();
            if (active === "text") {
              replaceText();
            } else {
              replaceOuterHTML();
            }
            close();
          }}
        >
          確定
        </Button>
      </Wrap>
    </Modal>
  );
};
