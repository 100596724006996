import React from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Image } from "semantic-ui-react";

import { MetaContainer } from "containers";
import COLOR from "config/color.json";
import ResetForm from "components/PasswordReset/ResetForm";

const PasswordReset = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const metaContainer = MetaContainer.useContainer();
  const { token } = useParams<{ token: string }>();

  // TODO: SignInFormと共通化
  return (
    <div
      css={{
        height: "inherit",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div css={{ width: isMobile ? "90%" : "35%" }}>
        <Image
          src={metaContainer.logo}
          size="small"
          style={{ margin: "0 auto 16px" }}
        />
        <p css={{ textAlign: "center", color: COLOR.SUB2 }}>
          バージョン管理と直感的な操作でサイト制作・保守・拡張。
        </p>
        <ResetForm token={token} />
      </div>
    </div>
  );
};

export default PasswordReset;
