import { Expose } from "class-transformer";

export default class GaResource {
  @Expose({ name: "view_id" })
  viewId: string;
  @Expose({ name: "account_id" })
  accountId: string;
  @Expose({ name: "property_id" })
  propertyId: string;
}
