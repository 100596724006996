import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Form } from "semantic-ui-react";
import MESSAGE from "config/message.json";

type Props = {
  handleAdd: (categoryName: string) => Promise<any>;
};

const validationSchema = Yup.object().shape({
  categoryName: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
});

export const AddForm: React.FC<Props> = ({ handleAdd }) => {
  const formik = useFormik({
    initialValues: {
      categoryName: "",
    },
    onSubmit: (values, { resetForm }) => {
      handleAdd(values.categoryName).then(() => {
        resetForm();
      });
    },
    initialStatus: { success: "", error: "" },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });
  return (
    <Form
      onSubmit={formik.handleSubmit}
      css={{ display: "flex", justifyContent: "flex-end" }}
    >
      <Form.Input
        name="categoryName"
        width={4}
        placeholder="カテゴリ名"
        error={formik.errors.categoryName}
        value={formik.values.categoryName}
        onChange={formik.handleChange}
      />
      <Form.Button type="submit" color="blue" content="追加" />
    </Form>
  );
};
