const hostname = window.location.hostname;
const defaultLogo = `https://logo.staging.ccmphp.com/staging.ccmphp.com/logo.png`;
const defaultFavicon = `https://logo.staging.ccmphp.com/staging.ccmphp.com/favicon.ico`;

const ccmpHostNames = ["localhost", "ccmphp.com", "v2.staging.ccmphp.com"];

const fetchLogo = () => {
  if (ccmpHostNames.includes(hostname)) {
    return defaultLogo;
  } else if (
    "valuehp.info" === hostname ||
    "staging.valuehp.info" === hostname
  ) {
    return `https://logo.staging.ccmphp.com/${hostname}/logo.png`;
  } else {
    return `https://logo.ccmphp.com/${hostname}/logo.png`;
  }
};

const fetchFavicon = () => {
  if (ccmpHostNames.includes(hostname)) {
    return defaultFavicon;
  } else if (
    "valuehp.info" === hostname ||
    "staging.valuehp.info" === hostname
  ) {
    return `https://logo.staging.ccmphp.com/${hostname}/favicon.ico`;
  } else {
    return `https://logo.ccmphp.com/${hostname}/favicon.ico`;
  }
};

// eslint-disable-next-line
export default { fetchLogo, fetchFavicon };
