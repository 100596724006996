import React from "react";
import styled from "@emotion/styled";
import { Table, Select } from "semantic-ui-react";

import FORM_STATUS from "config/form_status.json";
import { FormStatus } from "config/raven";
import RemarkModal from "components/JobOffer/ApplicantsIndex/RemarkModal";
import { FormattedApplicant } from "components/JobOffer/ApplicantsIndex";

const StyledTableCell = styled(Table.Cell)`
  word-break: break-all;
`;

type Props = {
  applicant: FormattedApplicant;
  index: number;
  handleSelectStatus: (contactId: string, status: FormStatus) => void;
  updateRemarks: (contactId: string, newRemarks: string) => void;
  openRemarksContactId: string | undefined;
  setOpenRemarksContactId: (contactId?: string) => void;
};

const ApplicantItem: React.FC<Props> = ({
  applicant,
  index,
  handleSelectStatus,
  updateRemarks,
  setOpenRemarksContactId,
  openRemarksContactId,
}) => {
  const onOpenModal = () => {
    setOpenRemarksContactId(applicant.contactId);
  };

  const onCloseModel = () => {
    setOpenRemarksContactId(undefined);
  };

  const handleUpdateButton = (newRemarks: string) => {
    updateRemarks(applicant.contactId, newRemarks);
  };

  return (
    <Table.Row key={index}>
      <StyledTableCell>{index + 1}</StyledTableCell>
      <StyledTableCell>{applicant.name}</StyledTableCell>
      <StyledTableCell>{applicant.age}</StyledTableCell>
      <StyledTableCell>{applicant.currentJob}</StyledTableCell>
      <StyledTableCell>{applicant.address}</StyledTableCell>
      <StyledTableCell>{applicant.email}</StyledTableCell>
      <StyledTableCell>{applicant.phone}</StyledTableCell>
      <StyledTableCell>{applicant.formName}</StyledTableCell>
      <StyledTableCell>{applicant.jobOfferType}</StyledTableCell>
      <StyledTableCell>{applicant.createdAt}</StyledTableCell>
      <StyledTableCell>
        <Select
          options={Object.entries(FORM_STATUS).map(([key, value]) => {
            return {
              key,
              value: key,
              text: value,
            };
          })}
          onChange={(_, { value }) => {
            handleSelectStatus(applicant.contactId, value as FormStatus);
          }}
          value={applicant.status}
          css={{ minWidth: "8em!important" }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <RemarkModal
          open={applicant.contactId === openRemarksContactId}
          applicantName={applicant.name}
          remarks={applicant.remarks}
          onOpen={onOpenModal}
          onClose={onCloseModel}
          handleUpdateButton={handleUpdateButton}
        />
      </StyledTableCell>
    </Table.Row>
  );
};

export default ApplicantItem;
