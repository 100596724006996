import React from "react";

import { Modal, Button, Header } from "semantic-ui-react";

type Props = {
  open: boolean;
  setIsModalOpen: React.Dispatch<boolean>;
  createGaError: boolean;
};

export const MessageModal: React.FC<Props> = ({
  open,
  setIsModalOpen,
  createGaError,
}) => {
  return (
    <Modal open={open}>
      <Modal.Header>
        {!createGaError ? (
          <Header size="tiny" color="green">
            Google Analyticsプロジェクトの作成に成功しました
          </Header>
        ) : (
          <Header size="tiny" color="red">
            Google Analyticsプロジェクトの作成に失敗しました
          </Header>
        )}
      </Modal.Header>
      <Modal.Content>
        {!createGaError ? (
          <p>"トラッキングコードは自動で埋め込まれます"</p>
        ) : (
          <>
            <ul>
              <li>
                GoogleAnalyticsアカウントを作成する必要があります
                <a
                  href="https://analytics.google.com/analytics"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://analytics.google.com/analytics
                </a>
              </li>
              <li>ドメインを取得する必要があります</li>
            </ul>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <div css={{ textAlign: "center" }}>
          <Button color="blue" onClick={() => setIsModalOpen(false)}>
            OK
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
