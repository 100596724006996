import React, { useState } from "react";

import { Modal, Button, Header, Item } from "semantic-ui-react";
import { CurrentStore } from "config/raven";

type Props = {
  isAdmin: boolean;
  currentStore: CurrentStore;
  isAgency: boolean;
  handleDelete: () => void;
};

const DeleteStoreModal: React.FC<Props> = ({
  isAdmin,
  currentStore,
  isAgency,
  handleDelete,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  if (!currentStore || !isAgency || !isAdmin) {
    return <></>;
  }

  return (
    <Modal
      open={open}
      trigger={
        <Item.Group>
          <Item>
            <Item.Content>
              <Button
                floated="right"
                content="店舗削除"
                icon="trash alternate"
                onClick={onOpen}
              />
            </Item.Content>
          </Item>
        </Item.Group>
      }
    >
      <Header icon="trash alternate" content="店舗削除" />
      <Modal.Content>
        <p>店舗ID: {currentStore.id} を削除しますか？</p>
        <p>※一度削除すると元には戻せません。</p>
        <p>
          ※削除が反映されるまでには最長1日程度時間がかかる場合がございます。
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button content="削除" color="red" onClick={handleDelete} />
        <Button content="閉じる" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteStoreModal;
