import { Type, Expose } from "class-transformer";
import { AwsStatus, DomainRegistrar } from "config/raven";

export default class AwsResource {
  @Expose({ name: "hp_domain" }) homepageDomain: string;
  @Expose({ name: "s3_bucket" }) s3Bucket: string;
  @Expose({ name: "external_registrar" }) externalRegistrar: boolean;

  @Type(() => Domain)
  @Expose()
  domain: Domain | null;
}

class Domain {
  @Expose() name: string;
  @Expose() registrar: DomainRegistrar;
  @Expose() status: AwsStatus;
}
