import api from "utils/api";
import endpoint from "utils/endpoint";

class BasicAuthRepository {
  /**
   * 代理店ユーザーだけ利用可能
   */
  public async enable(storeId: string) {
    const url = endpoint.agency.basicAuth.use(storeId);
    return api.post(url);
  }

  public async update({
    storeId,
    authUser,
    authPass,
    privatePages,
  }: {
    storeId: string;
    authUser: string;
    /** undefined の場合は更新しない */
    authPass?: string;
    privatePages: string[];
  }) {
    const url = endpoint.agency.basicAuth.update(storeId);

    const body = {
      site_policy: {
        auth_user: authUser,
        auth_pass: authPass,
        private_pages: privatePages,
      },
    };

    return api.put(url, body);
  }
}

export default new BasicAuthRepository();
