import React from "react";
import { ExternalDnsRecordset } from "domain/model/ExternalDnsRecordset";
import { Table } from "semantic-ui-react";
import { DomainRegistrar } from "config/raven";

type Props = { recordSet: ExternalDnsRecordset; registrar?: DomainRegistrar };
export const DnsRecordsetTable: React.FC<Props> = ({
  recordSet,
  registrar,
}) => {
  const { certificate, publicate } = recordSet;
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>NAME</Table.HeaderCell>
          <Table.HeaderCell>VALUE</Table.HeaderCell>
          <Table.HeaderCell>TYPE</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {/* certificate */}
        {certificate && certificate.value && certificate.type ? (
          <Table.Row>
            <Table.Cell>{certificate.name}</Table.Cell>
            <Table.Cell>
              {certificate.value}
              {registrar === "sakura" && (
                <p>
                  <strong>※「DNSチェック」を外してご設定ください。</strong>
                </p>
              )}
            </Table.Cell>
            <Table.Cell>{certificate.type}</Table.Cell>
          </Table.Row>
        ) : null}

        {/* publicate */}
        {publicate && publicate.value && publicate.type ? (
          <Table.Row>
            <Table.Cell>{publicate.name}</Table.Cell>
            <Table.Cell>{publicate.value}</Table.Cell>
            <Table.Cell>{publicate.type}</Table.Cell>
          </Table.Row>
        ) : null}
      </Table.Body>
    </Table>
  );
};
