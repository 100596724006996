import React from "react";
import { Version } from "domain/model/Version";
import { Message, Table } from "semantic-ui-react";
import { VersionListItemContainer } from "components/Version/Index/VersionItemContainer";

const TableHeader: React.FC = () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>{/* index */}</Table.HeaderCell>
      <Table.HeaderCell>タイトル</Table.HeaderCell>
      <Table.HeaderCell> {/* 編集 ボタン */}</Table.HeaderCell>
      <Table.HeaderCell>作成日時</Table.HeaderCell>
      <Table.HeaderCell>編集者</Table.HeaderCell>
      <Table.HeaderCell>
        {/* ダウンロード、プレビュー ボタン */}
      </Table.HeaderCell>
      <Table.HeaderCell>{/* 公開ボタンやステータス */}</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

type Props = {
  isAgency: boolean;
  storeId: string;
  versions: Version[];
};
export const VersionList: React.FC<Props> = ({
  isAgency,
  storeId,
  versions,
}) => {
  if (versions.length === 0) {
    return <Message warning content="データが存在しません。" />;
  }
  return (
    <Table>
      <TableHeader />
      <Table.Body>
        {versions.map((v, index) => (
          <VersionListItemContainer
            key={v.data.versionHash}
            version={v}
            index={index}
            isAgency={isAgency}
            storeId={storeId}
          />
        ))}
      </Table.Body>
    </Table>
  );
};
