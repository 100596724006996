import React from "react";
import { Segment, Header, SemanticCOLORS } from "semantic-ui-react";

type Props = {
  headerContent: React.ReactNode;
  headerText: string;
  children: React.ReactChild;
  color?: SemanticCOLORS;
};

const SegmentWithHeader: React.FC<Props> = ({
  headerContent,
  headerText,
  children,
  color,
}) => {
  return (
    <Segment stacked style={{ padding: "4rem 3rem" }} color={color}>
      <div css={{ marginBottom: "2rem" }}>
        <Header as="h2" content={headerContent} />
        <p>{headerText}</p>
      </div>
      {children}
    </Segment>
  );
};

export default SegmentWithHeader;
