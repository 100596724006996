import React from "react";
import { Table, Button } from "semantic-ui-react";
import Product from "domain/model/Product";

type Props = {
  product: Product;
  handleClickEdit: () => void;
  index: number;
};

export const ProductItem: React.FC<Props> = ({
  product,
  handleClickEdit,
  index,
}) => {
  return (
    <Table.Row key={index}>
      <Table.Cell>{product.code}</Table.Cell>
      <Table.Cell>{product.name}</Table.Cell>
      <Table.Cell>{product.display ? "公開" : "非公開"}</Table.Cell>
      <Table.Cell>
        {product.productCategories.map(({ name }) => name).join(", ")}
      </Table.Cell>
      <Table.Cell>{product.price && `¥${product.price}`}</Table.Cell>
      <Table.Cell css={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
        {product.description}
      </Table.Cell>
      <Table.Cell>
        <Button onClick={handleClickEdit} color="blue" content="編集" />
      </Table.Cell>
    </Table.Row>
  );
};
