import api from "utils/api";
import endpoint from "utils/endpoint";

class RettyRepository {
  /**
   * 代理店ユーザーだけ利用可能
   */
  public async update({
    isAgency,
    storeId,
    rettyUrl,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    rettyUrl: string;
  }) {
    const url =
      isAgency && storeId
        ? endpoint.agency.retty.update(storeId)
        : endpoint.store.retty.update();

    const body = {
      retty: {
        url: rettyUrl,
      },
    };

    return api.put(url, body);
  }
}

export default new RettyRepository();
