import React from "react";

import PageContainer from "components/lib/PageContainer";
import { Segment, Loader, Header, Message } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { UserContainer } from "containers";
import { StoreIdParam } from "config/raven";
import { BasicAuthPreferencesForm } from "components/SiteManage/Content/BasicAuth/BasicAuthPreferencesForm";
import { basicAuthRepository } from "domain/repository";
import { useMessage } from "hooks/useMessage";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { useBasicAuthPreferences } from "hooks/useBasicAuthPreferences";
import OptionStatus from "components/lib/OptionStatus";
import { BasicAuthPreferences } from "domain/model/BasicAuthPreferences";

type Props = {
  storeId: string;
};

const Container: React.FC<Props> = ({ storeId }) => {
  const { basicAuthPreferences } = useBasicAuthPreferences({ storeId });
  const { renderMessage, setErrorMessage, setSuccessMessage } = useMessage({
    messageSize: "tiny",
  });
  const handleClickEnable = () => {
    if (!storeId) {
      return;
    }
    basicAuthRepository.enable(storeId).then((res) => {
      if (res.data?.error) {
        alert(res.data.error);
      } else {
        alert("有効にしました");
        window.location.reload();
      }
    });
  };
  const handleUpdate = (basicAuthPreferences: BasicAuthPreferences) => {
    if (!storeId) throw Error("storeId is undefined");

    return basicAuthRepository
      .update({
        ...basicAuthPreferences,
        // 空欄は無視
        privatePages: basicAuthPreferences.privatePages?.filter((i) => !!i),
        storeId,
      })
      .then(() => {
        setSuccessMessage("更新しました");
      })
      .catch(() => {
        setErrorMessage("更新に失敗しました");
      });
  };

  if (basicAuthPreferences === null) {
    return <Loader active />;
  }

  return (
    <>
      {renderMessage()}
      <Segment padded>
        <Header content={`BASIC認証オプション有効状況`} dividing />
        <OptionStatus
          isAgency={true}
          enabled={basicAuthPreferences !== false}
          toEnable={handleClickEnable}
        />
      </Segment>
      {basicAuthPreferences !== false ? (
        <Segment padded>
          <BasicAuthPreferencesForm
            basicAuthPreferences={basicAuthPreferences}
            handleUpdate={handleUpdate}
          />
        </Segment>
      ) : null}
    </>
  );
};

export const BasicAuth: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="BASIC認証設定"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("BASIC認証設定")}
    >
      <Segment>
        <Container storeId={storeId} />
      </Segment>
    </PageContainer>
  );
};
