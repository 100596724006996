import React from "react";
import { generatePath, useParams, useHistory } from "react-router-dom";

import { Button } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { MediaNewForm } from "components/Media/New/MediaNewForm";

import { UserContainer } from "containers";
import { mediaRepository } from "domain/repository";

import routes from "utils/routes";
import { StoreIdParam } from "config/raven";

export const MediaNew = () => {
  const { storeId } = useParams<StoreIdParam>();
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;

  const history = useHistory();

  const createMedia = (files: File[]) =>
    mediaRepository.createMedias(isAgency, storeId, files);

  return (
    <PageContainer
      header="画像追加"
      action={
        <Button
          color="blue"
          content="画像一覧"
          onClick={() =>
            history.push(
              generatePath(
                routes[isAgency ? "agency" : "store"].mediaIndex.pathname,
                { storeId }
              )
            )
          }
        />
      }
    >
      <MediaNewForm createMedia={createMedia} />
    </PageContainer>
  );
};
