import React, { useState, useEffect } from "react";

import {
  Button,
  Table,
  Image,
  Message,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import dayjs from "dayjs";

import tabelogTakeout from "img/tabelogTakeout.png";
import epark from "img/epark.png";
import menu from "img/menu.png";
import demaecan from "img/demaecan.png";
import toreta from "img/toreta.png";
import { externalTakeoutRepository } from "domain/repository";
import { externalTakeoutService } from "domain/service";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
};

export const TakeoutSalesContainer: React.FC<Props> = ({
  isAgency,
  storeId,
}) => {
  const [isSyncOk, setIsSyncOk] = useState<boolean | null>(null);

  const [tabelogLatestUpdated, setTabelogLatestUpdated] = useState<
    number | null
  >(0);
  const tabelogLatestUpdatedAtMsg = tabelogLatestUpdated
    ? `${dayjs(tabelogLatestUpdated * 1000).format("YYYY-MM-DD HH:mm")}`
    : "";

  const [eparkLatestUpdated, setEparkLatestUpdated] = useState<number | null>(
    0
  );
  const eparkLatestUpdatedAtMsg = eparkLatestUpdated
    ? `${dayjs(eparkLatestUpdated * 1000).format("YYYY-MM-DD HH:mm")}`
    : "";

  const [menuLatestUpdated, setMenuLatestUpdated] = useState<number | null>(0);
  const menuLatestUpdatedAtMsg = menuLatestUpdated
    ? `${dayjs(menuLatestUpdated * 1000).format("YYYY-MM-DD HH:mm")}`
    : "";

  const [demaecanLatestUpdated, setDemaecanLatestUpdated] = useState<
    number | null
  >(0);
  const demaecanLatestUpdatedAtMsg = demaecanLatestUpdated
    ? `${dayjs(demaecanLatestUpdated * 1000).format("YYYY-MM-DD HH:mm")}`
    : "";

  const [toretaLatestUpdated, setToretaLatestUpdated] = useState<number | null>(
    0
  );
  const toretaLatestUpdatedAtMsg = toretaLatestUpdated
    ? `${dayjs(toretaLatestUpdated * 1000).format("YYYY-MM-DD HH:mm")}`
    : "";

  // 2021年4月1日から取得
  const [startDate, setStartDate] = useState<number>(
    dayjs("2021-04-01").unix()
  );

  const nowDate = dayjs().unix();

  const createSyncTakeoutTabelog = () => {
    externalTakeoutRepository
      .syncTakeout({
        isAgency,
        storeId,
        takeoutSite: "tabelog",
        fromDate: startDate,
        toDate: nowDate,
      })
      .then(() => {
        setIsSyncOk(true);
      })
      .catch(() =>
        nowDate === startDate
          ? nowDate > startDate
            ? (setStartDate(startDate + 2678400), createSyncTakeoutTabelog())
            : (setStartDate(nowDate), createSyncTakeoutTabelog())
          : (setIsSyncOk(false), setIsLoading(false))
      );
  };
  const createSyncTakeoutEpark = () => {
    externalTakeoutRepository
      .syncTakeout({
        isAgency,
        storeId,
        takeoutSite: "epark",
        fromDate: startDate,
        toDate: nowDate,
      })
      .then(() => {
        setIsSyncOk(true);
      })
      .catch(() =>
        nowDate === startDate
          ? nowDate > startDate
            ? (setStartDate(startDate + 2678400), createSyncTakeoutEpark())
            : (setStartDate(nowDate), createSyncTakeoutEpark())
          : (setIsSyncOk(false), setIsLoading(false))
      );
  };
  const createSyncTakeoutMenu = () => {
    externalTakeoutRepository
      .syncTakeout({
        isAgency,
        storeId,
        takeoutSite: "menu",
        fromDate: nowDate,
        toDate: nowDate,
      })
      .then(() => {
        setIsSyncOk(true);
      })
      .catch(() =>
        nowDate === startDate
          ? nowDate > startDate
            ? (setStartDate(startDate + 2678400), createSyncTakeoutMenu())
            : (setStartDate(nowDate), createSyncTakeoutMenu())
          : (setIsSyncOk(false), setIsLoading(false))
      );
  };
  const createSyncTakeoutDemaecan = () => {
    externalTakeoutRepository
      .syncTakeout({
        isAgency,
        storeId,
        takeoutSite: "demaecan",
        fromDate: nowDate,
        toDate: nowDate,
      })
      .then(() => {
        setIsSyncOk(true);
      })
      .catch(() =>
        nowDate === startDate
          ? nowDate > startDate
            ? (setStartDate(startDate + 2678400), createSyncTakeoutDemaecan())
            : (setStartDate(nowDate), createSyncTakeoutDemaecan())
          : (setIsSyncOk(false), setIsLoading(false))
      );
  };

  const createSyncTakeoutToreta = () => {
    externalTakeoutRepository
      .syncTakeout({
        isAgency,
        storeId,
        takeoutSite: "toreta",
        fromDate: nowDate,
        toDate: nowDate,
      })
      .then(() => {
        setIsSyncOk(true);
      })
      .catch(() =>
        nowDate === startDate
          ? nowDate > startDate
            ? (setStartDate(startDate + 2678400), createSyncTakeoutToreta())
            : (setStartDate(nowDate), createSyncTakeoutToreta())
          : (setIsSyncOk(false), setIsLoading(false))
      );
  };

  const [isTakeoutTabelog, setIsTakeoutTabelog] = useState<boolean | null>(
    null
  );
  const [isTakeoutEpark, setIsTakeoutEpark] = useState<boolean | null>(null);
  const [isTakeoutMenu, setIsTakeoutMenu] = useState<boolean | null>(null);
  const [isTakeoutDemaecan, setIsTakeoutDemaecan] = useState<boolean | null>(
    null
  );
  const [isTakeoutToreta, setIsTakeoutToreta] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchTakeoutLogin = () => {
      externalTakeoutService
        .fetchTakeoutLogin({ isAgency, storeId })
        .then((res) => {
          res.forEach((takeoutLogin) => {
            if (takeoutLogin.site === "tabelog") {
              setIsTakeoutTabelog(takeoutLogin.use);
            }
            if (takeoutLogin.site === "epark") {
              setIsTakeoutEpark(takeoutLogin.use);
            }
            if (takeoutLogin.site === "menu") {
              setIsTakeoutMenu(takeoutLogin.use);
            }
            if (takeoutLogin.site === "demaecan") {
              setIsTakeoutDemaecan(takeoutLogin.use);
            }
            if (takeoutLogin.site === "toreta") {
              setIsTakeoutToreta(takeoutLogin.use);
            }
          });
        });
    };
    fetchTakeoutLogin();
  }, [isAgency, storeId]);

  useEffect(() => {
    const fetchTakeoutSalesUpdated = () => {
      externalTakeoutService
        .fetchTakeoutSalesUpdated({
          isAgency,
          storeId,
        })
        .then((res) => {
          res.forEach((fetchTakeoutSalesUpdated) => {
            if (fetchTakeoutSalesUpdated.site === "tabelog") {
              setTabelogLatestUpdated(fetchTakeoutSalesUpdated.latestUpdated);
            }
            if (fetchTakeoutSalesUpdated.site === "epark") {
              setEparkLatestUpdated(fetchTakeoutSalesUpdated.latestUpdated);
            }
            if (fetchTakeoutSalesUpdated.site === "menu") {
              setMenuLatestUpdated(fetchTakeoutSalesUpdated.latestUpdated);
            }
            if (fetchTakeoutSalesUpdated.site === "demaecan") {
              setDemaecanLatestUpdated(fetchTakeoutSalesUpdated.latestUpdated);
            }
            if (fetchTakeoutSalesUpdated.site === "toreta") {
              setToretaLatestUpdated(fetchTakeoutSalesUpdated.latestUpdated);
            }
          });
        });
    };
    fetchTakeoutSalesUpdated();
  }, [isAgency, storeId]);

  const [isLoading, setIsLoading] = useState(false);
  const [siteMessage, setSiteMessage] = useState<string | null>("");

  const SyncMessage = () => {
    if (isSyncOk === null) {
      return null;
    }
    if (isSyncOk) {
      setIsLoading(false);
      return (
        <>
          <Message
            positive
            content={`${siteMessage}とのデータ同期が完了しました`}
          />
        </>
      );
    }
    setIsLoading(false);
    return (
      <Message
        negative
        content={`${siteMessage}とのデータ同期に失敗しました`}
      />
    );
  };

  const Loading = () => {
    if (isLoading) {
      return (
        <>
          <Dimmer active>
            <Loader active content="データ同期中" size="large"></Loader>
          </Dimmer>
        </>
      );
    } else return null;
  };

  return (
    <>
      <Table stackable>
        <Table.Header>
          <Table.HeaderCell colSpan="5">
            <h3>連携サービス一括入稿</h3>
          </Table.HeaderCell>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>サービスロゴ</Table.Cell>
            <Table.Cell>連携サービス名</Table.Cell>
            <Table.Cell>サービス業種</Table.Cell>
            <Table.Cell>最終更新日時</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Image src={tabelogTakeout} size="tiny" />
            </Table.Cell>
            <Table.Cell>食べログTakeout</Table.Cell>
            <Table.Cell>飲食</Table.Cell>
            <Table.Cell>{tabelogLatestUpdatedAtMsg}</Table.Cell>
            <Table.Cell>
              {isTakeoutTabelog ? (
                <Button
                  color="blue"
                  value="tabelog"
                  onClick={() => {
                    setIsLoading(true);
                    setSiteMessage("食べログTakeout");
                    createSyncTakeoutTabelog();
                  }}
                >
                  <Loading />
                  最新データに更新
                </Button>
              ) : (
                <Button
                  color="red"
                  onClick={() => {
                    setIsLoading(true);
                    setSiteMessage("食べログTakeout");
                    createSyncTakeoutTabelog();
                  }}
                >
                  <Loading />
                  未連携
                </Button>
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <Image src={epark} size="tiny" />
            </Table.Cell>
            <Table.Cell>EPARK</Table.Cell>
            <Table.Cell>飲食</Table.Cell>
            <Table.Cell>{eparkLatestUpdatedAtMsg}</Table.Cell>
            <Table.Cell>
              {isTakeoutEpark ? (
                <Button
                  color="blue"
                  value="epark"
                  onClick={() => {
                    setIsLoading(true);
                    setSiteMessage("EPARK");
                    createSyncTakeoutEpark();
                  }}
                >
                  <Loading />
                  最新データに更新
                </Button>
              ) : (
                <Button
                  color="red"
                  onClick={() => {
                    setIsLoading(true);
                    setSiteMessage("EPARK");
                    createSyncTakeoutEpark();
                  }}
                >
                  <Loading />
                  未連携
                </Button>
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <Image src={menu} size="tiny" />
            </Table.Cell>
            <Table.Cell>menu</Table.Cell>
            <Table.Cell>飲食</Table.Cell>
            <Table.Cell>{menuLatestUpdatedAtMsg}</Table.Cell>
            <Table.Cell>
              {isTakeoutMenu ? (
                <Button
                  color="blue"
                  value="menu"
                  onClick={() => {
                    setIsLoading(true);
                    setSiteMessage("Menu");
                    createSyncTakeoutMenu();
                  }}
                >
                  <Loading />
                  最新データに更新
                </Button>
              ) : (
                <Button
                  color="red"
                  onClick={() => {
                    setIsLoading(true);
                    setSiteMessage("Menu");
                    createSyncTakeoutMenu();
                  }}
                >
                  <Loading />
                  未連携
                </Button>
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <Image src={demaecan} size="tiny" />
            </Table.Cell>
            <Table.Cell>出前舘</Table.Cell>
            <Table.Cell>飲食</Table.Cell>
            <Table.Cell>{demaecanLatestUpdatedAtMsg}</Table.Cell>
            <Table.Cell>
              {isTakeoutDemaecan ? (
                <Button
                  color="blue"
                  value="demaecan"
                  onClick={() => {
                    setIsLoading(true);
                    setSiteMessage("Demaecan");
                    createSyncTakeoutDemaecan();
                  }}
                >
                  <Loading />
                  最新データに更新
                </Button>
              ) : (
                <Button
                  color="red"
                  onClick={() => {
                    setIsLoading(true);
                    setSiteMessage("Demaecan");
                    createSyncTakeoutDemaecan();
                  }}
                >
                  <Loading />
                  未連携
                </Button>
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <Image src={toreta} size="tiny" />
            </Table.Cell>
            <Table.Cell>トレタ</Table.Cell>
            <Table.Cell>飲食</Table.Cell>
            <Table.Cell>{toretaLatestUpdatedAtMsg}</Table.Cell>
            <Table.Cell>
              {isTakeoutToreta ? (
                <Button
                  color="blue"
                  value="toreta"
                  onClick={() => {
                    setIsLoading(true);
                    setSiteMessage("Toreta");
                    createSyncTakeoutToreta();
                  }}
                >
                  <Loading />
                  最新データに更新
                </Button>
              ) : (
                <Button
                  color="red"
                  onClick={() => {
                    setIsLoading(true);
                    setSiteMessage("Toreta");
                    createSyncTakeoutToreta();
                  }}
                >
                  <Loading />
                  未連携
                </Button>
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <SyncMessage />
    </>
  );
};
