import React from "react";

import { Grid, Header, Modal, Button } from "semantic-ui-react";

import MenuItem from "components/lib/MenuItem";

import { FormTypes, FormType } from "domain/model/ContactFormTypes";

type Props = {
  setSelectedType: React.Dispatch<FormType>;
  setOpen: React.Dispatch<boolean>;
};

export const NewFormModalMenu: React.FC<Props> = ({
  setSelectedType,
  setOpen,
}) => {
  return (
    <>
      <Modal.Header>フォーム追加</Modal.Header>
      <Modal.Content>
        <Header content="フォームの種類を選択してください" color="grey" />
        <Grid columns="2">
          <Grid.Row>
            <Grid.Column>
              <MenuItem
                icon="language"
                header="一行テキスト"
                content="氏名,住所など"
                color="brown"
                onClick={() => {
                  setSelectedType(FormTypes.SINGLE_LINE_TEXT);
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <MenuItem
                icon="align left"
                header="複数行テキスト"
                content="問い合せ内容など"
                color="red"
                onClick={() => {
                  setSelectedType(FormTypes.MULTIPLE_LINE_TEXT);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <MenuItem
                icon="at"
                header="メールアドレス"
                content="ユーザーのメールアドレスなど"
                color="yellow"
                onClick={() => {
                  setSelectedType(FormTypes.EMAIL);
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <MenuItem
                icon="caret square down"
                header="セレクトボックス(一つのみ選択)"
                content="地域選択など"
                color="purple"
                onClick={() => {
                  setSelectedType(FormTypes.SELECT);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <MenuItem
                icon="dot circle outline"
                header="ラジオボタン(一つのみ選択)"
                content="アンケートなど"
                color="green"
                onClick={() => {
                  setSelectedType(FormTypes.RADIO);
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <MenuItem
                icon="calendar"
                header="日付"
                content="希望日時など"
                color="blue"
                onClick={() => {
                  setSelectedType(FormTypes.DATE);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <MenuItem
                icon="check"
                header="チェックボックス(複数選択)"
                content="アンケートなど"
                color="orange"
                onClick={() => {
                  setSelectedType(FormTypes.CHECKBOX);
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <div css={{ textAlign: "center" }}>
          <Button
            color="blue"
            content="閉じる"
            onClick={() => setOpen(false)}
          />
        </div>
      </Modal.Actions>
    </>
  );
};
