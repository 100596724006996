import React, { useState } from "react";
import { Form, Message, Loader } from "semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";
import MESSAGE from "config/message.json";
import { userRepository } from "domain/repository";
import { useHistory } from "react-router-dom";
import routes from "utils/routes";
import SegmentWithHeader from "components/lib/SegmentWithHeader";

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

const initialValues: FormValues = {
  password: "",
  passwordConfirmation: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  passwordConfirmation: Yup.string()
    .required(MESSAGE.ERROR.NO_INPUT)
    .oneOf([Yup.ref("password")], "パスワードが一致していません。"),
});

type Props = {
  token: string;
};

const ResetForm: React.FC<Props> = ({ token }) => {
  const [sending, setSending] = useState(false);
  const history = useHistory();
  return (
    <SegmentWithHeader
      headerContent="パスワード再設定"
      headerText="Reset your password"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          setSending(true);
          actions.resetForm();
          userRepository
            .resetPasword(token, values.password, values.passwordConfirmation)
            .then(() => {
              actions.setStatus({
                success:
                  "パスワードを再設定しました。ログイン画面に移動します。 ",
              });
              setTimeout(() => {
                history.push(routes.common.signInAgency.pathname);
              }, 2000);
            })
            .catch(() => {
              actions.setStatus({
                error: "更新に失敗しました。再度メールを送信してください。",
              });
              setTimeout(() => {
                history.push(routes.common.passwordResetRequest.pathname);
              }, 3000);
            })
            .finally(() => {
              setSending(false);
            });
        }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ status, handleChange, handleSubmit, values, errors }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Input
                name="password"
                type="password"
                label="新しいパスワード"
                value={values.password}
                onChange={handleChange}
                error={errors.password}
                disabled={sending}
              ></Form.Input>
              <Form.Input
                name="passwordConfirmation"
                type="password"
                label="新しいパスワード(確認)"
                value={values.passwordConfirmation}
                onChange={handleChange}
                error={errors.passwordConfirmation}
                disabled={sending}
              ></Form.Input>
              <div css={{ textAlign: "center" }}>
                <Form.Button
                  disabled={sending}
                  type="submit"
                  content="設定"
                  color="blue"
                ></Form.Button>
              </div>
            </Form>
            {status && status.success && (
              <Message success content={status.success} />
            )}
            {status && status.error && <Message error content={status.error} />}
            <Loader active={sending} content="設定中"></Loader>
          </>
        )}
      </Formik>
    </SegmentWithHeader>
  );
};

export default ResetForm;
