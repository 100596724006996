import React from "react";

import { Table, Message } from "semantic-ui-react";
import Agency from "domain/model/Agency";

type Props = {
  agencies: Agency[];
};

const AgencyList: React.FC<Props> = ({ agencies }) => {
  return agencies.length > 0 ? (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell css={{ width: "6rem" }}>代理店ID</Table.HeaderCell>
          <Table.HeaderCell>代理店名</Table.HeaderCell>
          <Table.HeaderCell>メールアドレス</Table.HeaderCell>
          <Table.HeaderCell>最終更新日</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {agencies.map((agency) => (
          <Table.Row key={agency.id}>
            <Table.Cell>{agency.id}</Table.Cell>
            <Table.Cell>{agency.name}</Table.Cell>
            <Table.Cell>{agency.email}</Table.Cell>
            <Table.Cell>{agency.updatedAt}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Message error content="データが存在しません。" />
  );
};

export default AgencyList;
