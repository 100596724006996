import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Message } from "semantic-ui-react";
import MESSAGE from "config/message.json";
import { ReplyType, ReplyTypes } from "domain/model/JobOffer/FormDetails";

export const SubmitTypes = {
  CREATE: "作成",
  UPDATE: "更新",
} as const;

type SubmitType = (typeof SubmitTypes)[keyof typeof SubmitTypes];

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string()
    .email(MESSAGE.ERROR.INVALID_EMAIL)
    .required(MESSAGE.ERROR.NO_INPUT),
  redirectUrl: Yup.string()
    .url(MESSAGE.ERROR.INVALID_URL)
    .required(MESSAGE.ERROR.NO_INPUT),
  replyType: Yup.string().required(),
  replySubject: Yup.string(),
  replyMessage: Yup.string(),
});

type FormValues = {
  name: string;
  email: string;
  redirectUrl: string;
  replyType: ReplyType;
  replySubject: string;
  replyMessage: string;
};

type Props = {
  initialValues: FormValues;
  handleSubmit: ({
    values,
    setStatus,
    resetForm,
  }: {
    values: FormValues;
    setStatus: (status: { success: string; error: string }) => void;
    resetForm: () => void;
  }) => void;
  submitType: SubmitType;
};

export const JobOfferForm: React.FC<Props> = ({
  initialValues,
  handleSubmit,
  submitType,
}) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setStatus, resetForm }) => {
      handleSubmit({ values, setStatus, resetForm });
    },
    initialStatus: { success: "", error: "" },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Input
        name="name"
        label="募集職種名"
        error={formik.errors.name}
        value={formik.values.name}
        onChange={formik.handleChange}
        placeholder="エンジニア、営業、経理など"
      />
      <Form.Field required>
        <label>受信用メールアドレス</label>
        <Form.Input
          name="email"
          error={formik.errors.email}
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder="contact@mail.com"
        />
      </Form.Field>
      <Form.Field required>
        <label>リダイレクト先URL</label>
        <Form.Input
          name="redirectUrl"
          type="url"
          error={formik.errors.redirectUrl}
          value={formik.values.redirectUrl}
          onChange={formik.handleChange}
          placeholder="https://example.com"
        />
      </Form.Field>
      <Form.Field
        name="replyType"
        control="select"
        label="返信方法"
        value={formik.values.replyType}
        error={formik.errors.replyType}
        onChange={formik.handleChange}
      >
        <option value={ReplyTypes.EMAIL}>Eメールで返信</option>
        <option value={ReplyTypes.SMS}>SMSで返信</option>
      </Form.Field>
      {formik.values.replyType === ReplyTypes.EMAIL && (
        <Form.Input
          name="replySubject"
          label="送信タイトル"
          error={formik.errors.replySubject}
          value={formik.values.replySubject}
          onChange={formik.handleChange}
          placeholder="お申し込みありがとうございます"
        />
      )}
      <Form.Field
        control="textarea"
        name="replyMessage"
        label="送信メッセージ"
        error={formik.errors.replyMessage}
        value={formik.values.replyMessage}
        onChange={formik.handleChange}
        placeholder={`
__APPLICANTS_NAME__ 様

このたびは弊社求人にご応募いただき、
誠にありがとうございます。
        `}
      />
      <Message
        list={[
          "__APPLICANTS_NAME__ : 応募者名として埋め込まれます",
          "__STORE_NAME__ : 店舗名として埋め込まれます",
        ]}
      />
      <div css={{ textAlign: "center" }}>
        <Form.Button
          type="submit"
          color="blue"
          content={submitType}
          size="medium"
        />
      </div>
      {formik.status.error && <Message error content={formik.status.error} />}
    </Form>
  );
};
