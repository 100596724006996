import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Message, Loader } from "semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";
import routes from "utils/routes";
import MESSAGE from "config/message.json";
import { userService } from "domain/service";
import SegmentWithHeader from "components/lib/SegmentWithHeader";

type FormValues = {
  email: string;
};

const initialValues: FormValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(MESSAGE.ERROR.INVALID_EMAIL)
    .required(MESSAGE.ERROR.NO_INPUT),
});

const ResetForm = () => {
  const [sending, setSending] = useState(false);
  return (
    <SegmentWithHeader
      headerContent="パスワード再設定"
      headerText="Reset your password"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          setSending(true);
          actions.resetForm();
          userService
            .requestPassWordReset(values.email)
            .then(() => {
              actions.setStatus({ success: "メールを送信しました" });
            })
            .catch(() => {
              actions.setStatus({ error: "送信に失敗しました" });
            })
            .finally(() => {
              setSending(false);
            });
        }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ status, handleChange, handleSubmit, values, errors }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Input
                name="email"
                label="メールアドレス"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
                disabled={sending}
              ></Form.Input>
              <div css={{ textAlign: "right" }}>
                <Link
                  to={routes.common.signInAgency.pathname}
                  css={{
                    display: "inline-block",
                    color: "royalblue",
                    marginBottom: "1.3rem",
                  }}
                >
                  ログインはこちら
                </Link>
              </div>
              <div css={{ textAlign: "center" }}>
                <Form.Button
                  disabled={sending}
                  type="submit"
                  content="送信"
                  color="blue"
                ></Form.Button>
              </div>
            </Form>
            {status && status.success && (
              <Message success content={status.success} />
            )}
            {status && status.error && <Message error content={status.error} />}
            <Loader active={sending} content="送信中"></Loader>
          </>
        )}
      </Formik>
    </SegmentWithHeader>
  );
};

export default ResetForm;
