import api from "utils/api";
import endpoint from "utils/endpoint";

class TabelogRepository {
  /**
   * 代理店ユーザーだけ利用可能
   */
  public async enable(isAgency: boolean, storeId: string) {
    const url = endpoint.agency.tabelog.use(storeId);
    return api.post(url);
  }

  public async update({
    isAgency,
    storeId,
    tabelogUrl,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    tabelogUrl: string;
  }) {
    const url =
      isAgency && storeId
        ? endpoint.agency.tabelog.update(storeId)
        : endpoint.store.tabelog.update();

    const body = {
      tabelog: {
        url: tabelogUrl,
      },
    };

    return api.put(url, body);
  }
}

export default new TabelogRepository();
