import React, { useEffect, ReactNode } from "react";
import { useParams } from "react-router-dom";

import { StoreContainer, UserContainer } from "containers";
import { Loader } from "semantic-ui-react";
import { StoreIdParam } from "config/raven";

type Props = {
  children: ReactNode;
};

const SetCurrentStore: React.FC<Props> = ({ children }) => {
  const storeContainer = StoreContainer.useContainer();
  const userContainer = UserContainer.useContainer();

  const { currentStore, changeCurrentStore } = storeContainer;
  const { userType } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  useEffect(() => {
    if (userType === "agency") {
      if (!currentStore || currentStore.id !== Number(storeId)) {
        changeCurrentStore(true, storeId);
      }
    } else {
      changeCurrentStore(false, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !currentStore ? (
    <Loader active content="店舗情報取得中..." />
  ) : (
    <>{children}</>
  );
};

export default SetCurrentStore;
