import React from "react";
import { Pagination } from "semantic-ui-react";

type Props = {
  active: number;
  total: number;
  handleChange: Function;
};

const MyPagination: React.FC<Props> = ({ active, total, handleChange }) => {
  return (
    <div css={{ display: total === 0 ? "none" : "block" }}>
      <Pagination
        defaultActivePage={active}
        totalPages={total}
        active={active}
        onPageChange={(_, { activePage }) => handleChange(Number(activePage))}
      />
    </div>
  );
};

export default MyPagination;
