import React from "react";
import Iframe from "./Iframe";

export default ({ html }) => {
  return (
    <Iframe
      title="サイトプレビュー"
      id="iframe"
      srcDoc={html}
      onBlur={(e) => {}}
    ></Iframe>
  );
};
