const loginUrls = {
  // NOTE: `dev.sitest.jp`` has IP address based ACL.
  development: "http://dev.sitest.jp/users/teory_login",
  production: "https://sitest.jp/users/teory_login",
};

export const getLoginUrl = (): string => {
  if (
    process.env.NODE_ENV === "development" ||
    window.location.hostname === "v2.staging.ccmphp.com"
  ) {
    return loginUrls.development;
  } else {
    return loginUrls.production;
  }
};

/**
 * v1のコードをそのまま持ってきたので、良い方法があれば移行したい
 * @param shopId Raven内では、sitest_shop_id にあたる
 * @param userId Raven内では、sitest_id にあたる
 */
export const autoLogin = (shopId: string, userId: string) => {
  const form = document.createElement("form");
  form.target = "analysis";
  form.action = getLoginUrl();
  form.method = "post";

  const input1 = document.createElement("input");
  input1.type = "hidden";
  input1.name = "shop_id";
  input1.value = shopId;
  const input2 = document.createElement("input");
  input2.type = "hidden";
  input2.name = "user_id";
  input2.value = userId;

  form.appendChild(input1);
  form.appendChild(input2);
  document.body.appendChild(form);

  form.submit();
};
