import { Expose } from "class-transformer";

export default class Notification {
  @Expose() id: number;
  @Expose() title: string;
  @Expose() content: string;

  @Expose({ name: "created_at" })
  createdAt: string;

  @Expose({ name: "updated_at" })
  updatedAt: string;
}
