import React, { useEffect, useState } from "react";
import { Button, Image, Modal } from "semantic-ui-react";
import Media from "domain/model/Media";
import { mediaService } from "domain/service";
import Pagination from "components/lib/Pagination";
import { generatePath, Link } from "react-router-dom";
import routes from "utils/routes";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
  onClose: () => void;
  selectImage: (url: string) => void;
};

export const MediaModal: React.FC<Props> = ({
  isAgency,
  storeId,
  onClose,
  selectImage,
}) => {
  const [medias, setMedias] = useState<Media[]>([]);
  const [totalPageNum, setTotalPageNum] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(1);

  useEffect(() => {
    mediaService
      .fetchMedias(isAgency, storeId, true, activePage)
      .then((res) => {
        setMedias(res.medias);
        setTotalPageNum(res.total);
      });
  }, [activePage, isAgency, storeId]);

  return (
    <Modal open={true}>
      <Modal.Header icon="pencil alternate" content={`画像`} />
      <Modal.Content>
        <div css={{ display: "flex", flexWrap: "wrap", marginBottom: 16 }}>
          {medias.map((media) => (
            <div
              key={media.id}
              css={{
                marginRight: 8,
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => {
                selectImage(media.url);
                onClose();
              }}
            >
              <Image
                src={media.url}
                size="small"
                css={{
                  cursor: "pointer",
                  transition: "opacity 0.25s ease",
                  ":hover": { opacity: 0.8 },
                }}
              />
            </div>
          ))}
        </div>
        {medias.length === 0 && (
          <div>
            画像が登録されていません。
            <br />
            <Link
              to={generatePath(
                routes[isAgency ? "agency" : "store"].mediaIndex.pathname,
                { storeId }
              )}
            >
              メディア管理
            </Link>
            から登録してください。
          </div>
        )}
        <div css={{ textAlign: "center" }}>
          <Pagination
            active={activePage}
            total={totalPageNum}
            handleChange={setActivePage}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button content="閉じる" onClick={onClose} type="button" />
      </Modal.Actions>
    </Modal>
  );
};
