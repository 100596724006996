import { Expose, Transform } from "class-transformer";
import dayjs from "dayjs";

import { SocialService } from "config/raven";

export default class SocialAccount {
  @Expose() id: number;
  @Expose() provider: SocialService;
  @Expose() name: string;
  @Expose({ name: "provider_profile" }) providerProfile: string;

  @Expose({ name: "created_at" })
  @Transform((value) => dayjs(value).format("YYYY-MM-DD"))
  createdAt: Date;

  @Expose({ name: "updated_at" })
  @Transform((value) => dayjs(value).format("YYYY-MM-DD"))
  updatedAt: Date;
}
