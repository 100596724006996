import api from "utils/api";
import endpoint from "utils/endpoint";

type NewYamlParams = {
  title: string;
  slug: string;
  body: string;
  parent: string;
  active: boolean;
};
type UpdateYamlParams = {
  body: string;
  active: boolean;
};

class YamlRepository {
  async createYaml(
    isAgency: boolean,
    storeId: string | undefined,
    params: NewYamlParams
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.yaml.create(storeId)
        : endpoint.store.yaml.create();

    return api.post(url, { yaml: params });
  }

  async updateYaml(
    isAgency: boolean,
    storeId: string | undefined,
    id: string,
    params: UpdateYamlParams
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.yaml.update(storeId, id)
        : endpoint.store.yaml.update(id);

    return api.patch(url, { yaml: params });
  }
}

export default new YamlRepository();
