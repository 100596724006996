import React from "react";
import { useParams, generatePath, useHistory } from "react-router-dom";

import PageContainer from "components/lib/PageContainer";
import { Button } from "semantic-ui-react";

import { Container } from "components/SiteManage/Content/Yaml/Index/Container";
import { UserContainer } from "containers";
import { StoreIdParam } from "config/raven";
import routes from "utils/routes";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const YamlIndex = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();
  const history = useHistory();

  const getContents = () => {
    // テンプレート
    return (
      <>
        <Container isAgency={isAgency} storeId={storeId} />
      </>
    );
  };

  return (
    <PageContainer
      header="YAML管理"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("YAML管理")}
      action={
        <Button
          color="blue"
          content="新規作成"
          onClick={() => {
            const newFormUrl = isAgency
              ? generatePath(routes.agency.yamlNew.pathname, {
                  storeId,
                })
              : routes.store.yamlNew.pathname;
            history.push(newFormUrl);
          }}
        />
      }
    >
      {getContents()}
    </PageContainer>
  );
};
