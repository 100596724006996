import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import Form from "domain/model/JobOffer/Form";
import { FormContent } from "domain/model/ContactFormTypes";

class ContactRepository {
  async createContactForm({
    isAgency,
    storeId,
    formName,
    pubEmail,
    forms,
    redirectUrl,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
  } & FormContent) {
    const url =
      isAgency && storeId
        ? endpoint.agency.contact.forms(storeId)
        : endpoint.store.contact.forms();

    const body = {
      contact_form: {
        name: formName || "名称未設定",
        pub_email: pubEmail,
        form: forms,
        redirect_to: redirectUrl,
        contact_type: "common",
      },
    };

    return api.post(url, body).then((res) => {
      return plainToClass(Form, res.data.form, {
        excludeExtraneousValues: true,
      });
    });
  }
  async updateContactForm({
    isAgency,
    storeId,
    formName,
    pubEmail,
    forms,
    redirectUrl,
    formId,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    formId: string;
  } & FormContent) {
    const url =
      isAgency && storeId
        ? endpoint.agency.contact.formShow(formId, storeId)
        : endpoint.store.contact.formShow(formId);

    const body = {
      contact_form: {
        name: formName || "名称未設定",
        pub_email: pubEmail,
        form: forms,
        redirect_to: redirectUrl,
        contact_type: "common",
      },
    };

    return api.put(url, body).then((res) => {
      return plainToClass(Form, res.data.form, {
        excludeExtraneousValues: true,
      });
    });
  }

  async deleteContactForm({
    storeId,
    formId,
  }: {
    storeId: string | undefined;
    formId: string;
  }) {
    const url = storeId
      ? endpoint.agency.contact.formShow(formId, storeId)
      : endpoint.store.contact.formShow(formId);

    return api.delete(url);
  }
}

export default new ContactRepository();
