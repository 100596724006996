import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { useMediaQuery } from "react-responsive";

import { Table, Image, Checkbox, Message } from "semantic-ui-react";

import Media from "domain/model/Media";

type Props = {
  medias: Media[];
  selectsMediaIds: number[];
  setSelectsMediaIds: Function;
};

export const MediaList: React.FC<Props> = ({
  medias,
  selectsMediaIds,
  setSelectsMediaIds,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return medias.length === 0 ? (
    <Message error content="データが存在しません。" />
  ) : (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <Checkbox
              checked={
                medias.length > 0 && medias.length === selectsMediaIds.length
              }
              onClick={() =>
                medias.length === selectsMediaIds.length
                  ? setSelectsMediaIds([])
                  : setSelectsMediaIds(medias.map((media) => media.id))
              }
            />
          </Table.HeaderCell>
          <Table.HeaderCell>サムネイル</Table.HeaderCell>
          <Table.HeaderCell>作成日</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {medias.map((media, index) => (
          <Table.Row key={index}>
            <Table.Cell>
              <Checkbox
                checked={selectsMediaIds.includes(media.id)}
                onClick={() => {
                  if (selectsMediaIds.includes(media.id)) {
                    setSelectsMediaIds(
                      selectsMediaIds.filter((id) => id !== media.id)
                    );
                  } else {
                    setSelectsMediaIds([...selectsMediaIds, media.id]);
                  }
                }}
              />
            </Table.Cell>
            <Table.Cell>
              {media.contentType.endsWith("pdf") ? (
                <a href={media.url} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFilePdf} size="4x" />
                </a>
              ) : (
                <Image
                  centered={isMobile}
                  src={media.url}
                  size="tiny"
                  onClick={() => window.open(media.url)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Table.Cell>
            <Table.Cell>{media.createdAt}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
