import api from "utils/api";
import endpoint from "utils/endpoint";

class RestyRepository {
  /**
   * 代理店ユーザーだけ利用可能
   */
  public async enable(isAgency: boolean, storeId: string) {
    const url = endpoint.agency.resty.root(storeId);
    return api.post(url);
  }

  public restyAuth({
    isAgency,
    storeId,
    restyGroupCode,
    restyShopId,
    restyUserId,
    restyPassword,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    restyGroupCode: string;
    restyShopId: number;
    restyUserId: string;
    restyPassword: string;
  }) {
    const url =
      isAgency && storeId
        ? endpoint.agency.resty.auth(storeId)
        : endpoint.store.resty.auth();
    const body = {
      resty_auth: {
        resty_group_code: restyGroupCode,
        resty_shop_id: restyShopId,
        resty_user_id: restyUserId,
        resty_password: restyPassword,
      },
    };
    return api
      .post(url, body)
      .then((res) => res.data.resty)
      .catch(() => Promise.reject());
  }
}

export default new RestyRepository();
