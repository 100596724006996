import { useState, useEffect } from "react";
import { createContainer } from "unstated-next";
import AGENCY from "config/agency.json";
import { logoService } from "domain/service";
import routes from "utils/routes";

type Agency = {
  [key: string]: {
    name: string;
    options: {
      sitest: boolean;
      recruit: boolean;
      tabelog: boolean;
      externalTakeout: boolean;
    };
  };
};
type Path = {
  title: string;
  pathname: string;
  path: Function;
};

const MetaContainer = () => {
  const hostname = window.location.hostname;
  const [logo, setLogo] = useState<string | null>(null);
  const [favicon, setFavicon] = useState<string | undefined>(undefined);
  const [title, setTitle] = useState<string | null>(null);
  const [agencyDisplayName, setAgencyDisplayName] = useState<string | null>(
    null
  );
  const AgencyList: Agency = AGENCY;

  const paths = Object.values(routes).reduce((res: Path[], cur) => {
    Object.values(cur).forEach((ele) => res.push(ele));
    return res;
  }, []);

  useEffect(() => {
    setLogo(logoService.fetchLogo());
    setFavicon(logoService.fetchFavicon());

    setAgencyDisplayName(AgencyList[hostname].name);
    setTitle(agencyDisplayName);
  }, [AgencyList, hostname, agencyDisplayName]);

  const handleTitle = (pathname: string) => {
    const path = paths.find((path: Path) => {
      const regPath = new RegExp(
        path.pathname
          .split("/")
          .map((p) => (p.match(":") ? ".*" : p))
          .join("/")
      );
      return regPath.test(pathname);
    });
    const pathTitle = path ? path.title : "Not found";

    const newTitle = `${agencyDisplayName} | ${pathTitle}`;

    setTitle(newTitle);
  };

  return { agencyDisplayName, logo, favicon, title, handleTitle };
};

export default createContainer(MetaContainer);
