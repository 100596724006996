import styled from "styled-components";
import color from "./color";

export default styled.label`
  display: block;
  font-size: 12px;
  color: ${color.black};
  margin: 5px 0;
  text-align: left;
`;
