import React from "react";
import { Button, Message } from "semantic-ui-react";

type Props = {
  createGaProject: () => void;
  isGaProjectCreated: boolean | null;
};

export const GoogleAnalyticsRegister: React.FC<Props> = ({
  createGaProject,
  isGaProjectCreated,
}) => {
  if (isGaProjectCreated) {
    return <Message positive content="Google Analyticsは有効です" />;
  }

  return (
    <div css={{ textAlign: "center" }}>
      <Message
        warning
        content="Google Analyticsによるサイト解析を行うにはプロジェクトを作成する必要があります"
      />
      <div>
        <Button color="blue" onClick={createGaProject}>
          プロジェクトを作成
        </Button>
      </div>
    </div>
  );
};
