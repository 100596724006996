import React from "react";
import { Modal, Header, Button, Tab } from "semantic-ui-react";
import { SelectMediaTab } from "components/lib/SelectMediaModal/SelectMediaTab";
import { MediaUploadTab } from "components/lib/SelectMediaModal/MediaUploadTab";
import { mediaRepository } from "domain/repository";
import { UserContainer } from "containers";
import { useParams } from "react-router-dom";
import { StoreIdParam } from "config/raven";

type Props = {
  open: boolean;
  mediaUrls: string[];
  onUploaded: () => void;
  handleMediaClick: (url: string) => void;
  close: () => void;
  title: string;
};

export const SelectMediaModal: React.FC<Props> = ({
  open,
  mediaUrls,
  onUploaded,
  handleMediaClick,
  close,
  title,
}) => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  const panes = [
    {
      menuItem: "画像を選択",
      render: () => (
        <Tab.Pane>
          <SelectMediaTab
            mediaUrls={mediaUrls}
            handleMediaClick={handleMediaClick}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "画像をアップロード",
      render: () => (
        <Tab.Pane>
          <MediaUploadTab
            uploadMedia={async (file: File) => {
              await mediaRepository.createMedias(isAgency, storeId, [file]);
            }}
            onUploaded={onUploaded}
          />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <Modal open={open}>
      <Header content={title} />
      <Modal.Content>
        <Modal.Description>
          <Tab panes={panes} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content="閉じる" onClick={close} />
      </Modal.Actions>
    </Modal>
  );
};
