import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import { BasicAuthPreferences } from "domain/model/BasicAuthPreferences";

class BasicAuthService {
  async fetchPreferences(
    storeId: string
  ): Promise<false | BasicAuthPreferences> {
    const url = endpoint.agency.basicAuth.show(storeId);
    return api
      .get(url)
      .then((res) => {
        const rawData = plainToClass(
          BasicAuthPreferences,
          res.data.site_policy,
          {
            excludeExtraneousValues: true,
          }
        );
        const data = {
          ...rawData,
          privatePages: rawData.privatePages ?? [],
        };
        return data;
      })
      .catch(() => {
        return false;
      });
  }
}

export const basicAuthService = new BasicAuthService();
