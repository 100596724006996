import React from "react";

import { Label, Select, Input } from "semantic-ui-react";

import FORM_STATUS from "config/form_status.json";
import { FormStatus } from "config/raven";

type StatusLabelProps = {
  label: string;
  onClick: Function;
  active: boolean;
};

const StatusLabel: React.FC<StatusLabelProps> = ({
  label,
  onClick,
  active,
}) => (
  <Label
    circular
    color={active ? "yellow" : undefined}
    onClick={(e) => onClick(e)}
    css={{ cursor: "pointer" }}
  >
    {label}
  </Label>
);

type Props = {
  activeLabel: FormStatus[];
  setActiveLabel: React.Dispatch<FormStatus[]>;
  forms?: Map<string, string>;
  activeFormKey: string;
  setActiveFormKey: React.Dispatch<string>;
  activeSearchText: string;
  setActiveSearchText: React.Dispatch<string>;
};

const ApplicantsFilters: React.FC<Props> = ({
  activeLabel,
  setActiveLabel,
  forms,
  activeFormKey,
  setActiveFormKey,
  activeSearchText,
  setActiveSearchText,
}) => {
  const labelItemKeys = Object.keys(FORM_STATUS).filter(
    (key) => key !== "archived"
  );

  const handleClickLabel = (label: "all" | FormStatus) => {
    switch (label) {
      case "all":
        if (
          activeLabel.length === labelItemKeys.length ||
          activeLabel.length === Object.keys(FORM_STATUS).length
        ) {
          setActiveLabel([]);
        } else {
          setActiveLabel(Object.keys(FORM_STATUS) as FormStatus[]);
        }
        return;
      default:
        if (activeLabel.includes(label)) {
          setActiveLabel(
            activeLabel.filter((lab) => {
              switch (lab) {
                case "archived":
                  return false;
                case label:
                  return false;
                default:
                  return true;
              }
            })
          );
        } else {
          setActiveLabel([...activeLabel, label]);
        }
        return;
    }
  };

  const formOptions =
    forms !== undefined
      ? Array.from(forms.entries()).map(([k, v]) => ({
          key: k,
          value: k,
          text: v,
        }))
      : undefined;

  return (
    <div css={{ color: "grey", textAlign: "right" }}>
      <span css={{ marginRight: "1rem" }}>
        対応状況：
        <StatusLabel
          label={
            activeLabel.length === labelItemKeys.length ||
            activeLabel.length === Object.keys(FORM_STATUS).length
              ? "すべて非表示"
              : "すべて表示"
          }
          active={
            activeLabel.length === labelItemKeys.length ||
            activeLabel.length === Object.keys(FORM_STATUS).length
          }
          onClick={() => handleClickLabel("all")}
        />
        {labelItemKeys.map((key) => (
          <StatusLabel
            key={key}
            label={FORM_STATUS[key as FormStatus]}
            active={activeLabel.includes(key as FormStatus)}
            onClick={() => handleClickLabel(key as FormStatus)}
          />
        ))}
      </span>
      {formOptions && (
        <span css={{ marginRight: "1rem" }}>
          職種：
          <Select
            options={[
              { key: "all", value: "all", text: "全て" },
              ...formOptions,
            ]}
            value={activeFormKey}
            onChange={(_, { value }) => setActiveFormKey(value as string)}
          />
        </span>
      )}
      <span>
        <Input
          icon="search"
          placeholder="キーワードを入力"
          value={activeSearchText}
          onChange={(e) => setActiveSearchText(e.target.value)}
        />
      </span>
    </div>
  );
};

export default ApplicantsFilters;
