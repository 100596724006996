import React, { useState } from "react";

import PageContainer from "components/lib/PageContainer";
import { useParams } from "react-router-dom";
import { StoreIdParam } from "config/raven";
import { Button, Grid, Segment, Message } from "semantic-ui-react";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { StoreContainer, UserContainer } from "containers";
import { orderService } from "domain/service";

import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("ja", ja);

export const OrderInvoice: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();
  const { currentStore } = StoreContainer.useContainer();
  const useTakeout = currentStore?.property?.useTakeout || false;
  const useInvoice = currentStore?.property?.takeoutUseEligibleInvoice || false;
  const today = new Date();
  const [startDate, setStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth() - 1, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 0)
  );

  const download = (startDate: Date, endDate: Date) => async () => {
    const pdf = await orderService
      .downloadInvoicePeriodPdf(isAgency, storeId, startDate, endDate)
      .catch(() => null);

    if (!pdf) {
      return;
    }

    const link = document.createElement("a");
    link.href = URL.createObjectURL(new Blob([pdf]));
    link.setAttribute("download", `invoice.pdf`);
    document.body.appendChild(link);
    link.click();
  };

  if (!useTakeout) {
    return <Message content="テイクアウト機能が有効になっていません" />;
  } else if (!useInvoice) {
    return <Message content="「適格簡易請求書の発行」が有効になっていません" />;
  }

  return (
    <PageContainer
      header="適格簡易請求書ダウンロード"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .orderPage()
        .build("適格簡易請求書ダウンロード")}
    >
      <Segment>
        <p>
          適格簡易請求書を期間指定で1つのPDFファイルにまとめてダウンロードします
        </p>
        <Grid columns={2} doubling>
          <Grid.Row>
            <Grid.Column>
              <Segment>
                <label>開始日：</label>
                <DatePicker
                  onChange={(date: Date) => {
                    setStartDate(date);
                  }}
                  selected={startDate}
                  dateFormat="yyyy/MM/dd"
                  locale="ja"
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <label>終了日：</label>
                <DatePicker
                  onChange={(date: Date) => {
                    setEndDate(date);
                  }}
                  selected={endDate}
                  dateFormat="yyyy/MM/dd"
                  locale="ja"
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <div css={{ textAlign: "center" }}>
          <Button
            color="blue"
            content="ダウンロードする"
            onClick={download(startDate, endDate)}
          />
        </div>
      </Segment>
    </PageContainer>
  );
};
