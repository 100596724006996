import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";
import { articleService } from "domain/service";

import Post from "domain/model/Post";

const IndexLimit = 8;

const ArticleContainer = () => {
  const [articles, setArticles] = useState<Post[] | null>(null);
  const [editArticle, setEditArticle] = useState<string | null>(null);
  const [hasMoreArticles, setHasMoreArticles] = useState<boolean | null>(false);
  const [loading, setLoading] = useState(false);

  const fetchArticles = useCallback(
    async (
      isAgency: boolean,
      storeId: string | undefined,
      createdAt?: string
    ) => {
      setLoading(true);
      const lastFetchedArticle = await articleService
        .fetchAllArticles(isAgency, storeId, createdAt)
        .then((fetchedArticles) => {
          setArticles((currentState) => {
            if (currentState === null) {
              return fetchedArticles;
            } else {
              return [...currentState, ...fetchedArticles];
            }
          });
          // limit 以下の場合はそれ以上の記事取得は行わない
          if (fetchedArticles.length < IndexLimit) {
            return undefined;
          }
          return fetchedArticles[fetchedArticles.length - 1];
        });
      setLoading(false);
      if (lastFetchedArticle === undefined) {
        return;
      }
      articleService
        .fetchAllArticles(isAgency, storeId, lastFetchedArticle.createdAtStr)
        .then((fetchedNextArticles) => {
          if (fetchedNextArticles.length !== 0) {
            setHasMoreArticles(true);
          } else {
            setHasMoreArticles(false);
          }
        });
    },
    []
  );

  return {
    loading,
    articles,
    editArticle,
    fetchArticles,
    setEditArticle,
    hasMoreArticles,
  };
};

export default createContainer(ArticleContainer);
