import api from "utils/api";
import endpoint from "utils/endpoint";
import BusinessDayType from "domain/model/BusinessDayType";

type Params = {
  id: string | null;
  day_type: string;
  time_type: string;
  opening_time: string | null;
  closing_time: string | null;
  _destroy?: 1;
};

class BusinessDayTypeRepository {
  async updateAllBusinessDayTypes(
    isAgency: boolean,
    storeId: string | undefined,
    params: (BusinessDayType & { destroy?: 1 })[]
  ) {
    const requestBody: Params[] = params.map(
      ({ id, dayType, timeType, openingTime, closingTime, destroy }) => ({
        id,
        day_type: dayType,
        time_type: timeType,
        opening_time: openingTime,
        closing_time: closingTime,
        _destroy: destroy,
      })
    );

    const url =
      isAgency && storeId
        ? endpoint.agency.businessDayType.updateAll(storeId)
        : endpoint.store.businessDayType.updateAll();

    return api.post(url, { business_day_types: requestBody });
  }
}

export default new BusinessDayTypeRepository();
