import React from "react";
import * as Yup from "yup";
import { Table, Button, Form } from "semantic-ui-react";

import Category from "domain/model/Category";
import { useFormik } from "formik";
import MESSAGE from "config/message.json";

const validationSchema = Yup.object().shape({
  categoryName: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
});

type Props = {
  category: Category;
  index: number;
  editCategoryValue: string | null;
  setEditCategoryValue: React.Dispatch<string>;
  handleUpdateCategory: (
    updateCategoryValue: string,
    updateCategoryName: string
  ) => Promise<any>;
  handleDeleteCategoryButton: (deleteCategoryValue: string) => void;
};

export const CategoryItem: React.FC<Props> = ({
  index,
  category,
  editCategoryValue,
  setEditCategoryValue,
  handleUpdateCategory,
  handleDeleteCategoryButton,
}) => {
  const formik = useFormik({
    initialValues: {
      categoryName: category.categoryName,
    },
    onSubmit: (values, { setSubmitting }) => {
      handleUpdateCategory(category.categoryValue, values.categoryName).then(
        () => {
          setSubmitting(false);
        }
      );
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });
  return (
    <Table.Row>
      <Table.Cell>{index + 1}</Table.Cell>
      <Table.Cell>
        {editCategoryValue === category.categoryValue ? (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Input
              name="categoryName"
              error={formik.errors.categoryName}
              value={formik.values.categoryName}
              onChange={formik.handleChange}
              action={{
                color: "blue",
                content: "更新",
                loading: formik.isSubmitting,
              }}
            />
          </Form>
        ) : (
          category.categoryName
        )}
      </Table.Cell>
      <Table.Cell colSpan="2">{category.categoryValue}</Table.Cell>
      <Table.Cell>{category.createdAt}</Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          icon="edit"
          content="編集"
          onClick={() => setEditCategoryValue(category.categoryValue)}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Button
          icon="trash alternate"
          content="削除"
          negative
          onClick={() => handleDeleteCategoryButton(category.categoryValue)}
        />
      </Table.Cell>
    </Table.Row>
  );
};
