import React from "react";
import PageContainer from "components/lib/PageContainer";
import { Button } from "semantic-ui-react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import routes from "utils/routes";
import { StoreContainer, UserContainer } from "containers";
import { StoreIdParam } from "config/raven";
import { Container } from "components/Version/Index/Container";
import { useMediaQuery } from "react-responsive";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const VersionIndex: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const storeContainer = StoreContainer.useContainer();
  const { currentStore } = storeContainer;
  const { storeId } = useParams<StoreIdParam>();
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <PageContainer
      header="バージョン一覧"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId).build(
        "バージョン一覧"
      )}
      action={
        <div
          css={
            isMobile
              ? {
                  display: "flex",
                  flexDirection: "column",
                }
              : { display: "flex", flexDirection: "row" }
          }
        >
          {!!currentStore?.awsResource.homepageDomain ? (
            <Button
              as="a"
              color="green"
              content="公開中のサイトを開く"
              href={`https://${currentStore?.awsResource.homepageDomain}`}
              target="_blank"
              rel="noopener noreferrer"
            />
          ) : null}
          <div
            css={
              isMobile
                ? { margin: "auto", marginTop: "6px" }
                : { marginLeft: "16px" }
            }
          >
            <Button
              color="blue"
              content="バージョン追加"
              onClick={() => {
                const versionNewUrl = isAgency
                  ? generatePath(routes.agency.versionNew.pathname, { storeId })
                  : routes.store.versionNew.pathname;
                history.push(versionNewUrl);
              }}
            />
          </div>
        </div>
      }
    >
      <Container isAgency={isAgency} storeId={storeId} />
    </PageContainer>
  );
};
