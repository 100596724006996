import {
  FormattedApplicant,
  ApplicantInfo,
} from "components/JobOffer/ApplicantsIndex";

export const formatApplicants = (
  applicants: ApplicantInfo[]
): FormattedApplicant[] => {
  return applicants.map((applicant) => {
    const { content } = applicant;
    const age = content["年齢"] ?? "-";
    const name = content["氏名"] ?? "-";
    const address = content["住所"] ?? "-";
    const phone = content["携帯電話番号"] ?? content["電話番号"] ?? "-";
    const email = content["メールアドレス"] ?? "-";
    const jobOfferType = content["採用形態"] ?? "-";
    const currentJob = content["現在のご職業"] ?? "-";
    return {
      ...applicant,
      createdAt: applicant.createdAt,
      name,
      age,
      address,
      phone,
      email,
      jobOfferType,
      currentJob,
    };
  });
};
