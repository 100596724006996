import { Expose, Type } from "class-transformer";

import { ExternalTakeoutService } from "config/raven";

export default class TakeoutLogin {
  @Expose() site: "tabelog" | "epark" | "menu" | "demaecan" | "toreta";
  @Expose() use: boolean;
  @Expose({ name: "last_updatedAt" }) lastUpdatedAt: number;
  @Expose() provider: ExternalTakeoutService;
}

export class TakeoutSites {
  [x: string]: any;
  @Expose() tabelog: boolean;
  @Expose() epark: boolean;
  @Expose() menu: boolean;
}

export class TakeoutSales {
  @Expose() site: string | null;
  @Expose() order: number | null;
  @Expose() sales: number | null;
  @Expose() date: number | null;
}

export class TakeoutSalesUpdated {
  @Expose() site: string | null;
  @Expose({ name: "latest_updated" }) latestUpdated: number;
}

export class TakeoutAuth {
  @Expose() site: "tabelog" | "epark" | "menu";
  @Type(() => Auth)
  @Expose()
  auth: Auth;
}

class Auth {
  @Expose() session: string;
  @Expose({ name: "xsrf_token" }) xsrfToken: string | null;
  @Expose({ name: "laravel_token" }) laravelToken: string | null;
  @Expose() token: string | null;
}
