import { useState, useEffect } from "react";
import { UserType } from "config/raven";
import { createContainer } from "unstated-next";
import { changeToken } from "utils/api";

import { getToken, setToken, removeToken } from "utils/localStorage";

import { userService } from "domain/service";
import { CurrentUser } from "config/raven";

const UserContainer = () => {
  const [userToken, setUserToken] = useState(() => {
    const agencyToken = getToken("agency");
    const storeToken = getToken("store");
    return agencyToken
      ? { type: "agency", token: agencyToken }
      : storeToken
      ? { type: "store", token: storeToken }
      : null;
  });

  const [currentUser, setCurrentUser] = useState<CurrentUser>(null);

  useEffect(() => {
    updateCurrentUser();
    // eslint-disable-next-line
  }, []);

  const updateCurrentUser = () => {
    if (!userToken) {
      return;
    }
    if (userToken.type === "agency") {
      userService
        .fetchAgencyUser()
        .then((res) => setCurrentUser(res))
        .catch((err) => {
          if (err.response.data.error === "Invalid Token or Expired Token") {
            removeToken();
          }
        });
    } else if (userToken.type === "store") {
      userService
        .fetchStoreUser()
        .then((res) => setCurrentUser(res))
        .catch((err) => {
          if (err.response.data.error === "Invalid Token or Expired Token") {
            removeToken();
          }
        });
    }
  };

  const userType = userToken?.type;

  const isAgency = userToken?.type === "agency";

  const signIn = async (email: string, password: string, type: UserType) => {
    return userService.signIn(email, password, type).then((res) => {
      const { token } = res.data;
      changeToken(token);
      setToken(type, token);
      setUserToken({ type, token });
    });
  };

  const signOut = () => {
    removeToken();
    setCurrentUser(null);
    setUserToken(null);
  };

  return {
    currentUser,
    userToken,
    userType,
    isAgency,
    updateCurrentUser,
    signIn,
    signOut,
  };
};

export default createContainer(UserContainer);
