import React, { useState } from "react";
import { MessageSizeProp, Message } from "semantic-ui-react";

const MessageTypes = {
  SUCCESS: "success",
  ERROR: "error",
} as const;

type MessageType = (typeof MessageTypes)[keyof typeof MessageTypes];

type MessageState = {
  content: string;
  type: MessageType;
};

/**
 * メッセージを管理するHooks
 * renderMessage: messageの状態に応じたMessageコンポーネントを表示する
 * setSuccessMessage: 成功時のメッセージを設定する
 * setErrorMessage: 失敗時のメッセージを設定する
 */
export const useMessage = ({
  messageSize,
}: {
  messageSize: MessageSizeProp;
}) => {
  const [message, setMessage] = useState<MessageState | null>(null);

  const setSuccessMessage = (content: string) => {
    setMessage({
      content,
      type: MessageTypes.SUCCESS,
    });
  };

  const setErrorMessage = (content: string) => {
    setMessage({
      content,
      type: MessageTypes.ERROR,
    });
  };

  const renderMessage = () => {
    if (message === null) {
      return null;
    }
    if (message.type === MessageTypes.SUCCESS) {
      return <Message size={messageSize} content={message.content} success />;
    }

    return <Message size={messageSize} content={message.content} error />;
  };

  return { renderMessage, setSuccessMessage, setErrorMessage };
};
