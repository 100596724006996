import styled from "styled-components";
import color from "./color";

export default styled.div`
  display: ${(props) =>
    props.justifyContent || props.alignItems ? "flex" : "block"};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};

  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  max-height: ${(props) => props.maxHeight};
  height: ${(props) => props.height};
  box-sizing: border-box;
`;

export const MainContentsWrap = styled.div`
  background-color: ${color.grayDark};
  min-height: calc(100vh - 55px);
  box-sizing: border-box;
  width: 100%;
  padding: 50px 10%;
  margin: auto;
  justify-content: center;
`;

export const GridWrap = styled.div`
  display: grid;
  grid-template-rows: calc(100vh - 55px);
  grid-template-columns: 200px 1fr;
  background-color: ${color.grayDark};
  transition: 0.3s;
  margin-left: ${(props) => (props.showSidebar ? "0" : "-200px")};
`;

export const AppWrap = styled.div`
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  background-color: ${color.grayDark};
  overflow-y: scroll;
`;
