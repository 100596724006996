import React, { useEffect, useState } from "react";
import { Button, Form, Confirm, Popup, Message } from "semantic-ui-react";
import MESSAGE from "config/message.json";
import { generatePath, useHistory } from "react-router-dom";
import routes from "utils/routes";
import { SeoPreferencesForm } from "components/lib/Post/SeoPreferencesForm";
import { PostContentForm } from "components/lib/Post/PostContentForm";
import { articleService } from "domain/service";
import { Formik } from "formik";
import dayjs from "dayjs";
import { articleRepository } from "domain/repository";
import { PostPreview, PostTypes } from "components/lib/Post/PostPreview";
import { SelectMediaModal } from "components/lib/SelectMediaModal";
import { usePostSchema } from "hooks/usePostSchema";
import { usePostMenu } from "hooks/usePostMenu";
import { useAllMediaUrls } from "hooks/useAllMediaUrls";
import { useArticleSubCategory } from "components/Post/Article/useArticleSubCategory";
import { useArticleTemplate } from "components/Post/Article/useArticleTemplate";
import Post from "domain/model/Post";
import { RavenLoader } from "components/lib/RavenLoader";

type Props = {
  isAgency: boolean;
  storeId: string;
  filePath: string;
};

export const EditFormContainer: React.FC<Props> = ({
  isAgency,
  storeId,
  filePath,
}) => {
  const [editArticle, setEditArticle] = useState<Post | null>(null);
  const [editContent, setEditContent] = useState<string | null>(null);
  const { template } = useArticleTemplate({ isAgency, storeId });
  const { subCategories, NON_SUB_CATEGORY_VALUE } = useArticleSubCategory({
    isAgency,
    storeId,
  });
  const { mediaUrls, refetchMediaUrls } = useAllMediaUrls({
    isAgency,
    storeId,
    imageOnly: true,
  });
  const { activeMenu, MenuItems, renderMenu } = usePostMenu();
  const { validationSchema } = usePostSchema();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [openThumbnailModal, setOpenThumbnailModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const articleIndexUrl = isAgency
    ? generatePath(routes.agency.articleIndex.pathname, {
        storeId,
      })
    : routes.store.articleIndex.pathname;
  const history = useHistory();

  useEffect(() => {
    const fetchEditArticle = () => {
      articleService
        .fetchEditArticle(isAgency, storeId, filePath)
        .then((res) => {
          setEditArticle(res.article);
          setEditContent(res.content ?? "");
        });
    };
    fetchEditArticle();
  }, [filePath, isAgency, storeId]);

  const deletePost = () => {
    setDeleting(true);
    articleRepository
      .deletePost(isAgency, storeId, filePath)
      .then(() => {
        history.push(articleIndexUrl);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const renderContent = () => {
    if (activeMenu === MenuItems.POST_CONTENT) {
      return (
        <PostContentForm
          subCategories={subCategories}
          mediaUrls={mediaUrls}
          refetchMediaUrls={refetchMediaUrls}
          isEdit
          isPublished={editArticle!.isPublished}
        />
      );
    }
    if (activeMenu === MenuItems.POST_PREVIEW) {
      return <PostPreview template={template} postType={PostTypes.ARTICLE} />;
    }
    if (activeMenu === MenuItems.SEO_PREFERENCES) {
      return (
        <SeoPreferencesForm
          mediaUrls={mediaUrls}
          refetchMediaUrls={refetchMediaUrls}
        />
      );
    }
  };

  if (editArticle === null || editContent === null) {
    return <RavenLoader active />;
  }

  return (
    <>
      <RavenLoader active={deleting} content="削除中" />
      <Confirm
        open={openDeleteModal}
        content="この投稿を削除してもよろしいですか？"
        onCancel={() => setOpenDeleteModal(false)}
        onConfirm={deletePost}
      />
      <Formik
        initialValues={{
          postContent: {
            title: editArticle.title,
            postDate: dayjs(editArticle.createdAt).toDate(),
            subCategoryValue: editArticle.subcategory ?? NON_SUB_CATEGORY_VALUE,
            slug: editArticle.slug,
            content: editContent,
            thumbnailUrl: editArticle.metadata.thumbnailUrl,
          },
          seoPreferences: editArticle.metadata.seoPreferences,
          isDraft: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(
          { postContent, seoPreferences, isDraft },
          { setFieldValue, setFieldError, resetForm, setSubmitting }
        ) => {
          setErrorMessage(null);
          articleRepository
            .updateArticle(
              isAgency,
              storeId,
              filePath,
              {
                postContent: {
                  title: postContent.title,
                  slug: postContent.slug,
                  content: postContent.content,
                  postDate: postContent.postDate,
                  thumbnailUrl: postContent.thumbnailUrl,
                  subcategory:
                    postContent.subCategoryValue === NON_SUB_CATEGORY_VALUE
                      ? ""
                      : postContent.subCategoryValue,
                },
                seoPreferences,
              },
              isDraft
            )
            .then(() => {
              resetForm();
              history.push(articleIndexUrl);
            })
            .catch((e: string) => {
              if (e === `{:filepath=>["has already been taken"]}`) {
                setFieldError(
                  "postContent.slug",
                  MESSAGE.ERROR.POST.ALREADY_USE_SLUG
                );
              } else if (e === `{:slug=>["is invalid"]}`) {
                setFieldError(
                  "postContent.slug",
                  MESSAGE.ERROR.POST.INVALID_SLUG
                );
              } else {
                setErrorMessage(MESSAGE.ERROR.POST.EDIT_ERROR);
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ handleSubmit, setFieldValue, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div
              css={{
                margin: "16px 0",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                css={{ margin: "0 16px 0 0 !important" }}
                content="サムネイル"
                icon="image"
                primary
                onClick={(e) => {
                  setOpenThumbnailModal(true);
                  e.preventDefault();
                }}
              />
              <SelectMediaModal
                title="サムネイルの設定"
                mediaUrls={mediaUrls ?? []}
                onUploaded={refetchMediaUrls}
                open={openThumbnailModal}
                handleMediaClick={(url) => {
                  setFieldValue("postContent.thumbnailUrl", url);
                  setOpenThumbnailModal(false);
                }}
                close={() => setOpenThumbnailModal(false)}
              />
              <div css={{ margin: "0 16px 0 0" }}>
                {editArticle.isPublished ? (
                  <Popup
                    content="この記事を非公開に変更して下書きとして保存します"
                    position="top center"
                    trigger={
                      <Button
                        positive
                        icon="pencil alternate"
                        type="submit"
                        content="非公開"
                        onClick={() => {
                          setFieldValue("isDraft", true);
                        }}
                        loading={isSubmitting}
                      />
                    }
                  />
                ) : (
                  <Button
                    positive
                    icon="pencil alternate"
                    type="submit"
                    content="下書き保存"
                    onClick={() => {
                      setFieldValue("isDraft", true);
                    }}
                    loading={isSubmitting}
                  />
                )}
              </div>
              <Button
                positive
                icon="paper plane"
                type="submit"
                content={editArticle.isPublished ? "更新" : "公開"}
                onClick={() => {
                  setFieldValue("isDraft", false);
                }}
                css={{ margin: "0 16px 0 0 !important" }}
                loading={isSubmitting}
              />
              <Button
                negative
                content="削除"
                onClick={(e) => {
                  setOpenDeleteModal(true);
                  e.preventDefault();
                }}
              />
            </div>
            {renderMenu()}
            {errorMessage ? (
              <Message negative content={errorMessage} size="small" />
            ) : null}
            {renderContent()}
          </Form>
        )}
      </Formik>
    </>
  );
};
