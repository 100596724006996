import React, { useEffect, useState } from "react";
import { useLocation, useHistory, generatePath } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import routes from "utils/routes";
import {
  isEnableASidebarUrl,
  isEnableSidebarUrl,
} from "utils/isEnableSidebarUrl";
import { Image, Menu, Container, Popup, Icon } from "semantic-ui-react";
import { MetaContainer, UserContainer, StoreContainer } from "containers";
import { css } from "@emotion/core";

type Props = {
  toggleAsidebar: Function;
  closeAsidebar: Function;
};

const copy = (id: number, name: string) => {
  const text = `ID: ${id}\n店舗名: ${name}`;
  navigator.clipboard.writeText(text);
};

const Header: React.FC<Props> = ({ toggleAsidebar, closeAsidebar }) => {
  const userContainer = UserContainer.useContainer();
  const metaContainer = MetaContainer.useContainer();
  const currentStore = StoreContainer.useContainer().currentStore;

  const storeId = currentStore?.id;
  const storeName = currentStore?.name;

  const { currentUser, isAgency } = userContainer;
  const { logo } = metaContainer;

  const logoUrl = isAgency
    ? generatePath(routes.adminAgency.storeIndex.pathname, { page: 1 })
    : routes.store.dashboard.pathname;

  const [enableSidebar, setEnabledSidebar] = useState<boolean>(false);
  const [enableASidebar, setEnableASidebar] = useState<boolean>(false);

  const isMobile: boolean = useMediaQuery({ maxWidth: 767 });

  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const isEnableSidebar = isEnableSidebarUrl(pathname);
    setEnabledSidebar(isEnableSidebar);
    const isEnableASidebar = isEnableASidebarUrl(pathname);
    setEnableASidebar(isEnableASidebar);
  }, [pathname]);

  const disableHeader = !!pathname.match(/^\/users\//);

  return (
    <div css={{ display: disableHeader ? "none" : "block" }}>
      <Menu fixed="top" css={{ height: "3.5rem" }}>
        <Container fluid>
          {isMobile ? (
            <div
              css={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {enableSidebar && (
                <Menu.Item
                  header
                  icon="bars"
                  css={{ "&::before": { background: "none !important" } }}
                />
              )}
              <Menu.Item
                header
                css={{ "&::before": { background: "none !important" } }}
                onClick={() => {
                  history.push(logoUrl);
                  closeAsidebar();
                }}
              >
                <Image src={logo} size="tiny" />
              </Menu.Item>
              {enableASidebar && (
                <Menu.Item
                  header
                  icon="user"
                  onClick={() => toggleAsidebar()}
                />
              )}
            </div>
          ) : (
            <>
              <Menu.Item
                as="a"
                header
                onClick={() => {
                  history.push(logoUrl);
                  closeAsidebar();
                }}
              >
                <Image src={logo} size="tiny" />
              </Menu.Item>
              {enableSidebar && (
                <>
                  <Menu.Item
                    title="クリックでIDと店舗名をコピーします"
                    css={css`
                      user-select: auto !important;
                    `}
                  >
                    <span css={{ marginRight: 5 }}>
                      ID: {storeId} ｜ 店舗名: {storeName}
                    </span>
                    <Popup
                      trigger={
                        <Icon
                          link
                          name="copy"
                          size="small"
                          onClick={() =>
                            storeId && storeName && copy(storeId, storeName)
                          }
                        />
                      }
                    >
                      IDと店舗名をコピーします
                    </Popup>
                  </Menu.Item>
                </>
              )}
              <Menu.Menu position="right">
                <Menu.Item header as="p">
                  {currentUser && currentUser.staffName}
                </Menu.Item>
                {enableASidebar && (
                  <Menu.Item
                    header
                    icon="triangle down"
                    onClick={() => toggleAsidebar()}
                  />
                )}
              </Menu.Menu>
            </>
          )}
        </Container>
      </Menu>
    </div>
  );
};

export default Header;
