import React from "react";
import { Form, Table, Button, Message } from "semantic-ui-react";
import { FieldArray, useFormikContext, Field } from "formik";

import { FormTypeLabel } from "components/Contact/lib/form";
import { FormValues } from "domain/model/ContactFormTypes";
import { NewFormModalContainer } from "components/Contact/lib/NewFormModalContainer";

type Props = {
  isEdit: boolean;
};

export const ContactFormContent: React.FC<Props> = ({ isEdit }) => {
  const {
    values: { formContent: values },
    errors: { formContent: errors },
  } = useFormikContext<FormValues>();

  return (
    <>
      <div css={{ width: "300px", marginBottom: "10px" }}>
        <Form.Field>
          <label>フォーム</label>
          <Field
            name="formContent.formName"
            placeholder="お問い合わせフォーム・採用フォームなど"
          />
        </Form.Field>
        <Form.Field required>
          <label>受信用メールアドレス</label>
          <Field
            name="formContent.pubEmail"
            placeholder="contact@mail.com"
            error={errors && errors.pubEmail}
            as={Form.Input}
          />
        </Form.Field>
        <Form.Field required>
          <label>リダイレクト先URL</label>
          <Field
            name="formContent.redirectUrl"
            placeholder="https://sample.com"
            error={errors && errors.redirectUrl}
            as={Form.Input}
          />
        </Form.Field>
      </div>
      <Form.Field>
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>番号</Table.HeaderCell>
              <Table.HeaderCell>ラベル</Table.HeaderCell>
              <Table.HeaderCell>種類</Table.HeaderCell>
              <Table.HeaderCell>必須項目</Table.HeaderCell>
              <Table.HeaderCell>操作</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <FieldArray
            name="formContent.forms"
            render={({ remove, swap, push }) => {
              return (
                <>
                  <Table.Body>
                    {values?.forms && values.forms.length > 0 ? (
                      values.forms.map((form, index) => {
                        return (
                          <Table.Row key={index}>
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>
                              <Field
                                name={`formContent.forms.${index}.label`}
                              />
                            </Table.Cell>
                            <Table.Cell>{FormTypeLabel[form.type]}</Table.Cell>
                            <Table.Cell>
                              {form.type === "checkbox" ? null : (
                                <Field
                                  name={`formContent.forms.${index}.required`}
                                  type="checkbox"
                                />
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              <Button
                                icon="arrow up"
                                type="button"
                                onClick={() => {
                                  if (index > 0) {
                                    swap(index - 1, index);
                                  }
                                }}
                              />
                              <Button
                                icon="arrow down"
                                type="button"
                                onClick={() => {
                                  if (index < values.forms.length - 1) {
                                    swap(index, index + 1);
                                  }
                                }}
                              />
                              <Button
                                icon="trash"
                                type="button"
                                onClick={() => remove(index)}
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                    ) : (
                      <Table.Row textAlign="center">
                        <Table.Cell />
                        <Table.Cell />
                        <Table.Cell>
                          <Message>フォームを追加してください</Message>
                        </Table.Cell>
                        <Table.Cell />
                        <Table.Cell />
                      </Table.Row>
                    )}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell colSpan="5">
                        <NewFormModalContainer push={push} />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </>
              );
            }}
          />
        </Table>
      </Form.Field>
      <Form.Field>
        <div css={{ textAlign: "center" }}>
          <Button color="blue" type="submit">
            {isEdit ? "保存" : "作成"}
          </Button>
        </div>
      </Form.Field>
    </>
  );
};
