import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { UserContainer } from "containers";
import { mediaService } from "domain/service";
import { mediaRepository } from "domain/repository";
import Media from "domain/model/Media";

import { Button } from "semantic-ui-react";
import Pagination from "components/lib/Pagination";
import PageContainer from "components/lib/PageContainer";
import { MediaList } from "components/Media/Index/MediaList";
import { MediaNewForm } from "components/Media/New/MediaNewForm";

import { StoreIdParam } from "config/raven";
import { RavenLoader } from "components/lib/RavenLoader";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const MediaIndex = () => {
  const [medias, setMedias] = useState<Record<number, Media[]>>({});
  const [selectsMediaIds, setSelectsMediaIds] = useState<number[]>([]);
  const [totalPageNum, setTotalPageNum] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const { storeId } = useParams<StoreIdParam>();
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;

  useEffect(() => {
    if (!medias[activePage]) {
      mediaService
        .fetchMedias(isAgency, storeId, false, activePage)
        .then((res) => {
          setMedias({ ...medias, [activePage]: res.medias });
          setTotalPageNum(res.total);
        });
    }
  }, [activePage, isAgency, medias, storeId]);

  const createMedia = (files: File[]) =>
    mediaRepository.createMedias(isAgency, storeId, files);

  const deleteMedias = () => {
    setLoading(true);
    Promise.all(
      selectsMediaIds.map((mediaId) =>
        mediaRepository.deleteMedia(isAgency, storeId, mediaId)
      )
    )
      .then(() => {
        setSelectsMediaIds([]);
        setMedias({});
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PageContainer
      header="画像一覧"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId).build(
        "画像一覧"
      )}
      action={
        <>
          {selectsMediaIds.length > 0 && (
            <Button
              color="red"
              content={`選択した画像を削除 (${selectsMediaIds.length})`}
              onClick={deleteMedias}
            />
          )}
        </>
      }
    >
      <MediaNewForm createMedia={createMedia} />
      <RavenLoader active={loading || !medias[activePage]} />
      {medias[activePage] ? (
        <MediaList
          medias={medias[activePage]}
          selectsMediaIds={selectsMediaIds}
          setSelectsMediaIds={setSelectsMediaIds}
        />
      ) : null}
      <div css={{ textAlign: "center" }}>
        <Pagination
          active={activePage}
          total={totalPageNum}
          handleChange={setActivePage}
        />
      </div>
    </PageContainer>
  );
};
