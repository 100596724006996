import SignIn from "components/SignIn";
import PasswordResetRequest from "components/PasswordResetRequest";
import PasswordReset from "components/PasswordReset";
import AccountSetting from "components/AccountSetting";
import StoreNew from "components/StoreNew";
import StoreIndex from "components/StoreIndex";
import DashBoard from "components/DashBoard";
import StoreProfile from "components/StoreProfile";
import SiteManage from "components/SiteManage";
import JobOfferFormNew from "components/JobOffer/JobOfferFormNew";
import JobOfferFormEdit from "components/JobOffer/JobOfferFormEdit";
import JobOfferFormIndex from "components/JobOffer/JobOfferFormIndex";
import ApplicantsIndex from "components/JobOffer/ApplicantsIndex";
import JobFeedNew from "components/JobFeed/New";
import JobFeedEdit from "components/JobFeed/Edit";
import JobFeedIndex from "components/JobFeed/Index";

import Favicon from "components/SiteManage/Content/Favicon";
import Recruit from "components/SiteManage/Content/Recruit";
import Sitest from "components/SiteManage/Content/Sitest";
import Takeout from "components/SiteManage/Content/Takeout";
import MessageTemplate from "components/SiteManage/Content/TakeoutMessageTemplate";
import MessageTemplateEdit from "components/SiteManage/Content/TakeoutMessageTemplate/Edit";

import { StoreProfileBusinessDay } from "components/StoreProfile/BusinessDay";

import { VersionIndex } from "components/Version/Index";
import { VersionNew } from "components/Version/New";
import { VersionShow } from "components/Version/Show";
import { VersionEdit } from "components/Version/Edit";

import { ArticleNew } from "components/Post/Article/New";
import { ArticleEdit } from "components/Post/Article/Edit";
import { ArticleIndex } from "components/Post/Article/Index";
import { ArticleCategory } from "components/Post/Article/Category";
import { ArticleTemplate } from "components/Post/Article/Template";

import { NewsIndex } from "components/Post/News/Index";
import { NewsNew } from "components/Post/News/New";
import { NewsTemplate } from "components/Post/News/Template";
import { NewsCategory } from "components/Post/News/Category";
import { NewsEdit } from "components/Post/News/Edit";

import { AgencyIndex } from "components/Agency/AgencyIndex";
import { AgencyNew } from "components/Agency/AgencyNew";

import { AgencyStaffNew } from "components/AgencyStaff/New";
import { AgencyStaffIndex } from "components/AgencyStaff/Index";
import { AgencyStaffShow } from "components/AgencyStaff/Show";

import { StoreNotificationIndex } from "components/StoreNotification/Index";
import { StoreNotificationShow } from "components/StoreNotification/Show";
import { AgencyNotificationIndex } from "components/AgencyNotification/Index";
import { AgencyNotificationNew } from "components/AgencyNotification/New";
import { AgencyNotificationEdit } from "components/AgencyNotification/Edit";

import { ContactFormNew } from "components/Contact/FormNew";
import { ContactFormEdit } from "components/Contact/FormEdit";
import { ContactFormIndex } from "components/Contact/FormIndex";
import { ContactIndex } from "components/Contact/Index";

import { MediaIndex } from "components/Media/Index";
import { MediaNew } from "components/Media/New";

import { Ogp } from "components/SiteManage/Content/Ogp";
import { Sitemap } from "components/SiteManage/Content/Sitemap";
import { GoogleCooperation } from "components/SiteManage/Content/Google";
import { Tabelog } from "components/SiteManage/Content/Tabelog";

import { Domain } from "components/SiteManage/Content/Domain";
import { DomainRegist } from "components/SiteManage/Content/Domain/DomainRegist";
import { ExternalDomainRegist } from "components/SiteManage/Content/Domain/ExternalDomain";
import { DNSRecord } from "components/SiteManage/Content/DNSRecord";

import { TakeoutLogin } from "components/SalesManagement/TakeoutLogin";
import { TakeoutSales } from "components/SalesManagement/TakeoutSales";
import { OrderHistory } from "components/SalesManagement/OrderHistory";
import { ProductIndex } from "components/Product/Index";
import { ProductEdit } from "components/Product/Edit";
import { ProductNew } from "components/Product/New";
import { ProductImport } from "components/Product/Import";
import { ProductExport } from "components/Product/Export";

import { OrderIndex } from "components/Order/Index";
import { OrderDetail } from "components/Order/Detail";
import { OrderInvoice } from "components/Order/Invoice";

import { Resty } from "components/SiteManage/Content/Resty";
import { BasicAuth } from "components/SiteManage/Content/BasicAuth";
import { JOB_FEED_LABEL, JOB_OFFER_LABEL } from "config/ItemLabel";
import { Instagram } from "components/SiteManage/Content/Instagram";
import { YamlIndex } from "components/SiteManage/Content/Yaml/Index";
import { YamlNew } from "components/SiteManage/Content/Yaml/New";
import { YamlEdit } from "components/SiteManage/Content/Yaml/Edit";

/**
 * [key: string]: {
 *  [key: string]: {
 *    title: string;
 *    pathname: string;
 *    Component: any;
 *  };
 * };
 */
export const routes = {
  common: {
    signInAgency: {
      title: "サインイン - 代理店",
      pathname: "/users/sign_in/agency",
      Component: SignIn,
    },
    signInStore: {
      title: "サインイン - 店舗",
      pathname: "/users/sign_in/store",
      Component: SignIn,
    },
    passwordResetRequest: {
      title: "パスワードリセット申請",
      pathname: "/users/reset_password",
      Component: PasswordResetRequest,
    },
    passwordReset: {
      title: "パスワードリセットフォーム",
      pathname: "/users/reset_password/:token",
      Component: PasswordReset,
    },
  },
  adminAgency: {
    accountSetting: {
      title: "アカウント情報変更",
      pathname: "/agency/account/setting",
      Component: AccountSetting,
    },
    agencyNew: {
      title: "代理店作成",
      pathname: "/agency/agencies/new",
      Component: AgencyNew,
    },
    notificationIndex: {
      title: "代理店からのお知らせ一覧",
      pathname: "/agency/notifications/index",
      Component: AgencyNotificationIndex,
    },
    notificationNew: {
      title: "代理店からのお知らせ作成",
      pathname: "/agency/notifications/new",
      Component: AgencyNotificationNew,
    },
    notificationEdit: {
      title: "代理店からのお知らせ編集",
      pathname: "/agency/notifications/edit",
      Component: AgencyNotificationEdit,
    },
    agencyIndex: {
      title: "代理店一覧",
      pathname: "/agency/agencies",
      Component: AgencyIndex,
    },
    agencyStaffNew: {
      title: "代理店スタッフ作成",
      pathname: "/agency/staffs/new",
      Component: AgencyStaffNew,
    },
    agencyStaffShow: {
      title: "代理店スタッフ詳細",
      pathname: "/agency/staffs/detail",
      Component: AgencyStaffShow,
    },
    agencyStaffIndex: {
      title: "代理店スタッフ一覧",
      pathname: "/agency/staffs",
      Component: AgencyStaffIndex,
    },
    storeNew: {
      title: "店舗作成",
      pathname: "/agency/stores/new",
      Component: StoreNew,
    },
    storeIndex: {
      title: "店舗一覧",
      pathname: "/agency/stores/:page",
      Component: StoreIndex,
    },
  },
  agency: {
    dashboard: {
      title: "ホーム",
      pathname: "/stores/:storeId/dashboard",
      Component: DashBoard,
    },
    notificationIndex: {
      title: "代理店からのお知らせ一覧",
      pathname: "/store/:storeId/notifications/index",
      Component: StoreNotificationIndex,
    },
    notificationShow: {
      title: "代理店からのお知らせ表示",
      pathname: "/store/:storeId/notifications/show",
      Component: StoreNotificationShow,
    },
    // バージョン管理
    versionNew: {
      title: "バージョン追加",
      pathname: "/stores/:storeId/versions/new",
      Component: VersionNew,
    },
    versionShow: {
      title: "バージョン詳細",
      pathname: "/stores/:storeId/versions/show",
      Component: VersionShow,
    },
    versionEdit: {
      title: "バージョン編集",
      pathname: "/stores/:storeId/versions/edit",
      Component: VersionEdit,
    },
    versionIndex: {
      title: "バージョン一覧",
      pathname: "/stores/:storeId/versions",
      Component: VersionIndex,
    },
    // お知らせ管理
    newsNew: {
      title: "お知らせ - 新規投稿",
      pathname: "/stores/:storeId/news/new",
      Component: NewsNew,
    },
    newsEdit: {
      title: "お知らせ - 編集",
      pathname: "/stores/:storeId/news/:slug/edit",
      Component: NewsEdit,
    },
    newsTemplate: {
      title: "お知らせ - テンプレート",
      pathname: "/stores/:storeId/news/template",
      Component: NewsTemplate,
    },
    newsCategory: {
      title: "お知らせ - カテゴリ",
      pathname: "/stores/:storeId/news/category",
      Component: NewsCategory,
    },
    newsIndex: {
      title: "お知らせ一覧",
      pathname: "/stores/:storeId/news",
      Component: NewsIndex,
    },
    // 記事管理
    articleNew: {
      title: "記事作成",
      pathname: "/stores/:storeId/articles/new",
      Component: ArticleNew,
    },
    articleEdit: {
      title: "記事編集",
      pathname: "/stores/:storeId/articles/:slug/edit",
      Component: ArticleEdit,
    },
    articleCategory: {
      title: "カテゴリ管理",
      pathname: "/stores/:storeId/article/categories",
      Component: ArticleCategory,
    },
    articleTemplate: {
      title: "記事テンプレート",
      pathname: "/stores/:storeId/article/template/edit",
      Component: ArticleTemplate,
    },
    articleIndex: {
      title: "記事一覧",
      pathname: "/stores/:storeId/articles",
      Component: ArticleIndex,
    },
    // メディア管理
    mediaNew: {
      title: "画像追加",
      pathname: "/stores/:storeId/medias/new",
      Component: MediaNew,
    },
    mediaIndex: {
      title: "画像一覧",
      pathname: "/stores/:storeId/medias",
      Component: MediaIndex,
    },
    // 問い合わせ管理
    contactFormNew: {
      title: "問い合わせフォーム作成",
      pathname: "/stores/:storeId/contacts/forms/new",
      Component: ContactFormNew,
    },
    contactFormEdit: {
      title: "問い合わせフォーム編集",
      pathname: "/stores/:storeId/contacts/forms/:formId/edit",
      Component: ContactFormEdit,
    },
    contactFormIndex: {
      title: "問い合わせフォーム一覧",
      pathname: "/stores/:storeId/contacts/forms",
      Component: ContactFormIndex,
    },
    contactIndex: {
      title: "問い合わせ一覧",
      pathname: "/stores/:storeId/contacts",
      Component: ContactIndex,
    },
    // 求人管理
    jobOfferFormNew: {
      title: "求人フォーム作成",
      pathname: "/stores/:storeId/job_offers/forms/new",
      Component: JobOfferFormNew,
    },
    jobOfferFormEdit: {
      title: "求人フォーム編集",
      pathname: "/stores/:storeId/job_offers/forms/:formId/edit",
      Component: JobOfferFormEdit,
    },
    jobOfferFormIndex: {
      title: `${JOB_OFFER_LABEL}一覧`,
      pathname: "/stores/:storeId/job_offers/forms",
      Component: JobOfferFormIndex,
    },
    applicantsIndex: {
      title: "応募者一覧",
      pathname: "/stores/:storeId/applicants",
      Component: ApplicantsIndex,
    },
    // 求人管理(求人フィード)
    jobFeedNew: {
      title: `${JOB_FEED_LABEL}登録`,
      pathname: "/stores/:storeId/jobFeeds/new",
      Component: JobFeedNew,
    },
    jobFeedEdit: {
      title: `${JOB_FEED_LABEL}編集`,
      pathname: "/stores/:storeId/jobFeeds/:id",
      Component: JobFeedEdit,
    },
    jobFeedIndex: {
      title: `${JOB_FEED_LABEL}一覧`,
      pathname: "/stores/:storeId/jobFeeds",
      Component: JobFeedIndex,
    },
    // サイト管理
    // ドメイン
    domain: {
      title: "サイト管理 - ドメイン設定",
      pathname: "/stores/:storeId/site_management/domain",
      Component: Domain,
    },
    domainRegist: {
      title: "ドメインを取得",
      pathname: "/stores/:storeId/domain/regist",
      Component: DomainRegist,
    },
    externalDomain: {
      title: "外部で取得済みのドメインを登録",
      pathname: "/stores/:storeId/external_domain/regist",
      Component: ExternalDomainRegist,
    },
    storeProfileShow: {
      title: "ストア情報確認",
      pathname: "/stores/:storeId/profile/show",
      Component: StoreProfile,
    },
    storeProfilBusinessDay: {
      title: "営業時間管理",
      pathname: "/stores/:storeId/profile/business_day",
      Component: StoreProfileBusinessDay,
    },
    sitemap: {
      title: "サイト管理 - サイトマップ設定",
      pathname: "/stores/:storeId/site_management/sitemap",
      Component: Sitemap,
    },
    favicon: {
      title: "サイト管理 - ファビコン設定設定",
      pathname: "/stores/:storeId/site_management/favicon",
      Component: Favicon,
    },
    ogp: {
      title: "サイト管理 - OGPデフォルト値設定",
      pathname: "/stores/:storeId/site_management/ogp",
      Component: Ogp,
    },
    basicAuth: {
      title: "サイト管理 - BASIC認証設定",
      pathname: "/stores/:storeId/site_management/basic_auth",
      Component: BasicAuth,
    },
    googleAnalytics: {
      title: "サイト管理 - Google連携設定",
      pathname: "/stores/:storeId/site_management/google",
      Component: GoogleCooperation,
    },
    dnsRecord: {
      title: "サイト管理 - DNSレコード管理",
      pathname: "/stores/:storeId/site_management/dns_record",
      Component: DNSRecord,
    },
    tabelog: {
      title: "サイト管理 - 外部サイト連携設定",
      pathname: "/stores/:storeId/site_management/tabelog",
      Component: Tabelog,
    },
    yamlNew: {
      title: "サイト管理 - YAML管理 新規作成",
      pathname: "/stores/:storeId/site_management/yaml/new",
      Component: YamlNew,
    },
    yamlEdit: {
      title: "サイト管理 - YAML管理 編集",
      pathname: "/stores/:storeId/site_management/yaml/:id/edit",
      Component: YamlEdit,
    },
    yamlIndex: {
      title: "サイト管理 - YAML管理",
      pathname: "/stores/:storeId/site_management/yaml",
      Component: YamlIndex,
    },
    takeoutLogin: {
      title: "サイト管理 - 売上一元管理",
      pathname: "/stores/:storeId/site_management/takeout_login",
      Component: TakeoutLogin,
    },
    takeoutSales: {
      title: "売上一元管理　同期",
      pathname: "/stores/:storeId/takeout_sales",
      Component: TakeoutSales,
    },
    orderHistory: {
      title: "売上一元管理　注文履歴",
      pathname: "/stores/:storeId/order_history",
      Component: OrderHistory,
    },
    resty: {
      title: "Resty ストア情報管理",
      pathname: "/stores/:storeId/site_management/resty",
      Component: Resty,
    },
    instagram: {
      title: "Instagram 連携管理",
      pathname: "/stores/:storeId/site_management/instagram",
      Component: Instagram,
    },
    recruit: {
      title: "サイト管理 - 求人管理",
      pathname: "/stores/:storeId/site_management/recruit",
      Component: Recruit,
    },
    sitest: {
      title: "サイト管理 - サイト解析管理",
      pathname: "/stores/:storeId/site_management/sitest",
      Component: Sitest,
    },
    takeout: {
      title: "サイト管理 - テイクアウト機能",
      pathname: "/store/:storeId/site_management/takeout",
      Component: Takeout,
    },
    takeoutMessageTemplateEdit: {
      title: "サイト管理 - テイクアウト機能",
      pathname:
        "/store/:storeId/site_management/takeout_message_template/:messageType",
      Component: MessageTemplateEdit,
    },
    takeoutMessageTemplate: {
      title: "サイト管理 - テイクアウト機能",
      pathname: "/store/:storeId/site_management/takeout_message_template",
      Component: MessageTemplate,
    },
    siteManage: {
      title: "サイト管理",
      pathname: "/stores/:storeId/site_management",
      Component: SiteManage,
    },
    productNew: {
      title: "商品作成",
      pathname: "/stores/:storeId/products/new",
      Component: ProductNew,
    },
    productImport: {
      title: "商品インポート",
      pathname: "/stores/:storeId/products/import",
      Component: ProductImport,
    },
    productExport: {
      title: "商品エクスポート",
      pathname: "/stores/:storeId/products/export",
      Component: ProductExport,
    },
    productEdit: {
      title: "商品編集",
      pathname: "/stores/:storeId/products/:id",
      Component: ProductEdit,
    },
    productIndex: {
      title: "商品一覧",
      pathname: "/stores/:storeId/products",
      Component: ProductIndex,
    },
    orderDetail: {
      title: "受注詳細",
      pathname: "/stores/:storeId/orders/:id",
      Component: OrderDetail,
    },
    orderIndex: {
      title: "受注一覧",
      pathname: "/stores/:storeId/orders",
      Component: OrderIndex,
    },
    orderInvoice: {
      title: "請求書出力",
      pathname: "/stores/:storeId/orderInvoice",
      Component: OrderInvoice,
    },
  },

  store: {
    accountSetting: {
      title: "アカウント情報変更",
      pathname: "/store/account/setting",
      Component: AccountSetting,
    },
    dashboard: {
      title: "ホーム",
      pathname: "/store/dashboard",
      Component: DashBoard,
    },
    storeProfilBusinessDay: {
      title: "営業時間管理",
      pathname: "/store/profile/business_day",
      Component: StoreProfileBusinessDay,
    },
    storeProfileEdit: {
      title: "店舗情報編集",
      pathname: "/store/profile/edit",
      Component: StoreProfile,
    },
    notificationIndex: {
      title: "代理店からのお知らせ一覧",
      pathname: "/store/notifications/index",
      Component: StoreNotificationIndex,
    },
    notificationShow: {
      title: "代理店からのお知らせ表示",
      pathname: "/store/notifications/show",
      Component: StoreNotificationShow,
    },
    // バージョン管理
    versionNew: {
      title: "バージョン追加",
      pathname: "/store/versions/new",
      Component: VersionNew,
    },
    versionShow: {
      title: "バージョン詳細",
      pathname: "/store/versions/show",
      Component: VersionShow,
    },
    versionEdit: {
      title: "バージョン編集",
      pathname: "/store/versions/edit",
      Component: VersionEdit,
    },
    versionIndex: {
      title: "バージョン一覧",
      pathname: "/store/versions",
      Component: VersionIndex,
    },
    // お知らせ管理
    newsNew: {
      title: "お知らせ - 新規投稿",
      pathname: "/store/news/new",
      Component: NewsNew,
    },
    newsEdit: {
      title: "お知らせ - 編集",
      pathname: "/store/news/:slug/edit",
      Component: NewsEdit,
    },
    newsTemplate: {
      title: "お知らせ - テンプレート",
      pathname: "/store/news/template",
      Component: NewsTemplate,
    },
    newsCategory: {
      title: "お知らせ - カテゴリ",
      pathname: "/store/news/category",
      Component: NewsCategory,
    },
    newsIndex: {
      title: "お知らせ一覧",
      pathname: "/store/news",
      Component: NewsIndex,
    },
    // 記事管理
    articleNew: {
      title: "記事作成",
      pathname: "/store/articles/new",
      Component: ArticleNew,
    },
    articleEdit: {
      title: "記事編集",
      pathname: "/store/articles/:slug/edit",
      Component: ArticleEdit,
    },
    articleCategory: {
      title: "カテゴリ管理",
      pathname: "/store/article/categories",
      Component: ArticleCategory,
    },
    articleTemplate: {
      title: "記事テンプレート",
      pathname: "/store/article/template/edit",
      Component: ArticleTemplate,
    },
    articleIndex: {
      title: "記事一覧",
      pathname: "/store/articles",
      Component: ArticleIndex,
    },
    // メディア管理
    mediaNew: {
      title: "画像追加",
      pathname: "/store/medias/new",
      Component: MediaNew,
    },
    mediaIndex: {
      title: "画像一覧",
      pathname: "/store/medias",
      Component: MediaIndex,
    },
    //　問い合わせ管理
    contactFormNew: {
      title: "問い合わせフォーム作成",
      pathname: "/store/contacts/forms/new",
      Component: ContactFormNew,
    },
    contactFormEdit: {
      title: "問い合わせフォーム編集",
      pathname: "/store/contacts/forms/:formId/edit",
      Component: ContactFormEdit,
    },
    contactFormIndex: {
      title: "問い合わせフォーム一覧",
      pathname: "/store/contacts/forms",
      Component: ContactFormIndex,
    },
    contactIndex: {
      title: "問い合わせ一覧",
      pathname: "/store/contacts",
      Component: ContactIndex,
    },
    // 求人管理
    jobOfferFormNew: {
      title: "求人フォーム作成",
      pathname: "/store/job_offers/forms/new",
      Component: JobOfferFormNew,
    },
    jobOfferFormEdit: {
      title: "求人フォーム編集",
      pathname: "/store/job_offers/forms/:formId/edit",
      Component: JobOfferFormEdit,
    },
    jobOfferFormIndex: {
      title: `${JOB_OFFER_LABEL}一覧`,
      pathname: "/store/job_offers/forms",
      Component: JobOfferFormIndex,
    },
    applicantsIndex: {
      title: "応募者一覧",
      pathname: "/store/applicants",
      Component: ApplicantsIndex,
    },
    // 求人管理(求人フィード)
    jobFeedNew: {
      title: `${JOB_FEED_LABEL}登録`,
      pathname: "/stores/jobFeeds/new",
      Component: JobFeedNew,
    },
    jobFeedEdit: {
      title: `${JOB_FEED_LABEL}編集`,
      pathname: "/stores/jobFeeds/:id",
      Component: JobFeedEdit,
    },
    jobFeedIndex: {
      title: `${JOB_FEED_LABEL}一覧`,
      pathname: "/stores/jobFeeds",
      Component: JobFeedIndex,
    },
    // サイト管理
    domain: {
      title: "サイト管理 - ドメイン設定",
      pathname: "/store/site_management/domain",
      Component: Domain,
    },
    favicon: {
      title: "サイト管理 - ファビコン設定設定",
      pathname: "/store/site_management/favicon",
      Component: Favicon,
    },
    ogp: {
      title: "サイト管理 - OGPデフォルト値設定",
      pathname: "/store/site_management/ogp",
      Component: Ogp,
    },
    tabelog: {
      title: "サイト管理 - 外部サイト連携設定",
      pathname: "/store/site_management/tabelog",
      Component: Tabelog,
    },
    yamlNew: {
      title: "サイト管理 - YAML管理 新規作成",
      pathname: "/store/site_management/yaml/new",
      Component: YamlNew,
    },
    yamlEdit: {
      title: "サイト管理 - YAML管理 編集",
      pathname: "/store/site_management/yaml/:id/edit",
      Component: YamlEdit,
    },
    yamlIndex: {
      title: "サイト管理 - YAML管理",
      pathname: "/store/site_management/yaml",
      Component: YamlIndex,
    },
    recruit: {
      title: "サイト管理 - 求人管理",
      pathname: "/store/site_management/recruit",
      Component: Recruit,
    },
    sitest: {
      title: "サイト管理 - サイト解析管理",
      pathname: "/store/site_management/sitest",
      Component: Sitest,
    },
    takeout: {
      title: "サイト管理 - テイクアウト機能",
      pathname: "/store/site_management/takeout",
      Component: Takeout,
    },
    takeoutMessageTemplateEdit: {
      title: "サイト管理 - テイクアウト機能",
      pathname: "/store/site_management/takeout_message_template/:messageType",
      Component: MessageTemplateEdit,
    },
    takeoutMessageTemplate: {
      title: "サイト管理 - テイクアウト機能",
      pathname: "/store/site_management/takeout_message_template",
      Component: MessageTemplate,
    },
    siteManage: {
      title: "サイト管理",
      pathname: "/store/site_management",
      Component: SiteManage,
    },
    takeoutLogin: {
      title: "サイト管理 - 売上一元管理",
      pathname: "/store/site_management/takeout_login",
      Component: TakeoutLogin,
    },
    takeoutSales: {
      title: "売上一元管理　同期",
      pathname: "/store/takeout_sales",
      Component: TakeoutSales,
    },
    orderHistory: {
      title: "売上一元管理　注文履歴",
      pathname: "/stores/order_history",
      Component: OrderHistory,
    },
    resty: {
      title: "Resty ストア情報管理",
      pathname: "/stores/site_management/resty",
      Component: Resty,
    },
    instagram: {
      title: "Instagram 連携管理",
      pathname: "/stores/site_management/instagram",
      Component: Instagram,
    },
    productNew: {
      title: "商品作成",
      pathname: "/store/products/new",
      Component: ProductNew,
    },
    productImport: {
      title: "商品インポート",
      pathname: "/store/products/import",
      Component: ProductImport,
    },
    productExport: {
      title: "商品エクスポート",
      pathname: "/store/products/export",
      Component: ProductExport,
    },
    productEdit: {
      title: "商品編集",
      pathname: "/store/products/:id",
      Component: ProductEdit,
    },
    productIndex: {
      title: "商品一覧",
      pathname: "/store/products",
      Component: ProductIndex,
    },
    orderDetail: {
      title: "受注詳細",
      pathname: "/store/orders/:id",
      Component: OrderDetail,
    },
    orderIndex: {
      title: "受注一覧",
      pathname: "/store/orders",
      Component: OrderIndex,
    },
    orderInvoice: {
      title: "請求書出力",
      pathname: "/store/:storeId/orderInvoice",
      Component: OrderInvoice,
    },
  },
} as const;

export default routes;
