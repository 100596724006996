import React, { useEffect, useState } from "react";
import { Modal, Button, Progress, Header, Message } from "semantic-ui-react";

export const ModalTypes = {
  CREATE: "作成",
  SUBMIT: "提出",
} as const;

type ModalType = (typeof ModalTypes)[keyof typeof ModalTypes];

const ProgressStatus = {
  REQUESTING: "REQUESTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
} as const;

type ProgressStatus = (typeof ProgressStatus)[keyof typeof ProgressStatus];

const SuccessDescription = ({
  modalType,
  sitemapUrl,
}: {
  modalType: ModalType;
  sitemapUrl?: string;
}) => {
  if (modalType === ModalTypes.CREATE && sitemapUrl) {
    return (
      <Modal.Description>
        <Header
          content={`サイトマップをアップロードしました. 「${sitemapUrl}」にて確認いただけます．`}
        />
        <p>
          アップロード後はGoogleSearchConsoleにサイトマップを提出する必要があります．
        </p>
      </Modal.Description>
    );
  }
  return (
    <Modal.Description>
      <Header content="サイトマップをGoogleSearchConsoleに提出しました．" />
    </Modal.Description>
  );
};

const FailureDescription = ({ modalType }: { modalType: ModalType }) => {
  if (modalType === ModalTypes.CREATE) {
    return (
      <Modal.Description>
        <Header
          content={`サイトマップのアップロードに失敗しました．運営会社にお問い合わせください`}
        />
      </Modal.Description>
    );
  }
  return (
    <Modal.Description>
      <Header content="サイトマップのGoogleSearchConsoleへの提出に失敗しました．以下を確認してください." />
      <Message
        list={[
          "1. Google Search Consoleにウェブサイトを登録している．(https://search.google.com/search-console)",
          "2. Google認証から認証を行なっている.",
        ]}
      />
      <p>
        Google認証を再度行うことで，SearchConsoleへの提出ができることがあります．
      </p>
      <p>
        <a
          href="https://search.google.com/search-console"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://search.google.com/search-console
        </a>
        からサイトマップを提出することも可能です．
      </p>
      <p>
        参考:
        <a
          href="https://support.google.com/webmasters/answer/7451001"
          target="_blank"
          rel="noopener noreferrer"
        >
          サイトマップの管理
        </a>
        (項目: サイトマップの送信)
      </p>
    </Modal.Description>
  );
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  execute: () => Promise<void>;
  modalType: ModalType;
  sitemapUri?: string | undefined;
};

export const ExecuteSitemapModal: React.FC<Props> = ({
  open,
  setOpen,
  execute,
  modalType,
  sitemapUri,
}) => {
  const [progressStatus, setProgressStatus] = useState<ProgressStatus>(
    ProgressStatus.REQUESTING
  );
  const closeModal = () => {
    setOpen(false);
    setProgressStatus(ProgressStatus.REQUESTING);
  };

  useEffect(() => {
    if (open) {
      setProgressStatus(ProgressStatus.REQUESTING);
      execute()
        .then(() => {
          setProgressStatus(ProgressStatus.SUCCESS);
        })
        .catch(() => {
          setProgressStatus(ProgressStatus.FAILURE);
        });
    }
  }, [execute, open]);

  const ModalContent = () => {
    if (progressStatus === ProgressStatus.REQUESTING) {
      return (
        <Progress active percent={100}>
          {modalType === ModalTypes.CREATE ? "作成中" : "提出中"}
        </Progress>
      );
    } else if (progressStatus === ProgressStatus.SUCCESS) {
      return (
        <>
          <Progress success percent={100}>
            {modalType === ModalTypes.CREATE ? "作成しました" : "提出しました"}
          </Progress>
          <SuccessDescription modalType={modalType} sitemapUrl={sitemapUri} />
        </>
      );
    } else {
      return (
        <>
          <Progress error percent={100}>
            失敗しました
          </Progress>
          <FailureDescription modalType={modalType} />
        </>
      );
    }
  };

  return (
    <Modal open={open}>
      <Modal.Header content={`サイトマップを${modalType}`} />
      <Modal.Content>
        <ModalContent />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={closeModal}
          content="閉じる"
          disabled={progressStatus === ProgressStatus.REQUESTING}
        />
      </Modal.Actions>
    </Modal>
  );
};
