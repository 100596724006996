import React from "react";

import { jobOfferRepository } from "domain/repository";
import { useHistory, generatePath } from "react-router-dom";
import routes from "utils/routes";
import { useIsJobOfferEnabled } from "hooks/useIsJobOfferEnabled";
import {
  JobOfferForm,
  SubmitTypes,
} from "components/JobOffer/lib/JobOfferForm";
import { Message, Loader } from "semantic-ui-react";
import { ReplyTypes } from "domain/model/JobOffer/FormDetails";

type Props = {
  isAgency: boolean;
  storeId: string;
};

const NewForm: React.FC<Props> = ({ isAgency, storeId }) => {
  const isJobOfferEnabled = useIsJobOfferEnabled({ isAgency, storeId });

  const history = useHistory();

  if (isJobOfferEnabled === false) {
    return <Message content="求人管理機能が利用できません" negative></Message>;
  }

  if (isJobOfferEnabled === null) {
    return <Loader active />;
  }

  return (
    <JobOfferForm
      initialValues={{
        name: "",
        email: "",
        redirectUrl: "",
        replyType: ReplyTypes.EMAIL,
        replySubject: "",
        replyMessage: "",
      }}
      handleSubmit={({ values, setStatus, resetForm }) => {
        jobOfferRepository
          .createForm(isAgency, storeId, {
            name: values.name,
            email: values.email,
            redirectUrl: values.redirectUrl,
            replyType: values.replyType,
            replySubject: values.replySubject,
            replyMessage: values.replyMessage,
          })
          .then((savedFormDom) => {
            resetForm();
            const jobOfferFormIndexUrl = isAgency
              ? generatePath(routes.agency.jobOfferFormIndex.pathname, {
                  storeId,
                })
              : routes.store.jobOfferFormIndex.pathname;

            history.push(jobOfferFormIndexUrl, { savedFormDom });
          })
          .catch(() => {
            setStatus({ success: "", error: "作成に失敗しました" });
          });
      }}
      submitType={SubmitTypes.CREATE}
    ></JobOfferForm>
  );
};

export default NewForm;
