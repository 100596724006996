import React, { useState, useEffect, useCallback } from "react";
import { generatePath, useParams, useHistory } from "react-router-dom";

import { Loader, Message, Label, Button } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import ApplicantsList from "components/JobOffer/ApplicantsIndex/ApplicantsList";
import ApplicantsFilters from "components/JobOffer/ApplicantsIndex/ApplicantsFilters";

import { UserContainer } from "containers";
import { jobOfferService } from "domain/service";
import { jobOfferRepository } from "domain/repository";
import Applicant from "domain/model/JobOffer/Applicant";

import { FormStatus, StoreIdParam } from "config/raven";
import Form from "domain/model/JobOffer/Form";
import ReadMoreButton from "components/JobOffer/ApplicantsIndex/ReadMoreButton";
import { formatApplicants } from "components/JobOffer/lib/formatApplicants";
import { useIsJobOfferEnabled } from "hooks/useIsJobOfferEnabled";
import routes from "utils/routes";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
import { JOB_OFFER_LABEL } from "config/ItemLabel";

export type ApplicantInfo = Applicant & {
  formName: string | undefined;
};

export type FormattedApplicant = ApplicantInfo & {
  name: string;
  age: string;
  address: string;
  phone: string;
  email: string;
  jobOfferType: string;
  currentJob: string;
};

// キーワードでの絞り込みに使う情報のオブジェクトキー
const seatchTextKey = [
  "address",
  "age",
  "currentJob",
  "name",
  "jobOfferType",
  "email",
  "phone",
  "remarks",
] as const;

const ApplicantsIndex = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();
  const isJobOfferEnabled = useIsJobOfferEnabled({ isAgency, storeId });

  const [applicants, setApplicants] = useState<FormattedApplicant[]>([]);
  const [formMap, setFormMap] = useState<Map<string, string>>();

  const [hasNextApplicants, setHasNextApplicants] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingReadmore, setLoadingReadmore] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  // filter state
  const [activeLabel, setActiveLabel] = useState<FormStatus[]>(["pending"]);
  const [activeFormKey, setActiveFormKey] = useState<string>("all");
  const [activeSearchText, setActiveSearchText] = useState<string>("");

  const fetchApplicants = useCallback(
    async (form: Map<string, string>, createdAt?: number) => {
      const lastUnixCreatedAt = await jobOfferService
        .fetchApplicants(isAgency, storeId, createdAt)
        .then((applicantList: Applicant[]) => {
          if (applicantList.length === 0) {
            return;
          }

          // フォーム情報から希望職種を抜き出して、応募者情報に追加する
          const fetchedApplicants: ApplicantInfo[] = applicantList.map((a) => {
            return {
              ...a,
              createdAt: a.createdAt,
              formName: form.get(a.formId),
            };
          });
          setApplicants((a) => formatApplicants([...a, ...fetchedApplicants]));

          return fetchedApplicants[fetchedApplicants.length - 1].unixCreatedAt;
        })
        .catch(() => {
          setErrorMessage("応募者情報情報を取得できませんでした");
        })
        .finally(() => {
          setloading(false);
          setLoadingReadmore(false);
        });
      if (!lastUnixCreatedAt) {
        setHasNextApplicants(false);
        return;
      }

      // 追加で読み込み可能な応募者情報があるか確認
      const nextApplicants = await jobOfferService.fetchApplicants(
        isAgency,
        storeId,
        lastUnixCreatedAt
      );
      if (nextApplicants.length > 0) {
        setHasNextApplicants(true);
      } else {
        setHasNextApplicants(false);
      }
    },
    [isAgency, storeId]
  );

  useEffect(() => {
    const fetchData = async () => {
      setloading(true);

      if (isJobOfferEnabled === null) {
        return;
      }

      if (isJobOfferEnabled === false) {
        setErrorMessage("求人管理機能が利用できません");
        setloading(false);
        return;
      }

      // Form情報を取得
      const formList: Form[] = await jobOfferService.fetchForms(
        isAgency,
        storeId
      );
      const map = new Map<string, string>(
        formList.map((f) => [f.idef, f.name]) as Iterable<
          readonly [string, string]
        >
      );
      setFormMap(map);

      // 応募者情報を取得
      await fetchApplicants(map, undefined);
      setloading(false);
    };
    fetchData();
  }, [fetchApplicants, isAgency, isJobOfferEnabled, storeId]);

  const handleSelectStatus = (contactId: string, status: FormStatus) => {
    jobOfferRepository
      .updateStatus(isAgency, storeId, contactId, status)
      .then(() => {
        const newApplicants = applicants.map((applicant) => {
          if (applicant.contactId === contactId) {
            return { ...applicant, createdAt: applicant.createdAt, status };
          } else {
            return applicant;
          }
        });
        setApplicants(newApplicants);
      });
  };
  const updateRemarks = (contactId: string, newRemarks: string) => {
    jobOfferRepository
      .updateRemarks(isAgency, storeId, contactId, newRemarks)
      .then(() => {
        const newApplicants = applicants.map((applicant) => {
          if (applicant.contactId === contactId) {
            return {
              ...applicant,
              createdAt: applicant.createdAt,
              remarks: newRemarks,
            };
          } else {
            return applicant;
          }
        });
        setApplicants(newApplicants);
      });
  };

  let contents = null;
  if (errorMessage) {
    contents = <Message content={errorMessage} negative></Message>;
  } else if (loading) {
    contents = <Loader active content="求人一覧を取得中..." />;
  } else if (applicants.length > 0) {
    const searchTextRegExp = new RegExp(activeSearchText, "ig");
    const filteredApplicants = applicants
      .filter((a) => activeLabel.includes(a.status))
      .filter((a) => {
        if (activeFormKey === "all") {
          return true;
        }
        return a.formId === activeFormKey;
      })
      .filter((a) => {
        if (activeSearchText === "") {
          return true;
        }
        return seatchTextKey.some((searchKey) => {
          return searchTextRegExp.test(a[searchKey]);
        });
      });
    const handleReadMore = () => {
      if (formMap === undefined) {
        return;
      }
      setLoadingReadmore(true);
      fetchApplicants(formMap, applicants[applicants.length - 1].unixCreatedAt);
    };
    contents = (
      <>
        <ApplicantsFilters
          activeLabel={activeLabel}
          setActiveLabel={setActiveLabel}
          forms={formMap}
          activeFormKey={activeFormKey}
          setActiveFormKey={setActiveFormKey}
          activeSearchText={activeSearchText}
          setActiveSearchText={setActiveSearchText}
        />
        <div css={{ textAlign: "right", marginTop: "14px" }}>
          <Label
            content={`${filteredApplicants.length}件`}
            icon="users"
          ></Label>
        </div>
        <ApplicantsList
          applicants={filteredApplicants}
          handleSelectStatus={handleSelectStatus}
          updateRemarks={updateRemarks}
        />
        {hasNextApplicants && (
          <div css={{ textAlign: "center" }}>
            <ReadMoreButton
              handleReadMore={handleReadMore}
              loading={loadingReadmore}
            ></ReadMoreButton>
          </div>
        )}
      </>
    );
  } else {
    contents = <Message content="応募者情報がありません"></Message>;
  }

  const history = useHistory();

  return (
    <PageContainer
      header="応募者一覧"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId).build(
        "応募者一覧"
      )}
      action={
        <Button
          color="blue"
          content={`新規${JOB_OFFER_LABEL}作成`}
          onClick={() => {
            const formNewUrl = isAgency
              ? generatePath(routes.agency.jobOfferFormNew.pathname, {
                  storeId,
                })
              : routes.store.jobOfferFormNew.pathname;
            history.push(formNewUrl);
          }}
        />
      }
    >
      {contents}
    </PageContainer>
  );
};

export default ApplicantsIndex;
