import api from "utils/api";
import endpoint from "utils/endpoint";
import GoogleOauth from "domain/model/Google/GoogleOauth";
import GaResource from "domain/model/Google/GaResource";
import { plainToClass } from "class-transformer";

class GoogleService {
  fetchGoogleOauthUrl(storeId: string) {
    const url = endpoint.agency.google.oauth(storeId);

    return api.get(url).then((res) =>
      plainToClass(GoogleOauth, res.data.context, {
        excludeExtraneousValues: true,
      })
    );
  }

  fetchGaResource(storeId: string) {
    const url = endpoint.agency.google.analyticsManage(storeId);

    return api
      .get(url)
      .then((res) =>
        plainToClass(GaResource, res.data, { excludeExtraneousValues: true })
      );
  }
}

export default new GoogleService();
