import React from "react";
import { Formik } from "formik";
import { Button, Form } from "semantic-ui-react";

type Props = {
  searchWord: string;
  handleSearch: (search: string) => void;
};

const SearchInput: React.FC<Props> = ({ handleSearch, searchWord }) => {
  const initialValues: { search: string } = { search: searchWord };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        handleSearch(values.search);
      }}
    >
      {({ values, handleSubmit, handleChange }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Input
            name="search"
            value={values.search}
            onChange={handleChange}
            action={() => {
              return <Button icon="search" type="submit" />;
            }}
            placeholder="店舗ID・店舗名・ドメイン名・店舗メールアドレスで検索"
          />
        </Form>
      )}
    </Formik>
  );
};

export default SearchInput;
