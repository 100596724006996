import { Expose, Transform } from "class-transformer";
import dayjs from "dayjs";

export default class Agency {
  @Expose() id: number;
  @Expose() name: string;
  @Expose() email: string;

  @Expose({ name: "updated_at" })
  @Transform((value) => dayjs(value).format("YYYY/MM/DD"))
  updatedAt: Date;
}
