import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Segment, Header, FormField } from "semantic-ui-react";
import dayjs from "dayjs";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import PageContainer from "components/lib/PageContainer";
import { UserContainer } from "containers";
import { StoreIdParam } from "config/raven";

import { externalTakeoutService } from "domain/service";
import { TakeoutSales } from "domain/model/ExternalTakeout";

import { OrderHistoryContainer } from "components/SalesManagement/OrderHistory/OrderHistoryContainer";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

registerLocale("ja", ja);

const Container: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  const [orderHistoryTabelog, setOrderHistoryTabelog] = useState<
    TakeoutSales[] | null
  >(null);
  const [orderHistoryEpark, setOrderHistoryEpark] = useState<
    TakeoutSales[] | null
  >(null);
  const [orderHistoryMenu, setOrderHistoryMenu] = useState<
    TakeoutSales[] | null
  >(null);
  const [orderHistoryDemaecan, setOrderHistoryDemaecan] = useState<
    TakeoutSales[] | null
  >(null);
  const [orderHistoryToreta, setOrderHistoryToreta] = useState<
    TakeoutSales[] | null
  >(null);

  // DatePicker 1カ月間の設定
  const [value, setValue] = useState<Date>(new Date());

  const firstMonth = dayjs(value).startOf("month").unix();
  const endMonth = dayjs(value).endOf("month").unix();

  useEffect(() => {
    const fetchOrderHistories = async () => {
      await externalTakeoutService
        .fetchOrderHistory({
          isAgency,
          storeId,
          takeoutSite: "tabelog",
          fromDate: firstMonth,
          toDate: endMonth,
        })
        .then((res) => setOrderHistoryTabelog(res));

      await externalTakeoutService
        .fetchOrderHistory({
          isAgency,
          storeId,
          takeoutSite: "epark",
          fromDate: firstMonth,
          toDate: endMonth,
        })
        .then((res) => {
          setOrderHistoryEpark(res);
        });

      await externalTakeoutService
        .fetchOrderHistory({
          isAgency,
          storeId,
          takeoutSite: "menu",
          fromDate: firstMonth,
          toDate: endMonth,
        })
        .then((res) => {
          setOrderHistoryMenu(res);
        });

      await externalTakeoutService
        .fetchOrderHistory({
          isAgency,
          storeId,
          takeoutSite: "demaecan",
          fromDate: firstMonth,
          toDate: endMonth,
        })
        .then((res) => {
          setOrderHistoryDemaecan(res);
        });

      await externalTakeoutService
        .fetchOrderHistory({
          isAgency,
          storeId,
          takeoutSite: "toreta",
          fromDate: firstMonth,
          toDate: endMonth,
        })
        .then((res) => {
          setOrderHistoryToreta(res);
        });
    };
    fetchOrderHistories();
  }, [isAgency, storeId, firstMonth, endMonth]);

  return (
    <>
      <Segment padded>
        <FormField css={{ display: "flex", alignItems: "center" }}>
          <Header content="集計月：" />
          <DatePicker
            onChange={(date: Date) => {
              setValue(date);
            }}
            selected={value}
            showMonthYearPicker
            showFullMonthYearPicker
            dateFormat="yyyy年MM月"
            locale="ja"
            maxDate={dayjs().toDate()}
            minDate={new Date("2021-04-01")}
            css={{ marginBottom: "18px" }}
          />
        </FormField>

        <OrderHistoryContainer
          orderHistoryTabelog={orderHistoryTabelog}
          orderHistoryEpark={orderHistoryEpark}
          orderHistoryMenu={orderHistoryMenu}
          orderHistoryDemaecan={orderHistoryDemaecan}
          orderHistoryToreta={orderHistoryToreta}
        />
      </Segment>
    </>
  );
};

export const OrderHistory = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const { storeId } = useParams<StoreIdParam>();

  return (
    <>
      <PageContainer
        header="注文履歴"
        breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
          .siteManagePage()
          .salesManagementPage()
          .build("注文履歴")}
      >
        <Container />
      </PageContainer>
    </>
  );
};
