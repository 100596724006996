import styled from "styled-components";
import color from "./color";

export default styled.input`
  width: 100%;
  background-color: ${color.white};
  color: ${color.blueGray};
  height: calc(2.0625rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  box-sizing: border-box;
  ::placeholder {
    color: ${color.gray};
  }

  border: 1px solid
    ${(props) =>
      props.errorMessage === "" || !props.errorMessage
        ? color.inputBorder
        : props.errorMessage === "ok"
        ? color.green
        : color.red};
  border-color: ${(props) => props.readOnly && color.grayLight};
  :focus {
    outline: ${(props) => props.readOnly && "none"};
  }
`;
