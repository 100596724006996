import React, { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import {
  Header,
  Segment,
  BreadcrumbSectionProps,
  Breadcrumb,
  StrictBreadcrumbSectionProps,
} from "semantic-ui-react";

type Props = {
  header: string;
  breadcrumbs?: StrictBreadcrumbSectionProps[];
  action?: ReactNode;
  children: ReactNode;
};

const mobileContainerStyle = { margin: "1.0rem 0.5rem" };

const otherContainerStyle = { width: "90%", margin: "1.5rem auto" };

const PageContainer: React.FC<Props> = ({
  header,
  breadcrumbs,
  children,
  action,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const history = useHistory();

  return (
    <div css={isMobile ? mobileContainerStyle : otherContainerStyle}>
      <Segment
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
          flexDirection: isMobile ? "column" : "row",
          height: "66px",
        }}
      >
        <div>
          {breadcrumbs ? (
            <Breadcrumb
              sections={breadcrumbs.map((b) => {
                if (b.active) {
                  return b;
                } else {
                  return {
                    ...b,
                    onClick: (
                      event: React.MouseEvent<HTMLAnchorElement>,
                      data: BreadcrumbSectionProps
                    ) => {
                      if (data.href) {
                        event.preventDefault();
                        history.push(data.href);
                      }
                    },
                  };
                }
              })}
              size="large"
            />
          ) : (
            <Header
              as="h3"
              color="grey"
              content={header}
              style={{ margin: 0 }}
            />
          )}
        </div>
        {action && (
          <div css={isMobile ? { marginTop: "6px" } : { marginRight: "1rem" }}>
            {action}
          </div>
        )}
      </Segment>
      <div>{children}</div>
    </div>
  );
};

export default PageContainer;
