import React from "react";

import { UserContainer } from "containers";
import PageContainer from "components/lib/PageContainer";
import AccountUpdateForm from "components/AccountSetting/AccountUpdateForm";
import PasswordUpdateForm from "components/AccountSetting/PasswordUpdateForm";

const PasswordUpdate = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency, currentUser, updateCurrentUser } = userContainer;

  return (
    <PageContainer header="アカウント情報変更">
      <AccountUpdateForm
        isAgency={isAgency}
        currentUser={currentUser}
        updateCurrentUser={updateCurrentUser}
      />
      <PasswordUpdateForm isAgency={isAgency} />
    </PageContainer>
  );
};

export default PasswordUpdate;
