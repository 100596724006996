import React from "react";
import { Menu } from "semantic-ui-react";

type Props = {
  active: number;
};

export const AsideMenu: React.FC<Props> = ({ active }) => {
  return (
    <div css={{ width: "100%", overflowX: "auto" }}>
      <Menu pointing secondary css={{ height: "3rem" }}>
        <Menu.Item
          as="a"
          icon="id card outline"
          content="Account"
          active={active === 1}
        />
      </Menu>
    </div>
  );
};
