import React, { useState, useEffect } from "react";

import { Formik } from "formik";
import * as Yup from "yup";

import MESSAGE from "config/message.json";
import { Form, Tab, Message, Loader, Dimmer, Button } from "semantic-ui-react";

import { externalTakeoutRepository } from "domain/repository";
import { externalTakeoutService } from "domain/service";

type Props = {
  isAgency: boolean;
  storeId: string | undefined;
};

type FormValues = {
  id: string;
  password: string;
  site: string;
  code: string;
  token: string;
};

const initialValues: FormValues = {
  id: "",
  password: "",
  site: "",
  code: "",
  token: "",
};

const validationSchema = Yup.object().shape({
  id: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  password: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
});
const validationSchemaForDemaecan = Yup.object().shape({
  id: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  password: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  code: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
});
const validationSchemaForToreta = Yup.object().shape({
  token: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
});

export const TakeoutLoginForm: React.FC<Props> = ({ isAgency, storeId }) => {
  const [isLinkOk, setIsLinkOk] = useState<boolean | null>(null);

  const [siteMessage, setSiteMessage] = useState<string | null>("");

  const LinkMessage = () => {
    if (isLinkOk === null) {
      return null;
    }
    if (isLinkOk) {
      return (
        <Message
          positive
          content={`${siteMessage}とのアカウント連携が完了しました`}
        />
      );
    }
    return <Message negative content="連携に失敗しました" />;
  };

  const [isTakeoutTabelog, setIsTakeoutTabelog] = useState<boolean | null>(
    null
  );
  const [isTakeoutEpark, setIsTakeoutEpark] = useState<boolean | null>(null);
  const [isTakeoutMenu, setIsTakeoutMenu] = useState<boolean | null>(null);
  const [isTakeoutDemaecan, setIsTakeoutDemaecan] = useState<boolean | null>(
    null
  );
  const [isTakeoutToreta, setIsTakeoutToreta] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchTakeoutLogin = () => {
      externalTakeoutService
        .fetchTakeoutLogin({ isAgency, storeId })
        .then((res) => {
          res.forEach((takeoutLogin) => {
            if (takeoutLogin.site === "tabelog") {
              setIsTakeoutTabelog(takeoutLogin.use);
            }
            if (takeoutLogin.site === "epark") {
              setIsTakeoutEpark(takeoutLogin.use);
            }
            if (takeoutLogin.site === "menu") {
              setIsTakeoutMenu(takeoutLogin.use);
            }
            if (takeoutLogin.site === "demaecan") {
              setIsTakeoutDemaecan(takeoutLogin.use);
            }
            if (takeoutLogin.site === "toreta") {
              setIsTakeoutToreta(takeoutLogin.use);
            }
          });
        });
    };
    fetchTakeoutLogin();
  }, [isAgency, storeId]);

  const panes = [
    {
      menuItem: "食べログTakeout",
      pane: (
        <Tab.Pane>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, formikHelpers) => {
              return externalTakeoutRepository
                .linkTakeout({
                  isAgency,
                  storeId,
                  takeoutId: values.id,
                  takeoutPassword: values.password,
                  takeoutCode: "",
                  takeoutToken: "",
                  takeoutSite: "tabelog",
                })
                .then(() => {
                  setIsLinkOk(true);
                  setSiteMessage("食べログTakeout");
                })
                .catch(() => setIsLinkOk(false))
                .finally(() => {
                  formikHelpers.setSubmitting(false);
                });
            }}
            // validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {({ handleChange, handleSubmit, isSubmitting, values, errors }) => (
              <>
                {isSubmitting ? (
                  <Dimmer active>
                    <Loader
                      active
                      content="食べログTakeout　テイクアウトアカウント登録中"
                      size="large"
                    ></Loader>
                  </Dimmer>
                ) : null}
                <Form onSubmit={handleSubmit}>
                  {isTakeoutTabelog ? (
                    <div css={{ textAlign: "center", padding: "1rem" }}>
                      <span css={{ fontWeight: "bold", marginRight: "12px" }}>
                        食べログTakeout連携中
                      </span>
                      別のアカウントを連携する場合は、再度アカウント連携してください。
                    </div>
                  ) : (
                    <div css={{ textAlign: "center", padding: "1rem" }}>
                      食べログTakeout店舗管理画面で使用しているログイン情報を入力してください。
                    </div>
                  )}

                  <Form.Input
                    name="id"
                    label="ログインID"
                    error={errors.id}
                    value={values.id}
                    onChange={handleChange}
                  />
                  <Form.Input
                    name="password"
                    label="パスワード"
                    type="password"
                    error={errors.password}
                    value={values.password}
                    onChange={handleChange}
                  />
                  <div css={{ textAlign: "center", margin: "2rem" }}>
                    <Button
                      type="submit"
                      content="アカウントを連携する"
                      color="blue"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={(event) => {
                        handleSubmit();
                        event.preventDefault();
                      }}
                    />
                    <div css={{ margin: "1rem" }}>
                      <a
                        href="https://owner.tabelog-takeout.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        管理画面にログインする
                      </a>
                    </div>
                  </div>
                  <LinkMessage />
                </Form>
              </>
            )}
          </Formik>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "EPARK",
      pane: (
        <Tab.Pane>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, formikHelpers) =>
              externalTakeoutRepository
                .linkTakeout({
                  isAgency,
                  storeId,
                  takeoutId: values.id,
                  takeoutPassword: values.password,
                  takeoutCode: values.code,
                  takeoutToken: values.token,
                  takeoutSite: "epark",
                })
                .then(() => {
                  setIsLinkOk(true);
                  setSiteMessage("EPARK");
                })
                .catch(() => setIsLinkOk(false))
                .finally(() => {
                  formikHelpers.setSubmitting(false);
                })
            }
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {({ handleChange, handleSubmit, isSubmitting, values, errors }) => (
              <>
                {isSubmitting ? (
                  <Dimmer active>
                    <Loader
                      active
                      content="EPARK　テイクアウトアカウント登録中"
                      size="large"
                    ></Loader>
                  </Dimmer>
                ) : null}
                <Form onSubmit={handleSubmit}>
                  {isTakeoutEpark ? (
                    <div css={{ textAlign: "center", padding: "1rem" }}>
                      <span css={{ fontWeight: "bold", marginRight: "12px" }}>
                        EPARK連携中
                      </span>
                      別のアカウントを連携する場合は、再度アカウント連携してください。
                    </div>
                  ) : (
                    <div css={{ textAlign: "center", padding: "1rem" }}>
                      EPARK店舗管理画面で使用しているログイン情報を入力してください。
                    </div>
                  )}
                  <Form.Input
                    name="id"
                    label="ログインID"
                    error={errors.id}
                    value={values.id}
                    onChange={handleChange}
                  />
                  <Form.Input
                    name="password"
                    label="パスワード"
                    type="password"
                    error={errors.password}
                    value={values.password}
                    onChange={handleChange}
                  />
                  <div css={{ textAlign: "center", margin: "2rem" }}>
                    <Button
                      type="submit"
                      content="アカウントを連携する"
                      color="blue"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={(event) => {
                        handleSubmit();
                        event.preventDefault();
                      }}
                    />
                    <div css={{ margin: "1rem" }}>
                      <a
                        href="https://takeout.epark.jp/business/login"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        管理画面にログインする
                      </a>
                    </div>
                  </div>
                  <LinkMessage />
                </Form>
              </>
            )}
          </Formik>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "menu",
      pane: (
        <Tab.Pane>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, formikHelpers) =>
              externalTakeoutRepository
                .linkTakeout({
                  isAgency,
                  storeId,
                  takeoutId: values.id,
                  takeoutPassword: values.password,
                  takeoutCode: "",
                  takeoutToken: "",
                  takeoutSite: "menu",
                })
                .then(() => {
                  setIsLinkOk(true);
                  setSiteMessage("menu");
                })
                .catch(() => setIsLinkOk(false))
                .finally(() => {
                  formikHelpers.setSubmitting(false);
                })
            }
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {({ handleChange, handleSubmit, isSubmitting, values, errors }) => (
              <>
                {isSubmitting ? (
                  <Dimmer active>
                    <Loader
                      active
                      content="menu　テイクアウトアカウント登録中"
                      size="large"
                    ></Loader>
                  </Dimmer>
                ) : null}
                <Form onSubmit={handleSubmit}>
                  {isTakeoutMenu ? (
                    <div css={{ textAlign: "center", padding: "1rem" }}>
                      <span css={{ fontWeight: "bold", marginRight: "12px" }}>
                        menu連携中
                      </span>
                      別のアカウントを連携する場合は、再度アカウント連携してください。
                    </div>
                  ) : (
                    <div css={{ textAlign: "center", padding: "1rem" }}>
                      menu店舗管理画面で使用しているログイン情報を入力してください。
                    </div>
                  )}
                  <Form.Input
                    name="id"
                    label="ログインID"
                    error={errors.id}
                    value={values.id}
                    onChange={handleChange}
                  />
                  <Form.Input
                    name="password"
                    label="パスワード"
                    type="password"
                    error={errors.password}
                    value={values.password}
                    onChange={handleChange}
                  />
                  <div css={{ textAlign: "center", margin: "2rem" }}>
                    <Button
                      type="submit"
                      content="アカウントを連携する"
                      color="blue"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={(event) => {
                        handleSubmit();
                        event.preventDefault();
                      }}
                    />
                    <div css={{ margin: "1rem" }}>
                      <a
                        href="https://management.console.menu.inc/chain/login/index"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        管理画面にログインする
                      </a>
                    </div>
                  </div>
                  <LinkMessage />
                </Form>
              </>
            )}
          </Formik>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "出前館",
      pane: (
        <Tab.Pane>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, formikHelpers) =>
              externalTakeoutRepository
                .linkTakeout({
                  isAgency,
                  storeId,
                  takeoutId: values.id,
                  takeoutPassword: values.password,
                  takeoutCode: values.code,
                  takeoutToken: "",
                  takeoutSite: "demaecan",
                })
                .then(() => {
                  setIsLinkOk(true);
                  setSiteMessage("出前館");
                })
                .catch(() => setIsLinkOk(false))
                .finally(() => {
                  formikHelpers.setSubmitting(false);
                })
            }
            validationSchema={validationSchemaForDemaecan}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {({ handleChange, handleSubmit, isSubmitting, values, errors }) => (
              <>
                {isSubmitting ? (
                  <Dimmer active>
                    <Loader
                      active
                      content="出前館　テイクアウトアカウント登録中"
                      size="large"
                    ></Loader>
                  </Dimmer>
                ) : null}
                <Form onSubmit={handleSubmit}>
                  {isTakeoutDemaecan ? (
                    <div css={{ textAlign: "center", padding: "1rem" }}>
                      <span css={{ fontWeight: "bold", marginRight: "12px" }}>
                        出前舘連携中
                      </span>
                      別のアカウントを連携する場合は、再度アカウント連携してください。
                    </div>
                  ) : (
                    <div css={{ textAlign: "center", padding: "1rem" }}>
                      出前館
                      店舗管理画面で使用しているログイン情報を入力してください。
                    </div>
                  )}
                  <Form.Input
                    name="code"
                    label="コード"
                    error={errors.code}
                    value={values.code}
                    onChange={handleChange}
                  />
                  <Form.Input
                    name="id"
                    label="ログインID"
                    error={errors.id}
                    value={values.id}
                    onChange={handleChange}
                  />
                  <Form.Input
                    name="password"
                    label="パスワード"
                    type="password"
                    error={errors.password}
                    value={values.password}
                    onChange={handleChange}
                  />
                  <div css={{ textAlign: "center", margin: "2rem" }}>
                    <Button
                      type="submit"
                      content="アカウントを連携する"
                      color="blue"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={(event) => {
                        handleSubmit();
                        event.preventDefault();
                      }}
                    />
                    <div css={{ margin: "1rem" }}>
                      <a
                        href="https://partner.demae-can.com/Admin/storelogin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        管理画面にログインする
                      </a>
                    </div>
                  </div>
                  <LinkMessage />
                </Form>
              </>
            )}
          </Formik>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "トレタ",
      pane: (
        <Tab.Pane>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, formikHelpers) => {
              return externalTakeoutRepository
                .linkTakeout({
                  isAgency,
                  storeId,
                  takeoutId: "",
                  takeoutPassword: "",
                  takeoutCode: "",
                  takeoutToken: values.token,
                  takeoutSite: "toreta",
                })
                .then(() => {
                  setIsLinkOk(true);
                  setSiteMessage("トレタ");
                })
                .catch(() => setIsLinkOk(false))
                .finally(() => {
                  formikHelpers.setSubmitting(false);
                });
            }}
            validationSchema={validationSchemaForToreta}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {({ handleChange, handleSubmit, isSubmitting, values, errors }) => (
              <>
                {isSubmitting ? (
                  <Dimmer active>
                    <Loader
                      active
                      content="トレタ　テイクアウトアカウント登録中"
                      size="large"
                    ></Loader>
                  </Dimmer>
                ) : null}
                <Form onSubmit={handleSubmit}>
                  {isTakeoutToreta ? (
                    <div css={{ textAlign: "center", padding: "1rem" }}>
                      <span css={{ fontWeight: "bold", marginRight: "12px" }}>
                        トレタ連携中
                      </span>
                      別のアカウントを連携する場合は、再度アカウント連携してください。
                    </div>
                  ) : (
                    <div css={{ textAlign: "center", padding: "1rem" }}>
                      トレタ店舗管理画面で使用しているログイン情報を入力してください。
                    </div>
                  )}
                  <Form.Input
                    name="token"
                    label="トークン"
                    error={errors.token}
                    value={values.token}
                    onChange={handleChange}
                  />
                  <div css={{ textAlign: "center", margin: "2rem" }}>
                    <Button
                      type="submit"
                      content="アカウントを連携する"
                      color="blue"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={(event) => {
                        handleSubmit();
                        event.preventDefault();
                      }}
                    />
                    <div css={{ margin: "1rem" }}>
                      <a
                        href="https://email.toreta.in/e/404882/login/bx3llp/1411400481?h=SmmjQaKF0UdN-CZEzsQnA-C29qtsAy3bqWb23KRuTGQ"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        管理画面にログインする
                      </a>
                    </div>
                  </div>
                  <div css={{ marginTop: "16px" }}>
                    <Message positive attached="bottom">
                      トークンは、
                      <a
                        href="https://developer.squareup.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        こちら
                      </a>{" "}
                      からトレタのアカウントでサインして取得してください
                    </Message>
                  </div>
                  <LinkMessage />
                </Form>
              </>
            )}
          </Formik>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <h3 className="ui block header">アカウント連携設定</h3>
      <Form>
        <Tab panes={panes} renderActiveOnly={false} />
      </Form>
    </>
  );
};
