import React from "react";
import { useHistory } from "react-router-dom";

import routes from "utils/routes";

import { AgencyNewForm } from "components/Agency/AgencyNew/AgencyNewForm";

import PageContainer from "components/lib/PageContainer";

import { Button } from "semantic-ui-react";

type Props = {};

export const AgencyNew: React.FC<Props> = () => {
  const history = useHistory();

  return (
    <PageContainer
      header="新規代理店作成"
      action={
        <Button
          color="blue"
          onClick={() => history.push(routes.adminAgency.agencyIndex)}
        >
          一覧へ
        </Button>
      }
    >
      <div css={{ width: "50%", margin: "auto" }}>
        <AgencyNewForm />
      </div>
    </PageContainer>
  );
};
