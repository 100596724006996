import React, { useEffect, useState } from "react";
import { generatePath, useParams, useHistory } from "react-router-dom";
import {
  Segment,
  Loader,
  Header,
  Button,
  Message,
  Item,
} from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import NotValidOption from "components/SiteManage/Content/lib/NotValidOption";
import { messageTemplateService } from "domain/service";
import { UserContainer, StoreContainer } from "containers";
import { AGENCY_OPTION, StoreIdParam } from "config/raven";
import routes from "utils/routes";
import MessageTemplate from "domain/model/MessageTemplate";
import styled from "@emotion/styled";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

const AGENCY: AGENCY_OPTION = require("config/agency.json");

const Container: React.FC = () => {
  const { isAgency } = UserContainer.useContainer();
  const { currentStore } = StoreContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();
  const [messageTemplates, setMessageTemplates] = useState<
    MessageTemplate[] | null
  >(null);
  const history = useHistory();
  const agencyEnabled = AGENCY[window.location.hostname].options.takeout;

  useEffect(() => {
    messageTemplateService
      .fetchAllMessageTemplates(isAgency, storeId)
      .then(setMessageTemplates);
  }, [isAgency, storeId]);

  if (!agencyEnabled) {
    return <NotValidOption heading="テイクアウト機能" />;
  }

  if (!currentStore || !messageTemplates) {
    return <Loader active />;
  }

  const { useTakeout } = currentStore.property;

  if (!useTakeout) {
    return <Message content="テイクアウト機能が有効になっていません" />;
  }

  return (
    <>
      <Header content="メッセージテンプレート設定" dividing />
      <Item.Group>
        {messageTemplates.map((messageTemplate, i) => (
          <Item
            key={messageTemplate.messageType}
            style={{
              paddingBottom: 8,
              marginBottom: 8,
              borderBottom:
                i + 1 === messageTemplates.length ? "none" : "1px solid #eee",
            }}
          >
            <Item.Content>
              <Item.Header
                style={{
                  color: messageTemplate.active ? "#111" : "#aaa",
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!messageTemplate.active && (
                  <DisabledLabel>停止中</DisabledLabel>
                )}
                {messageTemplate.title}
              </Item.Header>
              <Item.Description
                style={{
                  color: messageTemplate.active ? "#111" : "#aaa",
                }}
              >
                {messageTemplate.body}
              </Item.Description>
              <Item.Extra
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ color: "#777", fontWeight: "bolder" }}>
                  {messageTemplate.description}
                </p>
                <Button
                  primary
                  floated="right"
                  style={{ marginLeft: "auto", marginRight: 0 }}
                  onClick={() => {
                    const path = isAgency
                      ? generatePath(
                          routes.agency.takeoutMessageTemplateEdit.pathname,
                          {
                            storeId,
                            messageType: messageTemplate.messageType,
                          }
                        )
                      : generatePath(
                          routes.store.takeoutMessageTemplateEdit.pathname,
                          {
                            messageType: messageTemplate.messageType,
                          }
                        );
                    history.push(path);
                  }}
                >
                  編集
                </Button>
              </Item.Extra>
            </Item.Content>
          </Item>
        ))}
      </Item.Group>
    </>
  );
};

const TakeoutMessageTemplate = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="テイクアウト通知設定"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("テイクアウト通知設定")}
    >
      <Segment padded>
        <Container />
      </Segment>
    </PageContainer>
  );
};

const DisabledLabel = styled.div`
  font-size: 1rem;
  border: 1px solid #ccc;
  padding: 0.1em 0.5em;
  margin-right: 0.5rem;
  border-radius: 5px;
  display: inline-block;
`;

export default TakeoutMessageTemplate;
