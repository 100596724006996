import { Expose } from "class-transformer";
import dayjs from "dayjs";

export default class Yaml {
  @Expose() id: string;
  @Expose() title: string;
  @Expose() slug: string;
  @Expose() body: string;
  @Expose() parent: string;
  @Expose() active: boolean;
  @Expose() public_url: string;

  @Expose({ name: "created_at" })
  createdAtStr: string;
  @Expose({ name: "created_at" })
  get createdAt() {
    return dayjs(Number(this.createdAtStr) * 1000).format("YYYY/MM/DD HH:mm");
  }
}
