export const fetchDom = () => {
  const elm = document.getElementById("iframe");
  const doc = elm.contentDocument || elm.contentWindow.document;
  const html = `<!DOCTYPE html>\n${doc.documentElement.outerHTML}`;

  return html;
};

export const replaceImage = (target, src) => {
  const { tagName, style } = target;

  if (tagName === "IMG") {
    target.src = src;
  }

  if (style.backgroundImage) {
    target.style.backgroundImage = `url("${src}")`;
  }

  if (style.background) {
    const splited = target.style.background.split(" ");
    const srcReplacedBackground = splited
      .map((str) => str.match("url") && `url("${src}")`)
      .join(" ");
    target.style.background = srcReplacedBackground;
  }
};

export const editAnchor = (target, href) => {
  if (target.tagName === "A") target.href = href;
};

export const replaceText = (target, text) => {
  target.innerHTML = text;
};

export const replaceOuterHTML = (target, newOuterHTML) => {
  target.innerHTML = newOuterHTML;
};

export const removeDOM = (target) => {
  if (target.parentNode) {
    target.parentNode.removeChild(target);
  }
};

export const formatTag = (target) => {
  const { tagName, id, className } = target;
  const idName = id ? `#${id}` : "";
  const classes = className
    ? className.split(" ").reduce((prev, cur) => `${prev}.${cur}`, "")
    : "";
  return `${tagName.toLowerCase()}${idName}${classes}`;
};

export const imageExist = (target) => {
  const { tagName, style } = target;

  if (tagName === "IMG" || style.backgroundImage || style.background) {
    return true;
  } else {
    return false;
  }
};

export const undo = (e) => {
  e.preventDefault();
  const iframe = document.getElementById("iframe");
  iframe.contentDocument.execCommand("undo");
};

export const redo = (e) => {
  e.preventDefault();
  const iframe = document.getElementById("iframe");
  iframe.contentDocument.execCommand("redo");
};

export default fetchDom;
