import React, { useEffect } from "react";
import { Table, Grid } from "semantic-ui-react";
import c3 from "c3";
import "c3/c3.css";

import { TakeoutSales } from "domain/model/ExternalTakeout";

type Props = {
  orderHistoryTabelog: TakeoutSales[] | null;
  orderHistoryEpark: TakeoutSales[] | null;
  orderHistoryMenu: TakeoutSales[] | null;
  orderHistoryDemaecan: TakeoutSales[] | null;
  orderHistoryToreta: TakeoutSales[] | null;
};

export const OrderHistoryContainer: React.FC<Props> = ({
  orderHistoryTabelog,
  orderHistoryEpark,
  orderHistoryMenu,
  orderHistoryDemaecan,
  orderHistoryToreta,
}) => {
  let tabelogOrder = 0;
  if (orderHistoryTabelog && orderHistoryTabelog[0]) {
    tabelogOrder = orderHistoryTabelog.reduce((previousValue, currentValue) => {
      if (currentValue.order !== null) {
        return previousValue + currentValue.order;
      } else {
        return previousValue;
      }
    }, 0);
  }
  let tabelogSales = 0;
  if (orderHistoryTabelog && orderHistoryTabelog[0]) {
    tabelogSales = orderHistoryTabelog.reduce((previousValue, currentValue) => {
      if (currentValue.sales !== null) {
        return previousValue + currentValue.sales;
      } else {
        return previousValue;
      }
    }, 0);
  }

  let eparkOrder = 0;
  if (orderHistoryEpark && orderHistoryEpark[0]) {
    eparkOrder = orderHistoryEpark.reduce((previousValue, currentValue) => {
      if (currentValue.order !== null) {
        return previousValue + currentValue.order;
      } else {
        return previousValue;
      }
    }, 0);
  }
  let eparkSales = 0;
  if (orderHistoryEpark && orderHistoryEpark[0]) {
    eparkSales = orderHistoryEpark.reduce((previousValue, currentValue) => {
      if (currentValue.sales !== null) {
        return previousValue + currentValue.sales;
      } else {
        return previousValue;
      }
    }, 0);
  }

  let menuOrder = 0;
  if (orderHistoryMenu && orderHistoryMenu[0]) {
    menuOrder = orderHistoryMenu.reduce((previousValue, currentValue) => {
      if (currentValue.order !== null) {
        return previousValue + currentValue.order;
      } else {
        return previousValue;
      }
    }, 0);
  }
  let menuSales = 0;
  if (orderHistoryMenu && orderHistoryMenu[0]) {
    menuSales = orderHistoryMenu.reduce((previousValue, currentValue) => {
      if (currentValue.sales !== null) {
        return previousValue + currentValue.sales;
      } else {
        return previousValue;
      }
    }, 0);
  }

  let demaecanOrder = 0;
  if (orderHistoryDemaecan && orderHistoryDemaecan[0]) {
    demaecanOrder = orderHistoryDemaecan.reduce(
      (previousValue, currentValue) => {
        if (currentValue.order !== null) {
          return previousValue + currentValue.order;
        } else {
          return previousValue;
        }
      },
      0
    );
  }
  let demaecanSales = 0;
  if (orderHistoryDemaecan && orderHistoryDemaecan[0]) {
    demaecanSales = orderHistoryDemaecan.reduce(
      (previousValue, currentValue) => {
        if (currentValue.sales !== null) {
          return previousValue + currentValue.sales;
        } else {
          return previousValue;
        }
      },
      0
    );
  }

  let toretaOrder = 0;
  if (orderHistoryToreta && orderHistoryToreta[0]) {
    toretaOrder = orderHistoryToreta.reduce((previousValue, currentValue) => {
      if (currentValue.order !== null) {
        return previousValue + currentValue.order;
      } else {
        return previousValue;
      }
    }, 0);
  }
  let toretaSales = 0;
  if (orderHistoryToreta && orderHistoryToreta[0]) {
    toretaSales = orderHistoryToreta.reduce((previousValue, currentValue) => {
      if (currentValue.sales !== null) {
        return previousValue + currentValue.sales;
      } else {
        return previousValue;
      }
    }, 0);
  }

  useEffect(() => {
    c3.generate({
      padding: {
        left: 60,
        right: 60,
      },
      bindto: "#chart",
      data: {
        columns: [
          [
            "注文数",
            tabelogOrder,
            eparkOrder,
            menuOrder,
            demaecanOrder,
            toretaOrder,
          ],
          [
            "売上",
            tabelogSales,
            eparkSales,
            menuSales,
            demaecanSales,
            toretaSales,
          ],
        ],
        type: "bar",
        types: {
          売上: "line",
        },
        axes: {
          注文数: "y2",
          売上: "y",
        },
      },
      axis: {
        x: {
          padding: {
            left: 1,
            right: 1,
          },
          type: "category",
          categories: ["食べログTakeout", "EPARK", "menu", "出前舘", "トレタ"],
        },
        y2: {
          show: true,
        },
      },
    });
  }, [
    tabelogOrder,
    tabelogSales,
    eparkOrder,
    eparkSales,
    menuOrder,
    menuSales,
    demaecanOrder,
    demaecanSales,
    toretaOrder,
    toretaSales,
  ]);

  return (
    <>
      <div id="chart" />

      <Grid centered>
        <Grid.Column width={12}>
          <Table striped celled>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell css={{ width: "16rem" }}>
                  サイト名
                </Table.HeaderCell>
                <Table.HeaderCell css={{ width: "10rem" }}>
                  注文数
                </Table.HeaderCell>
                <Table.HeaderCell css={{ width: "10rem" }}>
                  売上
                </Table.HeaderCell>
                <Table.HeaderCell css={{ width: "10rem" }}>
                  平均単価
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>食べログTakeout</Table.Cell>
                <Table.Cell textAlign="right">{tabelogOrder}</Table.Cell>
                <Table.Cell textAlign="right">{tabelogSales}</Table.Cell>
                <Table.Cell textAlign="right">
                  {(tabelogOrder / tabelogSales) | 0}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>EPARK</Table.Cell>
                <Table.Cell textAlign="right">{eparkOrder}</Table.Cell>
                <Table.Cell textAlign="right">{eparkSales}</Table.Cell>
                <Table.Cell textAlign="right">
                  {(eparkOrder / eparkSales) | 0}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>menu</Table.Cell>
                <Table.Cell textAlign="right">{menuOrder}</Table.Cell>
                <Table.Cell textAlign="right">{menuSales}</Table.Cell>
                <Table.Cell textAlign="right">
                  {(menuOrder / menuSales) | 0}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>出前舘</Table.Cell>
                <Table.Cell textAlign="right">{demaecanOrder}</Table.Cell>
                <Table.Cell textAlign="right">{demaecanSales}</Table.Cell>
                <Table.Cell textAlign="right">
                  {(demaecanOrder / demaecanSales) | 0}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>トレタ</Table.Cell>
                <Table.Cell textAlign="right">{toretaOrder}</Table.Cell>
                <Table.Cell textAlign="right">{toretaSales}</Table.Cell>
                <Table.Cell textAlign="right">
                  {(toretaOrder / toretaSales) | 0}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </>
  );
};
