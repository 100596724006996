import React from "react";

import { Segment, Form, Button } from "semantic-ui-react";

import { useFormik } from "formik";
import * as Yup from "yup";

import Store from "domain/model/Store";

type FormValues = {
  useInvoice: boolean;
  issuerName: string;
  issuerNumber: string;
};

const validationSchema = Yup.object().shape({
  issuerName: Yup.string().required("適格簡易請求書発行時の必須項目です"),
  issuerNumber: Yup.string().required("適格簡易請求書発行時の必須項目です"),
});

type Props = {
  initialValues: FormValues;
  currentStore: Store;
  handleSubmit: ({
    values,
    resetForm,
  }: {
    values: FormValues;
    resetForm: () => void;
  }) => void;
};

const InvoiceSettingForm: React.FC<Props> = ({
  initialValues,
  handleSubmit,
}) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      handleSubmit({ values, resetForm });
    },
    initialStatus: { success: "", error: "" },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <Segment>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Field>
          <Form.Checkbox
            name="useInvoice"
            label="適格簡易請求書を発行する"
            checked={formik.values.useInvoice}
            onChange={() =>
              formik.setFieldValue("useInvoice", !formik.values.useInvoice)
            }
          />
          <label>発行事業者名</label>
          <Form.Input
            name="issuerName"
            error={formik.errors.issuerName}
            value={formik.values.issuerName}
            onChange={formik.handleChange}
            disabled={!formik.values.useInvoice}
          />
          <label>登録番号</label>
          <Form.Input
            name="issuerNumber"
            error={formik.errors.issuerNumber}
            value={formik.values.issuerNumber}
            onChange={formik.handleChange}
            disabled={!formik.values.useInvoice}
          />
        </Form.Field>
        <div style={{ textAlign: "center" }}>
          <Button color="blue" content="更新" type="submit" />
        </div>
      </Form>
    </Segment>
  );
};

export default InvoiceSettingForm;
