import { Expose } from "class-transformer";

export default class AgencyUser {
  @Expose() id: number;

  @Expose({ name: "name" }) staffName: string;

  @Expose({ name: "email" }) staffEmail: string;
  @Expose() role: string;
}
