import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MetaContainer } from "containers";

const HeadProvider = () => {
  const { pathname } = useLocation();
  return <Head pathname={pathname} />;
};

const Head: React.FC<{ pathname: string }> = ({ pathname }) => {
  const metaContainer = MetaContainer.useContainer();

  useEffect(() => {
    metaContainer.handleTitle(pathname);
  }, [pathname, metaContainer]);
  return (
    <Helmet>
      <title>{metaContainer.title}</title>
      <link
        rel="icon"
        type="image/png"
        href={metaContainer.favicon}
        sizes="16x16"
      />
    </Helmet>
  );
};

export default HeadProvider;
