import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";

import Store from "domain/model/Store";

type storeQuery = {
  page?: number;
  search?: string;
};

class StoreService {
  public async fetchStores({ page, search }: storeQuery) {
    return api
      .get(endpoint.agency.stores.root(), { params: { page, search } })
      .then((res) => {
        const responce: { stores: Store[]; total: number } = {
          stores: res.data.stores.map((ele: Store) =>
            plainToClass(Store, ele, {
              excludeExtraneousValues: true,
            })
          ),
          total: res.data.total,
        };

        return responce;
      });
  }

  public async fetchStore(
    isAgency: boolean,
    storeId: string | undefined
  ): Promise<Store> {
    const url =
      isAgency && storeId
        ? endpoint.agency.stores.show(storeId)
        : endpoint.store.store.root();
    return api.get(url).then((res) => {
      return plainToClass(Store, res.data, { excludeExtraneousValues: true });
    });
  }

  public async fetchAccessKey(storeId: string): Promise<string> {
    return api
      .get(endpoint.agency.stores.accessKey(storeId))
      .then((res) => res.data.access_key);
  }
}

export default new StoreService();
