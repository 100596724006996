import React from "react";
import { Segment, Header, Grid } from "semantic-ui-react";
import { generatePath, useParams, useHistory } from "react-router-dom";
import MenuItem from "components/lib/MenuItem";
import routes from "utils/routes";
import { StoreIdParam } from "config/raven";
import { StoreContainer } from "containers";

type Props = {
  isAgency: boolean;
};

const MenuCard: React.FC<Props> = ({ isAgency }) => {
  const { push } = useHistory();
  const { storeId } = useParams<StoreIdParam>();
  const { currentStore } = StoreContainer.useContainer();
  const useTakeout = currentStore?.property?.useTakeout || false;

  const versionPath = isAgency
    ? generatePath(routes.agency.versionIndex.pathname, { storeId })
    : routes.store.versionIndex.pathname;

  const articlePath = isAgency
    ? generatePath(routes.agency.articleIndex.pathname, { storeId })
    : routes.store.articleIndex.pathname;

  const newsPath = isAgency
    ? {
        pathname: generatePath(routes.agency.newsIndex.pathname, {
          storeId,
        }),
        state: { isNews: true },
      }
    : {
        pathname: routes.store.newsIndex.pathname,
        state: { isNews: true },
      };

  const mediaPath = isAgency
    ? generatePath(routes.agency.mediaIndex.pathname, { storeId })
    : routes.store.mediaIndex.pathname;

  const sitePath = isAgency
    ? generatePath(routes.agency.siteManage.pathname, { storeId })
    : routes.store.siteManage.pathname;

  const contactPath = isAgency
    ? generatePath(routes.agency.contactIndex.pathname, { storeId })
    : routes.store.contactIndex.pathname;

  const jobOfferPath = isAgency
    ? generatePath(routes.agency.applicantsIndex.pathname, { storeId })
    : routes.store.applicantsIndex.pathname;

  const storePath = isAgency
    ? generatePath(routes.agency.storeProfileShow.pathname, { storeId })
    : routes.store.storeProfileEdit.pathname;

  const orderPath = isAgency
    ? generatePath(routes.agency.orderIndex.pathname, { storeId })
    : routes.store.orderIndex.pathname;

  const productPath = isAgency
    ? generatePath(routes.agency.productIndex.pathname, { storeId })
    : routes.store.productIndex.pathname;

  return (
    <Segment>
      <Header content="コンテンツ" color="grey" />

      <Grid columns={2} doubling>
        <Grid.Row>
          <Grid.Column>
            <MenuItem
              icon="zip"
              header="バージョン管理"
              content="zipファイルアップロード・編集"
              color="olive"
              onClick={() => push(versionPath)}
            />
          </Grid.Column>
          <Grid.Column>
            <MenuItem
              icon="home"
              header="店舗情報"
              content="店舗情報の確認・編集"
              color="grey"
              onClick={() => push(storePath)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <MenuItem
              icon="picture"
              header="メディア管理"
              content="メディアの一覧・削除"
              color="blue"
              onClick={() => push(mediaPath)}
            />
          </Grid.Column>
          <Grid.Column>
            <MenuItem
              icon="globe"
              header="サイト管理"
              content="ドメイン設定・SEO管理・DNSレコード管理・各種オプション設定等"
              color="brown"
              onClick={() => push(sitePath)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <MenuItem
              icon="bell"
              header="お知らせ管理"
              content="お知らせの追加・更新・削除"
              color="yellow"
              onClick={() => push(newsPath)}
            />
          </Grid.Column>
          <Grid.Column>
            <MenuItem
              icon="book"
              header="記事管理"
              content="記事の追加・更新・削除"
              color="red"
              onClick={() => push(articlePath)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <MenuItem
              icon="male"
              header="求人管理"
              content="求人一覧・フォーム作成等"
              color="teal"
              onClick={() => push(jobOfferPath)}
            />
          </Grid.Column>
          <Grid.Column>
            <MenuItem
              icon="mail"
              header="問い合わせ管理"
              content="問い合わせ一覧・フォーム作成等"
              color="violet"
              onClick={() => push(contactPath)}
            />
          </Grid.Column>
        </Grid.Row>
        {useTakeout && (
          <Grid.Row>
            <Grid.Column>
              <MenuItem
                icon="shop"
                header="受注管理"
                content="受注一覧"
                color="pink"
                onClick={() => push(orderPath)}
              />
            </Grid.Column>
            <Grid.Column>
              <MenuItem
                icon="box"
                header="商品管理"
                content="商品の追加・更新・削除"
                color="orange"
                onClick={() => push(productPath)}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Segment>
  );
};

export default React.memo(MenuCard);
