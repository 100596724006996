import React, { useEffect, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import routes from "utils/routes";
import { Button, Loader, Message } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import { StoreIdParam } from "config/raven";
import Product from "domain/model/Product";
import { ProductList } from "components/Product/Index/ProductList";
import { productService } from "domain/service";
import { StoreContainer, UserContainer } from "containers";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";

export const ProductIndex = () => {
  const userContainer = UserContainer.useContainer();
  const { isAgency } = userContainer;
  const history = useHistory();
  const { storeId } = useParams<StoreIdParam>();
  const [products, setProducts] = useState<Product[] | null>(null);
  const { currentStore } = StoreContainer.useContainer();
  const useTakeout = currentStore?.property?.useTakeout || false;

  useEffect(() => {
    productService
      .fetchAllProducts(isAgency, storeId)
      .then((res) => setProducts(res));
  }, [isAgency, storeId]);

  if (!useTakeout) {
    return <Message content="テイクアウト機能が有効になっていません" />;
  }
  if (products === null) {
    return <Loader active />;
  }

  return (
    <>
      <PageContainer
        header="商品一覧"
        breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId).build(
          "商品一覧"
        )}
        action={
          <>
            <Button
              color="blue"
              content="新規商品作成"
              onClick={() => {
                const url = isAgency
                  ? generatePath(routes.agency.productNew.pathname, {
                      storeId,
                    })
                  : routes.store.productNew;
                history.push(url);
              }}
            />
            <Button
              content="インポート"
              onClick={() => {
                const url = isAgency
                  ? generatePath(routes.agency.productImport.pathname, {
                      storeId,
                    })
                  : routes.store.productImport;
                history.push(url);
              }}
            />
            <Button
              content="エクスポート"
              onClick={() => {
                const url = isAgency
                  ? generatePath(routes.agency.productExport.pathname, {
                      storeId,
                    })
                  : routes.store.productExport;
                history.push(url);
              }}
            />
          </>
        }
      >
        <ProductList
          products={products}
          isAgency={isAgency}
          storeId={storeId}
        />
      </PageContainer>
    </>
  );
};
