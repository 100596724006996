import { useState } from "react";
import { createContainer } from "unstated-next";
import { classToPlain, plainToClass } from "class-transformer";

import { storeService } from "domain/service";
import { CurrentStore } from "config/raven";
import Store from "domain/model/Store";

const StoreContainer = () => {
  const [currentStore, setCurrentStore] = useState<CurrentStore>(null);

  const changeCurrentStore = async (
    isAgency: boolean,
    storeId: string | undefined
  ) => {
    return storeService
      .fetchStore(isAgency, storeId)
      .then((res) => setCurrentStore(res));
  };

  const changeHeadVersion = (versionHash: string) => {
    const newCurrentStore = {
      ...classToPlain(currentStore),
      head_version: versionHash,
    };
    setCurrentStore(
      plainToClass(Store, newCurrentStore, { excludeExtraneousValues: true })
    );
  };

  return {
    currentStore,
    changeCurrentStore,
    changeHeadVersion,
  };
};

export default createContainer(StoreContainer);
