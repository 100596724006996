import { plainToClass } from "class-transformer";
import api from "utils/api";
import endpoint from "utils/endpoint";
import Notification from "domain/model/Notification";

class notificationRepository {
  public async createNotification({
    title,
    content,
  }: {
    title: string;
    content: string;
  }) {
    return api
      .post(endpoint.agency.notification.root(), {
        notification: { title, content },
      })
      .then((res) =>
        plainToClass(Notification, res.data, { excludeExtraneousValues: true })
      );
  }
  public async updateNotification({
    id,
    title,
    content,
  }: {
    id: string;
    title: string;
    content: string;
  }) {
    return api.put(endpoint.agency.notification.show(id), {
      notification: { title, content },
    });
  }
  public async deleteNotification({ id }: { id: string }) {
    return api.delete(endpoint.agency.notification.show(id));
  }
}

export default new notificationRepository();
