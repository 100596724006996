import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import COLOR from "config/color.json";
import {
  agencyMenus,
  storeMenus,
  SidebarConfig,
} from "components/Layout/utils/sidebarConfig";
import { UserContainer, StoreContainer } from "containers";
import SidebarShortcutMenu from "components/Layout/utils/SidebarShortcutMenu";

type MenuItemProps = {
  main: SidebarConfig;
};

export const SidebarContent = () => {
  const userContainer = UserContainer.useContainer();
  const { userType } = userContainer;
  const currentStore = StoreContainer.useContainer().currentStore;
  const useTakeout = currentStore?.property?.useTakeout || false;
  const useTakeoutDashboard =
    currentStore?.property?.useTakeoutDashboard || false;

  const storeId = currentStore?.id;

  if (userType === "agency" && storeId === undefined) {
    return null;
  }

  const menus =
    userType === "agency"
      ? agencyMenus(String(storeId), { useTakeout, useTakeoutDashboard })
      : storeMenus({ useTakeout, useTakeoutDashboard });

  return (
    <div>
      <div
        css={{
          height: "100%",
          backgroundColor: "black",
        }}
      >
        {menus
          .filter(({ disabled }) => !disabled)
          .map((main, index) => (
            <MenuItem key={index} main={main} />
          ))}
      </div>
      <SidebarShortcutMenu />
    </div>
  );
};

const MenuItem: React.FC<MenuItemProps> = ({ main }) => {
  const [open, setOpen] = useState(true); // デフォルトで全て表示したほうがよいか要検討
  const history = useHistory();
  return (
    <div>
      <div
        css={{
          display: "flex",
          fontSize: "1rem",
          color: COLOR.ACCENT,
          padding: "1rem",
          cursor: "pointer",
          borderBottom: `1px solid ${COLOR.SUB3}`,
          "&:hover": {
            backgroundColor: COLOR.SUB3,
          },
        }}
        onClick={() => {
          if (main.subMenu) {
            setOpen(!open);
          } else if (main.path) {
            history.push(main.path);
          }
        }}
      >
        <Icon name={main.icon} inverted css={{ flexBasis: "25%" }} />
        <p css={{ textAlign: "center" }}>{main.title}</p>
      </div>
      {main.subMenu &&
        open &&
        main.subMenu
          .filter(({ disabled }) => !disabled)
          .map((sub, i) => (
            <div
              key={i}
              css={{
                backgroundColor: COLOR.MAIN,
                padding: "0.8rem",
                color: COLOR.ACCENT,
                textAlign: "center",
                borderBottom: `1px solid ${COLOR.SUB3}`,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: COLOR.SUB3,
                },
              }}
              onClick={() => {
                history.push(sub.path);
              }}
            >
              {sub.title}
            </div>
          ))}
    </div>
  );
};
