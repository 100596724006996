import React from "react";
import styled from "styled-components";
import { SourceCodeEditor } from "components/lib/Editor/SourceCodeEditor";
import Typography from "./Typography";
import Wrap from "./Wrap";
import color from "./color";

const ScrollBox = styled.div`
  border: 1px solid ${color.grayLight};
  box-sizing: border-box;
  height: 100px;
  overflow-y: scroll;
`;

export default ({ outerHTML, setOuterHTML, active }) => {
  const activeMessage = "（こちらの変更が反映されます。）";
  return (
    <Wrap margin="20px 0">
      <Typography margin="0 0 5px" color={color.black}>
        選択中のHTMLタグ情報{active && activeMessage}
      </Typography>
      <ScrollBox>
        <SourceCodeEditor
          source={outerHTML}
          fileType="html"
          handleChange={(content) => {
            if (active) {
              setOuterHTML(content);
            }
          }}
          height="100px"
          readOnly={!active}
        />
      </ScrollBox>
    </Wrap>
  );
};
