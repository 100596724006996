import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import PageContainer from "components/lib/PageContainer";
import UserContainer from "containers/User";
import routes from "utils/routes";
import staffService from "domain/service/staff";

import Staff from "domain/model/Staff";
import { StaffList } from "components/AgencyStaff/Index/StaffList";
import { Button, Loader } from "semantic-ui-react";

export type Props = {};

export const RoleTypes = {
  ADMIN: "admin",
  NORMAL: "normal",
};

export type RoleType = (typeof RoleTypes)[keyof typeof RoleTypes] | undefined;

export const AgencyStaffIndex: React.FC<Props> = () => {
  const useContainer = UserContainer.useContainer();
  const { currentUser } = useContainer;
  const userType: RoleType = currentUser?.role;
  const [staffs, setStaffs] = useState<Staff[] | null>(null);
  const staffNewEditUrl = routes.adminAgency.agencyStaffNew.pathname;
  const history = useHistory();

  useEffect(() => {
    const fetchStaffs = () => {
      staffService.fetchAllStaff().then((res) => {
        setStaffs(res);
      });
    };
    fetchStaffs();
  }, [setStaffs]);

  return (
    <PageContainer
      header="代理店スタッフ一覧"
      action={
        <>
          {userType === RoleTypes.ADMIN ? (
            <Button color="blue" onClick={() => history.push(staffNewEditUrl)}>
              スタッフ新規作成
            </Button>
          ) : (
            <></>
          )}
        </>
      }
    >
      {staffs ? (
        <StaffList staffs={staffs} userType={userType} />
      ) : (
        <Loader active />
      )}
    </PageContainer>
  );
};
