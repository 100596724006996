import { useCallback, useEffect, useState } from "react";
import { mediaService } from "domain/service";

/**
 * メディア管理の全ファイルのURLを取得する
 * (PDFファイルなどが含まれない「画像の一覧」が欲しい場合には、imageOnly=trueを指定すること)
 * mediaUrls: 全ファイルのURLリスト
 */
export const useAllMediaUrls = ({
  isAgency,
  storeId,
  imageOnly, // 画像ファイルだけ欲しい場合には、trueを指定
}: {
  isAgency: boolean;
  storeId: string | undefined;
  imageOnly: boolean;
}) => {
  const [mediaUrls, setMediaUrls] = useState<string[] | null>(null);
  const fetchAllMediaUrls = useCallback(() => {
    let fetchPageNumber = 1;
    const fetchMedias = (page: number) => {
      mediaService
        .fetchMedias(isAgency, storeId, imageOnly, page)
        .then(({ medias, total }) => {
          const urls = medias.map((m) => m.url);

          setMediaUrls((currentMediaUrls) => {
            if (currentMediaUrls === null) {
              return urls;
            } else {
              return [...currentMediaUrls, ...urls];
            }
          });

          // 未取得の画像があるなら、追加で取得
          if (total > fetchPageNumber) {
            fetchMedias(fetchPageNumber + 1);
            fetchPageNumber += 1;
          }
        });
    };
    fetchMedias(fetchPageNumber);
  }, [isAgency, storeId, imageOnly]);
  const refetchMediaUrls = useCallback(() => {
    setMediaUrls(null);
    fetchAllMediaUrls();
  }, [fetchAllMediaUrls]);
  useEffect(() => {
    fetchAllMediaUrls();
  }, [fetchAllMediaUrls]);
  return { mediaUrls, refetchMediaUrls };
};
