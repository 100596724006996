import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import Product, { ProductCategory } from "domain/model/Product";

class ProductService {
  async fetchAllProducts(
    isAgency: boolean,
    storeId: string | undefined
  ): Promise<Product[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.product.root(storeId)
        : endpoint.store.product.root();

    return api.get(url).then((res) => {
      return res.data.products.map((row: Product) =>
        plainToClass(Product, row, { excludeExtraneousValues: true })
      );
    });
  }

  async fetchProduct(
    isAgency: boolean,
    storeId: string | undefined,
    id: string
  ): Promise<Product> {
    const url =
      isAgency && storeId
        ? endpoint.agency.product.show(storeId, id)
        : endpoint.store.product.show(id);

    return api.get(url).then((res) => {
      return plainToClass(Product, res.data.product, {
        excludeExtraneousValues: true,
      });
    });
  }

  async fetchAllProductCategories(
    isAgency: boolean,
    storeId: string | undefined
  ): Promise<ProductCategory[]> {
    const url =
      isAgency && storeId
        ? endpoint.agency.productCategory.root(storeId)
        : endpoint.store.productCategory.root();

    return api.get(url).then((res) => {
      return res.data.product_categories.map((row: ProductCategory) =>
        plainToClass(ProductCategory, row, { excludeExtraneousValues: true })
      );
    });
  }

  async downloadProductsCsv(
    isAgency: boolean,
    storeId: string | undefined
  ): Promise<string> {
    const url =
      isAgency && storeId
        ? endpoint.agency.product.export(storeId)
        : endpoint.store.product.export();

    return api
      .get(url, {
        responseType: "text",
        headers: { "Content-Type": "text/csv", Accept: "text/csv" },
      })
      .then((res) => res.data);
  }

  async downloadProductCategoriesCsv(
    isAgency: boolean,
    storeId: string | undefined
  ): Promise<string> {
    const url =
      isAgency && storeId
        ? endpoint.agency.productCategory.export(storeId)
        : endpoint.store.productCategory.export();

    return api
      .get(url, {
        responseType: "text",
        headers: { "Content-Type": "text/csv", Accept: "text/csv" },
      })
      .then((res) => res.data);
  }
}

export default new ProductService();
