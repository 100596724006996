import api from "utils/api";
import endpoint from "utils/endpoint";

class StaffRepository {
  async staffNew(name: string, email: string, password: string, role: string) {
    const url = endpoint.agency.staff.root();
    const staffBody = {
      name,
      email,
      password,
      role,
    };

    return api.post(url, staffBody);
  }
  async staffUpdate(name: string, email: string, staffId: string) {
    const url = endpoint.agency.staff.update(staffId);
    const staffBody = {
      name,
      email,
    };

    return api.put(url, staffBody);
  }
}

export default new StaffRepository();
