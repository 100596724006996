import React from "react";
import { Button, Table } from "semantic-ui-react";
import {
  DNSRecordType,
  READONLY_DNS_RECORD_TYPE,
} from "domain/model/DNS/DNSRecordType";
import styled from "@emotion/styled";
import { DNSRecord } from "domain/model/DNS/DNSRecordSet";

const isReadOnlyType = (type: DNSRecordType) => {
  if (type === READONLY_DNS_RECORD_TYPE.NS) {
    return true;
  }
  if (type === READONLY_DNS_RECORD_TYPE.SOA) {
    return true;
  }

  return false;
};

const StyledTableCell = styled(Table.Cell)`
  word-break: break-all;
`;

type Props = {
  recordSets: DNSRecord[];
  handleDeleteButton: (recordSet: DNSRecord) => void;
};
export const DNSRecordSetTable: React.FC<Props> = ({
  recordSets,
  handleDeleteButton,
}) => (
  <Table striped>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell css={{ width: "35%" }}>NAME</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "15%" }}>TYPE</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "35%" }}>VALUE</Table.HeaderCell>
        <Table.HeaderCell css={{ width: "15%" }}></Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {recordSets.map(({ name, type, value }) => (
        <Table.Row key={name + type + value}>
          <StyledTableCell>{name}</StyledTableCell>
          <StyledTableCell>{type}</StyledTableCell>
          <StyledTableCell>{value}</StyledTableCell>
          <Table.Cell textAlign="center">
            <Button
              negative
              disabled={isReadOnlyType(type)}
              onClick={() => {
                handleDeleteButton({ name, type, value });
              }}
            >
              削除
            </Button>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);
