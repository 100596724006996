import { Expose, Transform } from "class-transformer";
import dayjs from "dayjs";

export default class Media {
  @Expose() id: number;
  @Expose({ name: "store_id" }) storeId: number;
  @Expose({ name: "content_type" }) contentType: string;
  @Expose() comment: string;
  @Expose() url: string;
  @Expose() width: number;
  @Expose() height: number;
  @Expose() timestamp: number;
  @Expose() filename: string;
  @Expose() filepath: string;
  @Expose() status: string;

  @Expose({ name: "created_at" })
  @Transform((value) => dayjs(value).format("YYYY/MM/DD"))
  createdAt: Date;

  @Expose({ name: "updated_at" })
  @Transform((value) => dayjs(value).format("YYYY/MM/DD"))
  updatedAt: Date;
}
