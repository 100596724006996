import api from "utils/api";
import endpoint from "utils/endpoint";

class UserRepository {
  async accountUpdate(
    isAgency: boolean,
    {
      staffId,
      name,
      email,
    }: {
      staffId: number;
      name: string;
      email: string;
    }
  ) {
    const body = { name, email };
    return api.put(
      endpoint[isAgency ? "agency" : "store"].staff.update(String(staffId)),
      body
    );
  }

  async passwordUpdate(
    isAgency: boolean,
    values: { password: string; password_confirmation: string }
  ) {
    const body = { password: values };
    return api.put(
      endpoint[isAgency ? "agency" : "store"].user.passwordUpdate(),
      body
    );
  }

  async resetPasword(
    token: string,
    password: string,
    passwordConfirmation: string
  ) {
    const body = {
      token,
      password: { password, password_confirmation: passwordConfirmation },
    };
    return api.post(endpoint.common.password.reset(), body);
  }
}

export default new UserRepository();
