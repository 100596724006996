import React from "react";

import Contact from "domain/model/Contact";
import { ContactItem } from "components/Contact/Index/ContactItem";

import { Table } from "semantic-ui-react";

type Props = {
  contacts: Contact[];
  selectedContactId: string | undefined;
  setSelectedContactId: React.Dispatch<string>;
};

export const ContactList: React.FC<Props> = ({
  contacts,
  selectedContactId,
  setSelectedContactId,
}) => {
  return (
    <Table fixed>
      <TableHeader />
      <Table.Body>
        {contacts.map((contact, i) => {
          return (
            <ContactItem
              contact={contact}
              contactNum={i}
              key={i}
              selectedContactId={selectedContactId}
              setSelectedContactId={setSelectedContactId}
            />
          );
        })}
      </Table.Body>
    </Table>
  );
};

const TableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell>受信日時</Table.HeaderCell>
        <Table.HeaderCell>操作</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};
