import React from "react";
import { Segment, Form, Button } from "semantic-ui-react";

import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import Store from "domain/model/Store";
import { PrefectureShippingFeeInputTable } from "components/SiteManage/Content/Takeout/PrefectureShippingFeeInputTable";

type FormValues = {
  shippingFee: string;
  ecCodFee: string;
  ecPrefectureShippingFeesJson: string;
};

const validationSchema = Yup.object().shape({
  shippingFee: Yup.number().test(
    "Is positive?",
    "配送料を指定する場合は0以上の数字を指定してください(配送料無しの場合は空欄)",
    (value) => value == null || value >= 0
  ),
});

type Props = {
  initialValues: FormValues;
  currentStore: Store;
  handleSubmit: ({
    values,
    resetForm,
  }: {
    values: FormValues;
    resetForm: () => void;
  }) => void;
};

const EcSettingForm: React.FC<Props> = ({ initialValues, handleSubmit }) => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      handleSubmit({ values, resetForm });
    },
    initialStatus: { success: "", error: "" },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <Segment>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Field>
          <label>代引き手数料</label>
          <Form.Input
            name="ecCodFee"
            error={formik.errors.ecCodFee}
            value={formik.values.ecCodFee}
            onChange={formik.handleChange}
          />
          <FormHelper>
            代引き指定注文に対して代引き手数料を請求する場合は、金額を指定してください（未指定時は代引き手数料無料となります）
          </FormHelper>
        </Form.Field>
        <Form.Field>
          <label>基本配送料</label>
          <Form.Input
            name="shippingFee"
            error={formik.errors.shippingFee}
            value={formik.values.shippingFee}
            onChange={formik.handleChange}
          />
          <FormHelper>
            注文に対して配送料を請求する場合は、金額を指定してください（未指定時は配送料無料となります）
          </FormHelper>
        </Form.Field>
        <Form.Field>
          <label>都道府県別配送料</label>
          <FormHelper>
            「基本配送料」とは別に、都道府県別に配送料を設定する場合は以下で設定してください。未指定の都道府県は「基本配送料」が適用されます。
          </FormHelper>
          <PrefectureShippingFeeInputTable
            defaultShippingFee={formik.errors.shippingFee}
            ecPrefectureShippingFeesJson={
              formik.values.ecPrefectureShippingFeesJson
            }
            onChange={(value: string) =>
              formik.setFieldValue("ecPrefectureShippingFeesJson", value)
            }
          />
        </Form.Field>
        <div style={{ textAlign: "center" }}>
          <Button color="blue" content="更新" type="submit" />
        </div>
      </Form>
    </Segment>
  );
};

const FormHelper = styled.div`
  color: #888;
  font-size: 0.9rem;
`;

export default EcSettingForm;
