import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";

import Contact from "domain/model/Contact";
import ContactForm from "domain/model/ContactForm";

class ContactService {
  async fetchAllContacts(
    isAgency: boolean,
    storeId?: string,
    createdAt?: string,
    limit: number = 20
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.contact.root(storeId)
        : endpoint.store.contact.root();

    const params = { created_at: createdAt, limit };

    return api
      .get(url, {
        params: params,
      })
      .then((res) => {
        return res.data.contacts
          .sort((a: { created_at: string }, b: { created_at: string }) => {
            const pre: number = parseInt(a.created_at);
            const cur: number = parseInt(b.created_at);
            return cur - pre;
          })
          .map((data: Contact) => {
            return plainToClass(Contact, data, {
              excludeExtraneousValues: true,
            });
          }) as Contact[];
      });
  }

  async fetchAllContactForm(isAgency: boolean, storeId: string | undefined) {
    const url =
      isAgency && storeId
        ? endpoint.agency.contact.forms(storeId)
        : endpoint.store.contact.forms();

    return api.get(url).then((res) => {
      return res.data.forms.map((data: ContactForm) => {
        return plainToClass(ContactForm, data, {
          excludeExtraneousValues: true,
        });
      });
    });
  }

  async fetchContactForm(
    isAgency: boolean,
    storeId: string | undefined,
    formId: string
  ): Promise<ContactForm> {
    const url =
      isAgency && storeId
        ? endpoint.agency.contact.formShow(formId, storeId)
        : endpoint.store.contact.formShow(formId);

    return api.get(url).then((res) => {
      return plainToClass(ContactForm, res.data.form, {
        excludeExtraneousValues: true,
      });
    });
  }
}

export default new ContactService();
