import api from "utils/api";
import endpoint from "utils/endpoint";

class InstagramRepository {
  public async enable(isAgency: boolean, storeId: string) {
    const url = endpoint.agency.instagram.root(storeId);
    return api.post(url);
  }

  public instagramAuth({
    isAgency,
    storeId,
    instagramAccountId,
    instagramHashtag,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    instagramAccountId: string;
    instagramHashtag: string;
  }) {
    const url =
      isAgency && storeId
        ? endpoint.agency.instagram.auth(storeId)
        : endpoint.store.instagram.auth();
    const body = {
      instagram_auth: {
        instagram_account_id: instagramAccountId,
        instagram_hashtag: instagramHashtag,
      },
    };
    return api
      .post(url, body)
      .then((res) => res.data.instagram)
      .catch(() => Promise.reject());
  }
}

export default new InstagramRepository();
