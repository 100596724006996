import { Type, Expose, Transform } from "class-transformer";

export class SeoPreferences {
  @Type(() => Doc)
  @Expose()
  doc: Doc;

  @Type(() => Og)
  @Expose()
  og: Og;

  @Type(() => Twitter)
  @Expose()
  twitter: Twitter;
}

class Doc {
  @Transform((value) => value ?? "")
  @Expose()
  title: string;

  @Transform((value) => value ?? "")
  @Expose()
  description: string;

  @Transform((value) => value ?? "")
  @Expose()
  keywords: string;
}

class Og {
  @Transform((value) => value ?? "")
  @Expose()
  title: string;

  @Transform((value) => value ?? "")
  @Expose()
  description: string;

  @Expose() image: string | null;

  @Transform((value) => value ?? "")
  @Expose({ name: "site_name" })
  siteName: string;
}

class Twitter {
  @Transform((value) => value ?? "")
  @Expose()
  title: string;

  @Transform((value) => value ?? "")
  @Expose()
  description: string;

  @Expose() image: string | null;

  @Transform((value) => value ?? "summary")
  @Expose()
  card: "summary" | "summary_large_image";
}
