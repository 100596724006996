import React, { ErrorInfo } from "react";
import { Button, Segment } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import * as Sentry from "@sentry/react";

// Init Bugsnag
Bugsnag.start({
  apiKey: "b84c445bba4749c7bf91c689673e517a",
  plugins: [new BugsnagPluginReact()],
});
const BugsnagErrorBoundary =
  Bugsnag.getPlugin("react")!.createErrorBoundary(React);

// Init Sentry
Sentry.init({
  dsn: "https://7c375a286d5bf9946187a790d50d943e@o4505798438617088.ingest.sentry.io/4505798442090496",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", "https://api.ravenhp.com/"],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Sentry.ErrorBoundary fallback={<p>エラーが発生しました</p>}>
          <BugsnagErrorBoundary>
            <PageContainer header="エラーが発生しました">
              <Segment>
                <Button as="a" href="/" color="blue" content="トップページへ" />
              </Segment>
            </PageContainer>
          </BugsnagErrorBoundary>
        </Sentry.ErrorBoundary>
      );
    }

    return this.props.children;
  }
}
