import React from "react";
import { useHistory } from "react-router-dom";

import { Table, Icon, Button } from "semantic-ui-react";

import Notification from "domain/model/Notification";
import dayjs from "dayjs";

import routes from "utils/routes";

type props = {
  notifications: Notification[];
};

export const AgencyNotificationList: React.FC<props> = ({ notifications }) => {
  const history = useHistory();

  const notificationEditPath = routes.adminAgency.notificationEdit.pathname;

  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>タイトル</Table.HeaderCell>
          <Table.HeaderCell>投稿日時</Table.HeaderCell>
          <Table.HeaderCell>最終更新日時</Table.HeaderCell>
          <Table.HeaderCell>操作</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {notifications?.map((notification: Notification, index: number) => {
          return (
            <Table.Row key={index}>
              <Table.Cell>{notification.id}</Table.Cell>
              <Table.Cell>{notification.title}</Table.Cell>
              <Table.Cell>
                {dayjs(notification.createdAt).format("YYYY-MM-DD HH:mm")}
              </Table.Cell>
              <Table.Cell>
                {dayjs(notification.updatedAt).format("YYYY-MM-DD HH:mm")}
              </Table.Cell>
              <Table.Cell>
                {
                  <Button
                    icon
                    onClick={() =>
                      history.push(notificationEditPath, {
                        id: notification.id,
                        title: notification.title,
                        content: notification.content,
                      })
                    }
                  >
                    <Icon name="write" /> 編集
                  </Button>
                }
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
