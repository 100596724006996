import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Segment, Header, Form, Loader, Message } from "semantic-ui-react";
import MESSAGE from "config/message.json";
import { storeRepository } from "domain/repository";

const initialValue = {
  storeName: "",
  staffName: "",
  staffEmail: "",
  password: "",
  passwordConfirmation: "",
};

const validationSchema = Yup.object().shape({
  storeName: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  staffName: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  staffEmail: Yup.string()
    .email(MESSAGE.ERROR.INVALID_EMAIL)
    .required(MESSAGE.ERROR.NO_INPUT),
  password: Yup.string().required(MESSAGE.ERROR.NO_INPUT),
  passwordConfirmation: Yup.string()
    .required(MESSAGE.ERROR.NO_INPUT)
    .oneOf([Yup.ref("password")], "パスワードが一致していません。"),
});

type Props = {
  storeId: number | undefined;
  setStoreId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setStep: React.Dispatch<React.SetStateAction<0 | 1>>;
};

const StoreNewForm: React.FC<Props> = ({ storeId, setStoreId, setStep }) => {
  const isCreatedStore = storeId !== undefined;
  const [loading, setLoading] = useState(false);

  return (
    <Segment padded css={{ margin: "0 auto !important" }}>
      <Header content="店舗情報" dividing />
      <Formik
        initialValues={initialValue}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        onSubmit={(
          { storeName, staffName, staffEmail, password, passwordConfirmation },
          actions
        ) => {
          setLoading(true);
          storeRepository
            .createStore({
              storeName,
              staffName,
              staffEmail,
              password,
              passwordConfirmation,
              role: "normal",
            })
            .then(({ data }) => {
              setStoreId(data.store.id);
            })
            .catch(({ error }) => {
              if (error) {
                const { staff } = error;
                if (staff.email) {
                  actions.setFieldError(
                    "staffEmail",
                    MESSAGE.ERROR.STORE_NEW.ALREADY_USE_STAFF_EMAIL
                  );
                }
              } else {
                // TODO: 予期せぬエラーのフィードバック
              }
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {({ values, handleChange, handleSubmit, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Input
              label="店舗名"
              name="storeName"
              value={values.storeName}
              onChange={handleChange}
              error={errors.storeName}
            />
            <Form.Input
              label="スタッフ名"
              name="staffName"
              value={values.staffName}
              onChange={handleChange}
              error={errors.staffName}
            />
            <Form.Input
              label="スタッフメールアドレス"
              name="staffEmail"
              value={values.staffEmail}
              onChange={handleChange}
              error={errors.staffEmail}
            />
            <Form.Input
              label="パスワード"
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              error={errors.password}
            />
            <Form.Input
              label="パスワード（確認）"
              name="passwordConfirmation"
              type="password"
              value={values.passwordConfirmation}
              onChange={handleChange}
              error={errors.passwordConfirmation}
            />
            <div>
              <div css={{ textAlign: "center" }}>
                <Form.Button
                  type="submit"
                  color="blue"
                  content="店舗作成"
                  disabled={isCreatedStore || loading}
                />
              </div>
              <div css={{ textAlign: "right" }}>
                <Form.Button
                  icon="right arrow"
                  labelPosition="right"
                  color="blue"
                  content="オプション選択へ"
                  disabled={!isCreatedStore}
                  onClick={() => setStep(1)}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Loader active={loading} content="作成中"></Loader>
      {isCreatedStore && (
        <Message
          info
          header="店舗を作成しました"
          content="オプション選択へ進んでください"
        />
      )}
    </Segment>
  );
};

export default StoreNewForm;
