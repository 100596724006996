import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";
import StoreProfile from "domain/model/Instagram";

class InstagramService {
  public fetchStoreProfile({
    isAgency,
    storeId,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
  }): Promise<StoreProfile> {
    const url =
      isAgency && storeId
        ? endpoint.agency.instagram.storeProfile(storeId)
        : endpoint.store.instagram.storeProfile();

    return api.get(url).then((res) => {
      return plainToClass(StoreProfile, res.data, {
        excludeExtraneousValues: true,
      });
    });
  }
}

export const instagramService = new InstagramService();
