import React from "react";
import { useParams } from "react-router-dom";

import { Segment, Loader, Header } from "semantic-ui-react";
import PageContainer from "components/lib/PageContainer";
import NotValidOption from "components/SiteManage/Content/lib/NotValidOption";
import OptionStatus from "components/lib/OptionStatus";

import { recruitRepository } from "domain/repository";

import { UserContainer, StoreContainer } from "containers";

import { AGENCY_OPTION, StoreIdParam } from "config/raven";
import { StoreBreadcrumbBuilder } from "utils/breadcrumb";
const AGENCY: AGENCY_OPTION = require("config/agency.json");

const Container: React.FC = () => {
  const userContainer = UserContainer.useContainer();
  const storeContainer = StoreContainer.useContainer();
  const { isAgency } = userContainer;
  const { currentStore, changeCurrentStore } = storeContainer;
  const { storeId } = useParams<StoreIdParam>();

  const { hostname } = window.location;
  const agencyEnabled = AGENCY[hostname].options.recruit;

  const handleClickEnable = () => {
    if (!storeId) {
      return;
    }
    recruitRepository
      .enable(isAgency, storeId)
      .then(() => changeCurrentStore(isAgency, storeId));
  };

  if (!agencyEnabled) {
    return <NotValidOption heading="求人管理" />;
  }

  if (!currentStore) {
    return <Loader active />;
  }

  return (
    <>
      <Header content={`求人管理オプション利用状況`} dividing />
      <OptionStatus
        enabled={currentStore.property.useRecruit}
        toEnable={handleClickEnable}
        isAgency={isAgency}
      />
    </>
  );
};

const Recruit = () => {
  const { isAgency } = UserContainer.useContainer();
  const { storeId } = useParams<StoreIdParam>();

  return (
    <PageContainer
      header="求人管理"
      breadcrumbs={new StoreBreadcrumbBuilder(isAgency, storeId)
        .siteManagePage()
        .build("求人管理")}
    >
      <Segment padded>
        <Container />
      </Segment>
    </PageContainer>
  );
};

export default Recruit;
