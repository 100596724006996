import { useState, useEffect } from "react";
import { jobOfferService } from "domain/service";

/**
 * 求人管理機能が有効かどうかを取得する
 * true: 有効
 * false: 無効
 * null: 情報取得中
 */
export const useIsJobOfferEnabled = ({
  isAgency,
  storeId,
}: {
  isAgency: boolean;
  storeId: string | undefined;
}) => {
  const [isJobOfferEnabled, setIsJobOfferEnabled] = useState<boolean | null>(
    null
  );
  useEffect(() => {
    jobOfferService.isEnable(isAgency, storeId).then((isEnabled) => {
      setIsJobOfferEnabled(isEnabled);
    });
  }, [isAgency, storeId]);

  return isJobOfferEnabled;
};
