export const WRITABLE_DNS_RECORD_TYPE = {
  A: "A",
  AAAA: "AAAA",
  CAA: "CAA",
  CNAME: "CNAME",
  MX: "MX",
  NAPTR: "NAPTR",
  PTR: "PTR",
  SPF: "SPF",
  SRV: "SRV",
  TXT: "TXT",
} as const;

export const READONLY_DNS_RECORD_TYPE = {
  NS: "NS",
  SOA: "SOA",
} as const;

export const DNS_RECORD_TYPE = {
  ...WRITABLE_DNS_RECORD_TYPE,
  ...READONLY_DNS_RECORD_TYPE,
};

export type DNSRecordType =
  (typeof DNS_RECORD_TYPE)[keyof typeof DNS_RECORD_TYPE];
