import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";

import Notification from "domain/model/Notification";

class NotificationService {
  public async fetchNotifications(isAgency: boolean): Promise<Notification[]> {
    return api
      .get(endpoint[isAgency ? "agency" : "store"].notification.root())
      .then((res) => {
        return res.data.map((ele: Notification) =>
          plainToClass(Notification, ele, { excludeExtraneousValues: true })
        );
      });
  }
}

export default new NotificationService();
