import api from "utils/api";
import endpoint from "utils/endpoint";

class OrderRepository {
  async changeStatusToProcessing(
    isAgency: boolean,
    storeId: string | undefined,
    id: string
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.order.toProcessing(storeId, id)
        : endpoint.store.order.toProcessing(id);

    return api.patch(
      url,
      {},
      { headers: { "Content-Type": "application/json" } }
    );
  }

  async changeStatusToCompleted(
    isAgency: boolean,
    storeId: string | undefined,
    id: string
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.order.toCompleted(storeId, id)
        : endpoint.store.order.toCompleted(id);

    return api.patch(url);
  }

  async changeStatusToCancel(
    isAgency: boolean,
    storeId: string | undefined,
    id: string
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.order.toCancel(storeId, id)
        : endpoint.store.order.toCancel(id);

    return api.patch(url);
  }
}

export default new OrderRepository();
