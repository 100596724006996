import { Type, Expose } from "class-transformer";

export class Version {
  @Expose({ name: "new_record" }) newRecord: boolean;

  @Type(() => VersionData)
  @Expose()
  data: VersionData;
}

export class VersionData {
  @Expose() filepaths: [];
  @Expose({ name: "created_at" }) createdAt: string;
  @Expose({ name: "version" }) versionHash: string;
  @Expose() title: string;
  @Expose({ name: "flatten_tree" }) flattenTree: [
    {
      path: string;
      endpoint: string;
    }
  ];

  @Type(() => Metadata)
  @Expose()
  metadata: Metadata;
}

class Metadata {
  @Expose({ name: "committed_by" }) committedBy: string;
}

export class Source {
  @Expose() context: { source: string };
  @Expose() error: string;
  @Expose() faild: boolean;
  @Expose() succeeded: boolean;
  @Expose() status: number;
}
