import React from "react";

import { DocumentWysiwygEditor } from "components/lib/Editor/DocumentWysiwygEditor";

import { Segment } from "semantic-ui-react";

type Props = {
  title: string;
  content: string;
};

export const StoreNotificationContent: React.FC<Props> = ({
  title,
  content,
}) => {
  return (
    <Segment>
      <label>タイトル</label>
      <Segment>{title}</Segment>
      <div css={{ marginBottom: "1rem" }}>
        <label>内容</label>
      </div>
      <DocumentWysiwygEditor readOnly data={content} onChange={() => {}} />
    </Segment>
  );
};
