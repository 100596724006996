import { useEffect, useState } from "react";
import { seoService } from "domain/service";
import { SeoPreferences } from "domain/model/SeoPreferences";

/**
 * デフォルトSEOを取得する
 * seoPreferences: デフォルトSEO設定
 */
export const useSeoPreferences = ({
  isAgency,
  storeId,
}: {
  isAgency: boolean;
  storeId: string | undefined;
}) => {
  const [seoPreferences, setSeoPreferences] = useState<SeoPreferences | null>(
    null
  );
  useEffect(() => {
    const fetchSeoPreferences = () => {
      seoService.fetchPreferences(isAgency, storeId).then((res) => {
        setSeoPreferences(res);
      });
    };
    fetchSeoPreferences();
  }, [isAgency, storeId]);
  return { seoPreferences };
};
