import React from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";

import {
  Message,
  List,
  Label,
  Header,
  Segment,
  Form,
  Input,
  Popup,
  Grid,
} from "semantic-ui-react";

import routes from "utils/routes";
import { CurrentStore } from "config/raven";
import { storeRepository } from "domain/repository";
import DeleteStoreModal from "components/StoreProfile/DeleteStoreModal";

type Props = {
  currentStore: CurrentStore;
  isAgency: boolean;
  isAdmin: boolean;
  storeAccessKey: string;
};

const StoreProfileForm: React.FC<Props> = ({
  currentStore,
  isAgency,
  isAdmin,
  storeAccessKey,
}) => {
  const history = useHistory();

  if (!currentStore) {
    return <></>;
  }

  const handleDelete = () => {
    storeRepository
      .delete(currentStore.id)
      .then(() => {
        history.push(routes.adminAgency.storeIndex.pathname);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Formik
      initialValues={{
        id: currentStore.id,
        name: currentStore.name,
        email: currentStore.email,
        tel: currentStore.tel,
        fax: currentStore.fax,
        lineAccessToken: currentStore.hasLineAccessToken ? "**********" : "",
        property: {
          takeoutMinimumLeadTime: currentStore.property.takeoutMinimumLeadTime,
          takeoutStripeSecret: currentStore.property.hasTakeoutStripeSecret
            ? "**********"
            : "",
        },
        editLineAccessToken: false,
        editTakeoutStripeSecret: false,
      }}
      initialStatus={{ success: "", error: "" }}
      onSubmit={(values, actions) => {
        storeRepository
          .updateProfile(isAgency, currentStore.id, values)
          .then(() =>
            actions.setStatus({ success: "サイト情報を更新しました" })
          )
          .catch(() => actions.setStatus({ error: "更新に失敗しました" }));
      }}
    >
      {({ values, status, handleChange, handleSubmit, setFieldValue }) => (
        <Segment padded>
          {status.success && <Message success content={status.success} />}
          {status.error && <Message error content={status.error} />}
          <div css={{ marginBottom: "2rem" }}>
            <List horizontal>
              <List.Item>
                <Label
                  content="awsステータス"
                  horizontal
                  color={
                    currentStore.awsState === "setup_completed"
                      ? "green"
                      : "red"
                  }
                />{" "}
                {currentStore.awsState === "setup_completed"
                  ? "認証済み"
                  : "未認証"}
              </List.Item>
              <List.Item>
                <Label horizontal content="ドメイン" color="blue" />{" "}
                {currentStore.awsResource.homepageDomain}
              </List.Item>
            </List>
          </div>
          <Header content="店舗プロフィール" dividing />
          <Form>
            <Form.Field>
              <label>店舗ID</label>
              <input name="id" value={values.id} disabled />
            </Form.Field>
            <Form.Field>
              <label>店舗名</label>
              <input
                name="name"
                value={values.name}
                onChange={handleChange}
                disabled={false}
              />
            </Form.Field>
            <Form.Field>
              <label>店舗メールアドレス</label>
              <input
                name="email"
                value={values.email}
                onChange={handleChange}
                disabled={false}
              />
            </Form.Field>
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <label>店舗電話番号</label>
                    <input
                      name="tel"
                      value={values.tel}
                      onChange={handleChange}
                      disabled={false}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>店舗FAX番号</label>
                    <input
                      name="fax"
                      value={values.fax || ""}
                      onChange={handleChange}
                      disabled={false}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <label>最短提供時間（分）</label>
                    <input
                      name="property.takeoutMinimumLeadTime"
                      type="number"
                      value={values.property.takeoutMinimumLeadTime || ""}
                      onChange={handleChange}
                      disabled={false}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column></Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid columns="equal">
              <Grid.Row css={{ marginBottom: "16px" }}>
                <Grid.Column>
                  <Form.Field>
                    <label>LINE Notify（LINE アクセストークン）</label>
                    {values.editLineAccessToken ? (
                      <input
                        name="lineAccessToken"
                        value={values.lineAccessToken}
                        onChange={handleChange}
                      />
                    ) : (
                      <DisabledSecretField>
                        <span>{values.lineAccessToken}</span>
                        <EnableSecretField
                          onClick={() => {
                            values.lineAccessToken = "";
                            setFieldValue("editLineAccessToken", true);
                          }}
                        >
                          編集
                        </EnableSecretField>
                      </DisabledSecretField>
                    )}
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Stripe Secret</label>
                    {values.editTakeoutStripeSecret ? (
                      <input
                        name="property.takeoutStripeSecret"
                        value={values.property.takeoutStripeSecret}
                        onChange={handleChange}
                      />
                    ) : (
                      <DisabledSecretField>
                        <span>{values.property.takeoutStripeSecret}</span>
                        <EnableSecretField
                          onClick={() => {
                            values.property.takeoutStripeSecret = "";
                            setFieldValue("editTakeoutStripeSecret", true);
                          }}
                        >
                          編集
                        </EnableSecretField>
                      </DisabledSecretField>
                    )}
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {isAgency && (
              <Popup
                content="クリックでコピー"
                inverted
                position="top right"
                trigger={
                  <Input
                    fluid
                    label="アクセスキー"
                    value={storeAccessKey}
                    icon="clipboard"
                    onClick={() => document.execCommand("copy")}
                  />
                }
              />
            )}
            <div css={{ textAlign: "center" }}>
              <Form.Button
                content="更新"
                color="blue"
                onClick={() => handleSubmit()}
              />
            </div>
          </Form>
          <DeleteStoreModal
            isAdmin={isAdmin}
            currentStore={currentStore}
            isAgency={isAgency}
            handleDelete={handleDelete}
          />
        </Segment>
      )}
    </Formik>
  );
};

const DisabledSecretField = styled.div`
  cursor: not-allowed;
  background: #eee;
  height: 2.8em;
  line-height: 2.8em;
  border-radius: 3px;
  border: 1px solid #ddd;
  padding: 0 1em;
  color: #333;
  position: relative;
`;

const EnableSecretField = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  font-weight: bolder;
  font-size: 1rem;
  background: #aaa;
  color: #fff;
  height: 2em;
  line-height: 2em;
  padding: 0 1.2em;
  border-radius: 3px;
  transition: all 0.25s ease;

  :hover {
    background: #555;
  }
`;

export default StoreProfileForm;
