import api from "utils/api";
import endpoint from "utils/endpoint";
import { plainToClass } from "class-transformer";

import Applicant from "domain/model/JobOffer/Applicant";
import Form from "domain/model/JobOffer/Form";
import FormDetails from "domain/model/JobOffer/FormDetails";

class JobOfferService {
  public async isEnable(isAgency: boolean, storeId: string | undefined) {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobOffer.use(storeId)
        : endpoint.store.jobOffer.use();
    return api.get(url).then((res) => res.data.use_recruit);
  }

  /**
   * 応募者情報を最大で20件取得する
   */
  async fetchApplicants(
    isAgency: boolean,
    storeId: string | undefined,
    createdAt?: number
  ) {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobOffer.root(storeId)
        : endpoint.store.jobOffer.root();
    return api
      .get(url, {
        params: {
          created_at: createdAt,
        },
      })
      .then((res: any) =>
        res.data.applicants.map((d: Applicant) =>
          plainToClass(Applicant, d, { excludeExtraneousValues: true })
        )
      );
  }
  async fetchForms(isAgency: boolean, storeId: string | undefined) {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobOffer.forms(storeId)
        : endpoint.store.jobOffer.forms();
    return api
      .get(url)
      .then((res) =>
        res.data.forms.map((d: Form) =>
          plainToClass(Form, d, { excludeExtraneousValues: true })
        )
      );
  }

  fetchFormDetails({
    isAgency,
    storeId,
    formId,
  }: {
    isAgency: boolean;
    storeId: string | undefined;
    formId: string;
  }) {
    const url =
      isAgency && storeId
        ? endpoint.agency.jobOffer.form(storeId, formId)
        : endpoint.store.jobOffer.form(formId);

    return api.get(url).then((res) =>
      plainToClass(FormDetails, res.data.form, {
        excludeExtraneousValues: true,
      })
    );
  }
}

export default new JobOfferService();
