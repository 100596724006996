import React, { useState } from "react";
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Message,
} from "semantic-ui-react";
import MESSAGE from "config/message.json";
import { generatePath, useHistory } from "react-router-dom";
import routes from "utils/routes";
import { Formik } from "formik";
import * as Yup from "yup";
import { yamlRepository } from "domain/repository";

type Props = {
  isAgency: boolean;
  storeId: string;
};

export const NewFormContainer: React.FC<Props> = ({ isAgency, storeId }) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().min(1, "必須項目です").required(),
    slug: Yup.string()
      .required("必須項目です")
      .matches(/^[a-z\d_]+$/, "不正な文字が使われています"),
    parent: Yup.string().matches(
      /(^http.*\.(yml|yaml)$|^$)/,
      ".yml, .yamlで終わるURLを指定してください"
    ),
    body: Yup.string().min(1, "必須項目です").required(),
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const yamlIndexUrl = isAgency
    ? generatePath(routes.agency.yamlIndex.pathname, {
        storeId,
      })
    : routes.store.yamlIndex.pathname;
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        title: "",
        slug: "",
        parent: "",
        body: "",
        active: true,
      }}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
      onSubmit={(
        { title, slug, parent, body, active },
        { setFieldValue, setFieldError, resetForm, setSubmitting }
      ) => {
        setErrorMessage(null);
        yamlRepository
          .createYaml(isAgency, storeId, {
            title,
            slug,
            parent,
            body,
            active,
          })
          .then(() => {
            resetForm();
            history.push(yamlIndexUrl);
          })
          .catch((e: string) => {
            setErrorMessage(`${MESSAGE.ERROR.YAML.NEW_ERROR} (${e})`);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        isSubmitting,
        errors,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div
            css={{
              margin: "16px 0",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              css={{ margin: "0 16px 0 0 !important" }}
              positive
              icon="pencil alternate"
              content="下書き保存"
              onClick={() => {
                setFieldValue("active", false);
              }}
              type="submit"
              loading={isSubmitting}
            />
            <Button
              positive
              icon="paper plane"
              content="公開"
              onClick={() => {
                setFieldValue("active", true);
              }}
              type="submit"
              loading={isSubmitting}
            />
          </div>
          {errorMessage ? (
            <Message negative content={errorMessage} size="small" />
          ) : null}

          <>
            <Message>
              タイトル・スラグ・親YAML・データ構造は後から変更できません。ご注意ください。
            </Message>
            <Form.Field required>
              <label>タイトル</label>
              <Form.Input
                name="title"
                error={errors && errors.title}
                value={values.title}
                onChange={handleChange}
                placeholder="管理画面上で管理しやすいように任意のタイトルを設定してください"
              />
            </Form.Field>
            <Form.Field required>
              <label>スラグ</label>
              <Form.Input
                name="slug"
                error={errors && errors.slug}
                value={values.slug}
                onChange={handleChange}
                placeholder="他のYAMLと重複しない値を指定してください（使用可能文字: a-b 0-9 _ ）"
              />
              {errors && errors.slug && (
                <Message color="red">
                  使用できない文字が含まれています（使用可能文字: a-b 0-9 _ ）
                </Message>
              )}
            </Form.Field>
            <Form.Field>
              <label>親YAML</label>
              <Form.Input
                name="parent"
                error={errors && errors.parent}
                value={values.parent}
                onChange={handleChange}
                placeholder="https://..."
              />
            </Form.Field>
            <Divider horizontal css={{ margin: "2rem 0!important" }}>
              <Header as="h4">
                <Icon name="file alternate" />
                YAMLデータ
              </Header>
            </Divider>
            <Form.Field>
              <Form.TextArea
                name="body"
                error={errors && errors.body}
                value={values.body}
                onChange={handleChange}
                rows={20}
              />
            </Form.Field>
          </>
        </Form>
      )}
    </Formik>
  );
};
