import { useEffect, useState } from "react";
import { articleService } from "domain/service";

const NON_SUB_CATEGORY_VALUE = "NONE_CATEGORY";

/**
 * 記事管理のカテゴリを取得する
 * subCategories: 「なし」を含むカテゴリの
 *                nameとvalueの情報を持つ配列(ドロップダウンリストで使われることを想定している)
 * NON_SUB_CATEGORY_VALUE: 選択肢「なし」にあたる値
 */
export const useArticleSubCategory = ({
  isAgency,
  storeId,
}: {
  isAgency: boolean;
  storeId: string | undefined;
}) => {
  const [subCategories, setSubCategories] = useState<
    { name: string; value: string }[]
  >([{ name: "なし", value: NON_SUB_CATEGORY_VALUE }]);
  useEffect(() => {
    const fetchSubCategory = () => {
      articleService.fetchCategory(isAgency, storeId).then((res) => {
        setSubCategories((currentState) => [
          ...currentState,
          ...res.map((c) => ({
            name: c.categoryName,
            value: c.categoryValue,
          })),
        ]);
      });
    };
    fetchSubCategory();
  }, [isAgency, storeId]);
  return { subCategories, NON_SUB_CATEGORY_VALUE };
};
