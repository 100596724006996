import React from "react";
import { useField } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { Message, Form } from "semantic-ui-react";

registerLocale("ja", ja);

type Props = {
  label: string;
  name: string;
};

export const DateTimePickerForm: React.FC<Props> = ({ label, name }) => {
  const [{ value }, { error }, { setValue }] = useField(name);

  return (
    <Form.Field error={!!error}>
      <label>{label}</label>
      <DatePicker
        onChange={(date: Date) => {
          setValue(date);
        }}
        selected={value}
        showTimeSelect
        dateFormat="yyyy/MM/dd hh:mm aa"
        locale="ja"
        maxDate={dayjs().toDate()}
      ></DatePicker>
      {error && <Message negative size="tiny" content={error} attached="top" />}
    </Form.Field>
  );
};

export const DateTimePickerFormForMonth: React.FC<Props> = ({
  label,
  name,
}) => {
  const [{ value }, { error }, { setValue }] = useField(name);

  return (
    <Form.Field error={!!error}>
      <label>{label}</label>
      <DatePicker
        onChange={(date: Date) => {
          setValue(date);
        }}
        selected={value}
        showMonthYearPicker
        showFullMonthYearPicker
        dateFormat="yyyy/MM"
        locale="ja"
        maxDate={dayjs().toDate()}
      ></DatePicker>
      {error && <Message negative size="tiny" content={error} attached="top" />}
    </Form.Field>
  );
};
